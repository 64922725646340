import { offerIncludesOverrideOrderRule } from '../offers';
import { Cart } from './cart.types';
import { cartHasInactiveOffers } from './cart.utils';
import { Offer } from '@box-types';

export {
  syncOfferCartQuantityWithCart,
  getCartOfferBeginPrice,
  getCartOfferFinalPrice,
  cartOverridesOrderMinimumPrice,
  getCartOfferMinOrderItemsPriceText,
  decorateOfferWithInactiveText,
  getCartInactiveOffers,
  getCartInactiveOffersMessage
};

function syncOfferCartQuantityWithCart(offer: Offer, cart: Cart): Offer {
  const cartOffers = cart.offers;
  const cartOffer = cartOffers.find((of) => of._id === offer._id);
  if (!cartOffer) return { ...offer, cartQuantity: 0 };
  return { ...offer, cartQuantity: cartOffer.cartQuantity };
}

function getCartOfferBeginPrice(offer: Offer): number {
  const instances = offer.cartInstances;
  if (!instances?.length) return 0;
  return instances.reduce((price, instance) => price + instance.quantity * instance.preOfferPrice, 0);
}

function getCartOfferFinalPrice(offer: Offer): number {
  const instances = offer.cartInstances;
  if (!instances?.length) return 0;
  return instances.reduce((price, instance) => price + instance.quantity * instance.price, 0);
}

function cartOverridesOrderMinimumPrice(cart: Cart): boolean {
  const offers = cart.offers;
  return offers.some((offer) => offerIncludesOverrideOrderRule(offer, 'minimumPrice'));
}

function getCartOfferMinOrderItemsPriceText(offer: Offer, cartPrice: number): string {
  const { minOrderItemsPrice } = offer;
  if (!minOrderItemsPrice || minOrderItemsPrice <= cartPrice) return;
  const formatOptions = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 };
  const remainingPrice = minOrderItemsPrice - cartPrice;
  const minOrderPrice = new Intl.NumberFormat('el-GR', formatOptions).format(remainingPrice / 100).replace(/\s/g, '');
  return `Για την απόκτηση της προσφοράς χρειάζονται ${minOrderPrice}`;
}

function decorateOfferWithInactiveText(offer: Offer, cart: Cart): Offer {
  const inactiveText = getCartOfferMinOrderItemsPriceText(offer, cart.itemsFinalPrice);
  if (!inactiveText) return offer;
  return { ...offer, inactiveText };
}

function getCartInactiveOffers(cart: Cart): Offer[] {
  if (!cartHasInactiveOffers(cart)) return [];
  return cart.offers.filter((offer) => offer.minOrderItemsPrice > cart.itemsFinalPrice);
}

function getCartInactiveOffersMessage(inactiveOffers: Offer[]): string {
  if (!inactiveOffers.length) return;
  if (inactiveOffers.length === 1) {
    const inactiveOfferName = `"${inactiveOffers[0].name}"`;
    return `Θα συνεχίσεις την παραγγελίας σου χωρίς την προσφορά ${inactiveOfferName} γιατί δεν κάλυψες την ελάχιστη παραγγελία, να προχωρήσουμε;`;
  }
  const inactiveOfferNames = inactiveOffers.map((offer) => `"${offer.name}"`).join(', ');
  return `Θα συνεχίσεις την παραγγελίας σου χωρίς τις προσφορές ${inactiveOfferNames} γιατί δεν κάλυψες την ελάχιστη παραγγελία, να προχωρήσουμε;`;
}
