<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="Δημιουργία νέας κάρτας" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-create-dialog-content" [formGroup]="createFormGroup">
      <div class="market-card-create-dialog-image-container">
        <figure class="market-card-create-dialog-image">
          <img [src]="logo" [alt]="logoAlt" />
        </figure>
      </div>

      <ng-container *ngIf="validationMode === 'EMAIL'; else pinotp">
        <p class="market-card-create-dialog-text ods-typography-body-s-regular">
          Πληκτρολόγησε το email με το οποίο θέλεις να δημιουργηθεί η νέα {{ shopName }} {{ cardName }} σου.
        </p>
      </ng-container>
      <ng-template #pinotp>
        <p class="market-card-create-dialog-text ods-typography-body-s-regular">
          Πληκτρολόγησε τον αριθμό του κινητού τηλεφώνου με τον οποίο θέλεις να δημιουργηθεί η νέα {{ shopName }}
          {{ cardName }} σου.
        </p>
      </ng-template>

      <ng-container *ngIf="validationMode === 'EMAIL'; else pinotpinput">
        <mat-form-field class="market-card-create-dialog-field">
          <mat-label>e-mail</mat-label>
          <input
            #mobileNumber
            matInput
            autocomplete="off"
            name="mobileNumber"
            (keydown.enter)="onCreate()"
            formControlName="inputControl"
          />
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.required"
            >Το πεδίο Email είναι υποχρεωτικό
          </mat-error>
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.pattern">Μη έγκυρο Email</mat-error>
        </mat-form-field>
      </ng-container>
      <ng-template #pinotpinput>
        <mat-form-field class="market-card-create-dialog-field">
          <mat-label>Κινητό τηλέφωνο</mat-label>
          <input
            #mobileNumber
            matInput
            autocomplete="off"
            name="mobileNumber"
            (keydown.enter)="onCreate()"
            formControlName="inputControl"
          />
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.required"
            >Το τηλέφωνο επικοινωνίας είναι υποχρεωτικό
          </mat-error>
          <mat-error *ngIf="createFormGroup.controls.inputControl.errors?.invalidPhone"
            >Το τηλέφωνο επικοινωνίας δεν είναι σωστό
          </mat-error>
        </mat-form-field>
      </ng-template>

      <mat-checkbox
        *ngIf="saveCardTermsText"
        class="market-card-create-dialog-checkbox"
        color="primary"
        formControlName="saveCardTermsControl"
      >
        <span class="market-card-create-dialog-checkbox-content ods-typography-body-s-regular">{{
          saveCardTermsText
        }}</span>
      </mat-checkbox>

      <button
        box-button
        theme="primary"
        class="market-card-create-dialog-button"
        [disabled]="createFormGroup.invalid"
        (click)="onCreate()"
      >
        Συνέχεια
      </button>
    </div>
  </main>
</div>
