import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData, ConfirmDialogResponse } from '@box-types';

@Component({
  selector: 'box-confirm-dialog',
  templateUrl: './box-confirm-dialog.component.html',
  styleUrls: ['./box-confirm-dialog.component.scss']
})
export class BoxConfirmDialogComponent implements OnInit {
  public title: string;
  public messages: string[];
  public confirmText: string;
  public cancelText: string;
  public buttonOrientation: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData,
    private dialogRef: MatDialogRef<BoxConfirmDialogComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.messages = this.data.messages ?? ([] as string[]);
    this.confirmText = this.data.confirmText ?? 'Ναι';
    this.cancelText = this.data.cancelText ?? 'Όχι';
    this.buttonOrientation = this.data.buttonOrientation ?? 'horizontal';
  }

  public closeDialog(response?: ConfirmDialogResponse): void {
    this.dialogRef.close(response);
  }
}
