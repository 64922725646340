<div class="checkout-order-points-content" *ngIf="showDetails">
  <mat-expansion-panel
    class="checkout-order-points-content-panel checkout-order-points-content-expansion-panel"
    expanded="true"
  >
    <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
      <mat-panel-title>
        <p class="checkout-order-points-content-panel-title ods-typography-body-m-bold">Ανάλυση πόντων</p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <checkout-synergy-banner *ngFor="let banner of transactionBanners" class="checkout-order-points-details-banner">
      <img [src]="banner.image" />
      <points-tag class="ods-typography-body-s-bold" sign="+">{{ banner.points | number: '1.0-0' }}</points-tag>
      <span class="checkout-order-points-details-banner-text dynamic-text">{{
        banner.orderAcceptanceDescription
      }}</span>
    </checkout-synergy-banner>
  </mat-expansion-panel>
</div>
