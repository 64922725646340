import { HomeSection } from './home.types';
import { Shop, Timeslot, FilterItemShopsExtraOptions } from '@box-types';
import { orderBy, shuffle } from 'lodash-es';
import { getShopAverageSortingWeight, removeShopFromBucket, sortShopSortingGroup } from '../shops';
import { isShopEligibleForPromoCampaign } from '../promo-campaigns';
import { getOutputBlockHtml } from '../block-editor';
import { filterItemShops } from '../core';

export {
  normalizeHomeSection,
  sortHomeSectionShops,
  showHomeSectionInfo,
  getHomeSectionInfoHtml,
  filterHomeSectionShops
};

function normalizeHomeSection(homeSection: HomeSection): HomeSection {
  const buttonTitle = homeSection.buttonTitle ?? 'Δες όλα τα καταστήματα';
  const minimumShopsNumber = homeSection.minimumShopsNumber ?? 0;
  const maximumShopsNumber = homeSection.maximumShopsNumber ?? 30;
  const shopViewType = homeSection.shopViewType ?? 'COMPACT';
  return { ...homeSection, minimumShopsNumber, maximumShopsNumber, buttonTitle, shopViewType };
}

/**
 * sortHomeSectionShops will sort the Home Section Shops by the below rules:
 *
 * The first and second place are reserved for the current bucket top 2 Shops (by average weight).
 *
 * The third place is reserved for a randomly picked boosted shop. If there are no boosted shops,
 * then we pick the top Explore shop (by average weight). If there are no Explore shops, we pick
 * the next top shop (by average weight).
 *
 * The fourth place is reserved for a randomly picked new boosted shop. If there are no new boosted
 * shops, then we pick a random new shop. If there are no new shops, then we pick the top Explore
 * shop (by average weight). If there are no Explore shops, we pick the next top shop (by average weight).
 *
 * We then shuffle the shops. If the reserved item for the fourth place is a new shop, then we should
 * show it only on either the third or fourth place.
 *
 * The above process will repeat until the results length reaches the `homeSection.maximumShopsNumber`
 * number, or there are no more shops to pick from.
 * The sortingMethod will be ignored only if the `homeSection.displayRecentOrderShops` is `true`. On that
 * occurrence, we return the shops as provided since they are already sorted by the latest order. For
 * reference see `filterFrequentShops`.
 *
 * @param {HomeSection} homeSection the Home Section that we want to get the Shops for
 * @param {Shop[]} shops the filtered Shops for the provided Home Section. IMPORTANT: This function requires
 * all the shops provided to have the isNew property filled and also to have been decorated with the
 * active PromoCampaigns.
 * @returns {Shop[]} an array of sorted Shops, with a maximum length equal or less than `homeSection.maximumShopsNumber`
 * */
function sortHomeSectionShops(homeSection: HomeSection, shops: Shop[], timeslot?: Timeslot): Shop[] {
  if (!shops.length) return [];
  const { maximumShopsNumber, displayRecentOrderShops, sortingMethod } = homeSection;
  if (displayRecentOrderShops) return shops;
  const shuffledShops = shuffle(shops);
  if (sortingMethod === 'RANDOM') return shuffledShops;

  const shopsBucket = orderBy(shuffledShops, (shop) => getShopAverageSortingWeight(shop, timeslot), 'desc');
  const boostedNewBucket = shuffledShops.filter((shop) => shop.isBoosted && shop.isNew);
  const boostedBucket = shuffledShops.filter((shop) => shop.isBoosted);
  const newBucket = shuffledShops.filter((shop) => shop.isNew);
  const exploreShops = shopsBucket.filter((shop) => isShopEligibleForPromoCampaign(shop, 'new_users'));
  const sectionShops: Shop[] = [];

  const removeShopFromBuckets = (shop: Shop) => {
    removeShopFromBucket(shop, shopsBucket);
    removeShopFromBucket(shop, boostedBucket);
    removeShopFromBucket(shop, boostedNewBucket);
    removeShopFromBucket(shop, newBucket);
    removeShopFromBucket(shop, exploreShops);
  };

  const shopsGroupSize = 4;
  const numberOfTopShops = 2;

  for (let sectionShopsNumber = 0; sectionShopsNumber < maximumShopsNumber; sectionShopsNumber += shopsGroupSize) {
    const shopsGroup: Shop[] = [];

    for (let topShopCounter = 0; topShopCounter < numberOfTopShops; topShopCounter++) {
      const topShop = shopsBucket[0];
      if (!topShop) continue;
      shopsGroup.push(topShop);
      removeShopFromBuckets(topShop);
    }

    const firstReservedShop = boostedBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (firstReservedShop) {
      shopsGroup.push(firstReservedShop);
      removeShopFromBuckets(firstReservedShop);
    }

    const secondReservedShop = boostedNewBucket[0] ?? newBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (secondReservedShop) {
      removeShopFromBuckets(secondReservedShop);
      shopsGroup.push(secondReservedShop);
    }

    /**
     * if the New shop was inserted into the 3rd plave, then we need to shuffle all the items and find a random
     * place for the New shop between the 3rd and 5th place. If not, then we just shuffle the whole group
     * */
    const secondReservedShopGroupIndex = secondReservedShop ? 2 : undefined;
    const sortedShopsGroup = sortShopSortingGroup(shopsGroup, secondReservedShopGroupIndex);
    sectionShops.push(...sortedShopsGroup);
    /**
     * If we have no more shops to choose from before we have reached the maximumShopsNumber, we should
     * terminate the process
     * */
    if (shopsBucket.length === 0) break;
  }

  return sectionShops;
}

function showHomeSectionInfo(homeSection: HomeSection): boolean {
  const { title, info } = homeSection;
  if (!title) return false;
  if (!info?.blocks?.length) return false;
  return true;
}

function getHomeSectionInfoHtml(homeSection: HomeSection): string {
  if (!homeSection.info?.blocks?.length) return '';
  return homeSection.info.blocks.map((block) => getOutputBlockHtml(block)).join('');
}

function filterHomeSectionShops(
  homeSection: HomeSection,
  shops: Shop[],
  extraOptions?: FilterItemShopsExtraOptions
): Shop[] {
  const { shopViewType } = homeSection;
  const validShops = shopViewType === 'DETAILED' ? shops.filter((shop) => Boolean(shop.mobileImage)) : shops;
  return filterItemShops(homeSection, validShops, extraOptions);
}
