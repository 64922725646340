import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CheckoutBagsConfig } from '@box-types';

@Injectable()
export class CheckoutBagsService {
  constructor(private decimalPipe: DecimalPipe) {}

  public getBagsQuantity(bagsConfig: CheckoutBagsConfig): number {
    if (!bagsConfig) return 0;
    if (bagsConfig.dontCalculate) {
      return bagsConfig.bagsQuantity > 0 ? bagsConfig.bagsQuantity : 0;
    }
    if (bagsConfig.cartQuantity <= 0) return 0;
    return Math.ceil((bagsConfig.cartQuantity ?? 0) / 8);
  }

  public getBagsTotalPrice(bagsConfig: CheckoutBagsConfig): number {
    if (!bagsConfig) return 0;
    if (bagsConfig.plasticBagPrice <= 0) return 0;
    return this.getBagsQuantity(bagsConfig) * bagsConfig.plasticBagPrice;
  }

  public getBagsDescription(bagsConfig: CheckoutBagsConfig): string {
    if (!bagsConfig) return '';
    const bagsQuantity = this.getBagsQuantity(bagsConfig);
    if (!bagsQuantity || bagsQuantity <= 0) return '';
    const bagsText = bagsQuantity === 1 ? 'σακούλα' : 'σακούλες';
    const priceText = this.decimalPipe.transform((bagsConfig.plasticBagPrice ?? 0) / 100, '1.2-2');
    return `${bagsQuantity} ${bagsText} (περιβαλλοντικό τέλος ${priceText}€ / σακούλα)`;
  }
}
