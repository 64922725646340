import { User } from '@box-types';

function getUserPointsBalanceText(user: User): string {
  const remainingPoints = user.marketPlacePoints.remainingPoints;
  return new Intl.NumberFormat('el-GR', { style: 'decimal' }).format(remainingPoints);
}

function getUserCurrencyBalanceText(user: User, redeemCurrencyRate: number): string {
  const remainingPoints = user.marketPlacePoints.remainingPoints;
  const currencyAmount = remainingPoints / redeemCurrencyRate;
  const formatOptions = { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 };
  return new Intl.NumberFormat('el-GR', formatOptions).format(currencyAmount);
}

export { getUserPointsBalanceText, getUserCurrencyBalanceText };
