<checkout-market-banner *ngIf="showCardBanner" class="checkout-order-market-card-points-banner">
  <img [src]="marketCardBanner.image" [alt]="marketCardBanner.cardName" />
  <span>{{ marketCardBanner.description }}</span>
  <a routerLink="/account/supermarket-cards"> εδώ </a>
</checkout-market-banner>
<checkout-market-banner *ngIf="showCardPointsBanner" class="checkout-order-market-card-points-banner">
  <img [src]="marketCardPointsBanner.image" [alt]="marketCardPointsBanner.superMarket" />
  <span>
    Με την παραγγελία σου κέρδισες <strong>+{{ marketCardPointsBanner.points }} πόντους</strong> στην
    {{ marketCardPointsBanner.cardName }} σου
  </span>
</checkout-market-banner>
