import { Injectable } from '@angular/core';
import { Observable, Subscriber, from, of, catchError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PlacesService {
  /* The Google Places Service requires a Map element to be initialized. Although that is mandatory,
  we can use whatever element we want, even a mock element, without displaying it to the user.
  It is prohibited to use Places API data on a map that is not a Google map.
  https://developers.google.com/maps/documentation/places/web-service/policies#map */
  private readonly mapElement = document.createElement('div');
  private sessionToken: google.maps.places.AutocompleteSessionToken;

  public getPlacePredictions(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    if (!this.sessionToken) this.createSessionToken();
    const autocompleteWidget = new google.maps.places.AutocompleteService();
    const request: google.maps.places.AutocompletionRequest = {
      componentRestrictions: { country: 'gr' },
      input,
      language: 'el',
      types: ['address'],
      sessionToken: this.sessionToken
    };
    return from(autocompleteWidget.getPlacePredictions(request)).pipe(
      map((response) => response.predictions),
      catchError(() => of([]))
    );
  }

  public getPlaceDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
    return new Observable((subscriber: Subscriber<google.maps.places.PlaceResult>) => {
      const placesService = new google.maps.places.PlacesService(this.mapElement);
      const request: google.maps.places.PlaceDetailsRequest = {
        placeId,
        fields: ['address_components', 'geometry.location'],
        language: 'el',
        sessionToken: this.sessionToken
      };

      placesService.getDetails(request, (place, status) => {
        this.clearSessionToken();
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          subscriber.next(place);
          subscriber.complete();
        } else {
          subscriber.error();
        }
      });
    });
  }

  private createSessionToken(): void {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  private clearSessionToken(): void {
    this.sessionToken = null;
  }
}
