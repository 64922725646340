<ng-container *ngIf="isDFY">
  <div class="offer-wizard-details-dfy-banner">
    <div class="offer-wizard-details-dfy-banner-content">
      <figure class="offer-wizard-details-dfy-banner-image">
        <img src="/assets/images/general/dfy.svg" alt="Deals For You Προσφορά" />
      </figure>
      <div class="offer-wizard-details-dfy-banner-text ods-typography-body-s-regular">
        Παρέχουμε τη συγκεκριμένη προσφορά σε συνεργασία με το Deals for You. Στην ολοκλήρωση της παραγγελίας σου, θα
        σου ζητηθεί κωδικός Deals for You.
      </div>
    </div>
    <button box-button size="small" (click)="openDFYDialog()">Που μπορώ να βρω κωδικό;</button>
  </div>
  <div class="box-divider"></div>
</ng-container>
<div class="offer-wizard-details-wrapper">
  <div class="offer-wizard-details-info">
    <div class="offer-wizard-details-name">
      <promo-badge *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
      <span>{{ offerName }}</span>
    </div>
    <div class="offer-wizard-details-description">{{ offerDescription }}</div>
    <div *ngIf="minCartPriceText" class="offer-wizard-details-min-cart-price-text ods-typography-body-s-regular">
      {{ minCartPriceText }}
    </div>
    <div *ngIf="maxQuantityText" class="offer-wizard-details-max-quantity-text ods-typography-body-s-regular">
      {{ maxQuantityText }}
    </div>
    <div *ngIf="offerBaseEnvFee > 0" class="offer-wizard-details-env-fee">
      Το προϊόν περιλαμβάνει περιβαλλοντικό τέλος {{ offerBaseEnvFee / 100 | currency: 'EUR' }}
    </div>
  </div>
  <div class="offer-wizard-details-actions">
    <div *ngIf="showPrice" class="offer-wizard-details-price-container">
      <div *ngIf="offerPreOfferPrice" class="offer-wizard-details-starting-price">
        {{ offerPreOfferPrice / 100 | currency: 'EUR' }}
      </div>
      <div class="offer-wizard-details-price">{{ offerInstancePrice / 100 | currency: 'EUR' }}</div>
    </div>
    <div class="offer-wizard-details-uom-step">{{ stepToUomDescription }}</div>
    <box-quantity
      [subtraction]="offerInstanceQuantity > 1"
      [quantity]="offerInstanceQuantity"
      (add)="onOfferAdd()"
      (remove)="onOfferRemove()"
    ></box-quantity>
  </div>
</div>
