<promo-tag [options]="tagOptions"></promo-tag>
<figure class="checkout-suggestion-banner-card-image">
  <img [src]="imageSrc" [alt]="campaignName" />
</figure>
<button
  box-button
  size="small"
  theme="primary"
  class="checkout-suggestion-banner-card-btn"
  (click)="onBannerSelect($event)"
>
  Το θέλω!
</button>
