import { Component, Input, OnInit } from '@angular/core';
import { ShopSuggestionDialogBanner } from '@box-types';

@Component({
  selector: 'shop-suggestion-dialog-banner',
  templateUrl: './shop-suggestion-dialog-banner.component.html',
  styleUrls: ['./shop-suggestion-dialog-banner.component.scss']
})
export class ShopSuggestionDialogBannerComponent implements OnInit {
  @Input() private suggestionDialogBanner: ShopSuggestionDialogBanner;

  public imageSrc: string;
  public imageAlt: string;
  public multiplier: string;
  public pointsAmount: string;
  public description: string;

  ngOnInit(): void {
    this.imageSrc = this.suggestionDialogBanner.image;
    this.imageAlt = this.suggestionDialogBanner.campaignName;
    this.multiplier = this.suggestionDialogBanner.multiplier;
    this.pointsAmount = this.suggestionDialogBanner.pointsAmount;
    this.description = this.suggestionDialogBanner.description;
  }
}
