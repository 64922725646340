import { Cuisine, Shop, Timeslot, ShopSortingInfoKey } from '@box-types';
import { SortCuisineShopsOptions } from './shop.types';
import dayjs from 'dayjs';
import { orderBy, random, remove, shuffle } from 'lodash-es';
import { isShopEligibleForPromoCampaign } from '../promo-campaigns';
import { shopMainCuisineMatchesCuisinesIds } from './shop-cuisines.utils';

export {
  SHOP_SECONDARY_CUISINE_SORTING_WEIGHT,
  getShopSortingInfoText,
  getShopAverageSortingWeight,
  moveShopFromShopsBucketToShopsGroup,
  sortShopSortingGroup,
  removeShopFromBucket,
  sortShops,
  getShopCuisineAverageSortingWeight,
  sortCuisineShops
};

const SHOP_SECONDARY_CUISINE_SORTING_WEIGHT = 0.8;

function getShopSortingInfoText(sortingInfo: Record<ShopSortingInfoKey, string>, key: ShopSortingInfoKey): string {
  if (!sortingInfo || !key) return;
  return sortingInfo[key];
}

function getShopAverageSortingWeight(shop: Shop, timeslot?: Timeslot): number {
  const { sortingWeights } = shop;
  if (!sortingWeights) return 0;
  const dateToCheck = timeslot ? dayjs.unix(timeslot.timeSlotStart) : dayjs();
  const hourToCheck = dateToCheck.hour();
  const previousHour = dateToCheck.subtract(1, 'hour').hour();
  const nextHour = dateToCheck.add(1, 'hour').hour();
  const currentHourWeight = sortingWeights[hourToCheck] ?? 0;
  const previousHourWeight = sortingWeights[previousHour] ?? 0;
  const nextHourWeight = sortingWeights[nextHour] ?? 0;
  const currentHourWeightDivisor = currentHourWeight > 0 ? 1 : 0;
  const previousHourWeightDivisor = previousHourWeight > 0 ? 1 : 0;
  const nextHourWeightDivisor = nextHourWeight > 0 ? 1 : 0;
  const weightsDivisor = currentHourWeightDivisor + previousHourWeightDivisor + nextHourWeightDivisor;
  if (weightsDivisor === 0) return 0;
  return (currentHourWeight + previousHourWeight + nextHourWeight) / weightsDivisor;
}

/**
 * moveShopFromShopsBucketToShopsGroup is an internal function that we are using for the Shop Sorting.
 * Should not be used alone. This function is mutating the provided arrays
 *
 * @param {Shop} shop the shop that we need to move from the Shop sBucket to the Shops Group
 * @param {Shop[]} shopsBucket the Shops Bucket that we need to remove the item from
 * @param {Shop[]} shopsGroup the Shop Group that we need to add the item to
 * @param {string} promotedTag optional. Will set the shop.promotedTag with the promotedTag if provided
 * @returns {void}
 * */
function moveShopFromShopsBucketToShopsGroup(shop: Shop, shopsBucket: Shop[], shopsGroup: Shop[], tag?: string): void {
  if (!shopsBucket.length) return;
  const bucketIndex = shopsBucket.findIndex((bucketShop) => bucketShop.collectionType === shop.collectionType);
  if (bucketIndex === -1) return;
  const bucketShop = shopsBucket.splice(bucketIndex, 1)[0];
  const bucketShopWithTag = tag ? { ...bucketShop, promotedTag: tag } : bucketShop;
  shopsGroup.push(bucketShopWithTag);
}

/**
 * sortShopSortingGroup is an internal function that we are using for the Shop Sorting.
 * Should not be used alone. This function is mutating the provided array
 *
 * On any Shop Sorting Algorithm, there is a special case for the second reserved shop.
 * If that shop is New, the sorting process should ALWAYS put that shop after the second index.
 *
 * @param {Shop[]} shopsGroup the Shops Group that we need to sort
 * @param {number} secondReservedShopIndex the index of the Shop that is marked as New. Optional.
 * @returns {Shop[]} the Shops Group sorted
 * */
function sortShopSortingGroup(shopsGroup: Shop[], secondReservedShopIndex?: number): Shop[] {
  if (!secondReservedShopIndex || secondReservedShopIndex < 0) return shuffle(shopsGroup);
  const secondReservedShop = shopsGroup[secondReservedShopIndex];
  if (secondReservedShop?.isNew) {
    shopsGroup.splice(secondReservedShopIndex, 1);
    const sortedGroup = shuffle(shopsGroup);
    const lastItemIndex = random(2, shopsGroup.length - 1);
    sortedGroup.splice(lastItemIndex, 0, secondReservedShop);
    return sortedGroup;
  }

  return shuffle(shopsGroup);
}

/**
 * removeShopFromBucket is a helper function that is used only for the Shop Sorting Algorithms
 * and shoul dnot be used alone. This function is mutating the provided array.
 *
 * @param {Shop} shop the provided shop to be removed
 * @param {Shop[]} bucket the Shops bucket to remove the shop from
 * @returns {void}
 */
function removeShopFromBucket(shop: Shop, bucket: Shop[]): void {
  const collectionType = shop.collectionType;
  remove(bucket, (shop) => shop.collectionType === collectionType);
}

/**
 * sortShops is the default Shops Sorting Algorithm. It will sort the Shops by the below rules:
 *
 * The first, second and third places are reserved for the current bucket top 3 Shops (by average weight).
 *
 * The fourth place is reserved for a randomly picked boosted shop. If there are no boosted shops,
 * then we pick the top Explore shop (by average weight). If there are no Explore shops, we pick
 * the next top shop (by average weight).
 *
 * The fifth place is reserved for a randomly picked new boosted shop. If there are no new boosted
 * shops, then we pick a random new shop. If there are no new shops, then we pick the top Explore
 * shop (by average weight). If there are no Explore shops, we pick the next top shop (by average weight).
 *
 * @param {Shop[]} shops the shops to be sorted
 * @returns {Shop[]} the sorted shops
 * */
function sortShops(shops: Shop[], timeslot?: Timeslot): Shop[] {
  if (!shops.length) return [];
  const shuffledShops = shuffle(shops);
  const openShops = shuffledShops.filter((shop) => shop.operatingState === 'OPEN');
  const shopsBucket = orderBy(openShops, (shop) => getShopAverageSortingWeight(shop, timeslot), 'desc');
  const boostedNewBucket = openShops.filter((shop) => shop.isBoosted && shop.isNew);
  const boostedBucket = openShops.filter((shop) => shop.isBoosted);
  const newBucket = openShops.filter((shop) => shop.isNew);
  const exploreShops = shopsBucket.filter((shop) => isShopEligibleForPromoCampaign(shop, 'new_users'));
  const sortedOpenShops: Shop[] = [];

  const removeShopFromBuckets = (shop: Shop) => {
    removeShopFromBucket(shop, shopsBucket);
    removeShopFromBucket(shop, boostedBucket);
    removeShopFromBucket(shop, boostedNewBucket);
    removeShopFromBucket(shop, newBucket);
    removeShopFromBucket(shop, exploreShops);
  };

  const numberOfTopShops = 3;

  while (shopsBucket.length > 0) {
    const shopsGroup: Shop[] = [];

    for (let topShopCounter = 0; topShopCounter < numberOfTopShops; topShopCounter++) {
      const topShop = shopsBucket[0];
      if (!topShop) continue;
      shopsGroup.push(topShop);
      removeShopFromBuckets(topShop);
    }

    const firstReservedShop = boostedBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (firstReservedShop) {
      shopsGroup.push(firstReservedShop);
      removeShopFromBuckets(firstReservedShop);
    }

    const secondReservedShop = boostedNewBucket[0] ?? newBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (secondReservedShop) {
      removeShopFromBuckets(secondReservedShop);
      shopsGroup.push(secondReservedShop);
    }

    /**
     * if the New shop was inserted into the 3rd plave, then we need to shuffle all the items and find a random
     * place for the New shop between the 3rd and 5th place. If not, then we just shuffle the whole group
     * */
    const secondReservedShopGroupIndex = secondReservedShop ? 2 : undefined;
    const sortedShopsGroup = sortShopSortingGroup(shopsGroup, secondReservedShopGroupIndex);
    sortedOpenShops.push(...sortedShopsGroup);
  }

  const sortedOutsideTimetableShops = shuffledShops.filter((shop) => shop.operatingState === 'OUTSIDE_TIMETABLE');
  const sortedTemporarilyClosedShops = shuffledShops.filter((shop) => shop.operatingState === 'TEMPORARILY_CLOSED');
  const sortedClosedShops = shuffledShops.filter((shop) => shop.operatingState === 'CLOSED');
  return [...sortedOpenShops, ...sortedOutsideTimetableShops, ...sortedTemporarilyClosedShops, ...sortedClosedShops];
}

/**
 * This sorting function will be used instead of the `getShopAverageSortingWeight` during the `sortCuisineShops`.
 * This is a temporal fix for the sorting algorithm when there is a cuisine selected, to balance the issue
 * with the shops that have high orders but much the selected main cuisine with their respective secondary cuisines.
 *
 * @param {Shop} shop The shop to generate the average weight for
 * @param {Cuisine} cuisine The selected cuisine
 * @param {Timeslot} timeslot The timeslot. This changes the hours we do generate the average weight from
 * @returns {number} The cuisine average weight result
 * */
function getShopCuisineAverageSortingWeight(shop: Shop, cuisine: Cuisine, timeslot?: Timeslot): number {
  const averageWeight = getShopAverageSortingWeight(shop, timeslot);
  const mainCuisineMatch = shopMainCuisineMatchesCuisinesIds(shop, [cuisine._id]);
  if (mainCuisineMatch) return averageWeight;
  return averageWeight * SHOP_SECONDARY_CUISINE_SORTING_WEIGHT;
}

/**
 * sortCuisineShops is the Shops Sorting Algorithm for a selected Cuisine. It will sort the Shops by the below rules:
 *
 * The first and second places are reserved for the current bucket top 2 Shops (by average weight).
 *
 * The third place is reserved for a randomly picked boosted shop. If there are no boosted shops,
 * then we pick the top Explore shop (by average weight). If there are no Explore shops, we pick
 * the next top shop (by average weight).
 *
 * The fourth place is reserved for a randomly picked new boosted shop. If there are no new boosted
 * shops, then we pick a random new shop. If there are no new shops, then we pick the top Explore
 * shop (by average weight). If there are no Explore shops, we pick the next top shop (by average weight).
 *
 * The fifth place is reserved for a randomly picked chain shop. If there are no chain shops,
 * then we pick the top Explore shop (by average weight). If there are no Explore shops, we pick
 * the next top shop (by average weight).
 *
 * For a shop to be considered new, in this sorting algorithm, the Shop needs to have their main cuisine
 * matching the provided cuisine. If not, we consider them normal shops.
 *
 * @param {Shop[]} shops the shops to be sorted
 * @param {SortCuisineShopsOptions} options the options needed for the shop sorting
 * @returns {Shop[]} the sorted shops
 * */
function sortCuisineShops(shops: Shop[], options: SortCuisineShopsOptions): Shop[] {
  if (!shops.length) return [];
  const { cuisine, promotedChainTag, timeslot } = options;
  const shuffledShops = shuffle(shops);
  const openShops = shuffledShops.filter((shop) => shop.operatingState === 'OPEN');
  const shopsBucket = orderBy(openShops, (s) => getShopCuisineAverageSortingWeight(s, cuisine, timeslot), 'desc');
  const newBucket = openShops.filter((shop) => shop.isNew && shopMainCuisineMatchesCuisinesIds(shop, [cuisine._id]));
  const boostedNewBucket = newBucket.filter((shop) => shop.isBoosted);
  const boostedBucket = openShops.filter((shop) => shop.isBoosted);
  const chainBucket = openShops.filter((shop) => shop.chain);
  const exploreShops = shopsBucket.filter((shop) => isShopEligibleForPromoCampaign(shop, 'new_users'));
  const sortedOpenShops: Shop[] = [];

  const removeShopFromBuckets = (shop: Shop) => {
    removeShopFromBucket(shop, shopsBucket);
    removeShopFromBucket(shop, boostedBucket);
    removeShopFromBucket(shop, boostedNewBucket);
    removeShopFromBucket(shop, newBucket);
    removeShopFromBucket(shop, chainBucket);
    removeShopFromBucket(shop, exploreShops);
  };

  const numberOfTopShops = 2;

  while (shopsBucket.length > 0) {
    const shopsGroup: Shop[] = [];

    for (let topShopCounter = 0; topShopCounter < numberOfTopShops; topShopCounter++) {
      const topShop = shopsBucket[0];
      if (!topShop) continue;
      shopsGroup.push(topShop);
      removeShopFromBuckets(topShop);
    }

    const firstReservedShop = boostedBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (firstReservedShop) {
      shopsGroup.push(firstReservedShop);
      removeShopFromBuckets(firstReservedShop);
    }

    const secondReservedShop = boostedNewBucket[0] ?? newBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (secondReservedShop) {
      removeShopFromBuckets(secondReservedShop);
      shopsGroup.push(secondReservedShop);
    }

    const thirdReservedShop = chainBucket[0] ?? exploreShops[0] ?? shopsBucket[0];
    if (thirdReservedShop) {
      removeShopFromBuckets(thirdReservedShop);
      const decoratedThirdReservedShop =
        thirdReservedShop.chain && promotedChainTag
          ? { ...thirdReservedShop, promotedTag: promotedChainTag }
          : thirdReservedShop;
      shopsGroup.push(decoratedThirdReservedShop);
    }

    /**
     * if the New shop was inserted into the 3rd plave, then we need to shuffle all the items and find a random
     * place for the New shop between the 3rd and 5th place. If not, then we just shuffle the whole group
     * */
    const secondReservedShopGroupIndex = secondReservedShop ? 2 : undefined;
    const sortedShopsGroup = sortShopSortingGroup(shopsGroup, secondReservedShopGroupIndex);
    sortedOpenShops.push(...sortedShopsGroup);
  }

  const sortedOutsideTimetableShops = shuffledShops.filter((shop) => shop.operatingState === 'OUTSIDE_TIMETABLE');
  const sortedTemporarilyClosedShops = shuffledShops.filter((shop) => shop.operatingState === 'TEMPORARILY_CLOSED');
  const sortedClosedShops = shuffledShops.filter((shop) => shop.operatingState === 'CLOSED');
  return [...sortedOpenShops, ...sortedOutsideTimetableShops, ...sortedTemporarilyClosedShops, ...sortedClosedShops];
}
