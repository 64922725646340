import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';
import { Order, User, AssetInfo, GBRewardSkin, Reward, RewardClaimOptions, APIError } from '@box-types';
import {
  UserService,
  AuthenticationService,
  ConfigurationService,
  DialogService,
  LoaderService
} from '@box-core/services';
import { SelectMsisdnDialogComponent } from './components/select-msisdn-dialog/select-msisdn-dialog.component';
import { filterGBRewardsBySegment, getGBSegmentSkin, generateImageSrc } from '@box/utils';
import orderBy from 'lodash-es/orderBy';
import { MBBannerService } from './components/mb-banner.service';
import { MbState } from './types';

const CLAIMED_TITLE = 'Συγχαρητήρια! Με την ολοκλήρωση της παραγγελίας σου κέρδισες…';

@Component({
  selector: 'mb-banner',
  templateUrl: './mb-banner.component.html',
  styleUrls: ['./mb-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MBBannerService]
})
export class MbBannerComponent implements OnInit, OnDestroy {
  @Input() private order: Order;

  public state: string;
  public segment: string;
  public headerTitle: string;
  public headerImageSrc: string;
  public assetsInfo: AssetInfo[];
  public selectedAssetInfo: AssetInfo;
  public showRewards = false;
  public mbRewards: Reward[] = [];
  public segmentMBRewards: Reward[] = [];
  public claimUnavailable: boolean;
  public showSuggestion: boolean;
  public suggestionImageSrc: string;
  public suggestionText: string;
  public hasOrdered: boolean;
  public freeReward: Reward;
  public freeRewardMSISDN: string;
  public freeRewardSegment: string;
  public freeRewardTitle: string;
  public freeRewardImage: string;
  public suggestionRewardSegment: string;
  public showClaimedFreeReward: boolean;

  private suggestionAssetInfo: AssetInfo;
  private suggestionIndicator: string;
  private skin: GBRewardSkin;
  private user: User;
  private rewardsSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private configService: ConfigurationService,
    private mbBannerService: MBBannerService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.assetsInfo = this.user.assetsInfo;
    this.hasOrdered = this.user.hasOrdered;
    this.initializeState();
    this.fetchRewards();
  }

  ngOnDestroy(): void {
    if (this.rewardsSubscription) this.rewardsSubscription.unsubscribe();
  }

  public onMSISDNChange(): void {
    const dialogConfig: MatDialogConfig = {
      restoreFocus: false,
      autoFocus: false,
      panelClass: 'box-dialog',
      data: {
        assetsInfo: this.assetsInfo,
        selectedAssetInfo: this.selectedAssetInfo,
        suggestionIndicator: this.suggestionIndicator,
        suggestionRewardSegment: this.suggestionRewardSegment
      }
    };

    this.dialogService
      .openDialog(SelectMsisdnDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: { msisdn: AssetInfo }) => {
        if (!data || !data.msisdn) return undefined;
        this.selectedAssetInfo = data.msisdn;
        this.setSegment(this.selectedAssetInfo.segment);
        const config = this.configService.getConfiguration();
        this.skin = getGBSegmentSkin(config, this.segment);
        this.setSegmentedRewards(this.mbRewards, this.segment);
        this.suggestionCheck();
      });
  }

  public onRewardClaim(reward: Reward): void {
    const orderId: string = this.order._id;
    const guid: number = this.user.guid;
    const jwt = this.authenticationService.COSMOTE_ACCESS_TOKEN;
    const msisdn: string = this.selectedAssetInfo.msisdn;
    const claimOptions: RewardClaimOptions = { reward: reward._id, msisdn, orderId, guid, jwt };
    this.loaderService.setState(true);
    this.mbBannerService
      .claimRewardGB(claimOptions)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: () => {
          this.setState('claimed');
          this.headerTitle = this.getHeaderTitle();
          this.freeRewardTitle = this.freeReward && CLAIMED_TITLE;
          this.userService.addPoints(-reward.loyaltyPointsToRedeem);
          this.segmentMBRewards = [reward];
          const mbState: MbState = { orderId: this.order._id, rewardId: reward._id };
          window.sessionStorage.setItem('Box:mb', JSON.stringify(mbState));
          this.changeDetectorRef.detectChanges();
        },
        error: (error: APIError) => {
          if (error.code === 'RWRINT005') this.claimUnavailable = true;
          this.dialogService.openErrorDialog(error);
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  private setOrderFreeReward(order: Order, mbRewards: Reward[]): Reward {
    const orderRewards = order.rewards;
    if (!orderRewards || orderRewards.length === 0 || !mbRewards || mbRewards.length === 0) return undefined;
    const orderReward = orderRewards[0];
    this.freeReward = mbRewards.find((reward) => reward._id === orderReward.id);
    this.freeRewardMSISDN = orderReward.msisdn;
    this.showClaimedFreeReward = !!this.freeReward && order.shopResponse.status === 'ACCEPTED';
  }

  private setFreeRewardSegment(assetsInfo: AssetInfo[]): void {
    const freeRewardInfo = assetsInfo.find((assetInfo) => assetInfo.msisdn === this.freeRewardMSISDN);
    if (!freeRewardInfo) return undefined;
    this.freeRewardSegment = freeRewardInfo.segment;
    const config = this.configService.getConfiguration();
    const skin = getGBSegmentSkin(config, this.freeRewardSegment);
    if (!skin) return undefined;
    this.freeRewardTitle = this.state === 'claimed' ? CLAIMED_TITLE : skin.freeRewardsTitle;
    this.freeRewardImage = generateImageSrc(skin.logo);
  }

  private setSegment(segment: string): void {
    this.segment = segment;
    const config = this.configService.getConfiguration();
    this.skin = getGBSegmentSkin(config, segment);
    this.headerTitle = this.getHeaderTitle();
    this.headerImageSrc = generateImageSrc(this.skin.logo);
  }

  private initializeState(): void {
    const mbState = JSON.parse(window.sessionStorage.getItem('Box:mb')) as MbState;
    const claimed: boolean = mbState && mbState.orderId === this.order._id;
    this.setState(claimed ? 'claimed' : 'not-claimed');
  }

  private decorateRewardWithOrderPrice = (reward: Reward, order: Order): Reward => ({
    ...reward,
    orderPriceEligible: reward.minimumOrderPrice ? reward.minimumOrderPrice < order.totalPrice : true
  });

  private setSegmentedRewards(rewards: Reward[], segment: string): void {
    this.segmentMBRewards = filterGBRewardsBySegment(rewards, segment)
      .filter((reward) => reward.triggeredByClient)
      .sort((a, b) => a.megabytesReward - b.megabytesReward)
      .map((reward) => this.decorateRewardWithOrderPrice(reward, this.order));
  }

  private setRewards(order: Order, rewards: Reward[], segment: string): void {
    this.mbRewards = rewards;
    if (this.state === 'claimed') {
      const mbState = JSON.parse(window.sessionStorage.getItem('Box:mb')) as MbState;
      const mbReward: Reward = rewards.find((r) => r._id === mbState.rewardId);
      this.segmentMBRewards = [this.decorateRewardWithOrderPrice(mbReward, order)];
    } else {
      this.setSegmentedRewards(rewards, segment);
    }
    window.sessionStorage.removeItem('Box:mb');
  }

  private fetchRewards() {
    if (!this.assetsInfo?.length) return;
    this.rewardsSubscription = this.mbBannerService
      .fetchRewards()
      .pipe(
        map((rewards) => rewards.filter((r) => Boolean(r?.megabytesReward) && r.megabytesReward > 0)),
        tap((mbRewards) => this.setOrderFreeReward(this.order, mbRewards))
      )
      .subscribe((mbRewards) => {
        this.showRewards = Boolean(mbRewards?.length);
        if (this.showRewards) {
          this.setFreeRewardSegment(this.assetsInfo);
          this.selectedAssetInfo = this.getDefaultAssetInfo(this.user, this.assetsInfo);
          this.setSegment(this.selectedAssetInfo.segment);
          this.setRewards(this.order, mbRewards, this.segment);
          this.suggestionCheck();
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  private getDefaultAssetInfo(user: User, assetsInfo: AssetInfo[]): AssetInfo {
    const { cosmoteIdUsername } = user;
    if (!cosmoteIdUsername) return assetsInfo[0];
    return assetsInfo.find((asset) => asset.msisdn === cosmoteIdUsername) || assetsInfo[0];
  }

  private setState(state: string): void {
    this.state = state;
  }

  private getHeaderTitle(): string {
    if (this.state === 'not-claimed') return this.skin.packageSelectionTitle;
    if (this.state === 'claimed') return CLAIMED_TITLE;
  }

  private suggestionCheck(): void | boolean {
    const currentBestReward = orderBy(this.segmentMBRewards, 'megabytesReward', 'desc')[0];
    const clientTriggeredRewards = this.mbRewards.filter((reward) => reward.triggeredByClient);
    const bestReward = orderBy(clientTriggeredRewards, 'megabytesReward', 'desc')[0];
    const canClaimBetterReward = bestReward.megabytesReward > currentBestReward.megabytesReward;
    if (!canClaimBetterReward) return (this.showSuggestion = false);
    this.suggestionRewardSegment = bestReward.segments[0];
    const config = this.configService.getConfiguration();
    const skin = getGBSegmentSkin(config, this.suggestionRewardSegment);
    this.suggestionImageSrc = generateImageSrc(skin.logo);
    this.suggestionAssetInfo = this.assetsInfo.find((assetInfo) => assetInfo.segment === this.suggestionRewardSegment);
    this.suggestionText = this.suggestionAssetInfo
      ? `Κέρδισε καλύτερα πακέτα GB από το What’s Up Student για τον αριθμό ${this.suggestionAssetInfo.msisdn}!`
      : undefined;
    this.showSuggestion = !!this.suggestionText;
    this.suggestionIndicator = generateImageSrc(skin.offerIndicator);
  }
}
