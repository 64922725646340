import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CheckoutInvoiceDialogComponent } from '../checkout-invoice-dialog/checkout-invoice-dialog.component';
import { InvoiceService } from '@box-checkout/services';
import { Subscription } from 'rxjs';
import { InvoiceDialogResponseData, Invoice } from '@box-types';

@Component({
  selector: 'checkout-invoice',
  templateUrl: './checkout-invoice.component.html',
  styleUrls: ['./checkout-invoice.component.scss']
})
export class CheckoutInvoiceComponent implements OnInit, OnDestroy {
  @Input() public invoice: Invoice;

  public invoiceChecked: boolean;
  public invoiceString: string;

  private invoiceSubscription: Subscription;
  private checkedSubscription: Subscription;

  constructor(private dialog: MatDialog, private invoiceService: InvoiceService) {}

  ngOnInit(): void {
    this.setInvoiceSubscriptions();
  }

  ngOnDestroy(): void {
    if (this.invoiceSubscription) this.invoiceSubscription.unsubscribe();
    if (this.checkedSubscription) this.checkedSubscription.unsubscribe();
  }

  public onModeChange(): void {
    if (this.invoiceChecked) return this.openInvoiceDialog(this.invoice, 'confirm');
    return this.invoiceService.setChecked(false);
  }

  public onEdit(): void {
    this.openInvoiceDialog(this.invoice, 'edit');
  }

  private setInvoiceSubscriptions(): void {
    this.invoiceSubscription = this.invoiceService.invoice.subscribe((i) => this.setInvoiceData(i));
    this.checkedSubscription = this.invoiceService.invoiceChecked.subscribe((c) => (this.invoiceChecked = c));
  }

  private setInvoiceData(invoice: Invoice): void {
    this.invoice = invoice;
    this.invoiceString = this.invoiceService.invoiceToString(invoice);
  }

  private openInvoiceDialog(invoice: Invoice, action: string): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog-fit-content',
      data: { invoice }
    };

    this.dialog
      .open(CheckoutInvoiceDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: InvoiceDialogResponseData) => {
        if (action === 'edit') return this.handleEditDialogResponse(data);
        if (action === 'confirm') return this.handleConfirmDialogResponse(data);
      });
  }

  private handleEditDialogResponse(data: InvoiceDialogResponseData) {
    if (!data) return;
    if (data.save) return this.invoiceService.setInvoice(data.invoice);
  }

  private handleConfirmDialogResponse(data: InvoiceDialogResponseData) {
    if (!data) return (this.invoiceChecked = false);
    if (data.save) {
      this.invoiceService.setChecked(true);
      this.invoiceService.setInvoice(data.invoice);
    }
  }
}
