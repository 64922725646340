<div class="coupon-container">
  <div class="coupon-price-cut"></div>
  <div class="coupon-price">
    <span class="coupon-price-content">{{ price }}</span>
    <div class="coupon-separator"></div>
  </div>
  <div class="coupon-code">
    <span class="coupon-code-content">{{ code }}</span>
  </div>
  <div class="coupon-code-cut"></div>
</div>
