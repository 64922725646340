import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@box-env/environment';
import { CosmoteIDDataParams } from '@box-types';

@Injectable({ providedIn: 'root' })
export class CosmoteIDService {
  private renderer: Renderer2;
  private ROOT_URL: string = environment.application.ROOT_URL;
  private COSMOTE_ID_ACCOUNT_URL: string = environment.cosmote.ACCOUNT_URL;
  private COSMOTE_ID_CLIENT_ID: string = environment.cosmote.cosmoteID.CLIENT_ID;
  private COSMOTE_ID_CHANNEL_ID: string = environment.cosmote.cosmoteID.CHANNEL_ID;
  private COSMOTE_ID_DOMAIN: string = environment.cosmote.cosmoteID.DOMAIN;
  private COSMOTE_ID_SCOPE: string = environment.cosmote.cosmoteID.SCOPE;

  public scriptLoaded: boolean;
  public loginScript: HTMLScriptElement;

  private returnURL: string;

  constructor(private rendererFactory: RendererFactory2) {
    // We can't inject Renderer2 in a Service. This is a workaround until we find a better solution
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.returnURL = window.localStorage.getItem('Box:returnURL');
  }

  public setReturnURL(url: string): void {
    this.returnURL = url;
    window.localStorage.setItem('Box:returnURL', this.returnURL);
  }

  public getReturnUrl(): string {
    return this.returnURL ?? '';
  }

  public loadScript(data?: CosmoteIDDataParams): Promise<boolean> {
    const scriptPromise: Promise<boolean> = new Promise((resolve) => {
      const bodyEl: HTMLBodyElement = window.document.getElementsByTagName('body')[0];
      const scriptEl: HTMLScriptElement = this.getScriptElement();
      const dataParams: string = this.getDataParams(data);
      this.renderer.setAttribute(scriptEl, 'data-params', dataParams);
      scriptEl.onload = () => {
        this.scriptLoaded = true;
        resolve(true);
      };
      scriptEl.onerror = () => {
        this.scriptLoaded = false;
        resolve(false);
      };
      bodyEl.appendChild(scriptEl);
    });

    return scriptPromise;
  }

  public updateScriptDataParams(data: CosmoteIDDataParams): void {
    const scriptElement: HTMLScriptElement = window.document.querySelector('#md-login');
    const dataParams: string = this.getDataParams(data);
    this.renderer.setAttribute(scriptElement, 'data-params', dataParams);
  }

  public redirectToCosmoteId(): void {
    const loginElement: HTMLElement = window.document.querySelector('#cosid_md_login');
    loginElement.click();
  }

  private getDataParams(data?: CosmoteIDDataParams): string {
    const dataParams: CosmoteIDDataParams = {
      v2: true,
      dest: 'OAM',
      guid: '',
      qrCode: false,
      popupConsent: true,
      'alt-theme': 'third-party',
      scope: this.COSMOTE_ID_SCOPE,
      domain: this.COSMOTE_ID_DOMAIN,
      clientId: this.COSMOTE_ID_CLIENT_ID,
      loginUrl: this.ROOT_URL + '/login',
      logoutUrl: this.ROOT_URL + '/logout',
      ...data
    };

    const queryData = Object.keys(dataParams)
      .map((key) => key + '=' + dataParams[key]) // eslint-disable-line
      .join('&');

    return queryData;
  }

  private getScriptElement(): HTMLScriptElement {
    this.loginScript = this.renderer.createElement('script') as HTMLScriptElement;
    this.renderer.setAttribute(this.loginScript, 'id', 'md-login');
    this.renderer.setAttribute(this.loginScript, 'async', '');
    this.renderer.setAttribute(this.loginScript, 'src', this.COSMOTE_ID_ACCOUNT_URL + '/modalLogin/login.js');
    this.renderer.setAttribute(this.loginScript, 'data-chid', this.COSMOTE_ID_CHANNEL_ID);
    this.renderer.setAttribute(this.loginScript, 'data-modalstyle', 'simple');
    this.renderer.setAttribute(this.loginScript, 'data-provision-flow', 'register');
    this.renderer.setAttribute(this.loginScript, 'data-goto', this.ROOT_URL + '/login/redirect');
    this.renderer.setAttribute(this.loginScript, 'data-gotofail', this.ROOT_URL + '/login/redirect?failed=true');
    return this.loginScript;
  }
}
