import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService } from '@box-core/services';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { Router } from '@angular/router';
import { getGBSegmentSkin, generateImageSrc } from '@box/utils';

const DEFAULT_NAME = 'What’s Up Student χρήστες μας!';
const DEFAULT_IMAGE = '/assets/images/rewards/wu-promo-dialog-header.svg';

@Component({
  selector: 'gb-promo-dialog',
  templateUrl: './gb-promo-dialog.component.html',
  styleUrls: ['./gb-promo-dialog.component.scss']
})
export class GBPromoDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public title = `Αποκλειστικά για τους ${DEFAULT_NAME}!`;
  public imageUrl: string = DEFAULT_IMAGE;

  private segment: string;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<GBPromoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { segment: string },
    private configService: ConfigurationService,
    private router: Router
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.segment = this.data.segment;
    this.setGBRewardsConfigurationData(this.segment);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onOrderNowClick(): void {
    this.closeDialog();
    void this.router.navigate(['/discover']);
  }

  private setGBRewardsConfigurationData(segment: string): void {
    const config = this.configService.getConfiguration();
    if (!config || !config.rewards) return undefined;
    const skin = getGBSegmentSkin(config, segment);
    if (!skin) return undefined;
    this.title = `Αποκλειστικά για τους ${skin.name}!`;
    this.imageUrl = generateImageSrc(skin.webInformativeModalBanner) ?? DEFAULT_IMAGE;
  }
}
