<div class="checkout-tip-header">
  <span class="ods-typography-body-s-bold">Tip διανομέα</span>
</div>
<p class="checkout-tip-description ods-typography-body-s-regular">{{ description }}</p>
<checkout-amounts
  [amounts]="amounts"
  [minimumAmount]="minimumAmount"
  [maximumAmount]="maximumAmount"
  (amountChange)="onAmountChange($event)"
></checkout-amounts>
