import { currencyFormat, getPercentageDiscount } from '../core';
import { Coupon, CouponBenefitType } from '@box-types';

function isCouponLoyalty(coupon: Coupon): boolean {
  return coupon.benefitType === 'LOYALTY_POINTS';
}

function isCouponPersonalised(coupon: Coupon): boolean {
  return coupon.benefitType === 'PERSONALISED_CAMPAIGN';
}

function isCouponDiscount(coupon: Coupon): boolean {
  if (!coupon.benefitType) return false;
  return ['DISCOUNT_FIXED', 'DISCOUNT_PERCENTAGE'].includes(coupon.benefitType);
}

function discountMeetsCouponRequirements(coupon: Coupon, discount: number, value: number): boolean {
  if (!coupon.minimumPrice) return true;
  const valueAfterDiscount = value - discount;
  return coupon.minimumPrice <= valueAfterDiscount;
}

function isCouponKnown(coupon: Coupon): boolean {
  const knownTypes: CouponBenefitType[] = [
    'LOYALTY_POINTS',
    'PERSONALISED_CAMPAIGN',
    'DISCOUNT_PERCENTAGE',
    'DISCOUNT_FIXED'
  ];
  return knownTypes.includes(coupon.benefitType);
}

function isCouponUsed(coupon: Coupon): boolean {
  const { available, redemptionDate } = coupon;
  if (available) return false;
  return Boolean(redemptionDate);
}

function isCouponExpired(coupon: Coupon): boolean {
  const { available, expirationDate } = coupon;
  if (available) return false;
  return Boolean(expirationDate);
}

function isCouponDisabled(coupon: Coupon): boolean {
  return Boolean(coupon.disabledText);
}

function isCouponValid(coupon: Coupon): boolean {
  const { benefitType, loyaltyPoints, percentage, amount, info } = coupon;
  const { primaryText, secondaryText } = info ?? {};
  if (benefitType === 'DISCOUNT_FIXED' && !amount) return false;
  if (benefitType === 'DISCOUNT_PERCENTAGE' && !percentage) return false;
  if (benefitType === 'LOYALTY_POINTS' && !loyaltyPoints) return false;
  const missingText = !primaryText || !secondaryText;
  if (benefitType === 'PERSONALISED_CAMPAIGN' && missingText) return false;
  return true;
}

function isCouponDummy(coupon: Coupon): boolean {
  const { dummy, triggerPromoInitiation } = coupon;
  if (!dummy) return false;
  return Boolean(triggerPromoInitiation?.name);
}

function isCouponSelectable(coupon: Coupon): boolean {
  return !isCouponUsed(coupon) && !isCouponDisabled(coupon) && !isCouponExpired(coupon) && isCouponKnown(coupon);
}

function isOrderCoupon(coupon: Coupon): boolean {
  return isCouponDiscount(coupon) || isCouponPersonalised(coupon);
}

function isCouponRedeemamble(coupon: Coupon): boolean {
  return isCouponLoyalty(coupon);
}

function getCouponDiscount(coupon: Coupon, amount: number): number {
  if (!coupon) return 0;
  if (!isOrderCoupon(coupon)) return 0;
  if (coupon.amount) return coupon.amount; // fixed discount
  if (!amount || amount <= 0 || !coupon.percentage || coupon.percentage <= 0) return 0;
  const discount = Math.floor(getPercentageDiscount(amount, coupon.percentage));
  if (coupon.maximumDiscount) return Math.min(discount, coupon.maximumDiscount);
  return discount;
}

function getDiscountWarningMessage(coupon: Coupon, discount: number, value: number): string {
  const formatOptions = { style: 'currency', currency: 'EUR' };
  const valueAfterDiscount = value - discount;
  const couponDiscount = getCouponDiscount(coupon, valueAfterDiscount);
  const couponDiscountText = new Intl.NumberFormat('el-GR', formatOptions).format(couponDiscount / 100);
  const minimumPriceText = new Intl.NumberFormat('el-GR', formatOptions).format(coupon.minimumPrice / 100);
  return `Για να πάρεις ${couponDiscountText} έκπτωση, το καλάθι σου πρέπει να είναι τουλάχιστον ${minimumPriceText}.`;
}

function getDiscountResultMessage(coupon: Coupon, value: number): string {
  const formatOptions = { style: 'currency', currency: 'EUR' };
  const couponDiscount = getCouponDiscount(coupon, value);
  const valueAfterDiscount = value - couponDiscount;
  const cartPriceText = new Intl.NumberFormat('el-GR', formatOptions).format(valueAfterDiscount / 100);
  return `Με το εκπτωτικό κουπόνι, η νέα τιμή του καλαθιού σου είναι ${cartPriceText}.`;
}

function getCouponBenefitText(coupon: Coupon, startingPrice: number): string {
  if (coupon.loyaltyPoints) return ` ${coupon.loyaltyPoints} πόντους`;
  const couponDiscount = getCouponDiscount(coupon, startingPrice);
  const discount = Math.min(couponDiscount, startingPrice);
  if (discount <= 0) return '';
  return ` ${currencyFormat(discount, { symbolSpace: false })}`;
}

function getPizzaFanCouponDescription(coupon: Coupon): string {
  if (coupon?.code) return `Εξαργυρώνεις το κωδικό ${coupon.code} και παίρνεις την προσφορά Pizza Fan!`;
  return `Εξαργύρωσε έναν κωδικό Deals for You και πάρε την προσφορά Pizza Fan!`;
}

function isCouponRelatedToSynergy(coupon: Coupon, synergy: string): boolean {
  if (!coupon.synergy) return false;
  return coupon.synergy === synergy;
}

export {
  isCouponKnown,
  isCouponLoyalty,
  isCouponDiscount,
  isCouponDummy,
  getCouponDiscount,
  discountMeetsCouponRequirements,
  getDiscountWarningMessage,
  getDiscountResultMessage,
  isCouponUsed,
  isCouponExpired,
  isCouponDisabled,
  isCouponValid,
  isCouponPersonalised,
  isCouponSelectable,
  isOrderCoupon,
  isCouponRedeemamble,
  getCouponBenefitText,
  getPizzaFanCouponDescription,
  isCouponRelatedToSynergy
};
