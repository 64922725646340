<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      [title]="'Κέρδισε έκπτωση έως και ' + claimableValueInEuro / 100 + '€!'"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-points-dialog-main-wrapper">
      <p class="checkout-points-dialog-instructions ods-typography-body-s-regular">
        Για κάθε {{ stepValue | number }} πόντους που εξαργυρώνεις κερδίζεις 1€ έκπτωση για κάθε
        {{ euroRedemptionRate }}€ παραγγελίας
      </p>
      <div class="checkout-points-dialog-slider-status ods-typography-body-s-bold">
        Εξαργυρώνεις
        <points-tag class="checkout-points-dialog-slider-status-points">{{ selectedValue | number }}</points-tag>
        πόντους, κερδίζεις
        <span class="checkout-points-dialog-slider-status-amount ods-typography-subtitle-s">
          {{ selectedValueInEuro / 100 }}€</span
        >
      </div>
      <checkout-points-slider-data
        class="checkout-points-dialog-slider-data"
        [activeIndex]="activeIndex"
        [steps]="steps"
        [alternativeSteps]="alternativeSteps"
      >
        <box-slider
          [step]="stepValue"
          [value]="selectedValue"
          [claimableValue]="claimableValue"
          [availableValue]="availableValue"
          [maxValue]="maximumValue"
          (valueChange)="onValueChange($event)"
          (inputChange)="onInputChange($event)"
        ></box-slider>
      </checkout-points-slider-data>
      <div class="checkout-points-dialog-total">
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">Σύνολο</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            cartPrice / 100 | currency: 'EUR'
          }}</span>
        </div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular"
            >Από την έκπτωση κερδίζεις</span
          >
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s"
            >- {{ totalDiscount / 100 | currency: 'EUR' }}</span
          >
        </div>
        <div class="box-divider"></div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">Τελική τιμή προϊόντων</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            (cartPrice - totalDiscount) / 100 | currency: 'EUR'
          }}</span>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer checkout-points-dialog-footer">
    <button box-button theme="primary" class="checkout-points-dialog-footer-button" (click)="onSubmit()">
      Εφαρμογή
    </button>
  </footer>
</div>
