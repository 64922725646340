import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import {
  CheckoutSuggestionGroup,
  PromoCampaign,
  Shop,
  Product,
  CheckoutSuggestionBanner,
  Offer,
  APIError
} from '@box-types';
import { PromoCampaignsService, SentryService, ShopService, CartService } from '@box-core/services';
import {
  filterOffersCompatibleWithPromoCampaign,
  filterProductsCompatibleWithPromoCampaign,
  promoCampaignToCheckoutSuggestionGroup,
  isPromoCampaignCartSuggestion,
  getPromoCampaignImage,
  getPromoCampaignText,
  getSuggestedProducts,
  normalizeOffer,
  normalizeProduct,
  generateImageSrc
} from '@box/utils';
import uniq from 'lodash-es/uniq';
import orderBy from 'lodash-es/orderBy';

@Injectable()
export class CartSuggestionService {
  public suggestionPromoCampaigns = new BehaviorSubject<PromoCampaign[]>([]);
  public readonly reminderCampaignsAddedInCheckout = new BehaviorSubject<string[]>([]);

  constructor(
    private promoCampaignsService: PromoCampaignsService,
    private shopService: ShopService,
    private cartService: CartService,
    private sentryService: SentryService
  ) {}

  public getCheckoutSuggestionBanners$(): Observable<CheckoutSuggestionBanner[]> {
    const shop = this.shopService.getShop();
    const checkoutPromoCampaigns = this.getCheckoutSuggestionBannerCampaigns(shop);
    if (!checkoutPromoCampaigns?.length) return of([] as CheckoutSuggestionBanner[]);

    const { collectionType, supermarketGroup } = shop;
    const options = {
      promoCampaigns: checkoutPromoCampaigns.map((promoCampaign) => promoCampaign._id),
      supermarketGroup
    };

    return this.promoCampaignsService.fetchPromoCampaignsItems(collectionType, options).pipe(
      map((response) => {
        const responseCampaigns = response.campaigns;
        const bestCampaigns = this.getBestCampaignsWithUniqueSynergy(checkoutPromoCampaigns);
        const suggestionBanners = bestCampaigns.reduce((banners, promoCampaign) => {
          const responseCampaign = responseCampaigns.find((rpc) => rpc.name === promoCampaign.name);
          if (responseCampaign?.products?.length > 0 || responseCampaign?.offers?.length > 0) {
            /* We found a gap in the Web Applciation with the new Offer Types feature, where we cannot use Offer Wizard
            and Product MYO on the Checkout, making the usage of Cart Suggestion Banners with Offers obsolete. We will
            be disabling all the Cart Suggestion Offers from the Checkout until we find the solution, to minimize the
            production impact.
            If this comment has not been removed before July 2023, please inform the Web TPM */
            // const { offers, products } = responseCampaign;
            const { products } = responseCampaign; // This needs to be changed when we solve the Suggestion Offers Issue
            const campaignName = promoCampaign.name;
            const campaignType = promoCampaign.type;
            const priority = promoCampaign.priority;
            const groups = this.getShopCheckoutSuggestionGroups(
              promoCampaign,
              checkoutPromoCampaigns,
              // offers,
              [], // This needs to be changed when we solve the Suggestion Offers Issue
              products
            );

            if (groups.length) {
              // This needs to be changed when we solve the Suggestion Offers Issue
              const image = generateImageSrc(getPromoCampaignImage(promoCampaign, 'inShopPlacementImage'));
              const cardImage = generateImageSrc(getPromoCampaignImage(promoCampaign, 'smallCartSuggestionLogo'));
              const points = promoCampaign.marketPlacePointsAmount;
              const multiplier = promoCampaign.multiplier;
              const subtitle = getPromoCampaignText(promoCampaign, 'reminderTitle');
              banners.push({
                campaignName,
                subtitle,
                groups,
                campaignType,
                points,
                multiplier,
                image,
                cardImage,
                priority
              });
            }
          }
          return banners;
        }, [] as CheckoutSuggestionBanner[]);
        return orderBy(suggestionBanners, ['multiplier', 'points', 'priority'], ['desc', 'desc', 'desc']);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Checkout Page',
          domainDetails: 'Checkout Suggestion Banners',
          severity: 'error'
        });
        return of([] as CheckoutSuggestionBanner[]);
      })
    );
  }

  /* The getTriggeredByTheClientCartPromoCampaigns and getCheckoutSuggestionBannerCampaigns have a lot of
shared code that we could remove during Housekeeping. */
  private getTriggeredByTheClientCartPromoCampaigns(): PromoCampaign[] {
    const shop = this.shopService.getShop();
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    return promoCampaigns.filter((promoCampaign) => {
      if (!promoCampaign.triggeredByClient) return false;
      const isCartSuggestion = isPromoCampaignCartSuggestion(promoCampaign);
      if (!isCartSuggestion) return false;
      const isShopPromoCampaign = shop.promoCampaigns.includes(promoCampaign.name);
      if (!isShopPromoCampaign) return false;
      const isUsed = this.cartService.cartHasPromoCampaign(promoCampaign);
      if (isUsed) return true;
      const usedCategory = this.shopService.cartHasProductsThatBelongToCategory(promoCampaign);
      if (usedCategory) return true;
      return false;
    });
  }

  private getCheckoutSuggestionBannerCampaigns(shop: Shop): PromoCampaign[] {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    return promoCampaigns.filter((promoCampaign) => {
      const isCartSuggestion = isPromoCampaignCartSuggestion(promoCampaign);
      if (!isCartSuggestion) return false;
      const isShopPromoCampaign = shop.promoCampaigns.includes(promoCampaign.name);
      if (!isShopPromoCampaign) return false;
      const isUsed = this.cartService.cartHasPromoCampaign(promoCampaign);
      if (isUsed) return false;
      const usedCategory = this.shopService.cartHasProductsThatBelongToCategory(promoCampaign);
      if (usedCategory) return false;
      return true;
    });
  }

  public getEligibleReminderCampaignsTriggeredByClient(): string[] {
    const reminderCampaigns = this.getTriggeredByTheClientCartPromoCampaigns();
    if (!reminderCampaigns?.length) return [];
    const productsAddedFromCheckout = this.shopService.getCartProductsAddedFromCheckout();
    const offersAddedFromCheckout = this.shopService.getCartOffersAddedFromCheckout();
    if (!productsAddedFromCheckout?.length && !offersAddedFromCheckout?.length) return [];
    return reminderCampaigns
      .filter((campaign) => {
        const productMatch = Boolean(
          filterProductsCompatibleWithPromoCampaign(campaign, productsAddedFromCheckout)?.length
        );
        if (productMatch) return true;
        const offerMatch = Boolean(filterOffersCompatibleWithPromoCampaign(campaign, offersAddedFromCheckout)?.length);
        if (offerMatch) return true;
      })
      .map((campaign) => campaign.name);
  }

  private getShopCheckoutSuggestionGroups(
    targetCampaign: PromoCampaign,
    promoCampaigns: PromoCampaign[],
    responseOffers: Offer[],
    responseProducts: Product[]
  ): CheckoutSuggestionGroup[] {
    const campaignsWithSameSynergy = targetCampaign.synergyTitle
      ? promoCampaigns.filter((campaign) => campaign.synergyTitle === targetCampaign.synergyTitle)
      : [targetCampaign];
    const sortedCampaignsWithSameSynergy = orderBy(
      campaignsWithSameSynergy,
      ['multiplier', 'points', 'priority'],
      ['desc', 'desc', 'desc']
    );

    const normalizedResponseOffers = responseOffers.map((offer) => normalizeOffer(offer));
    const normalizedResponseProducts = responseProducts.map((product) => normalizeProduct(product));
    const usersFrequentPlates = this.shopService.usersFrequentPlates.getValue();
    const suggestedProducts = getSuggestedProducts(normalizedResponseProducts, usersFrequentPlates);
    const suggestedProductsIds = suggestedProducts.map((product) => product._id);
    const decoratedOffers = normalizedResponseOffers.map((offer) =>
      this.shopService.offerDecorator(offer, suggestedProductsIds)
    );
    const decoratedProducts = normalizedResponseProducts.map((product) => this.shopService.decorateProduct(product));
    const previousGroupProducts: Product[] = [];
    const previousGroupOffers: Offer[] = [];
    return sortedCampaignsWithSameSynergy
      .map((promoCampaign) => {
        const filteredPromoProducts = decoratedProducts.filter((product) => !previousGroupProducts.includes(product));
        previousGroupProducts.push(...filteredPromoProducts);
        const filteredPromoOffers = decoratedOffers.filter((offer) => !previousGroupOffers.includes(offer));
        previousGroupOffers.push(...filteredPromoOffers);
        return promoCampaignToCheckoutSuggestionGroup(promoCampaign, filteredPromoProducts, filteredPromoOffers);
      })
      .filter((group) => group.products?.length > 0 || group.offers?.length > 0);
  }

  private getBestCampaignsWithUniqueSynergy(promoCampaigns: PromoCampaign[]): PromoCampaign[] {
    // if a campaign has no synergyTitle it will also be returned
    const campaignsWithoutSynergy = promoCampaigns.filter((campaign) => !campaign.synergyTitle);
    const campaignsWithSynergy = promoCampaigns.filter((campaign) => campaign.synergyTitle);
    const uniqueSynergies = uniq(campaignsWithSynergy.map((campaign) => campaign.synergyTitle));
    const bestCampaignsWithUniqueSynergy = uniqueSynergies.map((title) => {
      const sameSynergyCampaigns = campaignsWithSynergy.filter((campaign) => campaign.synergyTitle === title);
      return orderBy(sameSynergyCampaigns, ['multiplier', 'points', 'priority'], ['desc', 'desc', 'desc'])[0];
    });
    return orderBy(
      [...campaignsWithoutSynergy, ...bestCampaignsWithUniqueSynergy],
      ['multiplier', 'points', 'priority'],
      ['desc', 'desc', 'desc']
    );
  }
}
