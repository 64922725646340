import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@box-env/environment';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Configuration } from '@box-types';
import { get } from 'lodash-es';

dayjs.extend(isBetween);
dayjs.locale('el');

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  private readonly CONFIGURATION_URL = environment.application.CONFIGURATION_URL;
  private readonly configurationSource = new BehaviorSubject<Configuration>(undefined);

  constructor(private http: HttpClient) {}

  public fetchConfiguration(): Observable<Configuration> {
    // Cache-Control: no-cache, no-store, must-revalidate, post-check=0, pre-check=0
    // Expires: 0
    return this.http.get<Configuration>(this.CONFIGURATION_URL);
  }

  public get<T>(path: string, defaultValue?: T): T {
    return get(this.configurationSource.getValue(), path, defaultValue);
  }

  public setConfiguration(configuration: Configuration): void {
    this.configurationSource.next(configuration);
  }

  public getConfiguration(): Configuration {
    return this.configurationSource.getValue();
  }

  public isUnderMaintenance(): boolean {
    const configuration = this.configurationSource.getValue();
    if (!configuration?.downtime_Web) return false;
    const currentDate = dayjs();
    const fromDate = dayjs.unix(configuration.downtime_Web.fromDate);
    const toDate = dayjs.unix(configuration.downtime_Web.toDate);
    return currentDate.isBetween(fromDate, toDate);
  }
}
