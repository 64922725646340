import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

export type PointsTagSign = '+' | '-';

@Component({
  selector: 'points-tag',
  templateUrl: './points-tag.component.html',
  styleUrls: ['./points-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointsTagComponent {
  @Input() public sign: PointsTagSign;
  @HostBinding('class') public hostClass = 'points-tag';
}
