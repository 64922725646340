import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AddressesService,
  CouponsService,
  DialogService,
  SentryService,
  ShopsService,
  UserService,
  LoaderService,
  TimeslotsService
} from '@box-core/services';
import { CouponDetailsDialogComponent, CouponRedemptionDialogComponent } from '@box-coupon-widget/components';
import {
  CouponDetailsDialogData,
  CouponDetailsDialogResponse
} from '@box-coupon-widget/components/coupon-details-dialog/coupon-details-dialog.types';
import { Shop, Coupon, APIError } from '@box-types';
import { RewardsUnavailableCouponsDialogComponent } from '@box-rewards/components';
import { normalizeWebAction, isCouponLoyalty, isCouponRedeemamble, sortShops } from '@box/utils';
import { catchError, map, Observable, of, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RewardsCouponsData } from '@box-rewards/pages/rewards-coupons/rewards-coupons.types';

@Injectable()
export class RewardsCouponsService {
  constructor(
    private couponsService: CouponsService,
    private sentryService: SentryService,
    private dialogService: DialogService,
    private userService: UserService,
    private router: Router,
    private shopsService: ShopsService,
    private addressesService: AddressesService,
    private timeslotsService: TimeslotsService,
    private loaderService: LoaderService
  ) {}

  public getAvailableCoupons(): Observable<Coupon[]> {
    return this.couponsService.getAvailableCoupons$().pipe(
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Rewards Coupons Available',
          severity: 'error'
        });
        return of([]);
      })
    );
  }

  public getShops(): Observable<Shop[]> {
    const address = this.addressesService.getAddress();
    if (!address) return of([] as Shop[]);
    const guid = this.userService.getUser().guid;
    return this.shopsService.getShops({ address, guid }).pipe(
      map((shops) => {
        const openShops = shops.filter((shop) => shop.operatingState === 'OPEN');
        const timeslot = this.timeslotsService.getTimeslot();
        return sortShops(openShops, timeslot);
      }),
      catchError((error: Error | APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Coupons',
          domainDetails: 'Rewards Coupons Shops',
          severity: 'error'
        });
        return of([]);
      })
    );
  }

  public getRewardsCouponsData(): Observable<RewardsCouponsData> {
    return forkJoin({ coupons: this.getAvailableCoupons(), shops: this.getShops() });
  }

  public onCouponClick(coupon: Coupon): void {
    if (!coupon.info?.detailsDescription) {
      if (isCouponRedeemamble(coupon)) this.initiateRedemptionFlow(coupon);
    } else {
      this.openCouponDetails(coupon).subscribe((response) => {
        if (response?.triggerAction) this.triggerCouponAction(coupon);
      });
    }
  }

  public openCouponDetails(coupon: Coupon): Observable<CouponDetailsDialogResponse> {
    const dialogConfig: MatDialogConfig<CouponDetailsDialogData> = {
      panelClass: 'box-dialog-fit-content',
      data: { coupon, shops: this.shopsService.shops.getValue(), showCta: true }
    };
    return this.dialogService
      .openDialog<CouponDetailsDialogComponent, CouponDetailsDialogResponse>(CouponDetailsDialogComponent, dialogConfig)
      .afterClosed();
  }

  public openUnavailableCoupons(): void {
    const dialogConfig = { panelClass: 'box-dialog-fit-content' };
    this.dialogService.openDialog(RewardsUnavailableCouponsDialogComponent, dialogConfig);
  }

  public shouldShowButtons(): boolean {
    return !this.userService.isGuest;
  }

  private triggerCouponAction(coupon: Coupon): void {
    if (isCouponLoyalty(coupon)) return this.initiateRedemptionFlow(coupon);
    const webAction = coupon.info?.webAction;
    if (!webAction) return;
    const navigationUrl = normalizeWebAction(webAction);
    return void this.router.navigateByUrl(navigationUrl);
  }

  private redeemCoupon(code: string): Observable<Coupon> {
    this.loaderService.setState(true);
    return this.couponsService.redeemCoupon(code).pipe(
      finalize(() => this.loaderService.setState(false)),
      catchError((error: APIError) => {
        this.dialogService.openErrorDialog(error);
        return of(null);
      })
    );
  }

  public showCouponRedemptionSuccessDialog(coupon: Coupon): Observable<unknown> {
    return this.dialogService
      .openDialog(CouponRedemptionDialogComponent, {
        panelClass: 'box-dialog-fit-content',
        data: { coupon }
      })
      .afterClosed();
  }

  public initiateRedemptionFlow(coupon: Coupon): void {
    this.redeemCoupon(coupon.code).subscribe((coupon) => {
      if (!coupon) return;
      this.showCouponRedemptionSuccessDialog(coupon).subscribe(() => {
        if (coupon.benefitType === 'LOYALTY_POINTS') this.userService.addPoints(coupon.loyaltyPoints);
      });
    });
  }
}
