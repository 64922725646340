<div
  box-button
  theme="muted"
  class="cosmote-id-btn"
  [class.cosmote-id-logged]="authenticated"
  [class.disabled]="disabled"
  (click)="onConnectClick()"
>
  <div id="cosid_md_login" class="my-account dropdown">
    <span class="cosid-span" data-toggle="dropdown_cosid">
      <ng-container *ngIf="!authenticated && isSmallScreen; else text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          class="login-image"
        >
          <defs>
            <path id="a" d="M0 0h32v32H0z" />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
              <use xlink:href="#a" />
            </mask>
            <path
              fill="#7A7B80"
              fill-rule="nonzero"
              d="M24.794 3C25.46 3 26 3.53 26 4.182v23.636c0 .653-.54 1.182-1.206 1.182H11.206C10.54 29 10 28.47 10 27.818c0-.653.54-1.182 1.206-1.182h12.381V5.364h-12.38c-.667 0-1.207-.53-1.207-1.182C10 3.529 10.54 3 11.206 3Zm-9.06 8.033c.35-.092.722.012.973.272l4 3.989a.995.995 0 0 1 0 1.412l-4 3.99a1.002 1.002 0 0 1-1.687-.44.996.996 0 0 1 .273-.97l2.293-2.288H6c-.552 0-1-.446-1-.997 0-.55.448-.997 1-.997h11.586l-2.293-2.29a.996.996 0 0 1 .442-1.681Z"
              mask="url(#b)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-template #text>
        {{ buttonText }}
      </ng-template>
    </span>
  </div>
</div>
