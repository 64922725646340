<div class="checkout-order-preview-wrapper">
  <main class="checkout-order-preview-main">
    <div class="checkout-order-preview-groups checkout-order-preview-section">
      <ng-container *ngIf="isSuperMarket; else foodItems">
        <ng-container *ngFor="let offer of offers">
          <market-cart-item [item]="offer" [disabled]="true"></market-cart-item>
          <div class="box-divider"></div>
        </ng-container>
        <ng-container *ngFor="let product of products">
          <market-cart-item [item]="product" [disabled]="true"></market-cart-item>
          <div class="box-divider"></div>
        </ng-container>
      </ng-container>
      <ng-template #foodItems>
        <ng-container *ngFor="let offer of offers">
          <cart-offer [offer]="offer" [disabled]="true"></cart-offer>
          <div class="box-divider"></div>
        </ng-container>
        <ng-container *ngFor="let product of products">
          <cart-product [product]="product" [disabled]="true"></cart-product>
          <div class="box-divider"></div>
        </ng-container>
      </ng-template>
    </div>
    <div class="checkout-order-preview-section">
      <div class="checkout-order-preview-total">
        <ng-container *ngIf="showPriceBreakDown">
          <informational-message *ngIf="showPriceBreakDownInfo" (showInfo)="onShowInfo()">
            Υπολογισμός κόστους παραγγελίας
          </informational-message>
          <div class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Σύνολο προϊόντων</span>
            <span class="checkout-order-preview-total-row-number">{{ formattedItemsPriceWithoutEnvFee }}</span>
          </div>
          <div *ngIf="formattedEnvFee" class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Περιβαλλοντικό τέλος</span>
            <span class="checkout-order-preview-total-row-number">{{ formattedEnvFee }}</span>
          </div>
          <div *ngIf="formattedDeliveryFee" class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Αξία διανομής</span>
            <span class="checkout-order-preview-total-row-number">{{ formattedDeliveryFee }}</span>
          </div>
          <div *ngIf="formattedServiceFee" class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Χρέωση υπηρεσίας</span>
            <span class="checkout-order-preview-total-row-number">{{ formattedServiceFee }}</span>
          </div>
          <div *ngIf="formattedTip" class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Tip διανομέα</span>
            <span class="checkout-order-preview-total-row-number">{{ formattedTip }}</span>
          </div>
          <div *ngIf="formattedDonation" class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Χαμόγελο του Παιδιού</span>
            <span class="checkout-order-preview-total-row-number">{{ formattedDonation }}</span>
          </div>
          <div *ngIf="formattedDiscount" class="checkout-order-preview-total-row">
            <span class="checkout-order-preview-total-row-title">Από την έκπτωση κερδίζεις</span>
            <span class="checkout-order-preview-total-row-number">-{{ formattedDiscount }}</span>
          </div>
          <div class="box-divider"></div>
        </ng-container>
        <div class="checkout-order-preview-total-row">
          <span class="checkout-order-preview-total-row-title">Τελική τιμή παραγγελίας</span>
          <span class="checkout-order-preview-total-row-number">{{ formattedTotal }}</span>
        </div>
      </div>
    </div>
  </main>
</div>
