import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardsCardDialogData } from './rewards-card-dialog.types';

@Component({
  selector: 'rewards-card-dialog',
  templateUrl: './rewards-card-dialog.component.html',
  styleUrls: ['./rewards-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardsCardDialogComponent implements OnInit {
  public title: string;
  public imageSrc: string;
  public contentSrc: string;

  constructor(
    private dialogRef: MatDialogRef<RewardsCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RewardsCardDialogData
  ) {}

  ngOnInit(): void {
    this.title = this.data.rewardsCard.action.tutorial.title;
    this.imageSrc = this.data.rewardsCard.dialogImage;
    this.contentSrc = this.data.rewardsCard.action.tutorial.contentSrc;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
