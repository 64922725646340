import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductReplacementOption } from '@box-types';
import { ProductReplacementState, setOptions, resetOptions, setOption } from '@box-state/product-replacement.state';

@Injectable()
export class ProductReplacementService {
  public replacementOption: BehaviorSubject<ProductReplacementOption> = ProductReplacementState.replacementOption;
  public replacementOptions: BehaviorSubject<ProductReplacementOption[]> = ProductReplacementState.replacementOptions;

  constructor() {}

  public setOptions(options: ProductReplacementOption[]): BehaviorSubject<ProductReplacementOption[]> {
    return setOptions(options);
  }

  public resetOptions(): BehaviorSubject<ProductReplacementOption[]> {
    return resetOptions();
  }

  public setOption(option: ProductReplacementOption): BehaviorSubject<ProductReplacementOption> {
    return setOption(option);
  }
}
