import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, interval, Subscription } from 'rxjs';
import {
  Address,
  Card,
  CheckoutPointsValueChange,
  ConfirmDialogResponse,
  Product,
  ProductReplacementOption,
  PromoCampaignBanner,
  SeoOptions,
  Shop,
  Timeslot,
  User,
  CheckoutSuggestionBanner,
  Order,
  Payment,
  PaymentTypesOptions,
  MarketOrderCheckOptions,
  OrderCreateOptions,
  DeliveryMethod,
  APIError,
  Invoice,
  GAClickCommentsCheckOutConfig,
  Offer,
  Coupon,
  CheckoutBagsConfig,
  CheckoutBagsChangeEvent
} from '@box-types';
import {
  AddressesService,
  CampaignTimerService,
  LoaderService,
  AnalyticsService,
  MarketLoyaltyService,
  PaymentGatewayService,
  DialogService,
  SentryService,
  OrdersService,
  PromoCampaignsService,
  SEOService,
  ShopService,
  ShopsService,
  UserService,
  NotificationsService,
  DeliveryMethodService,
  PaymentTypesService,
  CartService,
  RouterHistoryService,
  PurchaseEventService,
  CampaignEligibilityService
} from '@box-core/services';
import { phoneValidator, specialCharacterExclusionValidator } from '@box-shared/validators';
import {
  CheckoutPaymentTypesComponent,
  CheckoutPointsComponent,
  CheckoutSynergyDialogComponent
} from '@box-checkout/components';
import {
  CartSuggestionService,
  CheckoutCouponsService,
  InvoiceService,
  ProductReplacementService,
  CheckoutService,
  CheckoutStateService
} from '../../services';

import { finalize, map, startWith, pairwise, switchMap, tap } from 'rxjs/operators';
import {
  getInitiatePaymentOptions,
  getCheckoutCartCtaText,
  isPaymentCard,
  getProductReplacementOptions,
  getNextTimeslot,
  getCheckoutBagsConfig,
  getShopDeliveryFee,
  itemsPriceIsOverMinimum,
  getHappyHourExpirationMessage,
  isLowOrderProbabilityUser,
  getPurchaseEventGAConfig,
  getPurchaseEventMPConfig,
  isAddressReadyForDelivery,
  isCouponDummy,
  discountMeetsCouponRequirements,
  getCouponDiscount,
  getOffersForOrder,
  getProductsForOrder,
  isOrderRejected,
  isOrderPending,
  isOrderAccepted,
  getMultiplierSum,
  getPointsSum
} from '@box/utils';
import { TimeslotsService } from '@box-core/services/timeslots.service';

const EMPTY_PAYMENT_INFO_DATA = { messages: ['Παρακαλούμε επίλεξε τρόπο πληρωμής.'] };

@Component({
  selector: 'checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss']
})
export class CheckoutPage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paymentTypes') public paymentTypesRef: CheckoutPaymentTypesComponent;
  @ViewChild(CheckoutPointsComponent) public checkoutPointsComponent: CheckoutPointsComponent;

  public readonly INVALID_COMMENT_MESSAGE_CHARS = '$<>#^[]{}?|"\\~`=';
  public commentFormControl: FormControl;
  public phoneFormControl: FormControl;
  public shop: Shop;
  public businessVertical: string;
  public hasComment = false;
  public offers: Offer[];
  public products: Product[];
  public cartPriceWithPureDiscounts: number;
  public cartStartingPrice: number;
  public totalDiscount: number;
  public checkOrderPrice: number;
  public checkOrderDifference = 0;
  public checkOrderDiscount = 0;
  public totalPrice: number;
  public deliveryFee: number;
  public marketLoyaltyDiscount: number;
  public cartTotalQuantity: number;
  public showCheckoutPoints: boolean;
  public timeslot: Timeslot;
  public showScheduledSection = false;
  public cards: Card[];
  public totalEnvFee: number;
  public priceCoveredByCoupon: number;
  public bagsConfig: CheckoutBagsConfig;
  public cartCtaTitle: string;
  public multiplierSum: number;
  public pointsSum: number;

  public isSuperMarket: boolean;
  public hasLoyaltyCard: boolean;
  private bagsPrice: number;
  private bagsQuantity: number;
  private bagsChecked: boolean;
  public hasReplacementOption: boolean;
  public hasInvoiceOption: boolean;
  public canSubmitOrder: boolean;
  public checkDaasAvailability = true;
  public checkoutSuggestionBanners: CheckoutSuggestionBanner[];
  public paymentType: Payment;

  private user: User;
  private userSubscription: Subscription;
  private orderSubscription: Subscription;
  private timeslotSubscription: Subscription;
  private selectedAddress: Address;
  private addressSubscription: Subscription;
  private coupon: Coupon;
  private couponSubscription: Subscription;
  private paymentTypeSubscription: Subscription;
  private cartSubscription: Subscription;
  private shopSubscription: Subscription;
  private cocaColaOnCheckoutSubscription: Subscription;
  private checkoutOrderPreviewSubscription: Subscription;
  private benefitsSubscription: Subscription;

  private invoice: Invoice;
  private invoiceSubscription: Subscription;
  private bankLoyaltyRedemption: Subscription;
  private productReplacementOption: ProductReplacementOption;
  private productReplacementSubscription: Subscription;
  private marketPointsSubscription: Subscription;
  private marketLoyaltyPointsChecked: boolean;

  // This needs to be removed after the Checkout Refactor
  private happyHourSubscription: Subscription;

  // Tip & Donation
  public tip = 0;
  public tipEnabled: boolean;
  public donation = 0;
  public donationEnabled: boolean;

  // Rewards Redesign
  public checkoutPointsDisabled: boolean;

  // Delivery Method
  private deliveryMethodSubscription: Subscription;

  constructor(
    private router: Router,
    private routerHistoryService: RouterHistoryService,
    private shopService: ShopService,
    private seoService: SEOService,
    private addressesService: AddressesService,
    private checkoutCouponsService: CheckoutCouponsService,
    private dialogService: DialogService,
    private ordersService: OrdersService,
    private loaderService: LoaderService,
    private promoCampaignsService: PromoCampaignsService,
    private analyticsService: AnalyticsService,
    private paymentTypesService: PaymentTypesService,
    private userService: UserService,
    private cartSuggestionService: CartSuggestionService,
    private invoiceService: InvoiceService,
    private productReplacementService: ProductReplacementService,
    private shopsService: ShopsService,
    private timeslotsService: TimeslotsService,
    private marketLoyaltyService: MarketLoyaltyService,
    private campaignTimerService: CampaignTimerService,
    private checkoutService: CheckoutService,
    private paymentGatewayService: PaymentGatewayService,
    private sentryService: SentryService,
    private notificationsService: NotificationsService,
    private deliveryMethodService: DeliveryMethodService,
    private cartService: CartService,
    private purchaseEventService: PurchaseEventService,
    private checkoutStateService: CheckoutStateService,
    private campaignEligibilityService: CampaignEligibilityService
  ) {
    this.setShopSubscription();
    this.setProductReplacementOptions();
    this.setCartSubscription();
    this.setBenefitsSubscription();
    this.setCardsData();
    this.setOrderSubscription();
    this.setCouponSubscription();
    this.setInvoiceSubscription();
  }

  @HostBinding('class') public pageWrapper = 'page-wrapper checkout-page';

  ngOnInit(): void {
    this.setMetaTags();
    this.setUserSubscription();
    this.setPhoneFormControl();
    this.setCommentFormControl();
    this.setTimeslotSubscription();
    this.setAddressSubscription();
    this.initializePaymentTypes();
    this.paymentTypesService.setDefaultPaymentType();
    this.setMarketPointsSubscription();
    this.handleSynergyDialog();
    this.setHappyHourExpirationSubscription();
    this.canSubmitOrder = this.getCanSubmitValue();
    this.deliveryMethodService.initializeMethod(this.shop);
    this.setCheckoutOrderPreviewSubscription();
    this.cartSuggestionService
      .getCheckoutSuggestionBanners$()
      .subscribe((checkoutSuggestionBanners) => (this.checkoutSuggestionBanners = checkoutSuggestionBanners));
  }

  ngAfterViewInit(): void {
    this.setBankCardLoyaltySubscription();
    this.setPaymentTypeSubscription();
    this.setDeliveryMethodSubscription();
  }

  ngOnDestroy(): void {
    this.orderSubscription?.unsubscribe();
    this.timeslotSubscription?.unsubscribe();
    this.addressSubscription?.unsubscribe();
    this.couponSubscription?.unsubscribe();
    this.paymentTypeSubscription?.unsubscribe();
    this.cartSubscription?.unsubscribe();
    this.invoiceSubscription?.unsubscribe();
    this.productReplacementSubscription?.unsubscribe();
    this.marketPointsSubscription?.unsubscribe();
    this.shopSubscription?.unsubscribe();
    this.happyHourSubscription?.unsubscribe();
    this.cocaColaOnCheckoutSubscription?.unsubscribe();
    this.deliveryMethodSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.timeslotsService.clearTimeslots();
    this.ordersService.clearOrder();
    this.checkoutOrderPreviewSubscription?.unsubscribe();
    this.benefitsSubscription?.unsubscribe();
    this.bankLoyaltyRedemption?.unsubscribe();
  }

  private setUserSubscription(): void {
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      this.user = user;
      this.showCheckoutPoints = this.checkoutService.getCheckoutPointsVisibility(
        this.shop,
        this.cartPriceWithPureDiscounts
      );
    });
  }

  public onCheckoutSuggestionBannerTrigger(banner: CheckoutSuggestionBanner): void {
    const alreadyShown = this.checkoutService.getPromoCampaignsShowed()?.includes(banner.campaignName);
    if (alreadyShown) return;
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const promoCampaign = promoCampaigns.find((campaign) => campaign.name === banner.campaignName);
    if (!promoCampaign) return;
    const promoCampaignBanner = this.promoCampaignsService.campaignToCampaignBanner(promoCampaign);
    this.openSynergyDialog(this.shop, [promoCampaignBanner])
      .afterClosed()
      .subscribe(() => this.checkoutService.addPromoCampaignsShowed(banner.campaignName));
  }

  public onResetAddress(): void {
    this.shopService.clearMenuItemsQuantities();
    this.cartService.clearCartAndShop();
    void this.router.navigate(['/home']);
  }

  public onTipChange(tip: number): void {
    this.tip = tip;
    this.updateCheckoutOrderPreview();
  }

  public onDonationChange(donation: number): void {
    this.donation = donation;
    this.updateCheckoutOrderPreview();
  }

  public onBagsChange(event: CheckoutBagsChangeEvent): void {
    this.bagsChecked = event.checked;
    this.bagsPrice = event.price;
    this.bagsQuantity = event.bagsQuantity;
    this.updateCheckoutOrderPreview();
  }

  public onMarketCardConnected(): void {
    this.checkMarketOrder();
  }

  private checkMarketOrder(): void {
    const { offers: cartOffers, products: cartProducts } = this.cartService.getCart();
    if (!cartOffers?.length && !cartProducts?.length) return;
    const loyaltyCard = this.checkoutService.getSuperMarketLoyaltyCard();
    const userAddress: Address = this.addressesService.getAddress();
    const options: MarketOrderCheckOptions = {
      products: getProductsForOrder(cartProducts).map((p) => ({
        _id: p._id,
        quantity: p.quantity
      })) as Partial<Product[]>,
      offers: getOffersForOrder(cartOffers).map((ο) => ({
        _id: ο._id,
        quantity: ο.quantity
      })) as Partial<Offer[]>,
      shopId: this.shop._id,
      cardId: loyaltyCard?.cardId,
      customerToken: loyaltyCard?.customerToken,
      latitude: userAddress?.latitude,
      longitude: userAddress?.longitude,
      supermarket: this.shop.integrator?.company
    };
    this.loaderService.setState(true);
    this.marketLoyaltyService
      .checkOrder(options)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (marketOrderCheck) => {
          this.bagsConfig = getCheckoutBagsConfig(this.shop, this.cartTotalQuantity, marketOrderCheck);
          this.marketLoyaltyService.setCheckoutPoints(marketOrderCheck);
          this.updateCheckoutOrderPreview();
        },
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private getCanSubmitValue(): boolean {
    const validPhone = this.phoneFormControl.valid;
    const validComment = this.commentFormControl.valid;
    const hasItemsInCart = this.cartService.getCart().itemsQuantity > 0;
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const sufficientMinimumPrice = itemsPriceIsOverMinimum(
      this.shop,
      deliveryMethod,
      this.cartPriceWithPureDiscounts + this.totalEnvFee
    );
    return validPhone && hasItemsInCart && sufficientMinimumPrice && validComment;
  }

  private setShopSubscription(): void {
    this.shopSubscription = this.shopService.shop.subscribe((shop) => {
      this.shop = shop;
      const { businessVertical, hasInvoiceOption, isSuperMarket, hasLoyaltyCard } = shop;
      this.tipEnabled = this.checkoutService.isCheckoutTipEnabled();
      this.businessVertical = businessVertical;
      this.hasInvoiceOption = hasInvoiceOption;
      this.isSuperMarket = isSuperMarket;
      this.hasLoyaltyCard = hasLoyaltyCard;
      this.updateCheckoutOrderPreview();
    });
  }

  private handleSynergyDialog(): void {
    /* This check is to prevent the Synergy dialog showing after a user redirection from the Payment page.
    We are removing this after the In App Payment refactor (in iFrame payment) from the Cosmote Payments Team. */
    const previousUrl = this.routerHistoryService.getPreviousUrl();
    const afterPayment = previousUrl.startsWith('/payment');
    if (afterPayment) return;
    const notTriggeredByClientBanners = this.checkoutService.getCheckoutSynergyBanners();
    if (!notTriggeredByClientBanners?.length) return;
    this.openSynergyDialog(this.shop, notTriggeredByClientBanners)
      .afterOpened()
      .subscribe(() => {
        const currentNames = this.checkoutService.getPromoCampaignsShowed();
        const newNames = notTriggeredByClientBanners.map((banner) => banner.campaignName);
        this.checkoutService.setPromoCampaignsShowed([...currentNames, ...newNames]);
      });
  }

  private setHappyHourExpirationSubscription(): void {
    const campaigns = this.promoCampaignsService.getPromoCampaigns();
    this.happyHourSubscription = this.campaignTimerService.whenCampaignIsExpired$('happy_hour').subscribe(() => {
      this.dialogService
        .openInfoDialog({
          title: 'To BOX Hour έληξε',
          messages: ['Το ΒΟΧ Hour έληξε για σήμερα.', getHappyHourExpirationMessage(campaigns) ?? '']
        })
        .afterClosed()
        .subscribe(() => this.happyHourSubscription.unsubscribe());
    });
  }

  private setCartSubscription(): void {
    this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
      this.cartTotalQuantity = cart.itemsQuantity;
      if (this.isSuperMarket) {
        this.checkMarketOrder();
      } else {
        this.bagsConfig = getCheckoutBagsConfig(this.shop, this.cartTotalQuantity);
        this.updateCheckoutOrderPreview();
      }
    });
  }

  private setMarketPointsSubscription(): void {
    if (!this.isSuperMarket) return;
    this.marketPointsSubscription = combineLatest([
      this.marketLoyaltyService.pointsChecked$.pipe(tap((checked) => (this.marketLoyaltyPointsChecked = checked))),
      this.marketLoyaltyService.checkoutPoints$.pipe(
        tap((checkoutPoints) => {
          if (!checkoutPoints) return;
          this.checkOrderPrice = checkoutPoints.orderPrice;
          /* positive when we have a discount, negative when the price is raised */
          this.checkOrderDifference = this.cartPriceWithPureDiscounts - checkoutPoints.orderPrice;
          this.checkOrderDiscount = checkoutPoints.priceDiscount;
        })
      )
    ]).subscribe(([checked, checkoutPoints]) => {
      const hasDiscount = checked && checkoutPoints?.priceDiscount;
      this.marketLoyaltyDiscount = hasDiscount ? checkoutPoints.priceDiscount : 0;
      this.updateCheckoutOrderPreview();
    });
  }

  private setInvoiceSubscription(): void {
    this.invoiceSubscription = combineLatest([
      this.invoiceService.invoiceChecked,
      this.invoiceService.invoice
    ]).subscribe(([checked, invoice]) => {
      if (checked === false) return (this.invoice = undefined);
      if (checked === true && invoice) return (this.invoice = invoice);
    });
  }

  private setProductReplacementOptions() {
    const options = getProductReplacementOptions(this.shop);
    if (options.length === 0) return (this.hasReplacementOption = false);
    this.productReplacementService.setOptions(options);
    this.productReplacementService.setOption(options[0]);
    this.hasReplacementOption = true;
    this.productReplacementSubscription = this.productReplacementService.replacementOption.subscribe(
      (option) => (this.productReplacementOption = option)
    );
  }

  private setAddressSubscription(): void {
    this.addressSubscription = this.addressesService.address$.subscribe((address) => {
      this.selectedAddress = address;
    });
  }

  private setCouponSubscription(): void {
    this.couponSubscription = this.checkoutCouponsService.coupon$.subscribe((coupon) => {
      this.coupon = coupon;
      this.updateCheckoutOrderPreview();
    });
  }

  private setMetaTags(): void {
    const options: SeoOptions = { title: 'Ολοκλήρωση παραγγελίας', url: this.router.url };
    this.seoService.setTags(options);
  }

  private setFoodTimeslotData(): void {
    const timeslots = (this.shop.timeslots ?? []).filter((t) => t.isAvailable);
    this.timeslotsService.setTimeslots(timeslots);
    this.showScheduledSection = this.shop.hasTimeSlots;
  }

  private setMarketTimeslotData(): void {
    const timeslots = (this.shop.timeslots ?? []).filter((t) => t.isAvailable);
    this.timeslotsService.setTimeslots(timeslots);
    const currentTimeslot = this.timeslotsService.getTimeslot();
    if (!currentTimeslot) this.timeslotsService.setTimeslot(getNextTimeslot(timeslots));
    this.canSubmitOrder = this.getCanSubmitValue();
    this.showScheduledSection = this.shop.hasTimeSlots;
  }

  private setTimeslotSubscription(): void {
    if (!this.isSuperMarket) this.setFoodTimeslotData();
    if (this.isSuperMarket) this.setMarketTimeslotData();
    this.timeslotSubscription = this.timeslotsService.timeslot$.subscribe((timeslot) => (this.timeslot = timeslot));
  }

  private setCardsData(): void {
    this.cards = this.paymentGatewayService.getPaymentGatewayInfo()?.cardList ?? [];
  }

  private setPhoneFormControl(): void {
    const phone = this.user.contactPhone ? this.user.contactPhone : '';
    // There is an issue with the Validators.required validation
    // eslint-disable-next-line
    this.phoneFormControl = new FormControl(phone, Validators.compose([Validators.required, phoneValidator()]));
    this.phoneFormControl.markAsTouched({ onlySelf: true });
    this.canSubmitOrder = this.phoneFormControl.valid;
    this.phoneFormControl.valueChanges.subscribe(() => (this.canSubmitOrder = this.getCanSubmitValue()));
  }

  private setCommentFormControl(): void {
    this.commentFormControl = new FormControl(
      '',
      Validators.compose([Validators.maxLength(500), specialCharacterExclusionValidator()])
    );
    this.commentFormControl.valueChanges.subscribe(() => {
      this.commentFormControl.markAsTouched({ onlySelf: true });
      this.canSubmitOrder = this.getCanSubmitValue();
    });
  }

  onChangeSlider(): void {
    this.hasComment = !this.hasComment;
    this.triggerAnalyticsEvent();
    this.commentFormControl.reset();
  }

  onOrderSubmit(): void {
    if (!this.canSubmitOrder) return;
    if (!isAddressReadyForDelivery(this.selectedAddress)) return this.checkoutService.problematicAddressFlow();
    if (this.paymentType.type === 'empty') return void this.dialogService.openInfoDialog(EMPTY_PAYMENT_INFO_DATA);
    const showCheckOrderDialog = this.checkOrderDifference && this.checkOrderDifference < -10;
    if (showCheckOrderDialog) return this.openCheckOrderPriceConfirmDialog();
    this.createOrder();
  }

  private getOrderComment(): string {
    const comments: string[] = [];
    const comment = this.commentFormControl.value as string;
    if (comment?.length > 0) comments.push(comment);
    if (this.selectedAddress?.comments?.length > 0) comments.push(this.selectedAddress.comments);
    return comments.join(', ');
  }

  private generateOrderCreateOptions(): OrderCreateOptions {
    const options: OrderCreateOptions = {
      paymentType: this.paymentType.type,
      paidWithNewCard: Boolean(this.paymentType.addNew),
      addressObject: this.selectedAddress,
      changesAllowed: 'replace',
      wantPlasticBags: Boolean(this.bagsChecked),
      shop: this.shop._id,
      comments: this.getOrderComment(),
      orderContactPhone: this.phoneFormControl.value as string,
      myoBoxakiRedeem: this.checkoutStateService.getPointsDiscount() > 0,
      integratorRedeemPoints: this.marketLoyaltyPointsChecked,
      cartTotal: this.totalPrice,
      orderShippingType: this.deliveryMethodService.getDeliveryMethod()
    };

    const pointsDiscount = this.checkoutStateService.getPointsDiscount();
    if (pointsDiscount > 0) options.myoBoxakiEuroDiscountToRedeem = pointsDiscount / 100;
    const dummyCouponSelected = Boolean(this.coupon) && isCouponDummy(this.coupon);
    if (dummyCouponSelected) options.initiatePromos = [this.coupon.triggerPromoInitiation];
    const bankLoyaltyDiscount = Boolean(this.checkoutStateService.getBankLoyaltyRedemption()?.discount);
    if (bankLoyaltyDiscount) {
      const { cardBoxId, pointsToRedeem } = this.checkoutStateService.getBankLoyaltyRedemption();
      options.discounts = { bankCardLoyalty: { cardBoxId, pointsToRedeem } };
    }
    if (this.coupon?.code) options.couponCode = this.coupon.code;
    if (this.timeslot) {
      if (this.timeslot.originalAA) options.timeSlotId = String(this.timeslot.originalAA);
      options.timeSlotStart = this.timeslot.timeSlotStart;
      options.timeSlotEnd = this.timeslot.timeSlotEnd;
    }
    const selectedCampaigns = this.checkoutService.getOrderPromoCampaigns();
    if (selectedCampaigns.length) options.selectedCampaigns = selectedCampaigns;
    const { offers: cartOffers, products: cartProducts } = this.cartService.getCart();
    const products = getProductsForOrder(cartProducts);
    if (products.length) options.products = products;
    const offers = getOffersForOrder(cartOffers);
    if (offers.length) options.offers = offers;
    if (this.invoice) options.invoice = this.invoice;
    if (this.bagsQuantity) options.plasticBagsQuantity = this.bagsQuantity;
    if (this.tipEnabled) options.tip = this.tip;
    if (this.donationEnabled) options.donation = this.donation;
    if (this.isSuperMarket) {
      const loyaltyCard = this.checkoutService.getSuperMarketLoyaltyCard();
      if (loyaltyCard) {
        options.cardId = loyaltyCard.cardId;
        options.customerToken = loyaltyCard.customerToken;
      }
    }
    if (this.productReplacementOption) options[this.productReplacementOption.shopProperty] = true;

    return options;
  }

  private createOrder(): void {
    if (!this.canSubmitOrder) return;
    this.loaderService.setState(true);
    const orderCreateOptions = this.generateOrderCreateOptions();
    this.ordersService.createOrder(orderCreateOptions).subscribe({
      next: (order) => {
        this.triggerPurchaseAnalyticsEvent(order);
        this.loaderService.setState(false);
        this.ordersService.setOrder(order);
        if (this.paymentType.type === 'card' && !!order.marketPlacePoints.consumed) {
          this.userService.saveRemainingPointsToStorage(order._id);
        }
        // Since we use caching on those services, after a merchantCampaign claim, we need the new shop models
        this.shopsService.resetShopsTimestamp();
        this.userService.updateUserHasOrdered(true);
        this.notificationsService.removeAllNotifications();
      },
      error: (error: APIError) => {
        this.loaderService.setState(false);
        if (error?.code === 'SHOP_NOT_REGISTERED_CAMPAIGN') {
          this.dialogService.openConfirmDialog({
            title: '‘Εχεις ήδη κερδίσει 6x πόντους',
            messages: ['‘Εχεις ήδη πάρει 6x πόντους από την πρώτη σου παραγγελία στο κατάστημα.'],
            confirmText: 'OK',
            cancelText: 'Πίσω'
          });
        } else {
          this.dialogService.openErrorDialog(error);
        }
      }
    });
  }

  private setOrderSubscription(): void {
    this.orderSubscription = this.ordersService.order$.subscribe((order) => {
      // todo refactor when payment with iframe is implemented
      if (order) this.checkDaasAvailability = false;
      this.handleOrderResponse(order);
    });
  }

  private handleOrderResponse(order: Order): void {
    if (!order) return;
    if (isOrderRejected(order)) return this.completeDeclinedOrderFlow(order);

    const shopStatus = order.shopResponse.status;
    if (shopStatus === 'PAYMENT_NOT_CONFIRMED') return; // user is informed in payment resolver
    if (shopStatus === 'PAYMENT_PENDING') return this.handlePayment(order);

    if (isOrderPending(order)) {
      this.cartService.clearCartAndShop();
      return void this.router.navigate(['/checkout', 'order-status'], {
        queryParams: { friendlyId: order.friendlyId }
      });
    }

    if (isOrderAccepted(order)) {
      const lowOrderProbability = isLowOrderProbabilityUser(this.userService.getUser());
      if (lowOrderProbability) this.checkoutService.onLowProbabilitySuccessfulOrder();
      return this.completeAcceptedOrderFlow(order);
    }

    this.handleOrderLoyaltyStatus(order);
  }

  private completeAcceptedOrderFlow(order: Order): void {
    this.orderSubscription?.unsubscribe();
    this.earnPointsAnalyticsEvent(order);
    this.userService.addPoints(order.marketPlacePoints.collected);
    this.shopService.clearMenuItemsQuantities();
    this.cartService.clearCartAndShop();
    void this.router.navigate(['/checkout', 'order-status'], { queryParams: { friendlyId: order.friendlyId } });
  }

  private completeDeclinedOrderFlow(order: Order): void {
    this.orderSubscription?.unsubscribe();
    this.shopService.clearMenuItemsQuantities();
    this.cartService.clearCartAndShop();
    void this.router.navigate(['/checkout', 'order-status'], { queryParams: { friendlyId: order.friendlyId } });
  }

  private handleOrderLoyaltyStatus(order: Order): void {
    const int = interval(5000);
    this.loaderService.setState(true);
    const intSub = int.subscribe(() =>
      this.ordersService.fetchOrder(order.friendlyId).subscribe({
        next: (orderResponse) => {
          if (orderResponse.loyaltyCollectionStatus === 'PENDING') return;
          this.loaderService.setState(false);
          intSub.unsubscribe();
          this.ordersService.setOrder(orderResponse);
        },
        error: (error: APIError) => {
          this.dialogService.openErrorDialog(error);
          this.loaderService.setState(false);
          intSub.unsubscribe();
        }
      })
    );
  }

  private handlePayment(order: Order): void {
    this.loaderService.setState(true);

    this.paymentGatewayService
      .getPaymentToken(order._id, this.shop.businessVertical)
      .pipe(
        map((paymentTokenResponse) => getInitiatePaymentOptions(paymentTokenResponse, this.paymentType, order._id)),
        switchMap((initiatePaymentOptions) =>
          this.paymentGatewayService.initiatePayment(initiatePaymentOptions, this.shop.businessVertical).pipe(
            map((initiatePaymentResponse) => ({
              merchantRef: initiatePaymentResponse.merchantRef,
              secToken: initiatePaymentOptions.secToken
            }))
          )
        )
      )
      .subscribe({
        next: ({ merchantRef, secToken }) => {
          const paymentUrl = this.paymentGatewayService.getPaymentGatewayInfo()?.paymentUrls?.paymentGatewayUrl;
          const redirectionURL = `${paymentUrl}?merchantRef=${merchantRef}&secToken=${secToken}`;
          window.location.replace(redirectionURL);
        },
        error: (error: APIError) => {
          this.loaderService.setState(false);
          this.dialogService.openErrorDialog(error);
          this.ordersService.cancelOrder(order._id).subscribe({
            next: () => this.ordersService.clearOrder(),
            error: (cancelError: APIError) => this.dialogService.openErrorDialog(cancelError)
          });

          /* This block of code should be replaced when the BE fixes the gap we have with the
          dummy coupons and the WU Synergy. That should not take more than a month. If it does,
          please contact the Team to fix it asap */
          const dummyCoupon = this.checkoutCouponsService.getCoupon();
          if (isCouponDummy(dummyCoupon)) {
            this.checkoutCouponsService.updateCheckoutCoupons().subscribe({
              next: () => {
                const coupons = this.checkoutCouponsService.getCoupons();
                const coupon = coupons.find((coupon) => coupon.synergy === dummyCoupon.synergy);
                if (coupon) this.checkoutCouponsService.setCoupon(coupon);
              },
              error: (error: APIError) => {
                this.sentryService.captureException(error, {
                  domain: 'Checkout',
                  domainDetails: 'Dummy Coupon Reselection',
                  severity: 'error'
                });
              }
            });
          }
        }
      });
  }

  private updateCheckoutOrderPreview(): void {
    const { offers, products } = this.cartService.getCart();
    this.offers = offers;
    this.products = products;
    this.checkoutService.updateCheckoutOrderPreview({
      coupon: this.coupon,
      pointsDiscount: this.checkoutStateService.getPointsDiscount() ?? 0,
      smLoyaltyDiscount: this.marketLoyaltyPointsChecked ? this.checkOrderDiscount ?? 0 : 0,
      bankLoyaltyDiscount: this.checkoutStateService.getBankLoyaltyRedemption()?.discount ?? 0,
      checkOrderPrice: this.checkOrderPrice,
      bagsPrice: this.bagsChecked && this.bagsPrice > 0 ? this.bagsPrice : 0,
      tip: this.tip,
      donation: this.donation ?? 0
    });
  }

  private setCheckoutOrderPreviewSubscription(): void {
    this.checkoutOrderPreviewSubscription = this.checkoutService.checkoutOrderPreview$.subscribe(
      (checkoutOrderPreview) => {
        this.priceCoveredByCoupon = checkoutOrderPreview.priceCoveredByCoupon;
        this.deliveryFee = checkoutOrderPreview.deliveryFee;
        this.totalEnvFee = checkoutOrderPreview.totalEnvFee;
        this.cartPriceWithPureDiscounts = checkoutOrderPreview.cartPriceWithPureDiscounts;
        this.cartStartingPrice = checkoutOrderPreview.cartStartingPrice;
        this.totalDiscount = checkoutOrderPreview.totalDiscount;
        this.totalPrice = checkoutOrderPreview.totalPrice;
        this.showCheckoutPoints = this.checkoutService.getCheckoutPointsVisibility(
          this.shop,
          this.cartPriceWithPureDiscounts
        );
      }
    );
  }

  private openSynergyDialog(
    shop: Shop,
    synergyBanners: PromoCampaignBanner[]
  ): MatDialogRef<CheckoutSynergyDialogComponent> {
    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      panelClass: ['box-dialog-fit-content', 'no-background', 'borderless'],
      data: { shop, synergyBanners }
    };
    return this.dialogService.openDialog(CheckoutSynergyDialogComponent, dialogConfig);
  }

  private openCheckOrderPriceConfirmDialog(): void {
    const pointsDiscount = this.checkoutStateService.getPointsDiscount() ?? 0;
    const smLoyaltyDiscount = this.marketLoyaltyPointsChecked ? this.checkOrderDiscount ?? 0 : 0;
    const bagsPrice = this.bagsChecked && this.bagsPrice > 0 ? this.bagsPrice : 0;
    const priceBeforeCouponDiscount = this.checkOrderPrice + bagsPrice - pointsDiscount - smLoyaltyDiscount;
    const couponDiscount = getCouponDiscount(this.coupon, priceBeforeCouponDiscount);
    const totalDiscount = pointsDiscount + smLoyaltyDiscount + couponDiscount;
    const tip = this.tip;
    const donation = this.donation;
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const deliveryFee = getShopDeliveryFee(this.shop, deliveryMethod, this.checkOrderPrice);
    const totalAdditions = deliveryFee + tip + donation + bagsPrice;
    const totalPrice = Math.max(0, this.checkOrderPrice + totalAdditions - totalDiscount);

    this.checkoutService
      .openCheckOrderPriceConfirmDialog(totalPrice)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (data?.accepted) this.createOrder();
      });
  }

  private earnPointsAnalyticsEvent(order: Order): void {
    /* This block of code ensures that if the shop has Auto Accept, the Event will get registered
    before going to Order Progress Page */
    const pointsEarned = order.marketPlacePoints?.collected;
    if (!pointsEarned || order.shopResponse.status !== 'ACCEPTED') return;
  }

  // Keep after refactor

  /* Payment Types */
  private setPaymentTypeSubscription(): void {
    this.paymentTypeSubscription = this.paymentTypesService.payment$
      .pipe(tap((payment) => (this.paymentType = payment)))
      .subscribe((payment) => {
        const isCard = isPaymentCard(payment);
        if (!isCard) {
          this.tip = 0;
          this.donation = 0;
        }
        this.checkoutPointsDisabled = !isCard;
        this.tipEnabled = this.checkoutService.isCheckoutTipEnabled();
        this.donationEnabled = this.checkoutService.isCheckoutDonationEnabled();
        this.cartCtaTitle = getCheckoutCartCtaText(payment);
        if (payment?.type === 'cash') {
          this.resetBOXPoints();
          this.checkoutCouponsService.clearCoupon();
          this.checkoutStateService.setBankLoyaltyRedemption(null);
        }
      });
  }

  private initializePaymentTypes(): void {
    const paymentTypeOptions: PaymentTypesOptions = {
      cards: this.cards,
      shop: this.shop,
      cartPrice: this.totalPrice,
      deliveryMethod: this.deliveryMethodService.getDeliveryMethod()
    };

    this.paymentTypesService.initializePaymentTypes(paymentTypeOptions);
  }
  /* Payment Types End */

  /* Delivery Method */
  private checkMinimumPriceAfterTakeAwaySelection(deliveryMethod: DeliveryMethod): void {
    if (itemsPriceIsOverMinimum(this.shop, deliveryMethod, this.cartPriceWithPureDiscounts + this.totalEnvFee)) return;
    this.checkoutService
      .showInsufficientMinimumPriceDialog(this.shop, deliveryMethod)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        // user has selected Δεν θα παραλάβω απο το κατάστημα
        if (data?.accepted) return this.deliveryMethodService.saveDeliveryMethod(deliveryMethod, this.shop);
        return void this.router.navigate(['/delivery', this.shop.locationKey, this.shop.vanity_url]);
      });
  }

  private handleTakeAwayPaymentSelection(): void {
    const currentPaymentType = this.paymentTypesService.getPaymentType();
    if (currentPaymentType.type === 'card') return;
    this.paymentTypesService.setFirstAvailableCardPayment();
    const newPaymentType = this.paymentTypesService.getPaymentType();
    if (newPaymentType.type !== 'card') return;
    this.checkoutService.showTakeAwayAutoPaymentSelectionInfo();
  }

  private setDeliveryMethodSubscription(): void {
    this.deliveryMethodSubscription = this.deliveryMethodService.deliveryMethod
      .pipe(startWith(null), pairwise())
      .subscribe(([previousDeliveryMethod, currentDeliveryMethod]) => {
        this.checkMinimumPriceAfterTakeAwaySelection(currentDeliveryMethod);
        this.canSubmitOrder = this.getCanSubmitValue();
        this.tipEnabled = this.checkoutService.isCheckoutTipEnabled();
        this.donationEnabled = this.checkoutService.isCheckoutDonationEnabled();
        if (currentDeliveryMethod === 'takeAway') {
          this.tip = 0;
          this.donation = 0;
          this.deliveryFee = 0;
        }
        this.initializePaymentTypes();
        const changedToTakeAway = previousDeliveryMethod === 'delivery' && currentDeliveryMethod === 'takeAway';
        if (changedToTakeAway) this.handleTakeAwayPaymentSelection();
        this.updateCheckoutOrderPreview();
      });
  }

  /* Delivery Method End */

  // Rewards Redesign

  public onCheckoutPointsValueChange(event: CheckoutPointsValueChange): void {
    const pointsDiscount = event.pointsInCents;
    const coupon = this.checkoutCouponsService.getCoupon();
    const meetsRequirements =
      !coupon || discountMeetsCouponRequirements(coupon, pointsDiscount, this.cartPriceWithPureDiscounts);

    if (meetsRequirements) {
      this.checkoutStateService.setPointsDiscount(pointsDiscount);
      return this.updateCheckoutOrderPreview();
    }

    this.checkoutCouponsService
      .showCouponDiscountRemovalDialog(coupon, pointsDiscount, this.cartPriceWithPureDiscounts)
      .afterClosed()
      .subscribe((response: ConfirmDialogResponse) => {
        if (!response?.accepted) return this.resetBOXPoints();
        this.checkoutCouponsService.clearCoupon();
        this.checkoutStateService.setPointsDiscount(pointsDiscount);
        this.updateCheckoutOrderPreview();
      });
  }

  public setBankCardLoyaltySubscription(): void {
    this.bankLoyaltyRedemption = this.checkoutStateService.bankLoyaltyRedemption$.subscribe(() => {
      this.updateCheckoutOrderPreview();
    });
  }

  private resetBOXPoints(): void {
    if (!this.checkoutPointsComponent) return undefined;
    this.checkoutPointsComponent.reset();
  }

  private triggerAnalyticsEvent(): void {
    const gaConfig = { comments_option: this.hasComment ? 'on' : 'off' } as GAClickCommentsCheckOutConfig;
    this.analyticsService.addGACustomEvent('click_comments_checkout', gaConfig);
  }

  private triggerPurchaseAnalyticsEvent(order: Order): void {
    const purchaseEvent = this.purchaseEventService.getPurchaseEvent(this.shop._id);
    if (!purchaseEvent) return;

    const options = {
      order: order,
      cart: this.cartService.getCart(),
      shop: this.shop,
      purchaseEvent: purchaseEvent,
      categories: this.shopService.getShopItemsFromMemory().categories,
      deliveryFee: this.deliveryFee
    };

    if (!order.user.hasOrdered) {
      this.analyticsService.addGACustomEvent('first_ecommerce_purchase', { value: String(this.user.guid) });
      this.analyticsService.addMPCustomEvent('FirstEcommercePurchase', { value: String(this.user.guid) });
    }

    const gaConfig = getPurchaseEventGAConfig(options);
    this.analyticsService.addGAEcommerceEvent('purchase', gaConfig);

    const mpConfig = getPurchaseEventMPConfig(order, this.shop.name);
    this.analyticsService.addMPEvent('Purchase', mpConfig);
  }

  private setBenefitsSubscription(): void {
    this.benefitsSubscription = combineLatest([
      this.checkoutCouponsService.coupon$,
      this.cartService.cart$,
      this.paymentTypesService.payment$
    ]).subscribe(() => {
      this.setBenefitsData();
    });
  }

  private setBenefitsData(): void {
    const campaigns = this.campaignEligibilityService.getConsumedPromoCampaigns();
    this.multiplierSum = getMultiplierSum(campaigns);

    const coupon = this.checkoutCouponsService.getCoupon();
    const payment = this.paymentTypesService.getPaymentType();
    const extraPoints = (coupon?.loyaltyPoints ?? 0) + (payment?.firstOrderCardPoints ?? 0);
    this.pointsSum = getPointsSum(campaigns) + extraPoints;
  }
}
