<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header borderless">
    <box-dialog-header class="myo-dialog-header" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main class="box-dialog-content">
    <figure class="image contain-image dfu-image">
      <img src="/assets/images/general/cosmote-dfu-dialog-image.svg" alt="Cosmote Deals For You" />
    </figure>
    <p class="dfu-instructions">
      Οι προσφορές Deals for You είναι διαθέσιμες για όλους τους πελάτες κινητής και σταθερής COSMOTE και COSMOTE TV.
    </p>
    <p class="dfu-instructions">
      Μπορείς να πάρεις τον κωδικό σου από το MY COSMOTE APP, WHAT'S UP APP ή στέλνοντας κενό SMS στο 19019 (χρέωση
      0,20€, συμπεριλαμβανομένου ΦΠΑ)
    </p>
  </main>
</div>
