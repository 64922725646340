import {
  Category,
  Product,
  ProductReplacementOption,
  Shop,
  MarketCard,
  MarketCardDetails,
  Offer,
  APIError,
  SMLoyaltyConfiguration
} from '@box-types';
import uniq from 'lodash-es/uniq';
import { productReplacementOptions } from '@box-configs/product-replacement.options';
import { sortCategories } from './shops/';

export {
  generateMarketCard,
  generateOffersSubCategories,
  getProductReplacementOptions,
  getMarketFindCardTermsHTML,
  getSaveCardTermsText,
  getFirstPersonalDataTermsHTML,
  getSecondPersonalDataTermsHTML,
  getLoyaltyCardForIntegrator,
  isCardExpirationErrorCode,
  hasLocalKritikosCard,
  isEfreshTokenInvalid,
  supermarketHasOffers
};

function isCardExpirationErrorCode(error: APIError): boolean {
  if (!error?.code) return false;
  return ['SMVD050', 'SMVD009'].includes(error.code);
}

function isEfreshTokenInvalid(error: APIError): boolean {
  if (!error?.code) return false;
  return error.code === 'E_FRESH_INVALID_TOKEN';
}

function generateMarketCard(details: MarketCardDetails, loyaltyInfo: SMLoyaltyConfiguration): MarketCard {
  return {
    cardId: details.cardId,
    name: loyaltyInfo.shortCardName,
    supermarket: details.name,
    shopName: loyaltyInfo.smName,
    frontImage: loyaltyInfo.bigCardPlaceholder,
    balance: details.points,
    barcodeFormat: loyaltyInfo.barcodeGenerator
  };
}

function supermarketHasOffers(products: Product[], offers: Offer[]): boolean {
  const hasOffers = Boolean(offers?.length);
  const hasProductOffers = Boolean(products?.filter((p) => p.isOffer)?.length);
  return hasOffers || hasProductOffers;
}

function generateOffersSubCategories(
  products: Product[],
  offers: Offer[],
  categories: Category[],
  shop: Shop
): Category[] {
  const hasOffers = offers?.length > 0;
  const offerProducts = products?.filter((p) => p.isOffer);
  const hasOfferProducts = offerProducts?.length > 0;
  const subCategories: Category[] = [];
  if (hasOffers) subCategories.push({ name: 'Σας συμφέρει', _id: 'offers_category' });
  if (!hasOfferProducts) return subCategories;
  const productOfferCategories: Category[] = [];
  if (shop?.integrator?.company === 'masoutis' || !shop?.isSuperMarket) {
    // we build the offer sub categories straight from the information that is attached on the products
    // for masoutis stores. We use the product.category property and not the product.category.parent which is the
    // product's supercategory. We don't depend on the getItems/preview categories properties
    const uniqCategoryIDs = uniq(offerProducts?.flatMap((product) => product?.category?._id));
    const productCategories = offerProducts.flatMap((product) => product.category);
    const uniqCategories = uniqCategoryIDs.map((id) => productCategories.find((category) => category._id === id));
    productOfferCategories.push(...uniqCategories);
  } else {
    const uniqCategoryIDs = uniq(offerProducts?.map((product) => product?.category?.parent));
    const uniqCategories = (categories ?? [])?.filter((category) => uniqCategoryIDs?.includes(category._id));
    productOfferCategories.push(...uniqCategories);
  }

  const sortedProductOfferCategories = sortCategories(productOfferCategories);
  if (sortedProductOfferCategories?.length > 0) subCategories.push(...sortedProductOfferCategories);
  return subCategories;
}

function getProductReplacementOptions(shop: Shop): ProductReplacementOption[] {
  const options: ProductReplacementOption[] = [];
  const { phoneProductReplacement, smartProductReplacement, noProductReplacement } = shop;
  if (phoneProductReplacement) options.push(productReplacementOptions.phone);
  if (smartProductReplacement) options.push(productReplacementOptions.smart);
  if (noProductReplacement) options.push(productReplacementOptions.none);
  return options;
}

/* Loyalty Cards Terms */

function getMarketFindCardTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
  if (!loyaltyConfig?.findCardTermsUrl) return undefined;
  const termsAnchor = `<a href="${loyaltyConfig.findCardTermsUrl}" target="_blank" rel="noopener noreferrer">όρους χρήσης</a>`;
  return `Συμφωνώ με τους ${termsAnchor} της ${loyaltyConfig.shortCardName} και επιθυμώ την χορήγηση και διαβίβαση των στοιχείων μου στα Supermarkets ${loyaltyConfig.smName} για την καταχώρηση των πόντων και την απόδοση εκπτώσεων που τυχόν δικαιούμαι.`;
}

function getSaveCardTermsText(loyaltyConfig: SMLoyaltyConfiguration): string {
  if (!loyaltyConfig?.canSave) return undefined;
  return `Επιθυμώ να μείνει αποθηκευμένη η κάρτα προνομίων ${loyaltyConfig.shortCardName} στην πλατφόρμα του BOX και για τις επόμενες παραγγελίες.`;
}

function getFirstPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
  const termsAnchor = `<a href="${loyaltyConfig.loyaltyTerms}" target="_blank" rel="noopener noreferrer">όρους χρήσης</a>`;
  if (!loyaltyConfig?.personalDataTermsUrl) {
    return `Συμφωνώ με τους ${termsAnchor} και να σταλούν τα στοιχεία μου στα Supermarkets ${loyaltyConfig.smName} για την ολοκλήρωση της παραγγελίας μου.`;
  }
  const dataTermsAnchor = `<a href="${loyaltyConfig.personalDataTermsUrl}" target="_blank" rel="noopener noreferrer">επεξεργασία των προσωπικών δεδομένων</a>`;
  return `Έχω ενημερωθεί σχετικά με την ${dataTermsAnchor} μου εκ μέρους του Καταστήματος για την έκδοση και χρήση της ${loyaltyConfig.shortCardName}. Έχω ενημερωθεί και συμφωνώ με τους ${termsAnchor} της ${loyaltyConfig.shortCardName}.`;
}

function getSecondPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
  if (!loyaltyConfig?.personalDataTermsUrl) return undefined;
  return `Επιθυμώ την χορήγηση και διαβίβαση των στοιχείων μου στα Supermarkets ${loyaltyConfig.smName} για τη δημιουργία της νέας κάρτας μου.`;
}

function getLoyaltyCardForIntegrator(loyaltyCards: MarketCardDetails[], company: string): MarketCardDetails {
  if (!loyaltyCards?.length || !company) return;
  return loyaltyCards.find((card) => card.name === company);
}

function hasLocalKritikosCard(): boolean {
  const localKritikosGuestCard = window.localStorage.getItem('Box:kritikosGuestCard');
  return !!localKritikosGuestCard;
}
