<div class="instance-wrapper">
  <div class="instance-description">{{ description }}</div>
  <div class="instance-actions">
    <div class="instance-actions-price">{{ instance.price / 100 | currency: 'EUR' }}</div>
    <box-quantity
      class="instance-actions-quantity"
      dense="true"
      [disabled]="disabled"
      [quantity]="instance.quantity"
      (add)="onAdd()"
      (remove)="onRemove()"
    ></box-quantity>
  </div>
</div>
