<div class="download-header-wrapper" *ngIf="show">
  <button mat-icon-button (click)="onClose()">
    <mat-icon class="download-header-close-button-icon" fontSet="material-icons-round">close</mat-icon>
  </button>
  <figure class="download-header-figure">
    <img src="/assets/images/logo/box-application-logo.svg" alt="BOX Mobile Application" />
  </figure>
  <div class="download-header-texts">
    <span class="download-header-texts-text ods-typography-body-m-bold">BOX Food Delivery</span>
    <span class="download-header-texts-description ods-typography-body-m-bold">Ξεκίνα να παραγγέλνεις</span>
  </div>
  <a
    [attr.href]="link"
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Κατέβασε το BOX Android Application"
    (click)="onClickCta()"
    class="download-header-link"
  >
    <button box-button theme="primary" class="download-header-link-cta">Άνοιγμα</button>
  </a>
</div>
