<div class="offer-instance-wrapper">
  <div class="offer-instance-groups">
    <div *ngFor="let group of instance.groups; let i = index" class="offer-instance-group">
      <div class="offer-instance-group-header">
        <h4 class="offer-instance-group-header-title">{{ group.selectedProduct.name }}</h4>
        <svg
          *ngIf="i === freeGroupIndex || group.type === 'gift'"
          class="offer-instance-group-header-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          fill="none"
        >
          <rect width="14" height="14" rx="4" />
          <g clip-path="url(#a)">
            <path
              d="M6.514 7.46H3.697a.4.4 0 0 0-.406.39v2.927a.4.4 0 0 0 .406.39H6.56c.096 0 .096-.099.096-.099V7.593s0-.132-.143-.132ZM10.302 7.46H7.488c-.171 0-.146.172-.146.172v3.44s-.001.095.102.095h2.858a.4.4 0 0 0 .406-.39V7.85a.4.4 0 0 0-.406-.39ZM6.657 5.158s0-.132-.136-.132H3.239a.4.4 0 0 0-.406.39v1.217a.4.4 0 0 0 .406.39h3.293c.125 0 .125-.104.125-.104v-1.76ZM10.76 5.026H7.469c-.125 0-.125.115-.125.115v1.78s0 .102.152.102h3.266a.4.4 0 0 0 .406-.39V5.416a.4.4 0 0 0-.406-.39ZM5.277 4.738c-.186 0-.355-.015-.504-.042-.378-.071-.638-.21-.794-.422-.14-.191-.183-.426-.127-.7.097-.477.431-.74.94-.74.108 0 .227.011.352.035.32.06.73.236 1.094.471.62.399.651.647.62.799-.045.223-.262.382-.663.487-.271.071-.606.112-.918.112Zm-.485-1.28c-.197 0-.265.053-.302.236-.03.15.008.202.02.22.053.071.19.131.388.168.106.02.237.03.379.03.312 0 .587-.045.77-.094.012-.004.033-.02.01-.032-.238-.186-.674-.436-1.037-.504a1.257 1.257 0 0 0-.228-.024ZM8.732 4.738c-.312 0-.647-.041-.918-.112-.401-.105-.618-.264-.663-.487-.031-.152 0-.4.62-.799.365-.235.774-.411 1.093-.471.126-.024.245-.036.352-.036.51 0 .844.264.941.742.056.273.013.508-.127.699-.157.213-.416.35-.794.422a2.762 2.762 0 0 1-.504.042Zm-.776-.756c-.021.012-.011.03 0 .034.182.05.46.097.776.097.142 0 .273-.01.38-.03.196-.038.333-.098.386-.17.013-.017.051-.069.02-.219-.037-.183-.104-.236-.302-.236-.066 0-.142.008-.227.024-.364.068-.795.314-1.033.5Z"
            />
          </g>
        </svg>
      </div>
      <span class="offer-instance-group-description">{{
        productInstanceDescription(group.selectedProduct.cartInstances[0])
      }}</span>
    </div>
  </div>
  <div class="offer-instance-actions">
    <div class="offer-instance-actions-price">{{ instance.price / 100 | currency: 'EUR' }}</div>
    <box-quantity
      class="offer-instance-actions-quantity"
      dense="true"
      [disabled]="disabled"
      [quantity]="instance.quantity"
      (add)="onAdd()"
      (remove)="onRemove()"
    ></box-quantity>
  </div>
</div>
