import { Injectable } from '@angular/core';
import { storageGet, storageRemove, storageSet, isPrerenderBrowser } from '@box/utils';
import { DialogService } from '@box-core/services/dialog.service';
import { WhatsNewDialogComponent } from '@box-core/components';
import { Observable, of, switchMap } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { MatDialogRef } from '@angular/material/dialog';

const WHATS_NEW_STORAGE_KEY = 'Box:whatsNewVersion';
@Injectable({ providedIn: 'root' })
export class WhatsNewService {
  constructor(private configService: ConfigurationService, private dialogService: DialogService) {}

  public shouldShowWhatsNew(): boolean {
    const isPrerender = isPrerenderBrowser(window);
    if (isPrerender) return false;
    const config = this.configService.getConfiguration();
    const configVersion = config?.whatsNew?.targetVersion?.web;
    if (!configVersion) {
      this.removeWhatsNewFromStorage();
      return false;
    }
    const currentEntry = this.getWhatsNewShownVersion();
    return currentEntry !== configVersion;
  }

  public getWhatsNewShownVersion(): string {
    return storageGet(WHATS_NEW_STORAGE_KEY, window.localStorage);
  }

  public removeWhatsNewFromStorage(): void {
    storageRemove(WHATS_NEW_STORAGE_KEY, window.localStorage);
  }

  public saveWhatsNewVersionToStorage(): void {
    const configVersion = this.configService.getConfiguration()?.whatsNew?.targetVersion?.web;
    if (!configVersion) return;
    storageSet(WHATS_NEW_STORAGE_KEY, configVersion, window.localStorage);
  }

  public openWhatsNewDialog(): MatDialogRef<WhatsNewDialogComponent> {
    const configWhatsNew = this.configService.getConfiguration()?.whatsNew;
    if (!configWhatsNew) return;
    const dialogConfig = { panelClass: 'box-dialog-fit-content', data: configWhatsNew };
    return this.dialogService.openDialog<WhatsNewDialogComponent, void>(WhatsNewDialogComponent, dialogConfig);
  }

  public initWhatsNewSequence$(): Observable<null> {
    if (!this.shouldShowWhatsNew()) return of(null);
    const dialogRef = this.openWhatsNewDialog();
    if (!dialogRef) return of(null);
    dialogRef.afterOpened().subscribe(() => this.saveWhatsNewVersionToStorage());
    return dialogRef.afterClosed().pipe(switchMap(() => of(null)));
  }
}
