import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@box-env/environment';
import { map } from 'rxjs/operators';
import { Review, CreateReviewOptions, APIResponse } from '@box-types';
import { pickBy } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class ReviewsService {
  private BOX_API = environment.application.API_URL;
  private readonly reviewsSource = new BehaviorSubject<Review[]>([]);
  public readonly reviews$ = this.reviewsSource.asObservable();

  constructor(private http: HttpClient) {}
  public setReviews(reviews: Review[]): void {
    this.reviewsSource.next(reviews);
  }

  public getReviews(): Review[] {
    return this.reviewsSource.getValue();
  }

  public addReview(review: Review): void {
    const reviews = this.reviewsSource.getValue();
    this.setReviews([...reviews, review]);
  }

  public clearReviews(): void {
    this.reviewsSource.next([]);
  }

  public fetchReviews(collectionType: number): Observable<Review[]> {
    const params = new HttpParams().set('collectionType', collectionType);
    return this.http
      .get(`${this.BOX_API}/ratings`, { params })
      .pipe(map((response: APIResponse<{ ratings: Review[] }>) => response.payload.ratings));
  }

  public createReview(options: CreateReviewOptions): Observable<Review> {
    const body = pickBy(options);
    return this.http
      .post(`${this.BOX_API}/rating`, body)
      .pipe(map((response: APIResponse<Review>) => response.payload));
  }

  public getUserLatestReviews(): Observable<Review[]> {
    return this.http
      .get(`${this.BOX_API}/rating/myRatings`)
      .pipe(map((response: APIResponse<{ userRatings: Review[] }>) => response.payload.userRatings));
  }
}
