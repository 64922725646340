import { CheckoutAmountsConfiguration } from '@box-types';
import { currencyFormat } from '../core';

export { generateCheckoutAmountDescription };

function generateCheckoutAmountDescription(config: CheckoutAmountsConfiguration): string {
  const { descriptionTemplate, minimumAmount, maximumAmount } = config;
  const currencyFormatOptions = { symbolSpace: false };
  const fromattedMinimumAmount = currencyFormat(minimumAmount, currencyFormatOptions);
  const fromattedMaximumAmount = currencyFormat(maximumAmount, currencyFormatOptions);
  return descriptionTemplate
    .replace('MINIMUM_PRICE_TEXT', fromattedMinimumAmount)
    .replace('MAXIMUM_PRICE_TEXT', fromattedMaximumAmount);
}
