import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';
import { Category, Order, Product, ProductsByCategory, Shop, Offer, GAPromotionConfig } from '@box-types';
import { Subscription, first } from 'rxjs';
import { AnalyticsService, ShopService, UserService } from '@box-core/services';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ShopMenuNavService, ShopMenuPageService } from '../../services';
import { ShopMenuItemsService } from '@box-delivery/components/shop-menu-items/shop-menu-items.service';
import { generateImageSrc, clearCategoryFromEmptyChildren } from '@box/utils';
import { SwiperOptions } from 'swiper/types';
import { skip } from 'rxjs/operators';
import { Mousewheel } from 'swiper';

@Component({
  selector: 'categories-menu-items',
  templateUrl: './categories-menu-items.component.html',
  styleUrls: ['./categories-menu-items.component.scss'],
  providers: [ShopMenuItemsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesMenuItemsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public shop: Shop;
  @Output() public categorySelected: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public swiperOptions: SwiperOptions = {
    modules: [Mousewheel],
    mousewheel: { forceToAxis: true, releaseOnEdges: false },
    slidesPerView: 'auto',
    grabCursor: true
  };
  public orders: Order[] = [];
  public offers: Offer[] = [];
  public previewOffers: Offer[];
  public previewProductOffers: Product[];
  public previewItems: (Offer | Product)[];
  public productsByCategory: ProductsByCategory;
  public scrollOffset: number;
  public inFirstCategory: boolean;
  public previewCategories: Category[] = [];
  public currentCategory: Category;
  public loading: boolean;

  private ordersSubscription: Subscription;
  private layoutSubscription: Subscription;
  private categoriesSubscription: Subscription;
  private subCategoriesSubscription: Subscription;
  private mainItemsSubscription: Subscription;
  private previewItemsSubscription: Subscription;

  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1.0;

  constructor(
    private userService: UserService,
    private shopService: ShopService,
    private breakpointObserver: BreakpointObserver,
    private shopMenuNavService: ShopMenuNavService,
    private shopMenuItemsService: ShopMenuItemsService,
    private menuNavService: ShopMenuNavService,
    private shopMenuPageService: ShopMenuPageService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    if (!this.userService.isGuest) this.setOrdersSubscription();
    this.setLayoutSubscription();
    this.setPreviewItemsSubscription();
    this.setCategorySubscription();
    this.setMainItemsSubscription();
    this.setSubCategorySubscription();
  }

  @HostBinding('class') public hostClass = 'categories-menu-items';

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  ngOnDestroy(): void {
    this.ordersSubscription?.unsubscribe();
    this.layoutSubscription?.unsubscribe();
    this.categoriesSubscription?.unsubscribe();
    this.subCategoriesSubscription?.unsubscribe();
    this.mainItemsSubscription?.unsubscribe();
    this.previewItemsSubscription?.unsubscribe();
    this.menuNavService.clearState();
  }

  public onSectionChange(categoryId: string): void {
    if (!categoryId) return;
    const parentId = this.currentCategory?._id;
    if (this.isExtraCateogryViewNavItem(categoryId)) {
      this.shopMenuNavService.setMenuItem(categoryId);
    } else {
      this.shopMenuNavService.setMenuItem(parentId, categoryId);
    }
    this.changeDetectorRef.detectChanges();
  }

  public onItemAdd(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductAdd({
        item: item as Product,
        itemsType: 'supermarket'
      });
    }
    this.shopMenuPageService.onOfferAdd({ item: item as Offer, itemsType: 'supermarket' });
  }

  public onItemRemove(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductRemove({
        item: item as Product,
        itemsType: 'supermarket'
      });
    }
    this.shopMenuPageService.onOfferRemove({ item: item as Offer, itemsType: 'supermarket' });
  }

  public onItemClick(item: Offer | Product, index?: number): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductSelect({
        item: item as Product,
        itemsType: 'supermarket',
        index
      });
    }
    this.shopMenuPageService.onOfferSelect({ item: item as Offer, itemsType: 'supermarket', index });
  }

  private setOrdersSubscription(): void {
    this.ordersSubscription = this.shopService.orders.subscribe((orders) => {
      this.orders = orders;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setLayoutSubscription(): void {
    const layoutChanges = this.breakpointObserver.observe(['(max-width: 992px)']);
    this.layoutSubscription = layoutChanges.subscribe(() => {
      const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 992px)');
      const offsetTarget = isSmallScreen ? 'mobile' : 'desktop';
      this.scrollOffset = this.shopMenuNavService.getScrollOffset(offsetTarget);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCategorySubscription(): void {
    this.categoriesSubscription = this.menuNavService.selectedParentCategory.subscribe((selectedParentCategory) => {
      if (!selectedParentCategory) {
        this.shopService.setMenuProducts(this.previewProductOffers);
        this.shopService.setMenuOffers(this.previewOffers);
      }
      if (!selectedParentCategory || selectedParentCategory._id === this.currentCategory?._id) {
        this.updateViewWithCurrentlySelectedCategory();
        return this.changeDetectorRef.detectChanges();
      }
      this.loading = true;
      this.changeDetectorRef.detectChanges();
      this.shopMenuItemsService.getCategoryItems(selectedParentCategory._id).subscribe(() => {
        this.loading = false;
        this.scrollToSelectedCategory();
      });
    });
  }

  private setPreviewItemsSubscription(): void {
    this.previewItemsSubscription = this.shopMenuItemsService
      .getSmMenuItems()
      .pipe(first())
      .subscribe((items) => {
        this.previewOffers = items.offers;
        this.previewProductOffers = items.products;
        this.previewItems = [...this.previewOffers, ...this.previewProductOffers];
        this.previewCategories = items.categories.filter((cat) => cat._id !== 'client_offers_category');
        this.changeDetectorRef.detectChanges();
      });
  }

  private setMainItemsSubscription(): void {
    this.mainItemsSubscription = this.shopMenuItemsService
      .getSmMenuItems()
      .pipe(skip(1))
      .subscribe((items) => {
        const itemsWithUpdatedQuantities = [...items.offers, ...items.products];
        this.offers = items.offers;
        this.previewItems = this.previewItems.map((item) => {
          if (!itemsWithUpdatedQuantities?.length) return item;
          const updatedItem = itemsWithUpdatedQuantities.find((updatedItem) => updatedItem._id === item._id);
          return updatedItem ? updatedItem : item;
        });
        this.productsByCategory = items.productsByCategory;
        this.updateViewWithCurrentlySelectedCategory();
        this.changeDetectorRef.detectChanges();
      });
  }

  private setSubCategorySubscription(): void {
    this.subCategoriesSubscription = this.menuNavService.selectedSubCategory.subscribe(() => {
      if (this.loading) return;
      this.scrollToSelectedCategory();
    });
  }

  private scrollToSelectedCategory(): void {
    const selectedParentCategory = this.menuNavService.selectedParentCategory.getValue();
    if (!selectedParentCategory) return;
    const subCategory = this.menuNavService.selectedSubCategory.getValue();
    const belongsToParent = subCategory
      ? selectedParentCategory.children?.find((cat) => cat?._id === subCategory?._id)
      : false;
    const target = subCategory?._id && belongsToParent ? subCategory._id : selectedParentCategory._id;
    this.shopMenuNavService.setMenuItem(selectedParentCategory._id, subCategory?._id);
    this.changeDetectorRef.detectChanges();
    this.shopMenuNavService.scrollTo(target, this.scrollOffset);
  }

  public trackById(index: number, item: Category): string {
    return item?._id;
  }

  public trackByIdAndQuantity(index: number, item: Product | Offer): string {
    return item?._id + String(item?.cartQuantity ?? 0);
  }

  public getCategoryImageUrl(category: Category): string {
    if (!category?.image) return;
    if (category.image.exactUrl) return category.image.exactUrl;
    return generateImageSrc(category.image);
  }

  public onCategoryClick(category: Category): void {
    if (!category?._id) return;
    this.shopMenuNavService.setSelectedParentCategory(category);
  }

  public onSeeAllOffersClick(): void {
    const offersCategory = this.shopService.categories.getValue().find((cat) => cat._id === 'client_offers_category');
    if (!offersCategory) return;
    this.onCategoryClick(offersCategory);
  }

  private updateViewWithCurrentlySelectedCategory(): void {
    const selectedCategory = this.menuNavService.selectedParentCategory.getValue();
    const decoratedCategories = this.shopService.categories.getValue(); // may include categories that are built by us
    if (!selectedCategory || !decoratedCategories?.length) {
      this.categorySelected.emit(false);
      this.currentCategory = null;
      return;
    }
    const populatedSelectedCategory = decoratedCategories.find((cat) => cat._id === selectedCategory._id);
    this.currentCategory = clearCategoryFromEmptyChildren(populatedSelectedCategory, this.productsByCategory);
    this.categorySelected.emit(true); // for nav menu mobile
  }

  public onSwipeRight(): void {
    if (!this.currentCategory) return;
    this.shopMenuNavService.goToSideParentCategory(this.shopService.categories.getValue(), 'left');
  }

  public onSwipeLeft(): void {
    if (!this.currentCategory) return;
    this.shopMenuNavService.goToSideParentCategory(this.shopService.categories.getValue(), 'right');
  }

  public onShopOrdersEnteredViewport(): void {
    this.triggerAnalyticsEvent();
  }

  private triggerAnalyticsEvent(): void {
    const gaConfig = {
      creative_name: 'Πρόσφατες παραγγελίες',
      creative_slot: 'shop',
      promotion_id: '',
      promotion_name: 'recent_orders_shop'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }

  private isExtraCateogryViewNavItem(categoryId: string): boolean {
    if (categoryId === 'reminder_category' || categoryId === 'orders_category') return true;
    return false;
  }
}
