import { Injectable } from '@angular/core';
import { Cuisine } from '@box-types';
import { getCuisineColor, generateImageSrc } from '@box/utils';

@Injectable()
export class DeliveryCuisineHeroService {
  public getCuisineColor(cuisine: Cuisine): string {
    return getCuisineColor(cuisine);
  }

  public getCuisineImage(cuisine: Cuisine): string {
    return generateImageSrc(cuisine?.webImage);
  }
}
