import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ConfigurationService } from '@box-core/services';
import { getLegalDocumentBody } from '@box/utils';

@Injectable({ providedIn: 'root' })
export class LegalDocumentsService {
  constructor(private http: HttpClient, private configService: ConfigurationService) {}

  public fetchTermsAndConditions(): Observable<string> {
    const TERMS_URL = this.getLegalDocumentUrl('terms');
    return this.http.get(TERMS_URL, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }

  public fetchPrivacyTerms(): Observable<string> {
    const PRIVACY_TERMS = this.getLegalDocumentUrl('privacy');
    return this.http.get(PRIVACY_TERMS, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }

  public fetchMarketingTerms(): Observable<string> {
    const MARKETING_TERMS = this.getLegalDocumentUrl('marketingTerms');
    return this.http.get(MARKETING_TERMS, { responseType: 'text' }).pipe(map((html) => getLegalDocumentBody(html)));
  }

  private getLegalDocumentUrl(key: string): string {
    const legalDocuments = this.configService.getConfiguration()?.legalDocuments ?? {};
    return legalDocuments[key];
  }
}
