<div class="checkout-coupon-code-wrapper box-dialog-wrapper">
  <header class="checkout-coupon-code-header box-dialog-header">
    <box-dialog-header title="Εισαγωγή κουπονιού" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main class="box-dialog-content">
    <div class="checkout-coupon-code-body">
      <p class="checkout-coupon-code-instructions">{{ description }}</p>
      <mat-form-field class="checkout-coupon-code-field" [floatLabel]="'never'">
        <input
          #couponInput
          matInput
          name="couponCode"
          class="checkout-coupon-code-input"
          placeholder="Πληκτρολόγησε εδώ τον κωδικό σου"
          [formControl]="couponControl"
          (input)="onCouponInput()"
          (keydown.enter)="onSubmit()"
        />
      </mat-form-field>
    </div>
  </main>
  <footer class="checkout-coupon-code-footer box-dialog-footer">
    <button
      box-button
      theme="primary"
      class="checkout-coupon-code-btn"
      (click)="onSubmit()"
      [disabled]="!couponControl.value"
    >
      Εφαρμογή
    </button>
  </footer>
</div>
