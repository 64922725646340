import { Component, OnInit, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';
import { Order } from '@box-types';
import { getOrderTrackingStatusEstimationText, getOrderStatusTimeText, isOrderScheduled } from '@box/utils';

@Component({
  selector: 'order-tile-status',
  templateUrl: './order-tile-status.component.html',
  styleUrls: ['./order-tile-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderTileStatusComponent implements OnInit {
  @Input() private order: Order;

  public icon: string;
  public estimationText: string;
  public estimationTime: string;

  @HostBinding('class') public hostClass = 'order-tile-status';

  ngOnInit(): void {
    const isScheduled = isOrderScheduled(this.order);
    this.icon = isScheduled ? 'today' : 'schedule';
    this.estimationText = getOrderTrackingStatusEstimationText(this.order);
    this.estimationTime = getOrderStatusTimeText(this.order);
  }
}
