<div [swiper]="swiperOptions" class="checkout-amounts-swiper-wrapper" *ngIf="radioButtons?.length">
  <div class="swiper-wrapper">
    <div *ngFor="let radioButtons of radioButtons" class="swiper-slide checkout-amounts-radio-button">
      <input
        class="checkout-amounts-radio-button-input"
        [attr.id]="radioButtons.id"
        type="radio"
        [value]="radioButtons.value"
        (change)="onRadioButtonChange(radioButtons)"
        [checked]="selectedRadioButton.id === radioButtons.id"
      />
      <label class="checkout-amounts-radio-button-label ods-typography-body-m-regular" [attr.for]="radioButtons.id">{{
        radioButtons.label
      }}</label>
    </div>
    <div class="swiper-slide checkout-amounts-radio-button">
      <input
        class="checkout-amounts-radio-button-input"
        [attr.id]="customRadioButton.id"
        type="radio"
        [value]="customRadioButton.value"
        (change)="onRadioButtonChange(customRadioButton)"
        [checked]="selectedRadioButton.id === customRadioButton.id"
        (click)="onCustomAmountClick()"
      />

      <label
        class="checkout-amounts-radio-button-label ods-typography-body-m-regular"
        [attr.for]="customRadioButton.id"
      >
        <div *ngIf="edittingCustomAmount; else notEditting" class="checkout-amounts-custom-input-container">
          <input
            #customAmountInput
            class="checkout-amounts-custom-input ods-typography-body-m-regular"
            type="number"
            [formControl]="customAmountFormControl"
            (blur)="onCustomAmountSubmit()"
            (keydown.enter)="onCustomAmountSubmit()"
            (keydown.escape)="onCustomAmountCancel()"
            placeholder="0"
          />
          <span class="checkout-amounts-custom-input-suffix">&euro;</span>
        </div>
        <ng-template #notEditting>
          {{ customRadioButton.label }}
          <svg
            *ngIf="selectedRadioButton.id === customRadioButton.id && !edittingCustomAmount"
            class="checkout-amounts-custom-input-edit-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              d="M13.8067 4.69335C14.0667 4.43335 14.0667 4.00002 13.8067 3.75335L12.2467 2.19335C12 1.93335 11.5667 1.93335 11.3067 2.19335L10.08 3.41335L12.58 5.91335M2 11.5V14H4.5L11.8733 6.62002L9.37333 4.12002L2 11.5Z"
            />
          </svg>
        </ng-template>
      </label>
    </div>
  </div>
</div>
