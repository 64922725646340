import { Injectable } from '@angular/core';
import { LoyaltyService, ReviewsService, UserService } from '@box-core/services';
import { Order, CreateReviewOptions, Review, ReviewProduct } from '@box-types';
import { uniqBy } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReviewDialogRecommendation, ReviewDialogState } from './review-dialog.types';
import { isOrderDaas, isOrderDelivery } from '@box/utils';

const POSITIVE_PLACEHOLDER = 'Τι σου άρεσε;';
const NEGATIVE_PLACEHOLDER = 'Τι δεν σου άρεσε;';
const POSITIVE_RATING_THRESHOLD = 4;

@Injectable()
export class ReviewDialogService {
  private readonly stateSource = new BehaviorSubject<ReviewDialogState>('PRE_SUBMIT');
  public readonly state$ = this.stateSource.asObservable();

  constructor(
    private reviewsService: ReviewsService,
    private userService: UserService,
    private loyaltyService: LoyaltyService
  ) {}

  public setState(state: ReviewDialogState): void {
    this.stateSource.next(state);
  }

  public getReviewPlaceholder(rating: number): string {
    return this.isRatingPositive(rating) ? POSITIVE_PLACEHOLDER : NEGATIVE_PLACEHOLDER;
  }

  public createReview(options: CreateReviewOptions): Observable<Review> {
    return this.reviewsService.createReview(options);
  }

  public addUserPoints(points: number): void {
    this.userService.addPoints(points);
  }

  public generateOrderRecommendations(order: Order): ReviewDialogRecommendation[] {
    if (!order) return [];
    const orderProducts = order.products;
    const orderOfferProducts = order.offers.flatMap((o) => o.products);
    const products = [...orderProducts, ...orderOfferProducts];
    if (!products?.length) return [];
    return uniqBy(products, 'productId').map((product) => ({
      productId: product._id,
      productName: product.name,
      checked: false
    }));
  }

  public shouldShowDeliveryRating(order: Order): boolean {
    return isOrderDelivery(order);
  }

  public generateDeliveryRatingTitle(order: Order): string {
    if (isOrderDaas(order)) return 'Πώς ήταν η διανομή απο το ΒΟΧ;';
    return 'Πώς ήταν η διανομή;';
  }

  public isRatingPositive(rating: number): boolean {
    return POSITIVE_RATING_THRESHOLD <= rating;
  }

  public getClaimablePoints(): number {
    return this.loyaltyService.getClaimablePoints('order_rating_comments');
  }

  public getReviewProductsFromRecommendations(
    rating: number,
    recommendations: ReviewDialogRecommendation[]
  ): ReviewProduct[] {
    if (!this.isRatingPositive(rating)) return [];
    return recommendations
      .filter((recommendation) => recommendation.checked === true)
      .map((recommendation) => ({ productId: recommendation.productId, name: recommendation.productName }));
  }

  public getRecommendationsTitle(order: Order): string {
    if (order.shop.isSuperMarket) return 'Ποια προϊόντα θα πρότεινες στους φίλους σου; ';
    return 'Ποιο πιάτο θα πρότεινες στους φίλους σου;';
  }
}
