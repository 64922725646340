import { Shop, DeliveryMethod } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RouterHistoryService } from '@box-core/services/router-history.service';
import { storageGet, storageSet, storageRemove } from '@box/utils';

const DELIVERY_METHOD = 'Box:deliveryMethod';

@Injectable({ providedIn: 'root' })
export class DeliveryMethodService {
  public readonly deliveryMethod = new BehaviorSubject<DeliveryMethod>(
    storageGet(DELIVERY_METHOD, window.sessionStorage) ?? 'delivery'
  );

  constructor(private routerHistoryService: RouterHistoryService) {}

  public saveDeliveryMethod(method: DeliveryMethod, shop: Shop): void {
    if (!method) return;
    if (!shop || !shop[method]) return;
    const previousMethod = this.getDeliveryMethod();
    if (previousMethod === method) return;
    storageSet(DELIVERY_METHOD, method, window.sessionStorage);
    this.deliveryMethod.next(method);
  }

  public getDeliveryMethod(): DeliveryMethod {
    return this.deliveryMethod.getValue();
  }

  public removeDeliveryMethodFromStorage(): void {
    storageRemove(DELIVERY_METHOD, window.sessionStorage);
  }

  public selectDeliveryMethodForShopPage(shop: Shop): void {
    const comingFromCheckout = this.routerHistoryService.getPreviousUrl()?.includes('/checkout?');
    const comingFromRefresh = this.routerHistoryService.getPreviousUrl()?.includes('/delivery/');
    if (comingFromCheckout || comingFromRefresh) return this.initializeMethod(shop);
    this.selectDeliveryMethod(shop);
  }

  private selectDeliveryMethod(shop: Shop): void {
    if (shop?.delivery) {
      this.saveDeliveryMethod('delivery', shop);
    } else if (shop?.takeAway) {
      this.saveDeliveryMethod('takeAway', shop);
    } else {
      this.removeDeliveryMethodFromStorage();
    }
  }

  private selectTakeAwayMethod(shop: Shop): void {
    if (shop?.takeAway) {
      this.saveDeliveryMethod('takeAway', shop);
    } else if (shop?.delivery) {
      this.saveDeliveryMethod('delivery', shop);
    } else {
      this.removeDeliveryMethodFromStorage();
    }
  }

  public initializeMethod(shop: Shop): void {
    if (!shop) return;
    const deliveryMethod = this.getDeliveryMethod();
    switch (deliveryMethod) {
      case 'delivery':
        this.selectDeliveryMethod(shop);
        break;
      case 'takeAway':
        this.selectTakeAwayMethod(shop);
        break;
      default:
        this.selectDeliveryMethod(shop);
    }
  }
}
