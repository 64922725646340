import { ShopDetails } from '@box-types';

export { generateSEODescriptionHTML, generateShopDishesText, generateShopRatingText };

function generateShopDishesText(name: string, dishes: string[]): string {
  if (!name || !dishes?.length) return '';
  return `Οι επιλογές που σου προτείνουμε από το ${name} είναι: ${dishes.join(', ')}.`;
}

function generateShopRatingText(name: string, location: string, rating: number): string {
  if (!name || !location || !rating) return '';
  return `To κατάστημα ${name} στην περιοχή ${location} βαθμολογήθηκε με ${rating} αστεράκια. Εσύ τι λες;`;
}

function generateSEODescriptionHTML(shopDetails: ShopDetails, template: string): string {
  if (!shopDetails || !template) return undefined;
  const { location, deliveryLocations, name, address, ratingOverall, popularDishes } = shopDetails;
  return template
    .replace(/__SHOP_LOCATION__/gi, location.name)
    .replace(/__SHOP_DELIVERY_LOCATIONS__/gi, deliveryLocations.join(', '))
    .replace(/__SHOP_NAME__/gi, name)
    .replace(/__SHOP_ADDRESS__/gi, `${address?.street} ${address?.streetNo}`)
    .replace(/__SHOP_RATING_TEXT__/gi, generateShopRatingText(name, location.name, ratingOverall))
    .replace(/__SHOP_DISHES_TEXT__/gi, generateShopDishesText(name, popularDishes));
}
