<div class="offer-wizard-product-content">
  <div class="offer-wizard-product-header">
    <promo-badge *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
    <div class="ods-typography-body-s-bold">{{ name }}</div>
  </div>
  <div class="offer-wizard-product-description ods-typography-body-s-regular">{{ description }}</div>
  <span *ngIf="totalEnvFee" class="offer-wizard-product-env-fee">
    Το προϊόν περιλαμβάνει περιβαλλοντικό τέλος {{ totalEnvFee / 100 | currency: 'EUR' }}
  </span>
  <product-description-badges
    *ngIf="product.tags || product.brand"
    [brand]="product.brand"
    [tags]="product.tags"
  ></product-description-badges>
</div>
<div class="offer-wizard-product-action">
  <div *ngIf="showPrice" class="offer-wizard-product-price">{{ productPrice / 100 | currency: 'EUR' }}</div>
  <button
    *ngIf="product.checked"
    box-button
    theme="insignificant"
    size="small"
    class="offer-wizard-product-button"
    (click)="onProductMYO()"
    [disabled]="!product.available"
  >
    <div class="offer-wizard-product-button-content">
      <span>Επιλογές</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </button>
</div>
