import { Injectable } from '@angular/core';
import { Product, ProductInstance, Offer, OfferInstance, PromoCampaign } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from './dialog.service';
import {
  Cart,
  CreateCartOptions,
  CartActionResponse,
  addInstanceToItem,
  calculateCartVolume,
  cartSupportsVolume,
  createCart,
  emptyCart,
  isCartEmpty,
  maxCartQuantityForItemWillBeReached,
  maxCartVolumeWillBeReached,
  removeCartCollectionFromStorage,
  removeInstanceFromItem,
  setCartCollectionToStorage,
  updateCart,
  updateInstanceToItem,
  getCartCollectionsFromStorage,
  collectionExpired,
  CartCollection,
  calculateCartValues,
  getCartInactiveOffers,
  remainingRequiredChoices,
  cartHasCampaign,
  getOfferProductNamesWithRemainingChoices,
  isStorageSupported
} from '@box/utils';

/** Since the CartService is Global and it initialization depends on other services, we wanna
 * set the cart source to a default version to avoid handling undefined and null properties. When
 * we get rid of the Cart Service global usage, we are gonna initialize this state on the
 * Cart Component initialization. At that point we would have all the appropriate data we need
 * to initialize the service. */
const DEFAULT_CART = createCart({ shop: null });

@Injectable({ providedIn: 'root' })
export class CartService {
  private readonly cartSource = new BehaviorSubject<Cart>(DEFAULT_CART);
  public readonly cart$ = this.cartSource.asObservable();

  constructor(private dialogService: DialogService) {
    this.checkExpiredCartCollections();
  }

  public initializeCart(options: CreateCartOptions): void {
    const updatedCart = createCart(options);
    this.calculatePricesAndUpdateCart(updatedCart);
  }

  public getCart(): Cart {
    return this.cartSource.getValue();
  }

  public updateCart(cart: Cart): void {
    const currentCart = this.cartSource.getValue();
    this.cartSource.next(updateCart(currentCart, cart));
  }

  /** The emptyCart method will empty the cart but keep the shop state untouched. Use
   * this method when you want to stay on the Shop View or Checkout View but want to clear
   * all the products from the Cart */
  public emptyCart(): void {
    const cart = this.cartSource.getValue();
    this.cartSource.next(emptyCart(cart));
    const collectionType = this.cartSource.getValue().shop?.collectionType;
    if (collectionType) removeCartCollectionFromStorage(collectionType, window.localStorage);
  }

  /** The clearCart method will empty the cart and reset the shop as well. Due to the fact that cart is
   * perfectly tied to the shop, in order for the state to clear completely, we need to reset the shop
   * as well. Use this method when you want to leave the Shop View or Checkout View completely */
  public clearCartAndShop(): void {
    const collectionType = this.cartSource.getValue().shop?.collectionType;
    this.cartSource.next(DEFAULT_CART);
    if (collectionType) removeCartCollectionFromStorage(collectionType, window.localStorage);
  }

  public isCartEmpty(): boolean {
    return isCartEmpty(this.cartSource.getValue());
  }

  public addProduct(product: Product, instance: ProductInstance): CartActionResponse {
    const cart = this.cartSource.getValue();
    const cartProductIndex = cart.products.findIndex((item) => item._id === product._id);
    /** For the Grocery shops that are using the menuDisplay === 'BY_CATEGORY', there are cases
    that we do not have the Menu Item. Therefore we do add the Product as is.*/
    const cartProduct = cartProductIndex === -1 ? product : cart.products[cartProductIndex];
    const remainingChoices = remainingRequiredChoices(instance);
    if (remainingChoices > 0) {
      this.showProductChoicesThresholdDialog(remainingChoices);
      return 'CHOICES_THRESHOLD_NOT_REACHED';
    }
    const instanceQuantity = instance.quantity;
    const itemVolume = (cartProduct.volume ?? 0) * instanceQuantity;
    const maxVolumeWillBeReached = maxCartVolumeWillBeReached(cart, itemVolume);
    if (maxVolumeWillBeReached) {
      this.showMaxCartLimitReachedDialog();
      return 'CART_LIMIT_REACHED';
    }
    const itemQuantity = cartProduct.cartQuantity + instanceQuantity;
    const maxQuantityReached = maxCartQuantityForItemWillBeReached(itemQuantity, cartProduct);
    if (maxQuantityReached) {
      this.showMaxItemLimitReachedDialog();
      return 'ITEM_LIMIT_REACHED';
    }
    const clonedCartProduct = addInstanceToItem(cartProduct, instance);
    if (cartProductIndex === -1) {
      cart.products.push(clonedCartProduct);
    } else {
      cart.products[cartProductIndex] = clonedCartProduct;
    }
    this.calculatePricesAndUpdateCart(cart);
    this.setLocalCartCollections();
    return 'PRODUCT_ADDED';
  }

  public updateProduct(
    product: Product,
    instance: ProductInstance,
    updatedInstance: ProductInstance
  ): CartActionResponse {
    const cart = this.cartSource.getValue();
    const cartProductIndex = cart.products.findIndex((item) => item._id === product._id);
    if (cartProductIndex === -1) return;
    const cartProduct = cart.products[cartProductIndex];
    const remainingChoices = remainingRequiredChoices(updatedInstance);
    if (remainingChoices > 0) {
      this.showProductChoicesThresholdDialog(remainingChoices);
      return 'CHOICES_THRESHOLD_NOT_REACHED';
    }
    const instanceQuantityDifference = updatedInstance.quantity - instance.quantity;
    if (instanceQuantityDifference > 0) {
      const volume = (cartProduct.volume ?? 0) * instanceQuantityDifference;
      if (maxCartVolumeWillBeReached(cart, volume)) {
        this.showMaxCartLimitReachedDialog();
        return 'CART_LIMIT_REACHED';
      }
    }
    const newProductCartQuantity = cartProduct.cartQuantity + instanceQuantityDifference;
    const maxQuantityReached = maxCartQuantityForItemWillBeReached(newProductCartQuantity, cartProduct);
    if (maxQuantityReached) {
      this.showMaxItemLimitReachedDialog();
      return 'ITEM_LIMIT_REACHED';
    }
    const clonedCartProduct = updateInstanceToItem(cartProduct, instance, updatedInstance);
    cart.products[cartProductIndex] = clonedCartProduct;
    this.calculatePricesAndUpdateCart(cart);
    this.setLocalCartCollections();
    return 'PRODUCT_ADDED';
  }

  public removeProduct(product: Product, instance: ProductInstance): CartActionResponse {
    const cart = this.cartSource.getValue();
    const cartProductIndex = cart.products.findIndex((item) => item._id === product._id);
    if (cartProductIndex === -1) return;
    const cartProduct = cart.products[cartProductIndex];
    if (!cartProduct.cartInstances?.length) return;
    const clonedCartProduct = removeInstanceFromItem(cartProduct, instance);
    if (!clonedCartProduct.cartQuantity) {
      cart.products.splice(cartProductIndex, 1);
    } else {
      cart.products[cartProductIndex] = clonedCartProduct;
    }
    this.calculatePricesAndUpdateCart(cart);
    this.setLocalCartCollections();
    return 'PRODUCT_REMOVED';
  }

  public addOffer(offer: Offer, instance: OfferInstance): CartActionResponse {
    /** The next piece of code covers a case with the previous order offer being modified. When a previous order offer
    is the same but has new groups, we should not add it to the cart. sadly we do not support this functionality
    for now, but will fix it with the new SM Improvements (will be transfered to BE as full logic) */
    const allSelectedProductsExists = instance.groups.every((group) => Boolean(group.selectedProduct));
    if (!allSelectedProductsExists) return;
    const cart = this.cartSource.getValue();
    const cartOfferIndex = cart.offers.findIndex((item) => item._id === offer._id);
    /** For the Grocery shops that are using the menuDisplay === 'BY_CATEGORY', there are cases
    that we do not have the Menu Item. Therefore we do add the Product as is.*/
    const cartOffer = cartOfferIndex === -1 ? offer : cart.offers[cartOfferIndex];
    const incompleteProducts = getOfferProductNamesWithRemainingChoices(instance);
    if (incompleteProducts.length > 0) {
      this.showOfferChoicesThresholdDialog(incompleteProducts);
      return 'CHOICES_THRESHOLD_NOT_REACHED';
    }
    const instanceQuantity = instance.quantity;
    const volume = (offer.volume ?? 0) * instanceQuantity;
    if (maxCartVolumeWillBeReached(cart, volume)) {
      this.showMaxCartLimitReachedDialog();
      return 'CART_LIMIT_REACHED';
    }
    const itemQuantity = cartOffer.cartQuantity + instanceQuantity;
    const maxQuantityReached = maxCartQuantityForItemWillBeReached(itemQuantity, cartOffer);
    if (maxQuantityReached) {
      this.showMaxItemLimitReachedDialog();
      return 'ITEM_LIMIT_REACHED';
    }
    const clonedCartOffer = addInstanceToItem(cartOffer, instance);
    if (cartOfferIndex === -1) {
      cart.offers.push(clonedCartOffer);
    } else {
      cart.offers[cartOfferIndex] = clonedCartOffer;
    }
    this.calculatePricesAndUpdateCart(cart);
    this.setLocalCartCollections();
    return 'OFFER_ADDED';
  }

  public updateOffer(offer: Offer, instance: OfferInstance, updatedInstance: OfferInstance): CartActionResponse {
    const cart = this.cartSource.getValue();
    const cartOfferIndex = cart.offers.findIndex((item) => item._id === offer._id);
    if (cartOfferIndex === -1) return;
    const cartOffer = cart.offers[cartOfferIndex];
    const incompleteProducts = getOfferProductNamesWithRemainingChoices(updatedInstance);
    if (incompleteProducts.length > 0) {
      this.showOfferChoicesThresholdDialog(incompleteProducts);
      return 'CHOICES_THRESHOLD_NOT_REACHED';
    }
    const instanceQuantityDifference = updatedInstance.quantity - instance.quantity;
    if (instanceQuantityDifference > 0) {
      const volume = (cartOffer.volume ?? 0) * instanceQuantityDifference;
      if (maxCartVolumeWillBeReached(cart, volume)) {
        this.showMaxCartLimitReachedDialog();
        return 'CART_LIMIT_REACHED';
      }
    }
    const newOfferCartQuantity = cartOffer.cartQuantity + instanceQuantityDifference;
    const maxQuantityReached = maxCartQuantityForItemWillBeReached(newOfferCartQuantity, cartOffer);
    if (maxQuantityReached) {
      this.showMaxItemLimitReachedDialog();
      return 'ITEM_LIMIT_REACHED';
    }
    const clonedCartOffer = updateInstanceToItem(cartOffer, instance, updatedInstance);
    cart.offers[cartOfferIndex] = clonedCartOffer;
    this.calculatePricesAndUpdateCart(cart);
    this.setLocalCartCollections();
    return 'OFFER_UPDATED';
  }

  public removeOffer(offer: Offer, instance: OfferInstance): CartActionResponse {
    const cart = this.cartSource.getValue();
    const cartOfferIndex = cart.offers.findIndex((item) => item._id === offer._id);
    if (cartOfferIndex === -1) return;
    const cartOffer = cart.offers[cartOfferIndex];
    if (!cartOffer.cartInstances?.length) return;
    const clonedCartOffer = removeInstanceFromItem(cartOffer, instance);
    if (!clonedCartOffer.cartQuantity) {
      cart.offers.splice(cartOfferIndex, 1);
    } else {
      cart.offers[cartOfferIndex] = clonedCartOffer;
    }
    this.calculatePricesAndUpdateCart(cart);
    this.setLocalCartCollections();
    return 'OFFER_REMOVED';
  }

  public removeInactiveOffers(): void {
    const cart = this.cartSource.getValue();
    const inactiveOfferIds = getCartInactiveOffers(cart).map((offer) => offer._id);
    const updatedOffers = cart.offers.filter((offer) => !inactiveOfferIds.includes(offer._id));
    const updatedCart = { ...cart, offers: updatedOffers };
    this.calculatePricesAndUpdateCart(updatedCart);
    this.setLocalCartCollections();
  }

  private calculatePricesAndUpdateCart(cart: Cart): void {
    const updatedCart = calculateCartValues(cart);
    if (cartSupportsVolume(cart)) updatedCart.volume = calculateCartVolume(updatedCart);
    this.updateCart(updatedCart);
  }

  private showProductChoicesThresholdDialog(remainingChoices: number): void {
    const title = 'Προσθήκη υλικών';
    const ingredientString = `${remainingChoices} ${remainingChoices > 1 ? 'υλικά' : 'υλικό'}`;
    const messages = [`Πρέπει να προσθέσεις ακόμη ${ingredientString} για να προχωρήσεις με την παραγγελία σου.`];
    this.dialogService.openInfoDialog({ title, messages });
  }

  private showMaxCartLimitReachedDialog(): void {
    this.dialogService.openInfoDialog({
      title: 'Μέγιστο όριο παράδοσης',
      messages: ['Έχεις φτάσει στο μέγιστο επιτρεπόμενο όριο όγκου προϊόντων για Express παράδοση!']
    });
  }

  private showMaxItemLimitReachedDialog(): void {
    this.dialogService.openInfoDialog({
      title: 'Μέγιστο όριο αγοράς προϊόντος',
      messages: ['Εχεις φτάσει το μέγιστο όριο αγοράς αυτού του προϊόντος.']
    });
  }

  private showOfferChoicesThresholdDialog(productNames: string[]): void {
    this.dialogService.openInfoDialog({
      title: 'Προσθήκη υλικών',
      messages: [
        `Πρέπει να προσθέσεις υλικά στα προϊόντα ${productNames.join(', ')} για να προχωρήσεις με την παραγγελία σου`
      ]
    });
  }

  private checkExpiredCartCollections(): void {
    const cartCollections = getCartCollectionsFromStorage(window.localStorage);
    if (!cartCollections) return;
    const keys = Object.keys(cartCollections);
    for (const key of keys) {
      const expired = collectionExpired(cartCollections[key] as CartCollection);
      if (expired) removeCartCollectionFromStorage(Number(key), window.localStorage);
    }
  }

  private setLocalCartCollections(): void {
    const { shop, itemsQuantity, offers, products } = this.cartSource.getValue();
    const shouldRemove = itemsQuantity === 0;
    if (shouldRemove) return removeCartCollectionFromStorage(shop.collectionType, window.localStorage);
    const collection = { offers, products, timestamp: Date.now() };
    /** This will be changed when we do the refactor on the Products/Offers. Right now
    the size of the data that we store to the localStorage is way too much for the regular user.
    We have to reduce it to only options, so that we have no QuotaExceededError */
    if (!isStorageSupported()) return;
    setCartCollectionToStorage(shop.collectionType, collection, window.localStorage);
  }

  public cartHasPromoCampaign(promoCampaign: PromoCampaign): boolean {
    if (!promoCampaign) return false;
    const cartProducts: Product[] = this.getCart().products;
    const cartOffers: Offer[] = this.getCart().offers;
    return cartHasCampaign(promoCampaign, cartProducts, cartOffers);
  }
}
