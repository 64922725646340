import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ThemingService } from '@box-core/services/theming.service';

@Injectable({ providedIn: 'root' })
export class ThemeGuard implements CanActivate {
  constructor(private themingService: ThemingService) {}

  canActivate(): Observable<boolean> {
    this.themingService.initializeTheme();
    return of(true);
  }
}
