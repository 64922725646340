<ng-container *ngFor="let selection of productInstance.selections">
  <mat-expansion-panel
    *ngIf="!selection.disabledChoices"
    class="product-myo-selection"
    expanded
    [disabled]="!selection.multipleSelection"
    (afterCollapse)="onAfterGroupCollapse()"
    (afterExpand)="onAfterGroupExpand()"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="product-myo-selection-header">
          <div class="product-myo-selection-title">
            {{ selection.title }}
          </div>
          <div
            *ngIf="selection.freeChoices || selection.maxItems"
            class="product-myo-selection-limits ods-typography-body-s-regular"
          >
            {{ getSelectionLimitsText(selection) }}
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngIf="selection.multipleSelection; else noMultiple">
      <div
        *ngFor="let choice of selection.choices; let i = index"
        class="product-myo-selection-choices selection-choices"
      >
        <mat-checkbox
          color="primary"
          [disabled]="choice.disabled || !choice.available"
          [(ngModel)]="choice.checked"
          (change)="onOptionChange(selection)"
          (click)="onChoiceClick(choice, selection)"
        >
          <product-myo-choice
            [choice]="choice"
            [showPlus]="!selection.replacePrice"
            [discount]="extraIngredientDiscount"
          ></product-myo-choice>
        </mat-checkbox>
      </div>
    </ng-container>
    <ng-template #noMultiple>
      <mat-radio-group (change)="onOptionChange(selection)" class="product-myo-selection-choices selection-choices">
        <div *ngFor="let choice of selection.choices">
          <mat-radio-button
            color="primary"
            [value]="choice"
            [checked]="choice.checked"
            (change)="selectOption(selection, choice)"
            [disabled]="choice.disabled || !choice.available"
          >
            <product-myo-choice
              [choice]="choice"
              [discount]="discount"
              [showPlus]="!selection.replacePrice"
            ></product-myo-choice>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </ng-template>
  </mat-expansion-panel>
</ng-container>

<div *ngIf="commentsAllowed" class="product-myo-selection-comment">
  <div class="product-myo-selection-comment-title">Θέλεις να προσέξουμε κάτι;</div>
  <mat-form-field class="product-myo-selection-comment-field" [floatLabel]="'never'">
    <textarea
      matInput
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      placeholder="Γράψε το σχόλιό σου εδώ"
      type="text"
      [(ngModel)]="productInstance.comments"
    ></textarea>
  </mat-form-field>
</div>
