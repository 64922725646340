<div class="daas-delay-wrapper">
  <header class="daas-delay-header">
    <mat-icon fontSet="material-icons-round" class="daas-delay-header-icon">info_outline</mat-icon>
    <h4 class="daas-delay-header-title">Χρόνος παράδοσης</h4>
    <button class="daas-delay-header-close" mat-icon-button (click)="onClose()">
      <mat-icon fontSet="material-icons-round">close</mat-icon>
    </button>
  </header>
  <main class="daas-delay-body">
    <p>{{ message }}</p>
  </main>
</div>
