<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-dialog-content">
      <div class="market-card-dialog-card-container">
        <market-card [marketCard]="marketCard"></market-card>
      </div>
      <p class="market-card-dialog-text">
        {{ created ? 'Έτοιμη η' : 'Βρήκαμε την' }} {{ cardName }} σου! Μπορείς να τη χρησιμοποιήσεις για την παραγγελία
        σου στο ΒΟΧ ώστε να:
      </p>
      <div class="market-card-dialog-rewards">
        <div class="market-card-dialog-reward">
          <span class="market-card-dialog-reward-icon">
            <mat-icon fontSet="material-icons-round">check</mat-icon>
          </span>
          <span class="market-card-dialog-reward-text">
            Συλλέγεις πόντους από τις παραγγελίες σου στα supermarkets {{ shopName }}!
          </span>
        </div>
        <div class="market-card-dialog-reward">
          <span class="market-card-dialog-reward-icon">
            <mat-icon fontSet="material-icons-round">check</mat-icon>
          </span>
          <span class="market-card-dialog-reward-text">
            Εξαργυρώνεις τους {{ cardName }} πόντους σου και να κερδίζεις εκπτώσεις για τις παραγγελίες σου στα
            supermarkets {{ shopName }}!
          </span>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-header market-card-dialog-footer">
    <div class="market-card-dialog-footer-container">
      <button box-button theme="primary" class="market-card-dialog-footer-button" (click)="onContinue()">
        Συνέχεια
      </button>
    </div>
  </footer>
</div>
