export const appConfig = {
  name: 'BOX',
  support: {
    PHONE_NUMBER: '2106241990',
    EMAIL: 'support@mail.box.gr'
  },
  user: {
    MINIMUM_TASTES: 1
  },
  shops: {
    MINIMUM_TOP_RATING_VALUE: 4.5,
    menu: {
      MAXIMUM_FREQUENT_PRODUCTS: 2,
      MAXIMUM_POPULAR_PRODUCTS: 5,
      MAXIMUM_SUGGESTED_PRODUCTS: 5,
      ORDERS_CATEGORY_INDEX: -20,
      REMINDER_CATEGORY_INDEX: -15,
      SUGGESTIONS_CATEGORY_INDEX: -10,
      OFFERS_CATEGORY_INDEX: -5,
      DESKTOP_SCROLL_OFFSET: 500,
      MOBILE_SCROLL_OFFSET: 510
    }
  },
  cart: {
    EXPIRATION_TIME: 14400000 // 4 hours in milliseconds
  },
  discover: {
    search: {
      MINIMUM_SEARCH_ITEMS: 3,
      MAXIMUM_SEARCH_ITEMS: 10
    }
  },
  ZOHO_URL: 'https://forms.zohopublic.eu/loutzakis/form/BOX/formperma/uGAq48CPjJJZcD048lVgq5VcmEhhbTM2D_fSUETmIPE',
  EARN_EMBEDDED_URL: 'https://www.youtube-nocookie.com/embed/C-81TZiFHc4',
  social: {
    FACEBOOK: 'https://www.facebook.com/pg/boxfoodgr/posts/',
    INSTAGRAM: 'https://www.instagram.com/boxfoodgr/',
    YOUTUBE: 'https://www.youtube.com/channel/UChKm8_d5Zu-wHqTVQopliag'
  },
  mobile: {
    /* This firebase dynamic link detects whether android, ios device is installed and opens it,
     * if not found, it redirects to the corresponding store.
     * On desktop, it goes to box.gr
     * Does not support huawei devices
     * flow chart: https://link.box.gr/e1ZW?d=1
     * https://firebase.google.com/docs/dynamic-links/create-manually */
    dynamicLink: 'https://link.box.gr/e1ZW',
    ANDROID_APP: 'https://play.google.com/store/apps/details?id=gr.cosmote.box',
    IOS_APP: 'https://apps.apple.com/gr/app/box/id1479674149',
    HUAWEI_APP: 'https://appgallery.huawei.com/app/C104030589'
  }
};
