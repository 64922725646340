import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { getOutputBlockHtml } from '@box/utils';
import { PromoBanner } from '@box-types';

@Injectable()
export class PromoBannerDetailsDialogService {
  constructor(private snackBar: MatSnackBar) {}
  public getPromoBannerBlocksHtml(promoBanner: PromoBanner): string {
    if (!promoBanner?.detailsDescription?.blocks?.length) return '';
    return promoBanner.detailsDescription.blocks
      .map((block) => this.normalizeHTMLAnchor(getOutputBlockHtml(block)))
      .join('');
  }

  public showCopySnackBar(): void {
    const snackConfig: MatSnackBarConfig = { duration: 3000 };
    this.snackBar.open('Ο κωδικός του κουπονιού αντιγράφηκε στο πρόχειρο.', 'ΟΚ', snackConfig);
  }

  private normalizeHTMLAnchor(html: string): string {
    return html.replace('<a ', '<a target="_blank" ');
  }
}
