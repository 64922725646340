import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileDeletionDialogResponseResponse } from './profile-deletion-dialog-data.types';
import { BoxDialogWrapperComponent, VerificationResendCodeComponent } from '@box-shared/components';
import { ProfileDeletionDialogService } from '@box-shared/components/profile-deletion-dialog/profile-deletion-dialog.service';
import { DialogService } from '@box-core/services';
import { APIError } from '@box-types';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'profile-deletion-dialog',
  templateUrl: './profile-deletion-dialog.component.html',
  styleUrls: ['./profile-deletion-dialog.component.scss'],
  providers: [ProfileDeletionDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDeletionDialogComponent extends BoxDialogWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild('verificationResendCodeComponent') public resendCodeComponent: VerificationResendCodeComponent;

  public mobilePhone: string;
  public email: string;
  public phoneControl: FormControl;
  public verificationControl: FormControl;
  public loading: boolean;

  constructor(
    public renderer: Renderer2,
    private profileDeletionDialogService: ProfileDeletionDialogService,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<ProfileDeletionDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.mobilePhone = this.profileDeletionDialogService.getMobilePhone();
    this.email = this.profileDeletionDialogService.getEmail();
    this.verificationControl = new FormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)]) // eslint-disable-line @typescript-eslint/unbound-method
    );
  }

  ngAfterViewInit(): void {
    this.generateOtp();
  }

  public closeDialog(response?: ProfileDeletionDialogResponseResponse): void {
    this.dialogRef.close(response);
  }

  public verifyOtp(): void {
    if (this.verificationControl.invalid) return;
    const otp = this.verificationControl.value as string;
    if (!otp?.length) return;
    this.loading = true;
    this.profileDeletionDialogService
      .verifyOTP(otp)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe({
        next: () => this.closeDialog({ success: true }),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  public generateOtp(): void {
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    this.profileDeletionDialogService
      .generateOTP()
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe({
        next: () => {
          this.resendCodeComponent.startDelay();
          this.verificationControl.setValue('');
          this.verificationControl.markAsUntouched();
        },
        error: (err: APIError) => this.dialogService.openErrorDialog(err)
      });
  }
}
