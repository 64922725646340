import { Injectable } from '@angular/core';
import { toInteger } from '@box/utils';

@Injectable()
export class CheckoutPointsDialogService {
  private readonly NOT_ENOUGH_POINTS_MESSAGE =
    'Το υπόλοιπο των διαθέσιμων πόντων σου δεν επαρκεί ώστε να επιλέξεις αυτό το ποσό για εξαργύρωση.';

  public generatePointsSteps(stepValue: number, maximumValue: number): string[] {
    if (!stepValue || !maximumValue) return [];
    const length = toInteger(maximumValue / stepValue) + 1;
    return Array.from({ length }).map((value, index) => String(index * stepValue));
  }

  public generateEurosSteps(stepValue: number, maximumValue: number): string[] {
    if (!stepValue || !maximumValue) return [];
    const length = toInteger(maximumValue / stepValue) + 1;
    return Array.from({ length }).map((value, index) => `${index}€`);
  }

  public getErrorMessage(
    points: number,
    availablePoints: number,
    maximumPointsInEuro: number,
    euroRedemptionRate: number
  ): string {
    if (points > availablePoints) return this.NOT_ENOUGH_POINTS_MESSAGE;
    const wantedPrice = maximumPointsInEuro * euroRedemptionRate;
    return `Για να πάρεις ${maximumPointsInEuro}€ έκπτωση, το καλάθι σου πρέπει να είναι τουλάχιστον ${wantedPrice}€.`;
  }
}
