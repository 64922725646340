import { ValidatorFn } from '@angular/forms';
import { regExToValidatorFunction } from '@box-shared/validators/validator.utils';

/** https://www3.ntu.edu.sg/home/ehchua/programming/howto/Regexe.html */
/*all characters except the special characters   (, ), <, >, #, ^, [, ], {, }, ?, |, \, and ~*/

const REG_EXP = new RegExp('^[^$<>#^[\\]{}?|"~`=\\\\]+$');

export function specialCharacterExclusionValidator(): ValidatorFn {
  return regExToValidatorFunction(REG_EXP);
}
