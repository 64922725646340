import { Injectable } from '@angular/core';
import { ReviewsService, SentryService, ShopService } from '@box-core/services';
import { APIError, Review } from '@box-types';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { ShopPageDetailsDialogState } from './shop-page-details-dialog.types';
import { formatReviewsCounter } from '@box/utils';

@Injectable()
export class ShopPageDetailsDialogService {
  private readonly stateSource = new BehaviorSubject<ShopPageDetailsDialogState>('SHOP_DETAILS');
  private readonly reviewsSource = new BehaviorSubject<Review[]>([]);

  public readonly state$ = this.stateSource.asObservable();
  public readonly reviews$ = this.reviewsSource.asObservable();

  public userHasReviewed: boolean;

  constructor(
    private shopService: ShopService,
    private reviewsService: ReviewsService,
    private sentryService: SentryService
  ) {}

  public setState(state: ShopPageDetailsDialogState): void {
    this.stateSource.next(state);
  }

  public getReviews(): Review[] {
    return this.reviewsSource.getValue();
  }

  public setReviews(reviews: Review[]): void {
    this.reviewsSource.next(reviews);
  }

  public fetchReviews(): Observable<Review[]> {
    const collectionType = this.shopService.getShop().collectionType;
    return this.reviewsService.fetchReviews(collectionType).pipe(
      catchError((error: APIError) => {
        this.sentryService.captureException(error, {
          domain: 'Reviews',
          domainDetails: 'Shop Details Dialog - fetchReviewsData',
          severity: 'error'
        });
        return of([]);
      })
    );
  }

  public generateReviewsTabTitle(reviews: Review[]): string {
    if (!reviews.length) return 'Αξιολογήσεις';
    const formattedReviewsCounter = formatReviewsCounter(reviews.length);
    return `Αξιολογήσεις (${formattedReviewsCounter})`;
  }
}
