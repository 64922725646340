import { CoreItems, User, UserPreference, UserMarketPlacePoints, EarnedPointsInfoType } from '@box-types';

const DEFAULT_USER_RELATIONS: CoreItems = { chains: [], cuisines: [], drinks: [] };
const DEFAULT_USER_POINTS: UserMarketPlacePoints = { remainingPoints: 0, collectedPoints: 0, consumedPoints: 0 };

function normalizeUser(user: User): User {
  return {
    ...user,
    relations: { ...DEFAULT_USER_RELATIONS, ...user.relations },
    loyaltyEventsAlreadyUsed: user.loyaltyEventsAlreadyUsed ?? [],
    marketPlacePoints: { ...DEFAULT_USER_POINTS, ...user.marketPlacePoints },
    loyaltyCards: user.loyaltyCards ?? [],
    segments: user.segments ?? []
  };
}

function preferencesToRelations(preferences: UserPreference[], items: CoreItems): CoreItems {
  const cuisineIDs = preferences.filter((p) => p.cuisineId).map((p) => p.cuisineId);
  const cuisines = items.cuisines.filter((c) => cuisineIDs.includes(c._id));
  const chainIDs = preferences.filter((p) => p.brandId).map((p) => p.brandId);
  const chains = items.chains.filter((c) => chainIDs.includes(c._id));
  const drinkIDs = preferences.filter((p) => p.drinkId).map((p) => p.drinkId);
  const drinks = items.drinks.filter((c) => drinkIDs.includes(c._id));
  return { cuisines, chains, drinks };
}

function getUserEarnedPointsInfo(user: User, key: EarnedPointsInfoType): { points: number; count: number } {
  const earnedPointsInfo = user?.earnedRewardsInfo?.earnedPointsInfo ?? {};
  const earnedPointsInfoValue = earnedPointsInfo[key] as { points: number; count: number };
  if (earnedPointsInfoValue?.count === undefined) return;
  return earnedPointsInfoValue;
}

function getUserEarningsInfo(user: User, key: EarnedPointsInfoType): { count: number; amount: number } {
  const earnedPointsInfo = user?.earnedRewardsInfo?.earnedPointsInfo ?? {};
  const earnedPointsInfoValue = earnedPointsInfo[key] as { count: number; amount: number };
  if (earnedPointsInfoValue?.count === undefined) return;
  return earnedPointsInfoValue;
}

function getUserFullName(user: User): string {
  const { firstName, lastName } = user;
  return `${firstName} ${lastName}`;
}

function appendMsisdnToUserAssets(user: User, assets: string[]): string[] {
  const requestAssets: string[] = [];
  if (assets?.length) requestAssets.push(...assets);
  const msisdn = user?.verifiedMSISDN ?? user?.mobile?.phone;
  if (msisdn) requestAssets.push(msisdn);
  return requestAssets;
}

export {
  normalizeUser,
  preferencesToRelations,
  getUserEarnedPointsInfo,
  getUserEarningsInfo,
  getUserFullName,
  appendMsisdnToUserAssets
};
