<div class="on-boarding-dialog-wrapper">
  <figure class="image contain-image on-boarding-dialog-shop-image">
    <img src="assets/images/logo/box-logo.svg" alt="BOX Logo" />
  </figure>
  <button mat-icon-button class="on-boarding-dialog-close-button" (click)="onClose()">
    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color">close</mat-icon>
  </button>
  <div class="on-boarding-dialog-text-container">
    <p class="on-boarding-dialog-text">Μόλις κέρδισες τους πρώτους</p>
    <div class="on-boarding-dialog-equality">
      <div class="on-boarding-dialog-equality-content">
        <div class="on-boarding-dialog-points-bullet">
          <span class="on-boarding-dialog-indicator">{{ campaignPoints }}</span>
          <span class="on-boarding-dialog-indicator-points">πόντους</span>
        </div>
        <span class="on-boarding-dialog-equal">=</span>
        <span class="on-boarding-dialog-euro">1&euro;</span>
      </div>
    </div>
    <p class="on-boarding-dialog-text">
      Εξαργύρωσέ τους κατά την αποστολή της παραγγελίας σου και κέρδισε 1&euro; σε 4&euro; παραγγελίας!
    </p>
  </div>
</div>
