<div boxNav [options]="navOptions" class="rewards-nav-wrapper">
  <a
    *ngFor="let item of navItems"
    boxNavItem
    [title]="item.title"
    [routerLink]="item.relativeUrl"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="box-nav-item-active"
    class="box-nav-item-icon"
  >
    <ng-container *ngIf="item.badge; else noBadge">
      <span
        class="rewards-nav-item-content"
        [innerHTML]="item.innerHTML | safeHtml"
        [badge]="item.badge"
        badgeSize="small"
      ></span>
    </ng-container>
    <ng-template #noBadge>
      <span class="rewards-nav-item-content" [innerHTML]="item.innerHTML | safeHtml"></span>
    </ng-template>
  </a>
</div>
