<div class="box-bottom-sheet-wrapper">
  <header #bottomSheetHeader class="box-bottom-sheet-header">
    <box-bottom-sheet-header title="Επίλεξε κατηγορία" alignment="left" (dismiss)="dismiss()"></box-bottom-sheet-header>
  </header>
  <main #bottomSheetBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-bottom-sheet-content">
    <ul *ngIf="menuNav && menuNav.items" class="shop-menu-nav-bottom-sheet-list">
      <li *ngFor="let item of menuNav.items">
        <shop-menu-nav-bottom-sheet-item
          [item]="item"
          [disableToggle]="!menuNav.hasSubItems"
          (itemClick)="onItemClick($event)"
        ></shop-menu-nav-bottom-sheet-item>
      </li>
    </ul>
  </main>
</div>
