import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProductReplacementService } from '@box-checkout/services/product-replacement.service';
import { ProductReplacementOption } from '@box-types';
import { CheckoutProductReplacementDialogComponent } from '../checkout-product-replacement-dialog/checkout-product-replacement-dialog.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CheckoutProductReplacementDialogResponse } from '../checkout-product-replacement-dialog/checkout-product-replacement-dialog.types';

@Component({
  selector: 'checkout-product-replacement',
  templateUrl: './checkout-product-replacement.component.html',
  styleUrls: ['./checkout-product-replacement.component.scss']
})
export class CheckoutProductReplacementComponent implements OnInit, OnDestroy {
  @ViewChild('productReplacementPanel') private productReplacementPanel: MatExpansionPanel;

  public selectedOption: ProductReplacementOption;

  private replacementSubscription: Subscription;

  constructor(private dialog: MatDialog, private replacementService: ProductReplacementService) {}

  ngOnInit(): void {
    this.setReplacementSubscription();
  }

  ngOnDestroy(): void {
    if (this.replacementSubscription) this.replacementSubscription.unsubscribe();
  }

  public onEdit(): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      autoFocus: false,
      restoreFocus: false,
      data: { selectedOption: this.selectedOption }
    };

    this.dialog
      .open(CheckoutProductReplacementDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: CheckoutProductReplacementDialogResponse) => {
        this.productReplacementPanel.close();
        if (!data) return;
        if (data.save) this.replacementService.setOption(data.option);
      });
  }

  private setReplacementSubscription(): void {
    this.replacementSubscription = this.replacementService.replacementOption.subscribe(
      (option) => (this.selectedOption = option)
    );
  }
}
