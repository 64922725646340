<div data-mode="light" class="benefits-banner-wrapper" (click)="onClick()">
  <span class="benefits-banner-text ods-typography-body-m-bold">{{ infoText }}</span>
  <div class="benefits-banner-points" [ngSwitch]="state">
    <img src="/assets/images/rewards/euro-icon.svg" />
    <div class="benefits-banner-points-text-container" *ngSwitchCase="'POINTS'">
      <span class="benefits-banner-points-text">{{ pointsText }}</span>
      <span class="ods-typography-body-s-bold">πόντους</span>
    </div>
    <div class="benefits-banner-points-text-container" *ngSwitchCase="'CURRENCY'">
      <span class="benefits-banner-points-text">{{ currencyText }}</span>
    </div>
  </div>
</div>
