<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      class="myo-dialog-header"
      title="Πρόσθεσε προϊόντα και κέρδισε"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-suggestion-dialog-groups">
      <div *ngFor="let group of checkoutSuggestionGroups" class="checkout-suggestion-dialog-group">
        <div class="checkout-suggestion-dialog-group-header">
          <span>Εδώ κερδίζεις</span>
          <points-tag *ngIf="group.points" sign="+">{{ group.points | number: '1.0-0' }}</points-tag>
          <points-tag *ngIf="group.multiplier">x{{ group.multiplier }}</points-tag>
          <span>πόντους!</span>
        </div>
        <food-product
          *ngFor="let product of group.products"
          subtraction="true"
          [product]="product"
          (add)="onProductAdd(group, $event)"
          (remove)="onProductRemove(group, $event)"
        ></food-product>
      </div>
    </div>
  </main>
  <div class="checkout-suggestion-dialog-cta">
    <button box-button theme="primary" (click)="onAddToCart()">Προσθήκη στο καλάθι</button>
  </div>
</div>
