import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Invoice } from '@box-types';

@Injectable()
export class InvoiceService {
  public invoiceChecked = new BehaviorSubject<boolean>(false);
  public invoice = new BehaviorSubject<Invoice>(null);

  public setChecked(value: boolean): void {
    this.invoiceChecked.next(value);
  }

  public setInvoice(invoice: Invoice): void {
    this.invoice.next(invoice);
  }

  public clearInvoice(): void {
    this.setInvoice(null);
  }

  public invoiceToString(invoice: Invoice): string {
    if (!invoice) return undefined;
    return Object.values(invoice).join(', ');
  }
}
