import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AnalyticsService, ShopService, UserService } from '@box-core/services';
import { Category, Order, Product, ProductsByCategory, Shop, Offer, GAPromotionConfig } from '@box-types';
import { Subscription } from 'rxjs';
import { ShopMenuItemsService } from './shop-menu-items.service';
import { ShopMenuDialogService, ShopMenuNavService, ShopMenuPageService } from '@box-delivery/services';

@Component({
  selector: 'shop-menu-items',
  templateUrl: './shop-menu-items.component.html',
  styleUrls: ['./shop-menu-items.component.scss'],
  providers: [ShopMenuItemsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopMenuItemsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public shop: Shop;

  public orders: Order[] = [];
  public offers: Offer[] = [];
  public categories: Category[] = [];
  public productsByCategory: ProductsByCategory = {};
  public scrollOffset: number;

  private ordersSubscription: Subscription;
  private layoutSubscription: Subscription;
  private itemsSubscription: Subscription;

  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 1.0;

  constructor(
    private userService: UserService,
    private shopService: ShopService,
    private breakpointObserver: BreakpointObserver,
    private shopMenuNavService: ShopMenuNavService,
    private shopMenuItemsService: ShopMenuItemsService,
    private shopMenuPageService: ShopMenuPageService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private shopMenuDialogService: ShopMenuDialogService
  ) {}

  @HostBinding('class') public hostClass = 'shop-menu-items';

  ngOnInit(): void {
    if (!this.userService.isGuest) this.setOrdersSubscription();
    this.setLayoutSubscription();
    this.setItemsSubscription();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  ngOnDestroy(): void {
    if (this.ordersSubscription) this.ordersSubscription.unsubscribe();
    if (this.layoutSubscription) this.layoutSubscription.unsubscribe();
    if (this.itemsSubscription) this.itemsSubscription.unsubscribe();
  }

  public onSectionChange(categoryId: string): void {
    this.shopMenuNavService.setMenuItem(categoryId);
  }

  public onProductAdd(product: Product): void {
    this.shopMenuPageService.onProductAdd({ item: product, itemsType: 'regular' });
  }

  public onProductRemove(product: Product): void {
    this.shopMenuPageService.onProductRemove({ item: product, itemsType: 'regular' });
  }

  public onProductClick(index: number, product: Product): void {
    this.shopMenuPageService.onProductSelect({ item: product, itemsType: 'regular', index });
  }

  public onOfferAdd(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductAdd({ item: item as Product, itemsType: 'regular' });
    }
    this.shopMenuPageService.onOfferAdd({ item: item as Offer, itemsType: 'regular' });
  }

  public onOfferRemove(item: Product | Offer): void {
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductRemove({ item: item as Product, itemsType: 'regular' });
    }
    this.shopMenuPageService.onOfferRemove({ item: item as Offer, itemsType: 'regular' });
  }

  public onOfferClick(index: number, item: Offer | Product): void {
    if (!(item as Offer).available) return undefined;
    if (!(item as Offer).groups) {
      return this.shopMenuPageService.onProductSelect({ item: item as Product, itemsType: 'regular', index });
    }
    this.shopMenuPageService.onOfferSelect({ item: item as Offer, itemsType: 'regular', index });
  }

  private setOrdersSubscription(): void {
    this.ordersSubscription = this.shopService.orders.subscribe((orders) => {
      this.orders = orders;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setLayoutSubscription(): void {
    const layoutChanges = this.breakpointObserver.observe(['(max-width: 992px)']);
    this.layoutSubscription = layoutChanges.subscribe(() => {
      const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 992px)');
      const offsetTarget = isSmallScreen ? 'mobile' : 'desktop';
      this.scrollOffset = this.shopMenuNavService.getScrollOffset(offsetTarget);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setItemsSubscription(): void {
    this.itemsSubscription = this.shopMenuItemsService.getFoodMenuItems().subscribe((items) => {
      this.offers = items.offers;
      this.categories = items.categories;
      this.productsByCategory = items.productsByCategory;
      this.changeDetectorRef.detectChanges();
    });
  }

  public trackById(index: number, item: Product | Offer): string {
    return item._id;
  }

  public onShopOrdersEnteredViewport(category: Category): void {
    this.triggerAnalyticsEvent(category);
  }

  public onUnavailableSectionClick(category: string): void {
    this.shopMenuDialogService.showUnavailableItemsDialog(this.productsByCategory[category].unavailable);
  }

  private triggerAnalyticsEvent(category: Category): void {
    const gaConfig = {
      creative_name: category.name,
      creative_slot: 'shop',
      promotion_id: '',
      promotion_name: 'recent_orders_shop'
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('view_promotion', gaConfig);
  }
}
