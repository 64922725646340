<div class="delivery-details-container">
  <div matRipple class="delivery-details-button address" (click)="onAddressClick()">
    <svg-icon [svgPath]="addressIconPath" class="delivery-details-button-address-icon"></svg-icon>
    <span class="details-text ods-typography-body-s-bold">{{ addressText }}</span>
    <mat-icon class="delivery-details-button-arrow">keyboard_arrow_down</mat-icon>
  </div>
  <div matRipple class="delivery-details-button schedule" (click)="onTimeslotClick()">
    <mat-icon class="delivery-details-button-timeslot-icon">schedule</mat-icon>
    <span class="details-text ods-typography-body-s-bold">{{ timeslotText }}</span>
    <mat-icon class="delivery-details-button-arrow">keyboard_arrow_down</mat-icon>
  </div>
</div>
