<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="Επιβεβαίωση κάρτας" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-validation-dialog-content">
      <div class="market-card-validation-dialog-image-container">
        <figure class="market-card-validation-dialog-image">
          <img [src]="logo" [alt]="logoAlt" />
        </figure>
      </div>

      <p class="market-card-validation-dialog-text" [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'EMAIL'">
          <div *ngIf="maskedEmail">
            Σου έχουμε στείλει έναν κωδικό στο <strong>{{ maskedEmail }}</strong
            >, παρακαλούμε συμπλήρωσε αυτόν τον κωδικό στο παρακάτω πεδίο
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'OTP'">
          <div *ngIf="maskedPhoneNumber">
            Σου έχουμε στείλει έναν κωδικό με SMS στο <strong>{{ maskedPhoneNumber }}</strong
            >, παρακαλούμε συμπλήρωσε αυτόν τον κωδικό στο παρακάτω πεδίο.
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PIN'">
          Πληκτρολόγησε τον κωδικό (PIN) της κάρτας σου στο παρακάτω πεδίο.
        </ng-container>
      </p>

      <mat-form-field class="market-card-validation-dialog-field">
        <mat-label>Κωδικός επιβεβαίωσης</mat-label>
        <input
          matInput
          autocomplete="off"
          name="validationCode"
          (keydown.enter)="onValidate()"
          [formControl]="validationFormControl"
        />
      </mat-form-field>

      <button
        box-button
        theme="primary"
        class="market-card-validation-dialog-button"
        [disabled]="validationButtonDisabled"
        (click)="onValidate()"
      >
        Επιβεβαίωση
      </button>

      <p class="market-card-validation-dialog-text">
        Για οποιοδήποτε θέμα σχετικά με την επιβεβαίωση της κάρτας σου μπορείς να επικοινωνήσεις με τα supermarket
        {{ shopName }} στο {{ shopNumber }}.
      </p>

      <div class="market-card-validation-dialog-resend">
        <span class="market-card-validation-dialog-resend-text">{{ resendText }}</span>
        <button
          box-button
          theme="secondary"
          size="small"
          borders="none"
          class="market-card-validation-dialog-resend-button"
          (click)="onResend()"
        >
          {{ resendButtonText }}
        </button>
      </div>
    </div>
  </main>
</div>
