<div *ngIf="showPaymentBanner" class="payment-synergy-banner-wrapper">
  <ng-container *ngIf="multiView; else singleView">
    <div class="payment-synergy-banner-multi-view">
      <img
        class="image payment-synergy-banner-multi-view-left"
        [src]="paymentCampaignBanners[0].paymentDoubleBannerLeftSide"
        [alt]="paymentCampaignBanners[0].paymentDoubleBannerLeftSide"
      />
      <div class="payment-synergy-banner-content ods-typography-body-s-bold payment-synergy-banner-multi-view-right">
        <ng-container *ngIf="paymentCampaignBanners[1].multiplier > 1; else additive">
          <points-tag class="payment-synergy-banner-content-points-tag"
            >x{{ paymentCampaignBanners[1].multiplier }}</points-tag
          >
        </ng-container>
        <ng-template #additive>
          <points-tag class="payment-synergy-banner-content-points-tag" sign="+">{{
            paymentCampaignBanners[1].points | number: '1.0-0'
          }}</points-tag>
        </ng-template>

        <ng-container *ngIf="paymentCampaignBanners[1].paymentExtraInfoLogo">
          <span class="payment-synergy-banner-content-text-and"> & </span>
          <img
            class="image payment-synergy-banner-content-text-extra-info"
            [src]="paymentCampaignBanners[1].paymentExtraInfoLogo"
            [alt]="paymentCampaignBanners[1].paymentExtraInfoLogo"
          />
        </ng-container>
        <span class="payment-synergy-banner-content-text-me"> με </span>
        <img
          class="image payment-synergy-banner-content-text-combined-campaign-logo"
          [src]="paymentCampaignBanners[1].paymentDoubleBannerCampaignLogo"
          [alt]="paymentCampaignBanners[1].paymentDoubleBannerCampaignLogo"
        />
      </div>
    </div>
  </ng-container>

  <ng-template #singleView>
    <div *ngIf="paymentCampaignBanners?.length" class="payment-synergy-banner-single-view">
      <figure class="image payment-synergy-banner-image">
        <img [src]="paymentCampaignBanners[0].logo" [alt]="paymentCampaignBanners[0].orderTitle" />
      </figure>
      <div class="payment-synergy-banner-content ods-typography-body-s-bold">
        <span class="payment-synergy-banner-content-text">
          {{ paymentCampaignBanners[0].checkoutBannerTitle }}

          <ng-container *ngIf="paymentCampaignBanners[0].multiplier > 1; else additive">
            <points-tag>x{{ paymentCampaignBanners[0].multiplier }}</points-tag>
          </ng-container>
          <ng-template #additive>
            <points-tag sign="+">{{ paymentCampaignBanners[0].points | number: '1.0-0' }}</points-tag>
          </ng-template>

          <ng-container *ngIf="paymentCampaignBanners[0].paymentExtraInfoLogo; else noExtraInfo">
            <span> BOX πόντους & </span>
            <img
              class="image payment-synergy-banner-content-text-extra-info"
              [src]="paymentCampaignBanners[0].paymentExtraInfoLogo"
              [alt]="paymentCampaignBanners[0].paymentExtraInfoLogo"
            />
          </ng-container>
          <ng-template #noExtraInfo>
            <span> πόντους για την παραγγελία σου!</span>
          </ng-template>
        </span>
      </div>
    </div>
  </ng-template>
</div>
