<image-tile class="order-completion-ratings-order-image" [imgUrl]="imageSrc" [altText]="shopName"></image-tile>
<div class="order-completion-ratings-order-content">
  <div class="order-completion-ratings-order-content-date">{{ dateText }}</div>
  <div [title]="shopName" class="order-completion-ratings-order-content-title ods-typography-body-s-bold">
    {{ shopName }}
  </div>
  <div class="order-completion-ratings-order-content-rating">
    <span class="order-completion-ratings-order-content-rating-text ods-typography-body-s-regular">Η κριτική σου:</span>
    <rating
      size="24"
      class="order-completion-ratings-order-content-rating-stars"
      (changeRating)="onRate($event)"
    ></rating>
  </div>
</div>
