<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="checkout-points-dialog-main-wrapper">
      <p class="checkout-points-dialog-instructions">Σύρε τη μπάρα κι άρχισε να κερδίζεις!</p>
      <checkout-points-slider-data
        class="checkout-points-dialog-slider-data"
        [activeIndex]="activeIndex"
        [steps]="pointsStepsTexts"
        [alternativeSteps]="eurosStepsTexts"
      >
        <box-slider
          [step]="1"
          [value]="selectedSlideStep"
          [claimableValue]="claimableStep"
          [availableValue]="availableStep"
          [maxValue]="maximumStep"
          (valueChange)="onValueChange($event)"
          (inputChange)="onInputChange($event)"
        ></box-slider>
      </checkout-points-slider-data>
      <div class="checkout-points-dialog-total">
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">Σύνολο</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            cartPrice / 100 | currency: 'EUR'
          }}</span>
        </div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular"
            >Από την έκπτωση κερδίζεις</span
          >
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s"
            >-&nbsp;{{ totalDiscount / 100 | currency: 'EUR' }}</span
          >
        </div>
        <div class="box-divider"></div>
        <div class="checkout-points-dialog-total-row">
          <span class="checkout-points-dialog-total-row-text ods-typography-body-s-regular">Τελική τιμή προϊόντων</span>
          <span class="checkout-points-dialog-total-row-price ods-typography-subtitle-s">{{
            (cartPrice - totalDiscount) / 100 | currency: 'EUR'
          }}</span>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer checkout-points-dialog-footer">
    <button box-button theme="primary" class="checkout-points-dialog-footer-button" (click)="onSubmit()">
      Εφαρμογή
    </button>
  </footer>
</div>
