import { constructMediaSrc, getMediaPrefix } from './media.utils';
import { Image } from '@box-types';

export { generateImageSrc };

function generateImageSrc(image: Image, prefix?: string): string {
  if (!image) return '';
  if (image.exactUrl) return image.exactUrl;
  const imagePrefix = prefix ?? getMediaPrefix(window);
  return constructMediaSrc(image, imagePrefix);
}
