import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Timeslot, Address } from '@box-types';
import { timeslotToText, addressToHeaderText, getAddressIcon } from '@box/utils';
import { AnalyticsService } from '@box-core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'box-header-delivery-details',
  templateUrl: './box-header-delivery-details.component.html',
  styleUrls: ['./box-header-delivery-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxHeaderDeliveryDetailsComponent implements OnChanges {
  @Input() public address: Address;
  @Input() public timeslot: Timeslot;

  @Output() private addressChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() private timeslotChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public addressText: string;
  public timeslotText: string;
  public addressIconPath: string;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address) {
      this.address = changes.address.currentValue as Address;
      this.setAddressText();
      this.setAddressIconPath();
      this.changeDetectorRef.detectChanges();
    }
    if (changes.timeslot) {
      this.timeslot = changes.timeslot.currentValue as Timeslot;
      this.timeslotText = timeslotToText(this.timeslot);
      this.changeDetectorRef.detectChanges();
    }
  }

  private setAddressText(): void {
    this.addressText = addressToHeaderText(this.address);
  }

  private setAddressIconPath(): void {
    this.addressIconPath = getAddressIcon(this.address);
  }

  public onAddressClick(): void {
    const source = this.router.url;
    const gaCustomConfig = { source };
    this.analyticsService.addGACustomEvent('address_change', gaCustomConfig);
    this.addressChange.emit();
  }

  public onTimeslotClick(): void {
    this.timeslotChange.emit();
  }
}
