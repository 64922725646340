import { Injectable } from '@angular/core';
import { DialogService, ReviewsService, ShopService } from '@box-core/services';
import { Product, ProductDetails, ProductInstance, Review, APIError } from '@box-types';
import { getProductInstanceAdditionalPrice, getProductInstancePrice, generateImageSrc } from '@box/utils';
import { map, Observable } from 'rxjs';
import { ProductMYOOptions } from './product-myo.types';
import orderBy from 'lodash-es/orderBy';

@Injectable()
export class ProductMYOService {
  constructor(
    private shopService: ShopService,
    private reviewsService: ReviewsService,
    private dialogService: DialogService
  ) {}

  public getMYOTabText(): string {
    const shop = this.shopService.getShop();
    if (shop.businessVertical === 'food') return 'Φτιάξτο όπως θέλεις';
    return 'Πληροφορίες';
  }

  public fetchProductReviews(productId: string): Observable<Review[]> {
    const collectionType = this.shopService.getShop().collectionType;
    return this.reviewsService
      .fetchReviews(collectionType)
      .pipe(map((reviews) => reviews.filter((r) => r.products?.some((p) => p.productId === productId))));
  }

  public getDynamicPrice(productId: string, productInstance: ProductInstance): Observable<number> {
    return this.shopService.getDynamicPrice(productId, productInstance);
  }

  public showErrorDialog(error: APIError): void {
    this.dialogService.openErrorDialog(error);
  }

  public getProductHeroImageSrc(product: Product): string {
    return generateImageSrc(product.webImage);
  }

  public getProductImages(product: Product, isSuperMarket: boolean): string[] {
    if (!isSuperMarket || !product.webImages?.length) return [];
    const sortedImages = orderBy(product.webImages, 'galleryIndex', 'asc');
    return sortedImages.map((image) => generateImageSrc(image));
  }

  public getButtonPrice(options: ProductMYOOptions, productInstance: ProductInstance): number {
    if (options.showOnlyAdditionalPrice) {
      return getProductInstanceAdditionalPrice(
        productInstance,
        options.offerInstance?.percentageDiscount,
        options.offerInstance?.extraIngredientsHavePercentageDiscount
      );
    }

    return getProductInstancePrice(
      productInstance,
      options.offerInstance?.percentageDiscount,
      options.offerInstance?.extraIngredientsHavePercentageDiscount
    );
  }

  public getProductDetails(product: Product, isSuperMarket: boolean): ProductDetails[] {
    if (!isSuperMarket) return [];
    const { details, info } = product;
    const productDetails: ProductDetails[] = [];
    if (info?.el) productDetails.push({ title: 'Περιγραφή', text: info.el });
    if (details) productDetails.push(...details);
    return productDetails;
  }

  public showProductChoicesThresholdDialog(remainingChoices: number): void {
    const title = 'Προσθήκη υλικών';
    const ingredientString = `${remainingChoices} ${remainingChoices > 1 ? 'υλικά' : 'υλικό'}`;
    const messages = [`Πρέπει να προσθέσεις ακόμη ${ingredientString} για να προχωρήσεις με την παραγγελία σου.`];
    this.dialogService.openInfoDialog({ title, messages });
  }
}
