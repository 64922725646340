<div class="box-download-wrapper">
  <div class="box-download-content">
    <figure class="box-download-logo-figure">
      <img loading="lazy" src="/assets/images/logo/box-application-logo.svg" alt="BOX Mobile Application" />
    </figure>
    <h2 class="box-download-header">Κατέβασε το BOX app στο κινητό σου</h2>
  </div>
  <div class="box-download-links" [ngSwitch]="deviceType">
    <ng-container *ngSwitchCase="'ANDROID'">
      <a
        [attr.href]="androidUrl"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Κατέβασε το BOX Android Applciation"
        (click)="onDownload('Android')"
        class="box-download-link-single android-link"
      >
        <img loading="lazy" src="/assets/images/download/google-play-logo--single.svg" alt="Download BOX to Android" />
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="'IOS'">
      <a
        [attr.href]="iosUrl"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Κατέβασε το BOX iOS Applciation"
        (click)="onDownload('iOS')"
        class="box-download-link-single"
      >
        <img loading="lazy" src="/assets/images/download/app-store-logo--single.svg" alt="Download BOX to iOS" />
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="'HUAWEI'">
      <a
        [attr.href]="huaweiUrl"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Κατέβασε το BOX Huawei Applciation"
        (click)="onDownload('Huawei')"
        class="box-download-link-single"
      >
        <img loading="lazy" src="/assets/images/download/huawei-store-logo--single.svg" alt="Download BOX to Huawei" />
      </a>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <a
        [attr.href]="androidUrl"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Κατέβασε το BOX Android Applciation"
        (click)="onDownload('Android')"
        class="box-download-link"
      >
        <img loading="lazy" src="/assets/images/download/google-play-logo.svg" alt="Download BOX to Android" />
      </a>
      <a
        [attr.href]="iosUrl"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Κατέβασε το BOX iOS Applciation"
        (click)="onDownload('iOS')"
        class="box-download-link"
      >
        <img loading="lazy" src="/assets/images/download/app-store-logo.svg" alt="Download BOX to iOS" />
      </a>
      <a
        [attr.href]="huaweiUrl"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Κατέβασε το BOX Huawei Applciation"
        (click)="onDownload('Huawei')"
        class="box-download-link"
      >
        <img loading="lazy" src="/assets/images/download/huawei-store-logo.svg" alt="Download BOX to Huawei" />
      </a>
    </ng-container>
  </div>
</div>
