<div class="box-swiper" *ngIf="remindersLength">
  <div class="shop-suggestion-banners-header">
    <h3 class="shop-suggestion-banners-title">{{ title }}</h3>
    <div class="shop-suggestion-banners-navigation" [attr.hidden]="remindersLength <= 1">
      <button #prevBtn (click)="onPreviousSlideClick()" mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn (click)="onNextSlideClick()" mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div class="shop-reminders-swiper-container" *ngIf="remindersLength > 1; else singleSuggestion">
    <div [swiper]="swiperOptions">
      <div class="swiper-wrapper">
        <div class="swiper-slide shop-suggestion-coupon-slide" *ngFor="let coupon of coupons">
          <coupon [coupon]="coupon" overrideDisabledState="true" (click)="onCouponClick(coupon)"></coupon>
        </div>
        <div class="swiper-slide shop-suggestion-banner-slide" *ngFor="let suggestionBanner of suggestionBanners">
          <ng-container *ngIf="suggestionBanner?.campaignName === 'happy_hour'; else normalBanner">
            <shop-box-hour-banner [suggestionBanner]="suggestionBanner"></shop-box-hour-banner>
          </ng-container>
          <ng-template #normalBanner>
            <shop-suggestion-banner
              [suggestionBanner]="suggestionBanner"
              (action)="onSuggestionAction($event)"
            ></shop-suggestion-banner>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <ng-template #singleSuggestion>
    <ng-container *ngIf="coupons?.length === 1">
      <coupon [coupon]="coupons[0]" overrideDisabledState="true" (click)="onCouponClick(coupons[0])"></coupon>
    </ng-container>
    <ng-container
      *ngIf="suggestionBanners?.length === 1 && suggestionBanners[0].campaignName === 'happy_hour'; else normalBanner"
    >
      <shop-box-hour-banner
        [suggestionBanner]="suggestionBanners[0]"
        class="shop-suggestion-banner-wide"
      ></shop-box-hour-banner>
    </ng-container>
    <ng-template #normalBanner>
      <shop-suggestion-banner
        *ngIf="suggestionBanners?.length === 1"
        [suggestionBanner]="suggestionBanners[0]"
        (action)="onSuggestionAction($event)"
      ></shop-suggestion-banner>
    </ng-template>
  </ng-template>
</div>
