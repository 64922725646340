export interface BreadcrumbList {
  '@context': 'https://schema.org';
  '@type': 'BreadcrumbList';
  itemListElement: BreadcrumbListItem[];
}

export interface BreadcrumbListItem {
  '@type': 'ListItem';
  position: number;
  name: string;
  item?: string;
}

export interface AggresiveRating {
  '@context': 'https://schema.org/';
  '@type': 'AggregateRating';
  bestRating: '5';
  worstRating: '1';
  ratingValue: string;
  reviewCount: string;
  itemReviewed: {
    '@type': 'GroceryStore' | 'Restaurant';
    image: string;
    name: string;
    servesCuisine?: string;
    address: AddresJsonLd;
  };
}

interface AddresJsonLd {
  '@type': 'PostalAddress';
  streetAddress: string;
  addressLocality: string;
  postalCode: string;
  addressCountry: string;
}

export interface InitFunctonInterface {
  breadcrumbs?: BreadcrumbList;
  aggresiveRating?: AggresiveRating;
}

export const breadcrumbListId = 'breadcrumbListId';
export const aggresiveRatingId = 'aggresiveRatingId';
export type jsonScriptId = 'breadcrumbListId' | 'aggresiveRatingId';
export type JsonShopType = 'GroceryStore' | 'Restaurant';
