import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeliveryAnchor } from '@box-types';

@Component({
  selector: 'delivery-anchors',
  templateUrl: './delivery-anchors.component.html',
  styleUrls: ['./delivery-anchors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryAnchorsComponent implements OnChanges {
  @Input() public title: string;
  @Input() public anchors: DeliveryAnchor[] = [];

  @HostBinding('class') public hostClass = 'delivery-anchors';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) this.title = changes.title.currentValue as string;
    if (changes.anchors) this.anchors = changes.anchors.currentValue as DeliveryAnchor[];
  }
}
