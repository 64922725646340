import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function regExToValidatorFunction(regExp: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!regExp) return null;
    const input = control?.value as string;
    if (!input?.length) return null;
    const valid = regExp.test(input);
    if (valid) return null;
    return { invalid: { value: input } };
  };
}
