import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PurchaseEvent } from '@box-types';
import { storageGet, storageSet } from '@box/utils';
import { Platform } from '@angular/cdk/platform';
import { PrivacyConsentService } from './privacy-consent.service';

const PURCHASE_EVENT_STORAGE_KEY = 'Box:purchaseEvent';

@Injectable({ providedIn: 'root' })
export class PurchaseEventService {
  private readonly purchaseEventsSource = new BehaviorSubject<Record<string, PurchaseEvent>>({});
  public readonly purchaseEvent$ = this.purchaseEventsSource.asObservable();

  private readonly EMPTY_PURCHASE_EVENT: PurchaseEvent = {
    creativeName: 'not set',
    index: 0,
    itemListName: 'not set',
    creativeSlot: 'not set',
    promotionName: 'not set'
  };

  constructor(private platform: Platform, private privacyConsentService: PrivacyConsentService) {
    this.initializePurchaseEvent();
  }

  private initializePurchaseEvent(): void {
    const purchaseEvents = storageGet(PURCHASE_EVENT_STORAGE_KEY, window.sessionStorage);
    if (!purchaseEvents) return;
    const transformedPurchaseEvents = <Record<string, PurchaseEvent>>purchaseEvents;
    this.purchaseEventsSource.next(transformedPurchaseEvents);
  }

  private setPurchaseEvent(shopId: string, purchaseEvent: PurchaseEvent): void {
    const currentpurchaseEvents = { ...this.purchaseEventsSource.getValue() };
    currentpurchaseEvents[shopId] = purchaseEvent;
    this.purchaseEventsSource.next(currentpurchaseEvents);
    storageSet(PURCHASE_EVENT_STORAGE_KEY, this.purchaseEventsSource.getValue(), window.sessionStorage);
  }

  public getPurchaseEvent(shopId: string): PurchaseEvent {
    const purchaseEvents = this.purchaseEventsSource.getValue();
    if (!purchaseEvents) return this.EMPTY_PURCHASE_EVENT;
    return purchaseEvents[shopId] ?? this.EMPTY_PURCHASE_EVENT;
  }

  public updatePurchaseEvent(shopId: string, purchaseEvent: PurchaseEvent): void {
    if (!this.performancePermissionGranted) return;
    if (!purchaseEvent || !shopId) return;
    const currentpurchaseEvent = this.getPurchaseEvent(shopId);
    const newpurchaseEvent = { ...currentpurchaseEvent, ...purchaseEvent };
    this.setPurchaseEvent(shopId, newpurchaseEvent);
  }

  private performancePermissionGranted(): boolean {
    const performanceConsent: boolean = this.privacyConsentService.getPrivacyConsent()?.performance;
    return this.platform.isBrowser && performanceConsent;
  }
}
