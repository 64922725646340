<div class="order-status-container">
  <ng-container *ngIf="loading || !order; else loadedContent">
    <section class="section order-status-header-section">
      <div class="container">
        <div class="order-status-header-skeleton"></div>
      </div>
    </section>
    <div class="order-status-body">
      <p class="order-status-delivery-status-text-skeleton order-status-delivery-status-text-skeleton-70"></p>
      <p class="order-status-delivery-status-text-skeleton order-status-delivery-status-text-skeleton-80"></p>
      <p class="order-status-delivery-status-text-skeleton order-status-delivery-status-text-skeleton-50"></p>
    </div>
  </ng-container>
  <ng-template #loadedContent>
    <section class="section order-status-header-section">
      <div class="container">
        <order-status-header [order]="order"></order-status-header>
      </div>
    </section>
    <div class="order-status-body">
      <order-status-details></order-status-details>
      <order-status-rating *ngIf="showOrderStatusRating" [order]="order"></order-status-rating>
      <benefits-banner
        *ngIf="orderPoints"
        class="hoverable"
        (click)="onBenefitsBannerClick()"
        infoText="Έχεις κερδίσει:"
        [pointsText]="orderPoints"
      ></benefits-banner>
      <div *ngIf="showContanctInfo" class="order-status-contact-info">
        <order-completion-contact-info [contactInfo]="addressContactInfo"></order-completion-contact-info>
        <order-completion-contact-info [contactInfo]="phoneContactInfo"></order-completion-contact-info>
      </div>
      <mb-banner *ngIf="showGBRewards" [order]="order" class="mb-banner"></mb-banner>
      <ng-container *ngIf="showOrderDetails">
        <checkout-order-points [order]="order"></checkout-order-points>
        <checkout-order-market-card-points *ngIf="isSuperMarket" [order]="order"></checkout-order-market-card-points>
        <single-banner *ngIf="singleBannerConfig" [config]="singleBannerConfig"></single-banner>
        <order-receipt [order]="order"></order-receipt>
      </ng-container>
      <order-completion-ratings [excludedOrder]="order" *ngIf="showOrderCompletionRatings"></order-completion-ratings>
    </div>
  </ng-template>
</div>
