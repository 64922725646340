import { Injectable } from '@angular/core';
import { Observable, of, switchMap, BehaviorSubject } from 'rxjs';
import { BoxCookiesResponse } from '@box-core/components/box-cookies/box-cookies.types';
import { tap } from 'rxjs/operators';
import { MatBottomSheetRef, MatBottomSheetConfig, MatBottomSheet } from '@angular/material/bottom-sheet';
import { BoxCookiesComponent } from '@box-core/components';
import { PrivacyConsentService } from '@box-core/services/privacy-consent.service';
import { Router } from '@angular/router';
import {
  isPrerenderBrowser,
  legalDocumentUpdated,
  getLegalDocumentVersionFromConfig,
  getLegalDocumentUpdateData,
  updateLegalDocumentData
} from '@box/utils';
import { MatDialogConfig } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { ConfigurationService } from '@box-core/services/configuration.service';
import { DialogService } from '@box-core/services/dialog.service';
import {
  InfoDialogData,
  LegalDocumentData,
  ConfirmDialogData,
  ConfirmDialogResponse,
  PopupsServiceState,
  LegalDocumentVersion
} from '@box-types';

@Injectable({ providedIn: 'root' })
export class GlobalPopupsService {
  private readonly globalPopupsServiceState = new BehaviorSubject<PopupsServiceState>('pending');
  public globalPopupsServiceState$ = this.globalPopupsServiceState.asObservable();
  private readonly homePopupsServiceState = new BehaviorSubject<PopupsServiceState>('pending');
  public homePopupsServiceState$ = this.homePopupsServiceState.asObservable();

  constructor(
    private bottomSheet: MatBottomSheet,
    private privacyConsentService: PrivacyConsentService,
    private router: Router,
    private platform: Platform,
    private configService: ConfigurationService,
    private dialogService: DialogService
  ) {}

  public initializeGlobalPopups(): void {
    if (!this.platform.isBrowser) return;
    const isPrerender = isPrerenderBrowser(window);
    if (isPrerender) return;
    if (this.globalPopupsServiceState.getValue() === 'completed') return;

    of(0)
      .pipe(
        switchMap(() => this.setConsentSubscription()),
        switchMap(() => this.initMaintenanceSequence()),
        switchMap(() => this.checkLegalDocumentVersion$())
      )
      .subscribe(() => this.setGlobalPopupsServiceState('completed'));
  }

  private setConsentSubscription(): Observable<null> {
    if (this.privacyConsentService.isPrivacyDefined()) return of(null);
    return this.openBottomSheetCookies$().pipe(switchMap(() => of(null)));
  }

  private openBottomSheetCookies$(): Observable<BoxCookiesResponse> {
    return this.openBottomSheetCookies()
      .afterDismissed()
      .pipe(
        tap((response: BoxCookiesResponse) => {
          if (!response) return;
          if (!response.accepted) this.privacyConsentService.rejectAll();
          if (response.accepted) this.privacyConsentService.acceptAll();
        })
      );
  }

  private openBottomSheetCookies(): MatBottomSheetRef {
    const config: MatBottomSheetConfig = {
      autoFocus: false,
      restoreFocus: false,
      hasBackdrop: false,
      panelClass: ['box-bottom-sheet', 'box-bottom-sheet-full-width']
    };
    return this.bottomSheet.open(BoxCookiesComponent, config);
  }

  private initMaintenanceSequence(): Observable<null> {
    const config = this.configService.getConfiguration();
    const isUnderMaintenance = this.configService.isUnderMaintenance();
    if (!isUnderMaintenance) return of(null);

    const data = config.downtime_Web;
    const dialogConfig: MatDialogConfig = {
      restoreFocus: false,
      autoFocus: false,
      disableClose: true
    };

    const dialogData: InfoDialogData = { title: data.title, messages: [data.message], userAction: data.available };
    return this.dialogService
      .openInfoDialog(dialogData, dialogConfig)
      .afterClosed()
      .pipe(switchMap(() => of(null)));
  }

  private checkLegalDocumentVersion$(): Observable<null> {
    const remoteLegalDocumentVersion = getLegalDocumentVersionFromConfig(this.configService.getConfiguration());
    const documentUpdatedType: string = legalDocumentUpdated(remoteLegalDocumentVersion);
    if (documentUpdatedType) {
      return this.openLegalDocumentUpdateDialog$(documentUpdatedType, remoteLegalDocumentVersion);
    }
    return of(null);
  }

  private openLegalDocumentUpdateDialog$(
    type: string,
    remoteLegalDocumentVersion: LegalDocumentVersion
  ): Observable<null> {
    const documentData: LegalDocumentData = getLegalDocumentUpdateData(type);
    const dialogConfig: ConfirmDialogData = {
      title: documentData.title,
      messages: ['Μπορείς να δεις τις αλλαγές πατώντας "Μάθε περισσότερα".'],
      confirmText: 'Μάθε περισσότερα',
      cancelText: 'Ισως αργότερα'
    };

    return this.dialogService
      .openConfirmDialog(dialogConfig)
      .afterClosed()
      .pipe(
        switchMap((data: ConfirmDialogResponse) => {
          updateLegalDocumentData(type, remoteLegalDocumentVersion);
          if (!data) return of(null);
          if (!data.accepted) return this.showLegalDocumentInfoDialog$(type);
          if (data.accepted) {
            void this.router.navigate([documentData.routerLink]);
            return of(null);
          }
        })
      );
  }

  private showLegalDocumentInfoDialog$(type: string): Observable<null> {
    const documentData: LegalDocumentData = getLegalDocumentUpdateData(type);
    const dialogConfig: InfoDialogData = {
      title: documentData.title,
      messages: documentData.messages
    };

    return this.dialogService
      .openInfoDialog(dialogConfig)
      .afterClosed()
      .pipe(switchMap(() => of(null)));
  }

  private setGlobalPopupsServiceState(value: PopupsServiceState): void {
    this.globalPopupsServiceState.next(value);
  }

  public setHomePopupsServiceState(value: PopupsServiceState): void {
    this.homePopupsServiceState.next(value);
  }

  public getHomePopupsServiceState(): PopupsServiceState {
    return this.homePopupsServiceState.getValue();
  }
}
