import { UsefulLink } from '../types';

export const usefulLinks: UsefulLink[] = [
  {
    title: 'Ρυθμίσεις Cookies',
    routerLink: '/cookies'
  },
  {
    title: 'Όροι Χρήσης',
    routerLink: '/terms'
  },
  {
    title: 'Πολιτική Προστασίας Δεδομένων',
    routerLink: '/privacy'
  },
  {
    title: 'Συχνές Ερωτήσεις',
    routerLink: '/faqs'
  }
];
