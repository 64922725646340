<div class="shop-menu-nav-bottom-sheet-item-wrapper" [class.expanded]="expanded">
  <div class="shop-menu-nav-bottom-sheet-item-header">
    <span
      class="shop-menu-nav-bottom-sheet-item-header-text ods-typography-body-s-bold"
      (click)="onSelectItem($event)"
      >{{ item.name }}</span
    >
    <div *ngIf="!disableToggle" class="shop-menu-nav-bottom-sheet-item-header-chevron-wrapper">
      <button
        *ngIf="hasSubItems"
        mat-icon-button
        class="shop-menu-nav-bottom-sheet-item-header-chevron"
        (click)="onChevronClick($event)"
      >
        <mat-icon fontSet="material-icons-round">expand_more</mat-icon>
      </button>
    </div>
  </div>
  <ul *ngIf="hasSubItems && expanded" class="shop-menu-nav-bottom-sheet-item-list">
    <li
      *ngFor="let subItem of item.subItems"
      class="shop-menu-nav-bottom-sheet-item-list-item ods-typography-body-s-bold"
      (click)="onSelectItem($event, subItem)"
    >
      {{ subItem.name }}
    </li>
  </ul>
</div>
