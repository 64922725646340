import { Injectable } from '@angular/core';
import { storageGet, storageSet, storageRemove, updateGoogleConsent } from '@box/utils';
import { PrivacyConsent } from '@box-types';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';
import isEqual from 'lodash-es/isEqual';

const PRIVACY_CONSENT_KEY = 'Box:privacy';

@Injectable({ providedIn: 'root' })
export class PrivacyConsentService {
  private readonly DEFAULT_PRIVACY_CONSENT = { performance: undefined, ad: undefined, ux: undefined, ts: undefined };
  private readonly privacyConsentSource = new BehaviorSubject<PrivacyConsent>(this.DEFAULT_PRIVACY_CONSENT);
  public privacyConsent$ = this.privacyConsentSource.asObservable();

  constructor() {
    const localPrivacyConsent: PrivacyConsent = storageGet(PRIVACY_CONSENT_KEY, window.localStorage);
    const localPrivacyExpired: boolean = this.privacyExpired(localPrivacyConsent);
    if (localPrivacyExpired) storageRemove(PRIVACY_CONSENT_KEY, window.localStorage);
    if (localPrivacyConsent && !localPrivacyExpired) this.privacyConsentSource.next(localPrivacyConsent);
  }

  public setPrivacyConsent(privacyConsent: PrivacyConsent): void {
    storageSet(PRIVACY_CONSENT_KEY, privacyConsent, window.localStorage);
    updateGoogleConsent(privacyConsent);
    this.privacyConsentSource.next(privacyConsent);
  }

  public getPrivacyConsent(): PrivacyConsent {
    return this.privacyConsentSource.getValue();
  }

  public acceptAll(): void {
    this.setPrivacyConsent({
      performance: true,
      ad: true,
      ux: true,
      ts: dayjs().valueOf()
    });
  }

  public rejectAll(): void {
    this.setPrivacyConsent({
      performance: false,
      ad: false,
      ux: false,
      ts: dayjs().valueOf()
    });
  }

  public isPrivacyDefined(): boolean {
    return !isEqual(this.privacyConsentSource.getValue(), this.DEFAULT_PRIVACY_CONSENT);
  }

  public removeUXData(): void {
    window.localStorage.removeItem('Box:address');
    window.localStorage.removeItem('Box:cartCollections'); // todo not asking for ux consent when set
    window.localStorage.removeItem('Box:paymentType');
  }

  private privacyExpired(privacyConsent: PrivacyConsent): boolean {
    if (!privacyConsent || !privacyConsent.ts) return true;
    const dateToCheck: dayjs.Dayjs = dayjs(privacyConsent.ts).add(1, 'year');
    return dayjs().isAfter(dateToCheck);
  }
}
