<div class="rewards-history-earnings">
  <div *ngIf="historyEarningsEuro" class="rewards-history-earnings-row">
    <div class="rewards-history-earnings-row-image">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40">
        <defs><rect id="a" x="0" y="0" width="40" height="40" rx="4" /></defs>
        <g fill="none" fill-rule="evenodd">
          <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
          <use fill="#F87A1D" xlink:href="#a" />
          <text x="10" y="35" dx="0" dy="-1" mask="url(#b)" font-size="40" font-weight="500" fill="#FFF">€</text>
        </g>
      </svg>
    </div>
    <div class="rewards-history-earnings-row-content">
      <div class="rewards-history-earnings-row-title ods-typography-body-s-bold">
        {{ historyEarningsEuro.title }}
      </div>
      <div class="rewards-history-earnings-row-description ods-typography-microcopy-regular">
        {{ historyEarningsEuro.description }}
      </div>
    </div>
  </div>
  <div *ngIf="historyEarningsGB" class="rewards-history-earnings-row">
    <div class="rewards-history-earnings-row-image" [ngClass]="gbEarningsImageClass">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40">
        <defs><rect id="a" x="0" y="0" width="40" height="40" rx="4" /></defs>
        <g fill="none" fill-rule="evenodd">
          <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
          <use class="rewards-history-earnings-row-image-outer-path" fill="#F87A1D" xlink:href="#a" />
          <path
            class="rewards-history-earnings-row-image-inner-path"
            d="M25.257 5H14.743A3.736 3.736 0 0 0 11 8.75v22.5c0 2.08 1.701 3.75 3.743 3.75h10.514A3.736 3.736 0 0 0 29 31.25V8.75C29 6.67 27.333 5 25.257 5Zm-5.24 27.58c-.953 0-1.701-.75-1.701-1.705 0-.955.748-1.705 1.701-1.705.953 0 1.701.75 1.701 1.705 0 .955-.748 1.705-1.701 1.705Zm7.282-5.216H12.7V10.01H27.3v17.353Z"
            fill="#FFF"
            fill-rule="nonzero"
            mask="url(#b)"
          />
        </g>
      </svg>
    </div>
    <div class="rewards-history-earnings-row-content">
      <div class="rewards-history-earnings-row-title ods-typography-body-s-bold">{{ historyEarningsGB.title }}</div>
      <div class="rewards-history-earnings-row-description ods-typography-microcopy-regular">
        {{ historyEarningsGB.description }}
      </div>
    </div>
  </div>
</div>
