import { Injectable } from '@angular/core';
import { ConfigurationService } from '@box-core/services';
import { Order } from '@box-types';

@Injectable()
export class OrderReceiptService {
  constructor(private configService: ConfigurationService) {}

  public getOrderPaymentMethodText(order: Order): string {
    if (order.paymentType === 'card') return 'Πληρωμή με κάρτα';
    return 'Πληρωμή με μετρητά';
  }

  public getOrderPaymentMethodIcon(order: Order): string {
    if (order.paymentType === 'cash') return undefined;
    return `/assets/images/payments/${order.paymentType}.svg`;
  }

  public getCSRDonationName(): string {
    const config = this.configService.getConfiguration();
    return config?.ekeInfo?.name;
  }
}
