<button box-button theme="primary" [disabled]="disabled" class="cart-button-button">
  <div [@cartTwist]="cartTwistActive" class="cart-button-quantity ods-typography-microcopy-bold">
    {{ quantity }}
  </div>
  <div class="cart-button-text ods-typography-body-m-regular">
    <ng-content></ng-content>
  </div>
  <ng-container *ngIf="showBenefits">
    <span class="cart-button-benefits ods-typography-body-m-bold"> {{ benefitsText }} </span>
    <span class="cart-button-divider"></span>
  </ng-container>
  <span class="cart-button-price ods-typography-body-m-bold">
    <span *ngIf="offerAddition">+ </span>
    <span>{{ price | currency: 'EUR' }}</span>
  </span>
</button>
