import { Component, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { BreadCrumbService } from '@box-core/services/breadcrumb.service';
import { Subscription } from 'rxjs';
import {
  BreadcrumbListItem,
  removeJsonLdWithId,
  breadcrumbListId,
  BreadcrumbList,
  getPathWithoutQuery,
  addRootUrlToBreadCrumbsItem,
  addOrUpdateJsonLd,
  addBreadCrumbListItem
} from '@box/utils';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  private snapshot: ActivatedRouteSnapshot;
  private rootState: string;
  public breadcrubmData: BreadcrumbListItem[] = [];
  private breadCrumbSubscribe: Subscription;
  private routerSubscribe: Subscription;
  public isShopPage;

  constructor(private router: Router, private breadCrumbService: BreadCrumbService) {
    this.breadCrumbSubscribe = this.breadCrumbService.breadcrumbs$.subscribe(() => {
      this.setBreadcrumbData();
    });

    this.routerSubscribe = this.router.events.subscribe((event) => {
      if (this.router.routerState.snapshot.url != this.rootState) {
        removeJsonLdWithId(breadcrumbListId);
        this.breadcrubmData = [];
        this.rootState = this.router.routerState.snapshot.url;
      }
      if (event instanceof ActivationEnd && event.snapshot.data['breadcrumbName']) {
        this.snapshot = event.snapshot;
        this.setBreadcrumbData();
      }
    });
  }

  setBreadcrumbData() {
    if (this.snapshot) {
      const data = this.getUrlData(this.snapshot).reverse();
      const jsonObj: BreadcrumbList = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: addRootUrlToBreadCrumbsItem(data)
      };
      this.breadcrubmData = data;
      addOrUpdateJsonLd(breadcrumbListId, jsonObj);
    }
  }

  ngOnDestroy(): void {
    this.breadCrumbSubscribe.unsubscribe();
    this.routerSubscribe.unsubscribe();
  }

  getExtraDataFromRoute(snapshot: ActivatedRouteSnapshot, lastPath: string): string {
    let key: string;
    try {
      key = snapshot.data.breadcrumbKey as string;
      if (key) {
        const serviceData = this.breadCrumbService.getBreadcrumbs();
        if (serviceData[key]) {
          return serviceData[key];
        }
      }
    } catch {
      if (key === 'location' && lastPath === 'delivery') {
        return 'Delivery';
      }
      return '';
    }
    return '';
  }

  getUrlData(snapshot: ActivatedRouteSnapshot): BreadcrumbListItem[] {
    const breadcrumbJsonLdItems: BreadcrumbListItem[] = [];
    let positionState = 1;
    let path: string = this.rootState;
    while (snapshot?.firstChild) {
      snapshot = snapshot.firstChild;
    }

    this.isShopPage = snapshot.data.name === 'Shop Menu Page';

    while (snapshot.parent != undefined && path != '') {
      let lastPath = path.split('/').pop();
      const extraData = this.getExtraDataFromRoute(snapshot, lastPath);
      if (lastPath === 'kouzines' || lastPath == 'psonia') {
        if (path.split('/').length > 3) {
          path = path.replace(`/${lastPath}`, '');
          lastPath = path.split('/').pop();
          positionState++;
          breadcrumbJsonLdItems.push(
            addBreadCrumbListItem(positionState, this.breadCrumbService.getBreadcrumbs()?.location, path)
          );
        }
        path = path.replace(`/${lastPath}`, '');
        continue;
      }
      const breadcrumbName = `${snapshot.data.breadcrumbName as string}${extraData}`;
      if (breadcrumbName === 'undefined') {
        snapshot = snapshot.parent;
        path = path.replace(`/${lastPath}`, '');
        if (positionState == 1) {
          continue;
        }
        return [];
      }
      breadcrumbJsonLdItems.push(addBreadCrumbListItem(positionState, breadcrumbName, path));
      snapshot = snapshot.parent;
      positionState++;
      const lastPathWithoutQueryParam = getPathWithoutQuery(lastPath);
      if (lastPathWithoutQueryParam === 'details' || lastPathWithoutQueryParam === 'reviews') {
        path = path.replace(`/${lastPath}`, '');
        breadcrumbJsonLdItems.push(
          addBreadCrumbListItem(positionState, this.breadCrumbService.getBreadcrumbs()?.shopName, path)
        );
        lastPath = path.split('/').pop();
      }
      path = path.replace(`/${lastPath}`, '');
    }

    breadcrumbJsonLdItems.push(addBreadCrumbListItem(positionState, 'Αρχική', ''));
    return breadcrumbJsonLdItems;
  }
}
