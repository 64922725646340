import { BehaviorSubject } from 'rxjs';
import { ProductReplacementOption } from '@box-types';

const DEFAULT_REPLACEMENT_OPTION = undefined;
const DEFAULT_REPLACEMENT_OPTIONS = undefined;

const replacementOption = new BehaviorSubject<ProductReplacementOption>(DEFAULT_REPLACEMENT_OPTION);
const replacementOptions = new BehaviorSubject<ProductReplacementOption[]>(DEFAULT_REPLACEMENT_OPTIONS);

export const ProductReplacementState = { replacementOption, replacementOptions };

export function setOption(data: ProductReplacementOption): BehaviorSubject<ProductReplacementOption> {
  ProductReplacementState.replacementOption.next(data);
  return ProductReplacementState.replacementOption;
}

export function setOptions(data: ProductReplacementOption[]): BehaviorSubject<ProductReplacementOption[]> {
  ProductReplacementState.replacementOptions.next(data);
  return ProductReplacementState.replacementOptions;
}

export function resetOptions(): BehaviorSubject<ProductReplacementOption[]> {
  ProductReplacementState.replacementOptions.next(DEFAULT_REPLACEMENT_OPTIONS);
  return ProductReplacementState.replacementOptions;
}
