<div class="box-dialog-wrapper">
  <box-dialog-header
    [title]="title"
    class="box-dialog-header promo-banner-details-dialog-header"
    (closeDialog)="onCloseClick()"
  ></box-dialog-header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <section class="promo-banner-details-dialog-section">
      <figure class="promo-banner-details-dialog-image">
        <img [src]="imageUrl" [alt]="title" />
      </figure>
    </section>
    <section *ngIf="description" class="promo-banner-details-dialog-section">
      <div class="promo-banner-details-dialog-description" [innerHTML]="description"></div>
    </section>
    <section *ngIf="coupon" class="promo-banner-details-dialog-section">
      <div class="coupon-container">
        <promo-coupon class="promo-banner-details-dialog-coupon" [promoCoupon]="coupon"></promo-coupon>
        <button
          mat-icon-button
          class="promo-banner-details-dialog-coupon-button"
          matTooltip="Αντιγραφή στο πρόχειρο"
          matTooltipShowDelay="600"
          aria-label="Αντιγραγή του κωδικού κουπονιού στο πρόχειρο"
          ngxClipboard
          (cbOnSuccess)="onCouponCopy()"
          [cbContent]="coupon.code"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 20 20"
            height="20"
            width="20"
          >
            <defs>
              <path id="a" d="M0 0h20v20H0z" />
            </defs>
            <g fill-rule="evenodd">
              <mask id="b" fill="#fff">
                <use xlink:href="#a" />
              </mask>
              <path
                fill-rule="nonzero"
                d="M13.173 16.672H2.443c-.889-.001-1.609-.738-1.61-1.646V1.646C.834.737 1.554 0 2.443 0h10.73c.889.001 1.609.737 1.61 1.646v13.38c-.001.908-.721 1.645-1.61 1.646Z"
                mask="url(#b)"
              />
              <path
                fill-rule="nonzero"
                d="M17.557 3.328h-1.298a.586.586 0 0 0-.114.012v13.076c-.001.91-.723 1.646-1.612 1.646H5.284a.66.66 0 0 0-.067.292c.001.909.721 1.645 1.61 1.646h10.73c.889 0 1.61-.737 1.61-1.646V4.974c0-.909-.721-1.646-1.61-1.646Z"
                mask="url(#b)"
              />
            </g>
          </svg>
        </button>
      </div>
    </section>
    <section *ngIf="relatedShops?.length" class="promo-banner-details-dialog-section">
      <shop-tile-compact
        *ngFor="let shop of relatedShops"
        [shop]="shop"
        class="hoverable"
        visibilityObserver
        [triggerOnce]="true"
        [intersectionRoot]="dialogBody"
        [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
        [intersectionRootMargin]="DEFAULT_INTERSECTION_ROOT_MARGIN"
        (enteredViewport)="onShopEnteredViewport(shop)"
        (click)="onShopCardClick(shop)"
      ></shop-tile-compact>
    </section>
  </main>
  <footer #dialogFooter *ngIf="buttonText" class="box-dialog-footer">
    <section class="promo-banner-details-dialog-footer-section">
      <button box-button theme="primary" (click)="onAction()">{{ buttonText }}</button>
    </section>
  </footer>
</div>
