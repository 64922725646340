import { ChangeDetectionStrategy, Component, HostBinding, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogService, ShopService } from '@box-core/services';
import { Shop } from '@box-types';
import { generateImageSrc } from '@box/utils';
import { ShopPageDetailsDialogComponent } from '../shop-page-details-dialog/shop-page-details-dialog.component';
import {
  ShopPageDetailsDialogData,
  ShopPageDetailsDialogState
} from '../shop-page-details-dialog/shop-page-details-dialog.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'shop-page-info',
  templateUrl: './shop-page-info.component.html',
  styleUrls: ['./shop-page-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopPageInfoComponent implements OnInit, OnDestroy {
  public shop: Shop;
  public logoSrc: string;
  private shopSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private shopService: ShopService
  ) {}

  @HostBinding('class') public hostClass = 'shop-page-info';

  ngOnInit(): void {
    this.setShopSubscription();
  }

  ngOnDestroy(): void {
    this.shopSubscription?.unsubscribe();
  }

  private setShopSubscription(): void {
    this.shopSubscription = this.shopService.shop.subscribe((shop) => {
      this.shop = shop;
      this.logoSrc = generateImageSrc(shop.logo);
      this.changeDetectorRef.detectChanges();
    });
  }

  public onOpenDetails(state: ShopPageDetailsDialogState): void {
    const dialogConfig: MatDialogConfig<ShopPageDetailsDialogData> = {
      panelClass: 'box-dialog',
      data: { state }
    };
    this.dialogService.openDialog(ShopPageDetailsDialogComponent, dialogConfig);
  }
}
