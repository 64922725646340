import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function addressFormValidator(isAddressValid: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const street = control?.value as string;
    if (!street) return { invalid: { missingAddress: 'Το πεδίο Οδός είναι υποχρεωτικό' } };

    if (isAddressValid) return null;
    return { invalid: { addressNotFound: 'Η διεύθυνση δε βρέθηκε' } };
  };
}
