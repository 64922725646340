import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreadCrumbService {
  private readonly breadcrumbsSource = new BehaviorSubject<Record<string, string>>(undefined);
  public readonly breadcrumbs$ = this.breadcrumbsSource.asObservable();

  public clearBreadcrumbs(): void {
    this.breadcrumbsSource.next(undefined);
  }

  private setBreadcrumb(breadcrumb: Record<string, string>): void {
    this.breadcrumbsSource.next(breadcrumb);
  }
  public addBreadcrumbs(breadcrumb: Record<string, string>): void {
    if (this.breadcrumbsSource.getValue() !== undefined) {
      this.setBreadcrumb({ ...this.breadcrumbsSource.getValue(), ...breadcrumb });
    } else {
      this.setBreadcrumb(breadcrumb);
    }
  }

  public getBreadcrumbs(): Record<string, string> {
    return this.breadcrumbsSource.getValue();
  }
}
