import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  HostBinding,
  OnInit
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CheckoutBagsService } from './checkout-bags.service';
import { CheckoutBagsConfig, CheckoutBagsChangeEvent } from '@box-types';

@Component({
  selector: 'checkout-bags',
  templateUrl: './checkout-bags.component.html',
  styleUrls: ['./checkout-bags.component.scss'],
  providers: [CheckoutBagsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutBagsComponent implements OnInit, OnChanges {
  @Input() private bagsConfig: CheckoutBagsConfig;
  @Output() private valueChange = new EventEmitter<CheckoutBagsChangeEvent>();

  private totalBagsPrice: number;
  private bagsQuantity: number;
  private userCanDisable: boolean;

  public disabled: boolean;
  public checked: boolean;
  public description: string;

  constructor(private checkoutBagsService: CheckoutBagsService) {}

  @HostBinding('class') public hostClass = 'checkout-bags';

  ngOnInit(): void {
    this.checked = !this.bagsConfig.disablePlasticBagSelection;
    this.informParent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bagsConfig) {
      this.bagsConfig = changes.bagsConfig.currentValue as CheckoutBagsConfig;
      this.userCanDisable = this.bagsConfig.disablePlasticBagSelection;
      this.disabled = !this.userCanDisable;
      this.bagsQuantity = this.checked ? this.checkoutBagsService.getBagsQuantity(this.bagsConfig) : 0;
      this.totalBagsPrice = this.checked ? this.checkoutBagsService.getBagsTotalPrice(this.bagsConfig) : 0;
      this.description = this.checkoutBagsService.getBagsDescription(this.bagsConfig);
    }
  }

  public onSlideChange(event: MatSlideToggleChange): void {
    this.checked = event.checked;
    this.informParent();
  }

  private informParent(): void {
    this.bagsQuantity = this.checked ? this.checkoutBagsService.getBagsQuantity(this.bagsConfig) : 0;
    this.totalBagsPrice = this.checked ? this.checkoutBagsService.getBagsTotalPrice(this.bagsConfig) : 0;
    this.description = this.checkoutBagsService.getBagsDescription(this.bagsConfig);
    this.valueChange.emit({ checked: this.checked, price: this.totalBagsPrice, bagsQuantity: this.bagsQuantity });
  }
}
