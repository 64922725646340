import { Order, OrderPaymentIndicator, OrderHistoryGroup, OrderDeliveryStatus } from '@box-types';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/el';

dayjs.extend(weekday);
dayjs.locale('el');

export {
  CSR_PAYMENT_INDICATOR,
  CART_PAYMENT_INDICATOR,
  groupOrdersByDay,
  getOrderHistoryGroups,
  getOrderHistoryGroupsByStatus,
  generateOrderPaymentIndicators,
  getLastWeekOrders
};

const CSR_PAYMENT_INDICATOR: OrderPaymentIndicator = {
  imageSrc: '/assets/images/orders/csr-indicator.svg',
  imageAlt: 'CSR Donation'
};
const CART_PAYMENT_INDICATOR: OrderPaymentIndicator = {
  imageSrc: '/assets/images/orders/card-indicator.svg',
  imageAlt: 'Paid with Card'
};

function groupOrdersByDay(orders: Order[]): Record<string, Order[]> {
  return orders.reduce((grouped: Record<string, Order[]>, order: Order) => {
    const key = dayjs(order.createdAt).startOf('day').toString();
    return { ...grouped, [key]: [...(grouped[key] ?? []), order] };
  }, {});
}

function getOrderHistoryGroups(orders: Order[]): OrderHistoryGroup[] {
  if (!orders?.length) return [];
  const groupedOrdersByDay = groupOrdersByDay(orders);
  return Object.keys(groupedOrdersByDay)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
    .map((key) => ({ day: dayjs(key).format('dddd, DD/MM/YYYY'), orders: groupedOrdersByDay[key] }));
}

function getOrderHistoryGroupsByStatus(orders: Order[], status: OrderDeliveryStatus): OrderHistoryGroup[] {
  if (!orders.length) return [];
  return getOrderHistoryGroups(orders.filter((order) => order.status === status));
}

function generateOrderPaymentIndicators(order: Order): OrderPaymentIndicator[] {
  const indicators: OrderPaymentIndicator[] = [];
  if (order.donation) indicators.push(CSR_PAYMENT_INDICATOR);
  if (order.paymentType === 'card') indicators.push(CART_PAYMENT_INDICATOR);
  return indicators;
}

function getLastWeekOrders(orders: Order[]): Order[] {
  if (!orders?.length) return;
  const previousMonday = dayjs().weekday(-7).hour(0).minute(0).second(0);
  const lastMonday = dayjs().weekday(0).hour(0).minute(0).second(0);
  return orders.filter((order) => {
    const orderCreationDate = dayjs(order.createdAt);
    return orderCreationDate.isBetween(previousMonday, lastMonday);
  });
}
