<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="Επιβεβαίωση κινητού" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="verification-dialog-content">
      <div class="verification-dialog-image-container">
        <figure class="verification-dialog-image">
          <img src="/assets/images/logo/logo.svg" alt="BOX" />
        </figure>
      </div>

      <p class="verification-dialog-description">{{ description }}</p>

      <ng-container *ngIf="state === 'PHONE'; else verificationField">
        <mat-form-field class="verification-dialog-field">
          <mat-label>Κινητό</mat-label>
          <input
            matInput
            autocomplete="off"
            name="phoneInput"
            (keydown.enter)="onSendCode()"
            [formControl]="phoneControl"
          />
          <mat-error>Ο αριθμός δεν είναι έγκυρος</mat-error>
        </mat-form-field>
        <button
          box-button
          theme="primary"
          class="verification-dialog-button"
          [disabled]="phoneControl.invalid"
          (click)="onSendCode()"
        >
          Συνέχεια
        </button>
      </ng-container>
      <ng-template #verificationField>
        <mat-form-field class="verification-dialog-field">
          <mat-label>Κωδικός επιβεβαίωσης</mat-label>
          <input
            matInput
            autocomplete="off"
            name="verificationCode"
            (keydown.enter)="onVerification()"
            [formControl]="verificationControl"
          />
          <mat-error>Ο κωδικός επιβεβαίωσης πρέπει να αποτελείται από 4 ψηφία!</mat-error>
        </mat-form-field>
        <button
          box-button
          theme="primary"
          class="verification-dialog-button"
          [disabled]="verificationControl.invalid"
          (click)="onVerification()"
        >
          Συνέχεια
        </button>
      </ng-template>

      <footer class="verification-dialog-footer">
        <p class="verification-dialog-footer-description ods-typography-body-m-regular">
          Για οποιοδήποτε πρόβλημα, μπορείς να καλέσεις την ομάδα μας στο
          <a [attr.href]="'tel:' + supportPhoneNumber" class="verification-dialog-footer-description-number">{{
            supportPhoneNumber
          }}</a
          >, κάθε μέρα, 07:00 - 03:00, για να σε βοηθήσει.
        </p>
        <verification-resend-code
          #verificationResendCodeComponent
          *ngIf="state === 'PASSWORD'"
          (send)="onSendCode()"
        ></verification-resend-code>
      </footer>
    </div>
  </main>
</div>
