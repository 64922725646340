import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Cuisine, CollapsibleTile, BusinessVertical, GAPromotionConfig } from '@box-types';
import { SwiperOptions } from 'swiper/types';
import { AnalyticsService } from '@box-core/services';
import { Router } from '@angular/router';
import { Mousewheel, Navigation } from 'swiper';
import { HomeSection, getCollectionHomeCuisinesImpressionGAConfig } from '@box/utils';

@Component({
  selector: 'home-cuisines',
  templateUrl: './home-cuisines.component.html',
  styleUrls: ['./home-cuisines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeCuisinesComponent implements OnInit, AfterViewInit {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() public homeSection: HomeSection;
  public mainViewTiles: CollapsibleTile[];
  public title: string;
  public buttonText: string;
  public showActionButton: boolean;
  public swiperOptions: SwiperOptions;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.setSectionContent();
    this.setSwiperOptions();
  }

  // todo lots of duplicate code related to swiper written in home-shops-section contests-section and promo-banners
  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
    this.changeDetectorRef.detach();
  }

  private setSectionContent(): void {
    this.title = this.homeSection.title;
    this.buttonText = 'Δες τα όλα';
    this.mainViewTiles = this.homeSection.mainViewTiles;
    this.showActionButton = this.homeSection.showActionButton;
  }

  public onCuisineClick(cuisine: Cuisine): void {
    this.triggerAnalyticsEvent('select_promotion', cuisine);
    const queryParams = { vertical: cuisine.businessVertical, cuisine: cuisine.key };
    void this.router.navigate(['/discover'], { queryParams });
  }

  public onAction(): void {
    const vertical: BusinessVertical = this.homeSection.type === 'foodCuisines' ? 'food' : 'groceries';
    const queryParams = { vertical };
    this.triggerButtonAnalyticsEvents();
    void this.router.navigate(['/discover'], { queryParams });
  }

  public onCuisineEnteredViewport(cuisine: Cuisine): void {
    this.triggerAnalyticsEvent('view_promotion', cuisine);
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  private triggerAnalyticsEvent(eventName: string, cuisine: Cuisine): void {
    const gaConfig = getCollectionHomeCuisinesImpressionGAConfig(this.homeSection, this.mainViewTiles, cuisine);
    this.analyticsService.addGAEcommerceEvent(eventName, gaConfig);
  }

  private triggerButtonAnalyticsEvents(): void {
    const gaConfig = {
      creative_name: 'des_ta_ola',
      creative_slot: 'discover_home',
      promotion_id: '',
      promotion_name: this.homeSection.title
    } as GAPromotionConfig;
    this.analyticsService.addGAEcommerceEvent('select_promotion', gaConfig);
  }
}
