import { Injectable } from '@angular/core';
import { ConfigurationService } from '@box-core/services';
import {
  getFirstPersonalDataTermsHTML,
  getMarketFindCardTermsHTML,
  getSaveCardTermsText,
  getSecondPersonalDataTermsHTML
} from '@box/utils';
import { BoxDialogValidationMode, SMLoyaltyConfiguration } from '@box-types';
import { ValidatorFn } from '@angular/forms';
import { emailValidator } from '@box-shared/validators';
import { numbersOnlyValidator } from '@box-shared/validators/numbers-only.validator';

@Injectable()
export class MarketCardConnectDialogService {
  private readonly CREATE_BUTTON_TEXT = 'Δημιουργία νέας κάρτας';
  private readonly CREATE_GUEST_BUTTON_TEXT = 'Συνέχεια χωρίς κάρτα';

  constructor(private configService: ConfigurationService) {}

  public getSMLoyaltyConfig(integrator: string): SMLoyaltyConfiguration {
    const smLoyaltyInfo = this.configService.getConfiguration()?.smLoyaltyInfo ?? [];
    return smLoyaltyInfo.find((info) => info.integrator === integrator);
  }

  public getCreateButtonText(mode: string): string {
    if (mode === 'normal') return this.CREATE_BUTTON_TEXT;
    if (mode === 'guest') return this.CREATE_GUEST_BUTTON_TEXT;
  }

  public getFindCardTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getMarketFindCardTermsHTML(loyaltyConfig);
  }

  public getSaveCardTermsText(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getSaveCardTermsText(loyaltyConfig);
  }

  public getFirstPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getFirstPersonalDataTermsHTML(loyaltyConfig);
  }

  public getSecondPersonalDataTermsHTML(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getSecondPersonalDataTermsHTML(loyaltyConfig);
  }

  public getInputValidator(mode: BoxDialogValidationMode): ValidatorFn {
    if (mode === 'EMAIL') return emailValidator();
    return numbersOnlyValidator();
  }
}
