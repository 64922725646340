<ng-container *ngIf="state === 'AVAILABLE'; else waitingState">
  <span class="verification-resend-code-text ods-typography-body-s-regular"
    >Δεν έλαβες το {{ otpMethod?.email?.length ? 'email' : 'SMS' }};</span
  >
  <button
    box-button
    theme="secondary"
    size="small"
    borders="none"
    class="verification-resend-code-button"
    (click)="onSend()"
  >
    Αποστολή ξανά
  </button>
</ng-container>
<ng-template #waitingState>
  <span class="verification-resend-code-text ods-typography-body-s-regular">Αποστολή ξανά σε:</span>
  <span class="verification-resend-code-timer ods-typography-body-s-bold">{{ remainingTime }}</span>
</ng-template>
