import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Shop, APIError } from '@box-types';
import { Observable } from 'rxjs';
import { AddressesService, ShopsService, DialogService } from '@box-core/services';
import { isAddressReadyForView, filterShopsByChainKeys, getClosestShopToAddress } from '@box/utils';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShopWithChainResolver implements Resolve<Shop> {
  constructor(
    private addressesService: AddressesService,
    private shopsService: ShopsService,
    private router: Router,
    private dialogService: DialogService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Shop> {
    const address = this.addressesService.getAddress();
    const chainKey = route.params.chainKey as string;
    if (!chainKey?.length || !isAddressReadyForView(address)) return void this.goToLandingPage();

    const fetchOptions = { address };
    return this.shopsService.fetchShops(fetchOptions).pipe(
      map((shops) => {
        const shopsWithChain = filterShopsByChainKeys(shops, [chainKey]);
        if (!shopsWithChain?.length) {
          this.showNoShopsFoundDialog();
          this.goToLandingPage();
          return;
        }

        const shop = getClosestShopToAddress(address, shopsWithChain);
        this.gotoShopPage(shop);
        return shop;
      })
    );
  }

  private gotoShopPage(shop: Shop): void {
    if (!shop) return;
    const vanityUrl = shop.vanity_url;
    const locationKey = shop.locationKey;
    if (!vanityUrl || !locationKey) return;
    void this.router.navigate(['/delivery', locationKey, vanityUrl]);
  }

  private goToLandingPage(): void {
    void this.router.navigate(['/']);
  }

  private showNoShopsFoundDialog(): Observable<Error | APIError> {
    return this.dialogService
      .openErrorDialog({
        userMessage: 'Δεν βρέθηκαν καταστήματα για την συγκεκριμένη αλυσίδα στη διεύθυνση σου.'
      })
      .afterClosed();
  }
}
