import { getOfferMaxItemsPerOrder } from './offer.utils';
import { Offer } from '@box-types';

export { getOfferMinOrderItemsPriceText, getOfferMaxQuantityPerOrderText };

function getOfferMinOrderItemsPriceText(offer: Offer): string {
  const { minOrderItemsPrice } = offer;
  if (!minOrderItemsPrice) return;
  const formatOptions = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 };
  const formattedPrice = new Intl.NumberFormat('el-GR', formatOptions)
    .format(minOrderItemsPrice / 100)
    .replace(/\s/g, '');
  return `Με ελάχιστη παραγγελία ${formattedPrice}`;
}

function getOfferMaxQuantityPerOrderText(offer: Offer): string {
  const maxItemsPerOrder = getOfferMaxItemsPerOrder(offer);
  if (!maxItemsPerOrder) return;
  const timesText = maxItemsPerOrder === 1 ? 'φορά' : 'φορές';
  return `Μέχρι ${maxItemsPerOrder} ${timesText} ανά παραγγελία`;
}
