<component-loader *ngIf="loadingMapsApi"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="'Η διεύθυνση μου'" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main
    #dialogBody
    bodyScroll
    (bodyScroll)="onBodyScroll($event)"
    class="box-dialog-content address-dialog-container"
    [class.address-dialog-container-column-layout]="!verbose"
  >
    <div class="address-dialog-map-container">
      <ng-container *ngIf="mapsAPILoaded; else emptyState">
        <google-map
          #map
          class="address-google-map"
          width="100%"
          height="100%"
          [options]="mapOptions"
          (mapDragend)="onMapDragend()"
        >
          <div class="address-google-map-marker">
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="54" fill="none">
              <ellipse cx="23" cy="49" fill="url(#a)" opacity=".5" rx="19" ry="4" />
              <g filter="url(#b)">
                <path
                  fill="#fff"
                  fill-rule="evenodd"
                  d="M8 5a4 4 0 0 0-4 4v30a4 4 0 0 0 4 4h9.804L23 49l5.196-6H38a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4H8Z"
                  clip-rule="evenodd"
                />
              </g>
              <rect width="30" height="30" x="8" y="9" fill="#2A292E" rx="4" />
              <path
                fill="#fff"
                d="M23 16.5a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5Zm0 9.375c4.144 0 7.5 1.678 7.5 3.75V31.5h-15v-1.875c0-2.072 3.356-3.75 7.5-3.75Z"
              />
              <defs>
                <radialGradient
                  id="a"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientTransform="matrix(0 4 -19 0 23 49)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-opacity=".49" />
                  <stop offset=".906" stop-opacity="0" />
                </radialGradient>
                <filter
                  id="b"
                  width="46"
                  height="54"
                  x="0"
                  y="0"
                  color-interpolation-filters="sRGB"
                  filterUnits="userSpaceOnUse"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.160784 0 0 0 0 0.180392 0 0 0 0.1 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_392_1369" />
                  <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="-1" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix values="0 0 0 0 0.164706 0 0 0 0 0.160784 0 0 0 0 0.180392 0 0 0 0.1 0" />
                  <feBlend in2="effect1_dropShadow_392_1369" result="effect2_dropShadow_392_1369" />
                  <feBlend in="SourceGraphic" in2="effect2_dropShadow_392_1369" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </google-map>
      </ng-container>
      <ng-template #emptyState>
        <div [style.background-image]="emptyStateMapUrl" class="address-dialog-map-container-empty-state"></div>
      </ng-template>
    </div>

    <form [formGroup]="addressForm" class="address-dialog-form" autocomplete="off">
      <div class="address-dialog-form-input-group">
        <mat-form-field class="address-dialog-form-field">
          <input
            matInput
            name="street"
            formControlName="street"
            required
            placeholder="Οδός"
            (input)="onStreetInput()"
            [debounceTime]="DEBOUNCE_TIME"
            (debounceInput)="onStreetDebounceInput()"
          />
          <mat-error>
            {{ getStreetFormFieldErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="address-dialog-form-field right-field">
          <input
            matInput
            name="streetNo"
            formControlName="streetNo"
            placeholder="Αριθμός"
            (input)="onStreetInput()"
            [debounceTime]="DEBOUNCE_TIME"
            (debounceInput)="onStreetDebounceInput()"
          />
        </mat-form-field>
      </div>

      <div class="address-dialog-form-input-group">
        <mat-form-field class="address-dialog-form-field">
          <input matInput name="city" formControlName="city" required placeholder="Πόλη / Περιοχή" />
          <mat-error>Το πεδίο Πόλη / Περιοχή είναι υποχρεωτικό</mat-error>
        </mat-form-field>
        <mat-form-field class="address-dialog-form-field right-field">
          <input matInput name="postalCode" formControlName="postalCode" required placeholder="ΤΚ" />
          <mat-error>Το πεδίο ΤΚ είναι υποχρεωτικό</mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="verbose">
        <div class="address-dialog-form-input-group">
          <mat-form-field class="address-dialog-form-field">
            <input matInput name="nameAtBell" formControlName="nameAtBell" required placeholder="Κουδούνι" />
            <mat-error *ngIf="addressForm.controls.nameAtBell.errors?.required"
              >Το πεδίο Κουδούνι είναι υποχρεωτικό
            </mat-error>
            <mat-error *ngIf="addressForm.controls.nameAtBell.errors?.maxlength"
              >Το πεδίο Κουδούνι δεν μπορεί να ξεπερνάει τους {{ NAME_AT_BELL_MAX_LENGTH }} χαρακτήρες
            </mat-error>
          </mat-form-field>
          <mat-form-field class="address-dialog-form-field right-field">
            <input matInput name="floor" formControlName="floor" required placeholder="Όροφος" />
            <mat-error>Το πεδίο Όροφος είναι υποχρεωτικό</mat-error>
          </mat-form-field>
        </div>

        <div class="address-dialog-form-input-group">
          <mat-form-field class="address-dialog-form-field">
            <input matInput name="comments" formControlName="comments" placeholder="Σχόλια" />
            <mat-hint>π.χ. κτήριο Β, είσοδος από το πλάι, προσοχή σκύλος...</mat-hint>
          </mat-form-field>
        </div>

        <div class="address-dialog-form-input-group">
          <address-type
            class="address-dialog-form-field"
            [value]="addressForm.value.type"
            (typeChange)="onAddressTypeChange($event)"
          ></address-type>
        </div>
      </ng-container>
    </form>
  </main>
  <footer #dialogFooter class="box-dialog-footer address-dialog-footer">
    <button
      box-button
      theme="primary"
      class="address-dialog-footer-submit-btn"
      [class.address-dialog-footer-submit-btn--skeleton]="loadingPlacesResults"
      [disabled]="addressForm.invalid || loadingPlacesResults"
      (click)="onSaveAddress()"
    >
      Αποθήκευση
    </button>
  </footer>
</div>
