import { OnInit, ViewChild, ElementRef, Inject, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutCouponCodeDialogData, CheckoutCouponCodeDialogResponse } from './coupon-code-dialog.types';

const COUPON_ENTRY_DESCRIPTION =
  'Πληκτρολόγησε τον κωδικό κουπονιού σου, και κέρδισε έκπτωση στην παραγγελία σου ή πόντους δώρο!';

@Component({
  selector: 'coupon-code-dialog',
  templateUrl: './coupon-code-dialog.component.html',
  styleUrls: ['./coupon-code-dialog.component.scss']
})
export class CouponCodeDialogComponent implements OnInit {
  @ViewChild('couponInput', { static: true }) public couponInput: ElementRef<HTMLInputElement>;

  public couponControl: FormControl;
  public description: string;

  private code: string;

  constructor(
    private dialogRef: MatDialogRef<CouponCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CheckoutCouponCodeDialogData
  ) {}

  ngOnInit(): void {
    this.code = this.data?.code ?? '';
    this.couponControl = new FormControl(this.code);
    this.couponInput.nativeElement.focus();
    this.description = this.getCouponEntryDescription();
  }

  public closeDialog(response?: CheckoutCouponCodeDialogResponse): void {
    this.dialogRef.close(response);
  }

  public getCouponEntryDescription(): string {
    return COUPON_ENTRY_DESCRIPTION;
  }

  public onCouponInput(): void {
    const couponCode = this.couponControl.value as string;
    this.couponControl.setValue(couponCode.trim().toLocaleUpperCase());
  }

  public onSubmit(): void {
    const couponCode = this.couponControl.value as string;
    this.closeDialog({ code: couponCode });
  }
}
