<div class="header-wrapper" [ngClass]="headerClasses">
  <div *ngIf="canBack && mode === 'normal'" class="header-button-wrapper header-back">
    <button mat-icon-button class="header-button" (click)="onBack($event)">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <h1 *ngIf="title" class="header-title">{{ title }}</h1>
  <div *ngIf="canClose" class="header-button-wrapper header-close">
    <button *ngIf="mode !== 'switch'" mat-icon-button class="header-button" (click)="onClose($event)">
      <mat-icon *ngIf="mode === 'normal'">close</mat-icon>
      <mat-icon *ngIf="mode === 'check'" fontSet="material-icons-round">check</mat-icon>
    </button>
    <mat-slide-toggle *ngIf="mode === 'switch'" color="primary"></mat-slide-toggle>
  </div>
</div>
