import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of, switchMap, mapTo } from 'rxjs';
import {
  APIResponse,
  APIError,
  MarketCardCreateResponse,
  MarketCardCreateOptions,
  MarketCardConnectOptions,
  MarketCardDetails,
  MarketCardPasswordResetOptions,
  MarketOrderCheckOptions,
  MarketCardDetailsOptions,
  MarketOrderCheck,
  MarketOTPOptions,
  MarketOTPResponse,
  MarketVerifyOTPOptions,
  MarketCardDisconnectOptions,
  Shop
} from '@box-types';
import { environment } from '@box-env/environment';
import { tap, map, catchError, finalize } from 'rxjs/operators';
import {
  getLoyaltyCardForIntegrator,
  hasLocalKritikosCard,
  isCardExpirationErrorCode,
  isEfreshTokenInvalid
} from '@box/utils';
import { MarketLoyaltyDialogsService } from '@box-checkout/services';
import { UserService } from '@box-core/services/user.service';
import { DialogService } from '@box-core/services/dialog.service';
import { LoaderService } from '@box-core/services/loader.service';

const DEFAULT_POINTS_CHECKED_VALUE = false;
const DEFAULT_POINTS_VALUE: MarketOrderCheck = null;

@Injectable({ providedIn: 'root' })
export class MarketLoyaltyService {
  private BOX_API = environment.application.API_URL;

  private readonly pointsCheckedSource = new BehaviorSubject<boolean>(DEFAULT_POINTS_CHECKED_VALUE);
  public readonly pointsChecked$ = this.pointsCheckedSource.asObservable();

  private readonly checkoutPointsSource = new BehaviorSubject<MarketOrderCheck>(DEFAULT_POINTS_VALUE);
  public readonly checkoutPoints$ = this.checkoutPointsSource.asObservable();

  constructor(
    private http: HttpClient,
    private marketLoyaltyDialogsService: MarketLoyaltyDialogsService,
    private userService: UserService,
    private loaderService: LoaderService,
    private dialogService: DialogService
  ) {}

  public setChecked(value: boolean): void {
    this.pointsCheckedSource.next(value);
  }

  public clearChecked(): void {
    this.setChecked(DEFAULT_POINTS_CHECKED_VALUE);
  }

  public setCheckoutPoints(data: MarketOrderCheck): void {
    this.checkoutPointsSource.next(data);
  }

  public clearCheckoutPoints(): void {
    this.setCheckoutPoints(DEFAULT_POINTS_VALUE);
  }

  public checkUserMarketCard(shop: Shop): Observable<boolean> {
    const loyaltyCards = this.userService.getLoayltyCards();
    const integratorName = shop?.integrator?.company;
    const loyaltyCard = getLoyaltyCardForIntegrator(loyaltyCards, integratorName);
    if (!loyaltyCard) {
      if (integratorName === 'kritikos' && hasLocalKritikosCard()) {
        return of(true);
      } else {
        return this.marketLoyaltyDialogsService.openMarketCardConnectDialog(shop);
      }
    }
    const { customerToken, name: supermarket } = loyaltyCard;
    this.loaderService.setState(true);
    return this.fetchCardDetails({ customerToken, supermarket }).pipe(
      finalize(() => this.loaderService.setState(false)),
      mapTo(true),
      catchError((error: APIError) => {
        return this.dialogService
          .openErrorDialog(error)
          .afterClosed()
          .pipe(
            switchMap(() => {
              if (isCardExpirationErrorCode(error) || isEfreshTokenInvalid(error)) {
                this.userService.removeLoyaltyCardFromUser(loyaltyCard);
                return this.marketLoyaltyDialogsService.openMarketCardConnectDialog(shop);
              }
              return of(false);
            })
          );
      })
    );
  }

  public createCard(options: MarketCardCreateOptions): Observable<MarketCardCreateResponse> {
    const data = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/card/create', data)
      .pipe(map((response: APIResponse<MarketCardCreateResponse>) => response.payload));
  }

  public connectCard(options: MarketCardConnectOptions): Observable<MarketCardDetails> {
    const data = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/card/login', data)
      .pipe(map((response: APIResponse<MarketCardDetails>) => response.payload));
  }

  public disconnectCard(options: MarketCardDisconnectOptions): Observable<void> {
    const data = { ...options };
    return this.http.post<void>(this.BOX_API + '/integration/supermarket/card/remove', data);
  }

  public fetchCardDetails(options: MarketCardDetailsOptions): Observable<MarketCardDetails> {
    const data = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/card/info', data)
      .pipe(map((response: APIResponse<MarketCardDetails>) => response.payload));
  }

  public resetCardPassword(options: MarketCardPasswordResetOptions): Observable<HttpResponse<APIResponse>> {
    const data = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/card/forgotPass', data, { observe: 'response' })
      .pipe(
        tap((response: HttpResponse<APIResponse>) => {
          if (!response || response.status !== 204) throwError(() => 'PASSWORD_RESET_ERROR');
        })
      );
  }

  public checkOrder(options: MarketOrderCheckOptions): Observable<MarketOrderCheck> {
    const data = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/order/check', data)
      .pipe(map((response: APIResponse<MarketOrderCheck>) => response.payload));
  }

  public requestCardOTP(options: MarketOTPOptions): Observable<MarketOTPResponse> {
    const body = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/card/sendOTP', body)
      .pipe(map((response: APIResponse<MarketOTPResponse>) => response.payload));
  }

  public verifyCardOTP(options: MarketVerifyOTPOptions): Observable<MarketCardDetails> {
    const body = { ...options };
    return this.http
      .post(this.BOX_API + '/integration/supermarket/card/verifyOTP', body)
      .pipe(map((response: APIResponse<MarketCardDetails>) => response.payload));
  }
}
