<ng-container *ngIf="loading; else checkoutCouponComponent">
  <div class="checkout-coupon-wrapper checkout-coupon-wrapper-skeleton"></div>
</ng-container>
<ng-template #checkoutCouponComponent>
  <div class="checkout-coupon-wrapper" (click)="onCouponClick()">
    <div class="checkout-coupon-banner">
      <checkout-coupon-image
        [coupon]="coupon"
        [badge]="coupons.length"
        badgeSize="medium"
        [badgeHidden]="coupon || !coupons?.length"
      ></checkout-coupon-image>
    </div>
    <div class="checkout-coupon-content">
      <p class="checkout-coupon-text ods-typography-body-s-bold">{{ couponDescription }}</p>
    </div>
    <div class="checkout-coupon-slide-container">
      <mat-slide-toggle
        color="primary"
        class="checkout-coupon-slide"
        [checked]="!!coupon"
        (click)="onToggleClick($event)"
        (change)="onSlideChange($event)"
      ></mat-slide-toggle>
    </div>
  </div>
</ng-template>
