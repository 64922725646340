<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="'Πρόσφατες παραγγελίες'" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <div class="shop-orders-dialog-orders-wrapper">
      <ng-container *ngIf="inProgressOrdersDayGroups?.length">
        <section class="shop-orders-dialog-orders-section">
          <h2 class="shop-orders-dialog-orders-section-title">Σε εξέλιξη</h2>
          <div *ngFor="let group of inProgressOrdersDayGroups">
            <h3 class="shop-orders-dialog-orders-day-label">{{ group.day }}</h3>
            <div class="shop-orders-dialog-order-container">
              <order
                class="hoverable"
                *ngFor="let order of group.orders"
                [order]="order"
                (click)="onOrderClick(order)"
              ></order>
            </div>
          </div>
        </section>
        <div class="box-divider"></div>
      </ng-container>
      <ng-container *ngIf="scheduledOrdersDayGroups?.length">
        <section class="shop-orders-dialog-orders-section">
          <h2 class="shop-orders-dialog-orders-section-title">Προγραμματισμένες</h2>
          <div *ngFor="let group of scheduledOrdersDayGroups">
            <h3 class="shop-orders-dialog-orders-day-label">{{ group.day }}</h3>
            <div class="shop-orders-dialog-order-container">
              <order
                class="hoverable"
                *ngFor="let order of group.orders"
                [order]="order"
                (click)="onOrderClick(order)"
              ></order>
            </div>
          </div>
        </section>
        <div class="box-divider"></div>
      </ng-container>
      <ng-container *ngIf="completedOrdersDayGroups?.length">
        <section class="shop-orders-dialog-orders-section">
          <h2 class="shop-orders-dialog-orders-section-title">Προηγούμενες</h2>
          <div *ngFor="let group of completedOrdersDayGroups">
            <h3 class="shop-orders-dialog-orders-day-label">{{ group.day }}</h3>
            <div class="shop-orders-dialog-order-row" *ngFor="let order of group.orders">
              <div class="order-container">
                <order
                  class="hoverable"
                  [order]="order"
                  (click)="onOrderClick(order)"
                  (orderRate)="onOrderRate(order, $event)"
                ></order>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
    </div>
  </main>
</div>
