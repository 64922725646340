<a class="shop-tile-compact-link" [routerLink]="routerLink">
  <div class="shop-tile-compact-image">
    <div *ngIf="showTags" class="shop-tile-compact-image-tags">
      <promo-tag
        [@shopTagSlide]
        [@.disabled]="disableAnimation"
        *ngIf="firstGroupTagOptions"
        [options]="firstGroupTagOptions"
      ></promo-tag>
      <promo-tag
        [@shopTagSlide]
        [@.disabled]="disableAnimation"
        *ngIf="secondGroupTagOptions"
        [options]="secondGroupTagOptions"
      ></promo-tag>
    </div>
    <image-tile [overlayText]="disabledText" [imgUrl]="logoUrl" [altText]="name + ' logo'"> </image-tile>
  </div>
  <div class="shop-tile-compact-details" [class.shop-tile-compact-details-disabled]="disabledText">
    <div *ngIf="showBadges" class="shop-tile-compact-details-badges">
      <promo-badge *ngIf="firstGroupBadgeOptions" [options]="firstGroupBadgeOptions"></promo-badge>
      <promo-badge *ngIf="secondGroupBadgeOptions" [options]="secondGroupBadgeOptions"></promo-badge>
    </div>
    <div class="shop-tile-compact-info">
      <div class="shop-tile-compact-info-name shop-tile-compact-info-row" [title]="name">{{ name }}</div>
      <div class="shop-tile-compact-info-row">
        <div class="shop-tile-compact-info-row-block shop-tile-compact-info-new-tag" *ngIf="isNew">NEW</div>
        <shop-rating *ngIf="showRating" class="shop-tile-compact-info-row-block" [shop]="shop"></shop-rating>
        <span class="shop-tile-compact-info-row-block shop-tile-compact-info-cuisine-text" [title]="cuisineText">{{
          cuisineText
        }}</span>
      </div>
      <div class="shop-tile-compact-info-row">
        <shop-tile-delivery-info [shop]="shop"></shop-tile-delivery-info>
      </div>
    </div>
    <span *ngIf="showPromotedTag" class="shop-tile-compact-details-promoted-tag">{{ promotedTag }}</span>
  </div>
</a>
