import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Card, PaymentCardLoyaltyInfo, APIError, BankLoyaltyVerificationAlert } from '@box-types';
import { PaymentGatewayService, DialogService } from '@box-core/services';
import { BankPointsRedeemToggleService } from '@box-checkout/components/bank-points-redeem-toggle/bank-points-redeem-toggle.service';
import { finalize, switchMap, map, tap } from 'rxjs/operators';
import {
  BankPointsRedeemVerificationAlertComponent,
  BankPointsRedeemSlideDialogComponent,
  BankPointsRedeemVerificationDialogComponent
} from '@box-checkout/components';
import { BankPointsRedeemVerificationAlertResponse } from '@box-checkout/components/bank-points-redeem-verification-alert/bank-points-redeem-verification-alert-response.interface';
import { of, Observable } from 'rxjs';
import {
  BankPointsRedeemVerificationDialogData,
  BankPointsRedeemVerificationDialogResponse
} from '@box-checkout/components/bank-points-redeem-verification-dialog/bank-points-redeem-verification-dialog.interface';
import { MatDialogConfig } from '@angular/material/dialog';
import { BankPointsRedeemSlideDialogResponse } from '@box-checkout/components/bank-points-redeem-slide-dialog/bank-points-redeem-slide-dialog.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { currencyFormat, generateImageSrc } from '@box/utils';
import { CheckoutStateService } from '@box-checkout/services';

@Component({
  selector: 'bank-points-redeem-toggle',
  templateUrl: './bank-points-redeem-toggle.component.html',
  styleUrls: ['./bank-points-redeem-toggle.component.scss'],
  providers: [BankPointsRedeemToggleService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankPointsRedeemToggleComponent implements OnChanges {
  @Input() public card: Card;
  @Input() public cartPrice: number;
  @Input() private cartPriceWhichCanBeDiscounted: number;
  @HostBinding('class') public hostClass = 'bank-points-redeem-toggle';

  public state: 'VERIFIED' | 'SELECTED' | 'UNVERIFIED';
  public loading = false;
  public visible: boolean;

  public cardBoxId: string;
  public loyaltyName: string; // Bonus
  public checkoutBigLogoUrl: string;
  public checkoutSmallLogoUrl: string;
  public checkoutPointsImageUrl: string;
  public verifiedViewPoints: string;
  public verifiedViewEuros: string;
  public pointsToRedeem = 0;
  public discount = 0;
  public cardLoyaltyInfo: PaymentCardLoyaltyInfo;

  private verificationAlertAssets: BankLoyaltyVerificationAlert;

  constructor(
    private bankPointsRedeemToggleService: BankPointsRedeemToggleService,
    private paymentGatewayService: PaymentGatewayService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private checkoutStateService: CheckoutStateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.card) {
      this.card = changes.card.currentValue as Card;
      this.initializeAssets();
      this.initializeVerification();
    }
    if (changes.incomingPrice) this.cartPrice = changes.incomingPrice.currentValue as number;
    if (changes.cartPriceWhichCanBeDiscounted) {
      this.cartPriceWhichCanBeDiscounted = changes.cartPriceWhichCanBeDiscounted.currentValue as number;
    }
  }

  private initializeAssets(): void {
    this.visible = true;
    const bankLoyaltyInfo = this.bankPointsRedeemToggleService.getBankLoyaltyInfo(this.card);
    if (!bankLoyaltyInfo) return void (this.visible = false);
    if (!this.bankPointsRedeemToggleService.isBusinessVerticalMatching(bankLoyaltyInfo)) {
      return void (this.visible = false);
    }
    this.cardBoxId = this.card.cardDetailsAddedByBox?.boxId;
    this.loyaltyName = bankLoyaltyInfo.loyaltyName;
    this.verificationAlertAssets = bankLoyaltyInfo.verificationAlert;
    this.checkoutBigLogoUrl = generateImageSrc(bankLoyaltyInfo.checkout.bigLogo);
    this.checkoutSmallLogoUrl = generateImageSrc(bankLoyaltyInfo.checkout.smallLogo);
    this.checkoutPointsImageUrl = generateImageSrc(bankLoyaltyInfo.checkout.pointsImage);
    this.changeDetectorRef.detectChanges();
  }

  private initializeVerification(): void {
    if (!this.visible) return;
    const redemption = this.card.cardDetailsAddedByBox.loyalty;
    if (redemption?.needsVerification) {
      this.state = 'UNVERIFIED';
      this.changeDetectorRef.detectChanges();
      if (this.bankPointsRedeemToggleService.hasVerificationAlertBeenShown(this.card)) return;
      this.initiateVerificationDialogFlows$().subscribe();
    } else {
      this.state = 'VERIFIED';
      this.changeDetectorRef.detectChanges();
      this.bankPointsRedeemToggleService.removeVerificationAlertFromStorage(this.card);
      this.getLoyaltyState();
      return;
    }
  }

  private getLoyaltyState(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.paymentGatewayService
      .getCardLoyaltyPoints(this.cardBoxId)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (response: PaymentCardLoyaltyInfo) => {
          if (!response.conversionSteps?.length) {
            this.visible = false;
            return this.changeDetectorRef.detectChanges();
          }
          this.cardLoyaltyInfo = { points: response.points, conversionSteps: response.conversionSteps };
          this.setVerifiedViewPlanTexts();
          this.changeDetectorRef.detectChanges();
        },
        error: (err: APIError) => {
          this.visible = false;
          this.changeDetectorRef.detectChanges();
          this.dialogService.openErrorDialog(err);
        }
      });
  }

  public onSlideChange(event: MatSlideToggleChange): void {
    event.source.checked = false;
    this.changeDetectorRef.detectChanges();

    if (this.isMaximumDiscountAlreadyReached()) return void this.showMaximumDiscountAlreadyReachedDialog();

    switch (this.state) {
      case 'SELECTED':
        this.onCancelPlan();
        break;
      case 'UNVERIFIED':
        this.openVerificationDialog$().subscribe();
        break;
      case 'VERIFIED': {
        if (!this.bankPointsRedeemToggleService.hasEnoughPoints(this.cardLoyaltyInfo)) {
          this.showInsufficientPointsErrorMessage();
          break;
        }

        const singlePlanClaimable = this.bankPointsRedeemToggleService.getSingleClaimablePlan(this.cardLoyaltyInfo);
        if (singlePlanClaimable) {
          this.onRedeemPlanSelected(singlePlanClaimable.points, singlePlanClaimable.monetaryValue);
          break;
        }

        // this code never runs but was inserted for future proofing
        this.openPointsRedeemDialog$().subscribe((response) => {
          if (!response || response.points === 0) return this.onCancelPlan();
          this.onRedeemPlanSelected(response.points, response.euros);
        });
      }
    }
  }

  private setVerifiedViewPlanTexts(): void {
    this.verifiedViewPoints = this.bankPointsRedeemToggleService.getVerifiedViewPointsText(this.cardLoyaltyInfo);
    this.verifiedViewEuros = this.bankPointsRedeemToggleService.getVerifiedViewEurosText(this.cardLoyaltyInfo);
  }

  private onRedeemPlanSelected(points: number, discount: number): void {
    if (this.checkIfOrderIsLessThanDiscount(discount, this.cartPriceWhichCanBeDiscounted)) return;
    this.pointsToRedeem = points;
    this.discount = discount;
    this.checkoutStateService.setBankLoyaltyRedemption({
      cardBoxId: this.cardBoxId,
      pointsToRedeem: points,
      discount: discount
    });
    this.state = 'SELECTED';
    this.changeDetectorRef.detectChanges();
  }

  private onCancelPlan(): void {
    this.checkoutStateService.setBankLoyaltyRedemption(null);
    this.state = 'VERIFIED';
    this.changeDetectorRef.detectChanges();
  }

  private showInsufficientPointsErrorMessage(): void {
    this.dialogService.openInfoDialog({
      title: 'Εξαργύρωση πόντων',
      messages: ['Δεν έχεις αρκετούς Bonus πόντους για εξαργύρωση!']
    });
  }

  private isMaximumDiscountAlreadyReached(): boolean {
    return this.cartPriceWhichCanBeDiscounted === 0 && this.state !== 'SELECTED';
  }

  private showMaximumDiscountAlreadyReachedDialog(): void {
    this.dialogService.openInfoDialog({
      title: 'Εξαργύρωση πόντων',
      messages: ['Το ποσό της παραγγελίας σου έχει ήδη λάβει μέγιστη δυνατή έκπτωση.']
    });
  }

  private initiateVerificationDialogFlows$(): Observable<BankPointsRedeemVerificationDialogResponse> {
    this.bankPointsRedeemToggleService.saveVerificationAlertBeenShown(this.card);
    const config = {
      panelClass: 'box-dialog-fit-content',
      data: {
        ...this.verificationAlertAssets
      }
    };
    return this.dialogService
      .openDialog(BankPointsRedeemVerificationAlertComponent, config)
      .afterClosed()
      .pipe(
        switchMap((response: BankPointsRedeemVerificationAlertResponse) => {
          if (response?.action === 'VERIFY') return this.openVerificationDialog$();
          return of(null); // user clicked later
        })
      );
  }

  private openVerificationDialog$(): Observable<BankPointsRedeemVerificationDialogResponse> {
    return this.dialogService
      .openDialog(BankPointsRedeemVerificationDialogComponent, {
        panelClass: 'box-dialog-fit-content',
        data: {
          card: this.card
        } as BankPointsRedeemVerificationDialogData
      })
      .afterClosed()
      .pipe(
        map((response) => response as BankPointsRedeemVerificationDialogResponse),
        tap((response) => {
          if (!response?.cardBoxId) return;
          this.cardBoxId = response.cardBoxId;
          this.state = 'VERIFIED';
          this.changeDetectorRef.detectChanges();
          this.getLoyaltyState();
        })
      );
  }

  private openPointsRedeemDialog$(): Observable<BankPointsRedeemSlideDialogResponse> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: this.bankPointsRedeemToggleService.generateBankPointsRedeemSlideDialogData(
        this.pointsToRedeem,
        this.cardLoyaltyInfo,
        this.cartPrice,
        this.loyaltyName
      )
    };

    return this.dialogService
      .openDialog(BankPointsRedeemSlideDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(map((response) => response as BankPointsRedeemSlideDialogResponse));
  }

  private checkIfOrderIsLessThanDiscount(discount: number, cartPriceWhichCanBeDiscounted: number): boolean {
    if (discount <= cartPriceWhichCanBeDiscounted) return false;
    const discountInEuro = currencyFormat(discount, { symbolSpace: false });
    this.dialogService.openInfoDialog({
      title: 'Αδυναμία εξαργύρωσης',
      messages: [
        `Η εξαργύρωση δεν μπορεί να πραγματοποιηθεί γιατί η αξία του καλαθιού σου είναι μικρότερη από ${discountInEuro}. Μπορείς να προσθέσεις περισσότερα προϊόντα στο καλάθι για να την αποκτήσεις.`
      ]
    });
    return true;
  }
}
