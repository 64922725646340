<div class="rewards-card-header">
  <div class="rewards-card-header-image" [style.background-image]="'url(' + rewardsCard.image + ')'"></div>

  <div class="rewards-card-header-texts">
    <div class="rewards-card-header-texts-title">{{ rewardsCard.title }}</div>
    <div class="ods-typography-body-s-bold" [innerHTML]="rewardsCard.subHeader"></div>
  </div>

  <div class="rewards-card-header-status ods-typography-body-s-bold" *ngIf="rewardsCard.status !== 'NO_PROGRESS'">
    <ng-container *ngIf="rewardsCard.status === 'IN_PROGRESS'">
      <div *ngIf="rewardsCard.counter" [circular-progress]="rewardsCard.counter"></div>
    </ng-container>
    <ng-container *ngIf="rewardsCard.status === 'COMPLETED'">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <path
          fill-rule="nonzero"
          d="M9 0C4.038 0 0 4.038 0 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9Zm5.03 6.632-5.752 5.706a.885.885 0 0 1-1.24.023L3.992 9.586a.915.915 0 0 1-.067-1.263.892.892 0 0 1 1.263-.045l2.414 2.21 5.142-5.142a.899.899 0 0 1 1.286 0c.361.36.361.925 0 1.286Z"
        />
      </svg>
    </ng-container>
  </div>
</div>
<div
  *ngIf="rewardsCard.description"
  class="rewards-card-description ods-typography-body-s-bold"
  [innerHTML]="rewardsCard.description"
></div>
