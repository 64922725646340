import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { APIError } from '@box-types';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private readonly BOX_API_URL: string = environment.application.API_URL;
  private readonly ORDERS_API_URL: string = environment.application.ORDERS_API_URL;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isBoxApi = request.url.startsWith(this.BOX_API_URL) || request.url.startsWith(this.ORDERS_API_URL);
    if (!isBoxApi) return next.handle(request);
    return next
      .handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => throwError(() => (response.error as { error: APIError }).error))
      );
  }
}
