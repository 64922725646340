import { Shop, Tag } from '@box-types';

export { shopMatchesTagId, shopMatchesTag };

function shopMatchesTagId(shop: Shop, tagId: string): boolean {
  const shopTagIds = shop.tagIds?.map((shopTagId) => shopTagId.tagId);
  if (!shopTagIds?.length) return false;
  return shopTagIds.includes(tagId);
}
function shopMatchesTag(shop: Shop, tag: Tag): boolean {
  return shopMatchesTagId(shop, tag._id);
}
