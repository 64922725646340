import { Component } from '@angular/core';
import { BoxMenuItem } from '@box-types';
import { menuItems } from '@box-configs/menu-items.options';

@Component({
  selector: 'box-navigation-menu',
  templateUrl: './box-navigation-menu.component.html',
  styleUrls: ['./box-navigation-menu.component.scss']
})
export class BoxNavigationMenuComponent {
  public menuItems: BoxMenuItem[] = menuItems;
}
