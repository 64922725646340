import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Shop, MarketCard, MarketCardDetails, MarketCardDialogData, SMLoyaltyConfiguration } from '@box-types';
import { ConfigurationService } from '@box-core/services';
import { generateMarketCard } from '@box/utils';

@Component({
  selector: 'market-card-dialog',
  templateUrl: './market-card-dialog.component.html',
  styleUrls: ['./market-card-dialog.component.scss']
})
export class MarketCardDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  public title: string;
  public shopName: string;
  public cardName: string;
  public marketCard: MarketCard;
  public created: boolean;

  private shop: Shop;
  private cardDetails: MarketCardDetails;
  private integratorName: string;
  private integratorOptions: SMLoyaltyConfiguration;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<MarketCardDialogComponent>,
    private configService: ConfigurationService,
    @Inject(MAT_DIALOG_DATA) private data: MarketCardDialogData
  ) {
    super(renderer);
    this.shop = this.data.shop;
    this.cardDetails = this.data.cardDetails;
    this.created = this.data.created;
  }

  ngOnInit(): void {
    this.setDialogContent();
  }

  public closeDialog(): void {
    this.dialogRef.close({ continue: false, marketCard: this.marketCard });
  }

  public onContinue(): void {
    this.dialogRef.close({ continue: true, marketCard: this.marketCard });
  }

  private getIntegratorOptions(integrator: string): SMLoyaltyConfiguration {
    const boxConfig = this.configService.getConfiguration();
    if (!boxConfig) return undefined;
    const smLoyaltyInfo = boxConfig.smLoyaltyInfo || [];
    return smLoyaltyInfo.find((info) => info.integrator === integrator);
  }

  private setDialogContent(): void {
    this.integratorName = this.shop.integrator.company;
    this.integratorOptions = this.getIntegratorOptions(this.integratorName);
    this.shopName = this.integratorOptions.smName;
    this.cardName = this.integratorOptions.shortCardName;
    this.title = `Η ${this.cardName} σου`;
    this.marketCard = generateMarketCard(this.cardDetails, this.integratorOptions);
  }
}
