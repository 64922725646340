import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Shop } from '@box-types';

@Component({
  selector: 'shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopsComponent implements OnChanges {
  @Input() public title: string;
  @Input() public shops: Shop[] = [];

  @HostBinding('class') public hostClass = 'shops';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) this.title = changes.title.currentValue as string;
    if (changes.shops) this.shops = changes.shops.currentValue as Shop[];
  }

  public trackById(index: number, shop: Shop): string {
    return shop._id;
  }
}
