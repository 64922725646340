import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Address } from '@box-types';
import { getAddressIcon } from '@box/utils';

@Component({
  selector: 'address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressComponent implements OnInit {
  @Input() public address: Address;

  public addressIconPath: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setAddressType();
    this.changeDetectorRef.detectChanges();
  }

  private setAddressType(): void {
    this.addressIconPath = getAddressIcon(this.address);
  }
}
