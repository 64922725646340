<div
  class="shop-menu-items-wrapper"
  scrollSpy
  [spiedTags]="['SECTION']"
  [scrollSpyOffset]="scrollOffset"
  (sectionChange)="onSectionChange($event)"
>
  <section *ngFor="let category of categories" [attr.id]="category._id" class="shop-menu-items-category">
    <ng-container [ngSwitch]="category._id">
      <ng-container *ngIf="orders.length > 0">
        <ng-container *ngSwitchCase="'orders_category'">
          <h4 class="shop-menu-items-category-name">{{ category.name }}</h4>
          <div class="shop-menu-items-category-separator"></div>
          <div class="shop-menu-items-category-items">
            <shop-orders
              [orders]="orders"
              class="shop-menu-page-section"
              [shop]="shop"
              [title]="category.name"
              visibilityObserver
              [triggerOnce]="true"
              [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
              (enteredViewport)="onShopOrdersEnteredViewport(category)"
            >
            </shop-orders>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'reminder_category'">
        <div class="shop-menu-items-category-items">
          <shop-reminders [title]="category.name" class="shop-menu-page-section"></shop-reminders>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'client_offers_category'">
        <ng-container *ngIf="offers?.length > 0">
          <h4 class="shop-menu-items-category-name">{{ category.name }}</h4>
          <div class="shop-menu-items-category-separator"></div>
          <div class="shop-menu-items-category-items">
            <food-offer
              *ngFor="let offer of offers; trackBy: trackById; let i = index"
              class="hoverable"
              [offer]="offer"
              (add)="onOfferAdd($event)"
              (remove)="onOfferRemove($event)"
              (click)="onOfferClick(i, offer)"
            ></food-offer>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container
          *ngIf="
            productsByCategory[category._id].available?.length || productsByCategory[category._id]?.unavailable.length
          "
        >
          <h4 class="shop-menu-items-category-name">{{ category.name }}</h4>
          <div class="shop-menu-items-category-separator"></div>
          <div class="shop-menu-items-category-items">
            <food-product
              *ngFor="let product of productsByCategory[category._id].available; trackBy: trackById; let i = index"
              [product]="product"
              (add)="onProductAdd($event)"
              (remove)="onProductRemove($event)"
              (view)="onProductClick(i, product)"
            ></food-product>
            <div
              *ngIf="productsByCategory[category._id]?.unavailable.length"
              class="shop-menu-items-unavailable-wrapper hoverable"
              (click)="onUnavailableSectionClick(category._id)"
            >
              <span class="shop-menu-items-unavailable-text"
                >Μη διαθέσιμα προϊόντα (<span>{{ productsByCategory[category._id].unavailable.length }}</span
                ><span>)</span>
              </span>
              <mat-icon class="icon" fontSet="material-icons-round">keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </section>
  <section>
    <allergens-info [shopPhone]="shop.phone"></allergens-info>
  </section>
</div>
