import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  AddressesService,
  ConfigurationService,
  DialogService,
  MarketLoyaltyService,
  SentryService,
  UserService
} from '@box-core/services';
import {
  MarketCardCreateOptions,
  MarketCardCreateResponse,
  BoxDialogValidationMode,
  APIError,
  SMLoyaltyConfiguration
} from '@box-types';
import { getSaveCardTermsText, generateImageSrc } from '@box/utils';
import { catchError, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhoneVerificationDialogComponent } from '@box-shared/components';
import { PhoneVerificationDialogData } from '@box-shared/components/phone-verification-dialog/phone-verification-dialog.types';
import { ValidatorFn } from '@angular/forms';
import { emailValidator, phoneValidator } from '@box-shared/validators';

@Injectable()
export class MarketCardCreateDialogService {
  constructor(
    private configService: ConfigurationService,
    private userService: UserService,
    private sentryService: SentryService,
    private dialogService: DialogService,
    private addressesService: AddressesService,
    private marketLoyaltyService: MarketLoyaltyService
  ) {}

  public getShopLogoSrc(loyaltyConfig: SMLoyaltyConfiguration): string {
    return generateImageSrc(loyaltyConfig.superMarketLogoWide);
  }

  public getSMLoyaltyConfig(integrator: string): SMLoyaltyConfiguration {
    const smLoyaltyInfo = this.configService.getConfiguration()?.smLoyaltyInfo ?? [];
    return smLoyaltyInfo.find((info) => info.integrator === integrator);
  }

  public getSaveCardTermsText(loyaltyConfig: SMLoyaltyConfiguration): string {
    return getSaveCardTermsText(loyaltyConfig);
  }

  public openPhoneVerificationDialog(mobilePhone: string): MatDialogRef<PhoneVerificationDialogComponent> {
    const phoneVerificationDialogData: PhoneVerificationDialogData = {
      autoGenerate: true,
      mobilePhone,
      verificationReason: 'sm_phone_verification'
    };
    const config: MatDialogConfig = { panelClass: 'box-dialog-fit-content', data: phoneVerificationDialogData };
    return this.dialogService.openDialog(PhoneVerificationDialogComponent, config);
  }

  public createCard(
    mobileNumber: string,
    email: string,
    supermarket: string,
    shopId: string,
    otp: string,
    otpId: string,
    save: boolean
  ): Observable<MarketCardCreateResponse> {
    const user = this.userService.getUser();
    const address = this.addressesService.getAddress();
    const options: MarketCardCreateOptions = {
      lastName: user.lastName,
      firstName: user.firstName,
      addressName: address.street,
      addressNumber: address.streetNo,
      zip: address.postalCode,
      area: address.region,
      city: address.city,
      mobileNumber,
      email,
      supermarket,
      longitude: address.longitude,
      latitude: address.latitude,
      shopId,
      otp,
      otpId,
      save
    };

    return this.marketLoyaltyService.createCard(options).pipe(
      map((response) => ({
        cardId: response.cardId,
        otpId: response.otpId,
        email: response.email,
        customerToken: response.customerToken,
        points: response.points
      })),
      catchError((error: APIError) => {
        this.dialogService.openErrorDialog(error);
        this.sentryService.captureException(error, {
          domain: 'Supermarket Loyalty',
          domainDetails: 'Market Card Create Endpoint',
          severity: 'error'
        });
        return throwError(() => error);
      })
    );
  }

  public generateDefaultInputValue(validationMode: BoxDialogValidationMode): string {
    const user = this.userService.getUser();
    if (validationMode === 'EMAIL') {
      return user?.email ?? '';
    } else {
      return user?.verifiedMSISDN ?? '';
    }
  }

  public getInputValidator(mode: BoxDialogValidationMode): ValidatorFn {
    if (mode === 'EMAIL') return emailValidator();
    return phoneValidator();
  }
}
