<div class="cookies-sliders">
  <div class="cookies-slider-row">
    <mat-slide-toggle
      disabled
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="mandatoryAccepted"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>Απαραίτητα και Λειτουργικότητας:</strong> Τα cookies αυτά είναι τεχνικά απαραίτητα για την
      πραγματοποίηση της σύνδεσης στον ιστότοπο ή για την παροχή της υπηρεσίας διαδικτύου την οποία έχετε ζητήσει. Τα
      cookies αυτής της κατηγορίας δεν μπορούν να απενεργοποιηθούν. Εάν ρυθμίσετε τον browser σας, ώστε να μπλοκάρει
      τη χρήση αυτών των cookies η ιστοσελίδα ή ορισμένα τμήματα αυτής δεν θα λειτουργούν.
    </div>
  </div>
  <div class="cookies-slider-row">
    <mat-slide-toggle
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="performanceAccepted"
      (change)="onChangeConsent('performance', $event)"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>Επίδοσης:</strong> Bοηθούν στο να κατανοήσουμε πώς αλληλεπιδρούν οι επισκέπτες με τις σελίδες
      συλλέγοντας συγκεντρωτικές πληροφορίες που δεν ταυτοποιούν άμεσα κάποιον επισκέπτη.
    </div>
  </div>
  <div class="cookies-slider-row">
    <mat-slide-toggle
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="adAccepted"
      (change)="onChangeConsent('ad', $event)"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>Στόχευσης/διαφήμισης:</strong> Χρησιμοποιούνται για την παροχή προσωποποιημένου περιεχομένου στον
      επισκέπτη και τη διενέργεια ή τον έλεγχο στοχευμένης διαφημιστικής καμπάνιας.
    </div>
  </div>
  <div class="cookies-slider-row">
    <mat-slide-toggle
      color="primary"
      class="cookies-slider-row-slide"
      [checked]="uxAccepted"
      (change)="onChangeConsent('ux', $event)"
    ></mat-slide-toggle>
    <div class="cookies-slider-content">
      <strong>Βελτιστοποιήση εμπειρίας:</strong> Επιτρέπουν στην ιστοσελίδα να ανακαλεί πληροφορίες που έχει
      συμπληρώσει ο επισκέπτης σε πεδία, προκειμένου να διευκολύνει την πλοήγησή του.
    </div>
  </div>
</div>
<footer class="legal-cookies-footer">
  <button box-button theme="primary" class="legal-cookies-button" (click)="onAcceptAll()">Αποδοχή Cookies</button>
  <button box-button theme="primary" class="legal-cookies-button" (click)="onRejectAll()">Απόρριψη Cookies</button>
  <button box-button theme="primary" class="legal-cookies-button" (click)="onSave()">Αποθήκευση Επιλογών</button>
</footer>
