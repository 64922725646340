import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@box-env/environment';
import { Product, FetchProductOptions, APIError, APIResponse } from '@box-types';
import { normalizeProduct } from '@box/utils';
import { pickBy } from 'lodash-es';
import { map, Observable } from 'rxjs';

@Injectable()
export class ProductsService {
  private readonly BOX_API = environment.application.API_URL;

  constructor(private http: HttpClient) {}

  public fetchProduct(id: string, options: FetchProductOptions): Observable<Product> {
    const fromObject = pickBy(options);
    const params = new HttpParams({ fromObject });
    return this.http.get(`${this.BOX_API}/products/${id}`, { params }).pipe(
      map((response: APIResponse<{ product: Product }>) => {
        const product = response.payload.product;
        if (!product?._id) throw { userMessage: 'Προσπάθησε ξανά σε λίγο.' } as APIError;
        return normalizeProduct(product);
      })
    );
  }
}
