import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

type AddressType = 'home' | 'work' | 'other';
@Component({
  selector: 'address-type',
  templateUrl: './address-type.component.html',
  styleUrls: ['./address-type.component.scss']
})
export class AddressTypeComponent implements OnInit {
  @Input() public value = 'Σπίτι';
  @Output() private typeChange: EventEmitter<string> = new EventEmitter<string>();

  public type: AddressType;
  public customType: string;
  public customTypeFormControl: FormControl;

  ngOnInit(): void {
    this.type = this.getType();
    this.setCustomTypeFormControl();
  }

  public onTypeClick(event: Event, type: AddressType): void {
    this.type = type;
    this.typeChange.emit(this.getValueToEmit());
  }

  public onCustomTypeChange(type: string): void {
    this.typeChange.emit(type);
  }

  private getType(): AddressType {
    if (this.value === 'Σπίτι') return 'home';
    if (this.value === 'Εργασία') return 'work';
    return 'other';
  }

  private getValueToEmit(): string {
    if (this.type === 'home') return 'Σπίτι';
    if (this.type === 'work') return 'Εργασία';
    return this.customTypeFormControl.value || 'Άλλο';
  }

  private setCustomTypeFormControl(): void {
    const value = this.type === 'other' ? this.value : '';
    this.customTypeFormControl = new FormControl(value);
    this.customTypeFormControl.valueChanges.subscribe((value) => this.typeChange.emit(value));
  }
}
