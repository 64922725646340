import { Injectable } from '@angular/core';
import { AnalyticsService, ConfigurationService } from '@box-core/services';
import { GAClickDonationBannerConfig } from '@box-types';
import { generateCheckoutAmountDescription } from '@box/utils';

@Injectable()
export class CheckoutDonationService {
  private readonly DEFAULT_DONATION_AMOUNTS = [75, 100, 200];
  private readonly DEFAULT_DONATION_MINIMUM_AMOUNT = 50;
  private readonly DEFAULT_DONATION_MAXIMUM_AMOUNT = 2000;
  private readonly DEFAULT_DONATION_DESCRIPTION =
    'Επίλεξε το ποσό που επιθυμείς να δωρίσεις απο 0,5€ ως 20€ και θα υπολογιστεί στην τελική τιμή της παραγγελίας.';

  constructor(private configurationService: ConfigurationService, private analyticsService: AnalyticsService) {}

  public getMinimumAmount(): number {
    const configuration = this.configurationService.getConfiguration();
    return configuration?.ekeInfo?.minimumAmount ?? this.DEFAULT_DONATION_MINIMUM_AMOUNT;
  }

  public getMaximumAmount(): number {
    const configuration = this.configurationService.getConfiguration();
    return configuration?.ekeInfo?.maximumAmount ?? this.DEFAULT_DONATION_MAXIMUM_AMOUNT;
  }

  public getTitle(): string {
    const configuration = this.configurationService.getConfiguration();
    return configuration?.ekeInfo?.title;
  }

  public getDescription(): string {
    const configuration = this.configurationService.getConfiguration();
    if (!configuration?.ekeInfo?.descriptionTemplate) return this.DEFAULT_DONATION_DESCRIPTION;
    const csrConfiguration = {
      minimumAmount: this.DEFAULT_DONATION_MINIMUM_AMOUNT,
      maximumAmount: this.DEFAULT_DONATION_MAXIMUM_AMOUNT,
      amounts: this.DEFAULT_DONATION_AMOUNTS,
      ...configuration.ekeInfo
    };
    return generateCheckoutAmountDescription(csrConfiguration);
  }

  public getAmounts(): number[] {
    const configuration = this.configurationService.getConfiguration();
    if (!configuration?.ekeInfo?.amounts?.length) return [0, ...this.DEFAULT_DONATION_AMOUNTS];
    const configurationAmounts = configuration.ekeInfo.amounts.slice(0, 3);
    return [0, ...configurationAmounts];
  }

  public triggerAnalyticsEvent(donation: number, formattedDonation: string): void {
    const donation_option = donation > 0 ? 'on' : 'off';
    const gaConfig = { donation_option, donation_amount: formattedDonation } as GAClickDonationBannerConfig;
    this.analyticsService.addGACustomEvent('click_donation_banner', gaConfig);
  }
}
