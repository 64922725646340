<div class="address-type-wrapper">
  <p class="address-type-title">Αποθήκευση ως</p>
  <ng-container *ngIf="type !== 'other'; else customField">
    <div class="address-type-options">
      <div class="address-type-option" (click)="onTypeClick($event, 'home')" [class.selected]="type === 'home'">
        <mat-icon>home</mat-icon>
        <span class="address-type-option-title">Σπίτι</span>
      </div>
      <div class="address-type-option" (click)="onTypeClick($event, 'work')" [class.selected]="type === 'work'">
        <mat-icon>work</mat-icon>
        <span class="address-type-option-title">Εργασία</span>
      </div>
      <div class="address-type-option" (click)="onTypeClick($event, 'other')">
        <mat-icon>room</mat-icon>
        <span class="address-type-option-title">Άλλο</span>
      </div>
    </div>
  </ng-container>
  <ng-template #customField>
    <div class="address-custom-type-wrapper">
      <mat-icon class="address-custom-type-icon">place</mat-icon>
      <mat-form-field class="address-custom-type-form-field">
        <input matInput name="addressFriendlyName" placeholder="Άλλο" [formControl]="customTypeFormControl" />
      </mat-form-field>
      <button mat-icon-button (click)="onTypeClick($event, 'home')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-template>
</div>
