import { Component, OnInit, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CartService, ConfigurationService, DialogService } from '@box-core/services';
import { currencyFormat, CurrencyFormatOptions } from '@box/utils';
import { Subscription } from 'rxjs';
import { CheckoutOrderPreviewInfoDialogComponent } from '../checkout-order-preview-info-dialog/checkout-order-preview-info-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { CheckoutOrderPreviewInfoDialogData } from '../checkout-order-preview-info-dialog/checkout-order-preview-info-dialog.types';
import { Product, Offer } from '@box-types';
import { CheckoutService } from '@box-checkout/services';

@Component({
  selector: 'checkout-order-preview',
  templateUrl: './checkout-order-preview.component.html',
  styleUrls: ['./checkout-order-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutOrderPreviewComponent implements OnInit, OnDestroy {
  public products: Product[] = [];
  public offers: Offer[] = [];
  public isSuperMarket: boolean;
  public donationOrganizationName: string;
  public formattedItemsPriceWithoutEnvFee: string;
  public formattedTotal: string;
  public formattedEnvFee: string;
  public formattedDeliveryFee: string;
  public formattedServiceFee: string;
  public formattedTip: string;
  public formattedDonation: string;
  public formattedDiscount: string;
  public showPriceBreakDown: boolean;
  public showPriceBreakDownInfo: boolean;

  private cartSubscription: Subscription;
  private checkoutOrderpreviewSubscription: Subscription;

  constructor(
    private configService: ConfigurationService,
    private checkoutService: CheckoutService,
    private cartService: CartService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'checkout-order-preview';

  ngOnInit(): void {
    this.donationOrganizationName = this.getDonationOrganizationName();
    this.setCartSubscription();
    this.setCheckoutOrderPreviewSubscription();
  }

  ngOnDestroy(): void {
    this.cartSubscription?.unsubscribe();
    this.checkoutOrderpreviewSubscription?.unsubscribe();
  }

  public onShowInfo(): void {
    const dialogConfig: MatDialogConfig<CheckoutOrderPreviewInfoDialogData> = {
      panelClass: 'box-dialog-fit-content',
      data: {
        showEnvFeeInfo: Boolean(this.formattedEnvFee),
        showDeliveryFeeInfo: Boolean(this.formattedDeliveryFee),
        showServiceFeeInfo: Boolean(this.formattedServiceFee)
      }
    };
    this.dialogService.openDialog(CheckoutOrderPreviewInfoDialogComponent, dialogConfig);
  }

  private getDonationOrganizationName(): string {
    const config = this.configService.getConfiguration();
    return config?.ekeInfo?.name;
  }

  private setCartSubscription(): void {
    this.cartSubscription = this.cartService.cart$.subscribe((cart) => {
      this.products = cart.products;
      this.offers = cart.offers;
      /* When we navigate to the Order Status from the Checkout, we reset the Cart
      State before the ngOnDestroy executes. The cart.shop property is undefined
      and we are trying to access the cart.shop.isSuperMarket, resolving into a type
      error. THerefore, we want to check for the Shops existence before accessing
      any properties. */
      this.isSuperMarket = cart.shop?.isSuperMarket;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCheckoutOrderPreviewSubscription(): void {
    this.checkoutOrderpreviewSubscription = this.checkoutService.checkoutOrderPreview$.subscribe(
      (checkoutOrderPreview) => {
        const {
          cartStartingPrice,
          cartPriceWithPureDiscounts,
          totalPrice,
          totalEnvFee,
          deliveryFee,
          serviceFee,
          tip,
          donation,
          totalDiscount
        } = checkoutOrderPreview;
        const currencyFormatOptions = { minimumFractionDigits: 2, symbolSpace: false } as CurrencyFormatOptions;
        /** We are using the itemsFinalPrice instead of the beginPrice on food shops,
         * due to a tech design flaw calculating the starting price. This needs to be
         * taken in consideration when we refactor the cart */
        const itemsPrice = this.isSuperMarket ? cartStartingPrice : cartPriceWithPureDiscounts;
        const itemsPriceWithoutFee = itemsPrice - totalEnvFee;
        this.formattedItemsPriceWithoutEnvFee = currencyFormat(itemsPriceWithoutFee, currencyFormatOptions);
        this.formattedTotal = currencyFormat(totalPrice, currencyFormatOptions);
        this.formattedEnvFee = totalEnvFee ? currencyFormat(totalEnvFee, currencyFormatOptions) : null;
        this.formattedDeliveryFee = deliveryFee ? currencyFormat(deliveryFee, currencyFormatOptions) : null;
        this.formattedServiceFee = serviceFee ? currencyFormat(serviceFee, currencyFormatOptions) : null;
        this.formattedTip = tip ? currencyFormat(tip, currencyFormatOptions) : null;
        this.formattedDonation = donation ? currencyFormat(donation, currencyFormatOptions) : null;
        this.formattedDiscount = totalDiscount ? currencyFormat(totalDiscount, currencyFormatOptions) : null;
        this.showPriceBreakDown =
          totalDiscount > 0 || deliveryFee > 0 || serviceFee > 0 || tip > 0 || donation > 0 || totalEnvFee > 0;
        this.showPriceBreakDownInfo = totalEnvFee > 0 || deliveryFee > 0 || serviceFee > 0;
        this.changeDetectorRef.detectChanges();
      }
    );
  }
}
