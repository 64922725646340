import {
  Component,
  OnInit,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { Address, APIError } from '@box-types';
import { AddressCreateDialogResponse } from './address-create-dialog.component.types';
import { PlacesService, MapsService, DialogService, GeolocationService } from '@box-core/services';
import { BoxDialogWrapperComponent } from '../box-dialog-wrapper/box-dialog-wrapper.component'; // todo investigate
import {
  getAddressDescription,
  placeResultToAddress,
  decorateAddressWithDescription,
  isPlaceResultDeliveryCompliant
} from '@box/utils';
import { finalize } from 'rxjs/operators';

@Component({
  templateUrl: './address-create-dialog.component.html',
  styleUrls: ['./address-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressCreateDialogComponent extends BoxDialogWrapperComponent implements OnInit {
  @ViewChild('addressInput') addressInput: ElementRef<HTMLInputElement>;

  public loadingMapsApi: boolean;
  public loadingPlaces: boolean;
  public address: Address;
  public locationInputAddressDescription = '';
  public placePredictions: google.maps.places.AutocompletePrediction[] = [];
  public showEmptyState: boolean;
  public mapsAPILoaded: boolean;

  constructor(
    public renderer: Renderer2,
    private placesService: PlacesService,
    private mapsService: MapsService,
    private dialogService: DialogService,
    private geolocationService: GeolocationService,
    private dialogRef: MatDialogRef<AddressCreateDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.loadingMapsApi = true;
    this.changeDetectorRef.detectChanges();
    this.mapsService.loadAPI().subscribe((mapsAPILoaded) => {
      this.loadingMapsApi = false;
      this.mapsAPILoaded = mapsAPILoaded;
      this.addressInput.nativeElement.focus();
      this.changeDetectorRef.detectChanges();
    });
  }

  public closeDialog(data?: AddressCreateDialogResponse): void {
    this.dialogRef.close(data);
  }

  public clearAddress(): void {
    this.locationInputAddressDescription = '';
    this.address = null;
    this.changeDetectorRef.detectChanges();
  }

  public onAddressSearch(event: InputEvent): void {
    const value = (event.target as HTMLInputElement).value.trim();
    if (value === '') return this.clearPlacePredictions();
    this.loadingPlaces = true;
    this.changeDetectorRef.detectChanges();
    this.placesService
      .getPlacePredictions(value)
      .pipe(
        finalize(() => {
          this.loadingPlaces = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (predictions) => {
          this.placePredictions = predictions;
          this.showEmptyState = predictions.length === 0;
          this.changeDetectorRef.detectChanges();
        },
        error: (error: APIError) => {
          this.dialogService.openErrorDialog(error);
          this.clearPlacePredictions();
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public onAutoCompleteOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value as google.maps.places.AutocompletePrediction;
    if (!value) return;
    this.loadingPlaces = true;
    this.changeDetectorRef.detectChanges();
    this.placesService
      .getPlaceDetails(value.place_id)
      .pipe(
        finalize(() => {
          this.loadingPlaces = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (placeResult) => this.handlePlaceResult(placeResult),
        error: (error: APIError) => this.dialogService.openErrorDialog(error)
      });
  }

  private clearPlacePredictions(): void {
    this.placePredictions = [];
    this.loadingPlaces = false;
    this.changeDetectorRef.detectChanges();
  }

  public onAddressInput(): void {
    this.loadingPlaces = true;
    this.changeDetectorRef.detectChanges();
  }

  public displayWithFunction(): string {
    /* we override the default mat-autocomplete behaviour since we fill the input from 2 different sources */
    return '';
  }

  public geolocateUser(): void {
    this.loadingPlaces = true;
    this.changeDetectorRef.detectChanges();
    this.geolocationService
      .getUserPlace$()
      .pipe(
        finalize(() => {
          this.loadingPlaces = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((result) => this.handlePlaceResult(result));
  }

  private handlePlaceResult(placeResult: google.maps.places.PlaceResult): void {
    if (!placeResult) return;

    if (!isPlaceResultDeliveryCompliant(placeResult)) {
      this.dialogService.openErrorDialog({
        userTitle: 'Αδυναμία εύρεσης διεύθυνσης',
        userMessage:
          'Δυστυχώς η επιλεγμένη διεύθυνση δεν έχει ολοκληρωμένα στοιχεία. Παρακαλούμε, εισήγαγε μία άλλη διεύθυνση, για να προχωρήσεις.'
      });
      return;
    }
    const address = placeResultToAddress(placeResult);
    this.locationInputAddressDescription = getAddressDescription(address);
    this.address = address;
    this.changeDetectorRef.detectChanges();
  }

  public onOrderClick(): void {
    const decoratedAddress = decorateAddressWithDescription(this.address);
    this.closeDialog({ address: decoratedAddress });
  }
}
