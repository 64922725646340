import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { catchError, Observable, of, tap } from 'rxjs';
import { ConfigurationService, SentryService, GlobalPopupsService } from '@box-core/services';
import { APIError, Configuration } from '@box-types';
import { DelayedRetryService } from '@box-core/services/delayed-retry.service';

@Injectable({ providedIn: 'root' })
export class ConfigurationResolver implements Resolve<Configuration> {
  constructor(
    private configService: ConfigurationService,
    private sentryService: SentryService,
    private delayedRetryService: DelayedRetryService,
    private globalPopupsService: GlobalPopupsService
  ) {}

  resolve(): Observable<Configuration> {
    return this.fetchConfiguration().pipe(
      tap((config) => this.configService.setConfiguration(config)),
      tap(() => this.globalPopupsService.initializeGlobalPopups())
    );
  }

  private fetchConfiguration(): Observable<Configuration> {
    return this.configService.fetchConfiguration().pipe(
      this.delayedRetryService.delayedRetry(),
      catchError((error: APIError) => {
        this.sentryService.captureException(error);
        return of({} as Configuration);
      })
    );
  }
}
