import { Environment } from '../types';
import versionStamp from './version.json';

export const environment: Environment = {
  name: 'Production',
  code: 'production',
  production: true,
  server: {
    prerender: {
      enabled: true,
      PRERENDER_TOKEN: 'MkPFLOCj2XkcO7uZwDIZ'
    },
    appRedirection: true,
    areaRedirection: true
  },
  application: {
    VERSION: versionStamp.version,
    RELEASE: versionStamp.release,
    BUILD: versionStamp.build,
    API_URL: 'https://box-client.wavecxm.com/api',
    ORDERS_API_URL: 'https://box-merchant.wavecxm.com/api',
    ROOT_URL: 'https://box.gr',
    SENTRY_DSN: 'https://7dc4d044b1644c9aaa5288b9563b43bc@sentry.io/1827673',
    CONFIGURATION_URL: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/configuration.json',
    FAQS_URL: 'https://w4ve.s3.eu-central-1.amazonaws.com/box/configuration/faqs_v2.json',
    APP_ENGINE_HOST: 'box-web-production.appspot.com',
    GCLOUD_STORAGE_BUCKET: 'box-web-production.appspot.com',
    legalDocuments: {
      STYLES_URL: 'https://storage.googleapis.com/box-web-production.appspot.com/legal-documents/styles.css'
    }
  },
  cosmote: {
    ACCOUNT_URL: 'https://account.box.gr',
    EXTSSO_URL: 'https://extsso.cosmote.gr',
    PAYMENT_URL: 'https://www.cosmote.gr/nbg/begin',
    cosmoteID: {
      CHANNEL_ID: 'foodboxweb',
      CLIENT_ID: 'DSQ',
      DOMAIN: 'DSQDomain',
      SCOPE: 'DSQApp.Info'
    }
  },
  google: {
    MAPS_API_KEY: 'AIzaSyAdZVRhegK8gf2CwktlgiKSi959JsmaEhE',
    STATIC_MAPS_API_KEY: 'AIzaSyAAqnrUwyLPO-J_o6IlyjlAVWNVtyFb1nc',
    MEASUREMENT_ID: 'G-Y6705E4L62'
  }
};
