import { OrderCompletionContactInfo, Address, Order, DeliveryMethod } from '@box-types';
import pickBy from 'lodash-es/pickBy';
import { addressToString, generateGoogleMapsDirectionsUrl, getAddressDescription, getAddressType } from '../address';
import { isOrderDaas, isOrderDaasBeforeShipping } from './order-daas.utils';

export {
  BOX_SUPPORT_CONTACT_PHONE,
  getOrderCompletionAddressIcon,
  getOrderCompletionAddressContactInfo,
  getOrderCompletionPhoneContactInfo,
  getDeclinedOrderContactText,
  getDeclinedOrderContactPhone
};

const BOX_SUPPORT_CONTACT_PHONE = '+302106241990';

function getOrderCompletionAddressIcon(address: Address, deliveryMethod: DeliveryMethod = 'delivery'): string {
  const type = deliveryMethod === 'delivery' ? getAddressType(address) : 'takeAway';
  return `/assets/images/order-acceptance/contact-info-address_${type}.svg`;
}

function getOrderCompletionAddressContactInfo(order: Order): Partial<OrderCompletionContactInfo> {
  const deliveryMethod = order.orderShippingType;
  const address = deliveryMethod === 'delivery' ? order.address : order.shop.address;
  const icon = getOrderCompletionAddressIcon(address, deliveryMethod);
  const title = deliveryMethod === 'delivery' ? `Διανομή σε ${address.type}` : 'Παραλαβή από το κατάστημα';
  const subtitle = deliveryMethod === 'delivery' ? addressToString(address) : getAddressDescription(address);
  const anchorHref = deliveryMethod === 'takeAway' ? generateGoogleMapsDirectionsUrl(address) : undefined;
  const anchorText = deliveryMethod === 'takeAway' ? 'Οδηγίες' : undefined;
  return pickBy<OrderCompletionContactInfo>({ icon, title, subtitle, anchorHref, anchorText });
}

function getOrderCompletionPhoneContactInfo(order: Order): Partial<OrderCompletionContactInfo> {
  const shopPhone = order.shop.phone;
  const icon = `/assets/images/order-acceptance/contact-info-phone.svg`;
  const anchorText = 'Κλήση';

  let title: string;
  let subtitle: string;
  let anchorHref: string;

  if (isOrderDaas(order) && !isOrderDaasBeforeShipping(order)) {
    title = 'Επικοινώνησε μαζί μας';
    subtitle = BOX_SUPPORT_CONTACT_PHONE;
    anchorHref = `tel:+30${BOX_SUPPORT_CONTACT_PHONE}`;
  } else {
    title = 'Τηλέφωνο καταστήματος';
    subtitle = shopPhone;
    anchorHref = `tel:${shopPhone}`;
  }

  return { icon, title, subtitle, anchorHref, anchorText };
}

function getDeclinedOrderContactText(order: Order): string {
  const shopResponseStatus = order.shopResponse.status;
  if (shopResponseStatus === 'DECLINED_AUTOMATICALLY') return `Επικοινώνησε μαζί μας`;
  if (shopResponseStatus === 'DECLINED_MANUALLY') return `Επικοινώνησε με το κατάστημα`;
}

function getDeclinedOrderContactPhone(order: Order): string {
  const shopResponseStatus = order.shopResponse.status;
  if (shopResponseStatus === 'DECLINED_AUTOMATICALLY') return `+30${BOX_SUPPORT_CONTACT_PHONE}`;
  if (shopResponseStatus === 'DECLINED_MANUALLY') return `${order.shop.phone}`;
}
