<div class="box-dialog-wrapper">
  <box-dialog-header
    title="Υπολογισμός κόστους παραγγελίας"
    class="box-dialog-header"
    (closeDialog)="closeDialog()"
  ></box-dialog-header>
  <main class="box-dialog-content">
    <section class="checkout-order-preview-info-dialog-section">
      <div *ngIf="showEnvFeeInfo" class="checkout-order-preview-info-dialog-block ods-typography-body-s-regular">
        <div class="checkout-order-preview-info-dialog-block-title">Περιβαλλοντικό τέλος</div>
        <div class="checkout-order-preview-info-dialog-block-description">{{ envFeeText }}</div>
      </div>
      <div *ngIf="showDeliveryFeeInfo" class="checkout-order-preview-info-dialog-block ods-typography-body-s-regular">
        <div class="checkout-order-preview-info-dialog-block-title">Αξία διανομής</div>
        <div class="checkout-order-preview-info-dialog-block-description">{{ deliveryFeeText }}</div>
      </div>
      <div *ngIf="showServiceFeeInfo" class="checkout-order-preview-info-dialog-block ods-typography-body-s-regular">
        <div class="checkout-order-preview-info-dialog-block-title">Χρέωση υπηρεσίας</div>
        <div class="checkout-order-preview-info-dialog-block-description">{{ serviceFeeText }}</div>
      </div>
    </section>
  </main>
</div>
