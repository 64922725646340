import { Injectable } from '@angular/core';
import { ConfigurationService } from '@box-core/services';
import { MarketCardBanner, Order, MarketCardPointsBanner, SMLoyaltyConfiguration } from '@box-types';
import { generateImageSrc } from '@box/utils';

@Injectable()
export class CheckoutOrderMarketCardPointsService {
  constructor(private configService: ConfigurationService) {}

  public getMarketLoyaltyInfo(order: Order): SMLoyaltyConfiguration {
    const integrator = order.shop.integrator?.company;
    const config = this.configService.getConfiguration();
    if (!config || !config.smLoyaltyInfo) return undefined;
    return config.smLoyaltyInfo.find((i) => i.integrator === integrator);
  }

  public marketCardCreated(): boolean {
    return Boolean(window.sessionStorage.getItem('Box:MarketCardCreated'));
  }

  public clearMarketCardCreated(): void {
    window.sessionStorage.removeItem('Box:MarketCardCreated');
  }

  public getMarketCardBanner(config: SMLoyaltyConfiguration): MarketCardBanner {
    const image = generateImageSrc(config.smallCardPlaceholder);
    const cardName = config.shortCardName;
    const superMarket = config.smName;
    const description = `Δημιουργήθηκε η νέα ${config.shortCardName} σου. Μπορείς να τη δεις`;
    return { image, cardName, superMarket, description };
  }

  public getMarketCardPointsBanner(order: Order, config: SMLoyaltyConfiguration): MarketCardPointsBanner {
    const image = generateImageSrc(config.orderAcceptanceLogo);
    const cardName = config.shortCardName;
    const superMarket = config.smName;
    const points = order.integratorLoyalty?.pointsFromOrder;
    return { image, cardName, superMarket, points };
  }
}
