import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { PrivacyConsentService } from './privacy-consent.service';
import { environment } from '@box-env/environment';

declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private readonly MEASUREMENT_ID = environment.google.MEASUREMENT_ID;
  constructor(private platform: Platform, private privacyConsentService: PrivacyConsentService) {}

  public addGAUserIdEvent(id: string): void {
    if (!this.permissionGranted('performance')) return;
    gtag('config', this.MEASUREMENT_ID, {
      user_id: id
    });
  }

  public addGACustomEvent<T>(eventName: string, config: T): void {
    if (!this.permissionGranted('performance')) return;
    gtag('event', eventName, config);
  }

  public addGAEcommerceEvent<T>(eventName: string, config: T): void {
    if (!this.permissionGranted('performance')) return;
    gtag('event', eventName, config);
  }

  public addMPCustomEvent<T>(eventName: string, config: T): void {
    if (!this.permissionGranted('advertisement')) return;
    window.fbq('trackCustom', eventName, config);
  }

  public addMPEvent<T>(eventName: string, config?: T): void {
    if (!this.permissionGranted('advertisement')) return;
    window.fbq('track', eventName, config);
  }

  private permissionGranted(type: string): boolean {
    if (type === 'performance') return this.performancePermissionGranted();
    if (type === 'advertisement') return this.advertisementPermissionGranted();
  }

  private performancePermissionGranted(): boolean {
    const performanceConsent: boolean = this.privacyConsentService.getPrivacyConsent()?.performance;
    return this.platform.isBrowser && performanceConsent;
  }

  private advertisementPermissionGranted(): boolean {
    const adConsent: boolean = this.privacyConsentService.getPrivacyConsent()?.ad;
    return this.platform.isBrowser && adConsent;
  }
}
