<header class="header">
  <section class="page-section header-section">
    <div class="container header-container">
      <div class="header-container-left">
        <a routerLink="{{ isGuest ? '' : '/home' }}" class="header-logo-container">
          <div class="header-logo-container-image"></div>
        </a>
        <box-header-delivery-details
          *ngIf="showDeliveryDetails"
          [address]="address"
          [timeslot]="timeslot"
          (timeslotChange)="onTimeslotChange()"
          (addressChange)="onAddressChange()"
        ></box-header-delivery-details>
      </div>
      <div class="header-container-right">
        <rewards-balance-badge *ngIf="showBalanceBadge"></rewards-balance-badge>
        <box-navigation-menu *ngIf="authenticated" class="box-navigation-menu"></box-navigation-menu>
        <box-cosmote-id-button class="box-cosmote-id-button"></box-cosmote-id-button>
      </div>
    </div>
  </section>
</header>
