import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from '@box-core/services';
import { User, Reward } from '@box-types';
import { finalize } from 'rxjs/operators';
import { RewardsPromoBannerService } from './rewards-promo-banner.service';

const FIRST_ORDER_CLIENT_REWARDS_TEXT = 'Επιπλέον, με την ολοκλήρωση της κάθε παραγγελίας σου μπορείς να πάρεις:';
const NEXT_ORDERS_CLIENT_REWARDS_TEXT = 'Με την ολοκλήρωση της κάθε παραγγελίας σου μπορείς να πάρεις:';

@Component({
  selector: 'rewards-promo-banner',
  templateUrl: './rewards-promo-banner.component.html',
  styleUrls: ['./rewards-promo-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RewardsPromoBannerService]
})
export class RewardsPromoBannerComponent implements OnInit {
  public studentFreeReward: boolean;
  public serverTriggeredRewards: Reward[] = [];
  public clientTriggeredRewards: Reward[] = [];
  public userSegment: string;
  public showServerTriggeredRewards: boolean;
  public showClientTriggeredRewards: boolean;
  public serverTriggeredRewardsText: string;
  public clientTriggeredRewardsText: string;

  private user: User;
  private mbRewards: Reward[];

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private rewardsPromoBannerService: RewardsPromoBannerService
  ) {}

  @HostBinding('class') public hostClass = 'rewards-promo-banner';

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.userSegment = this.user.mainCosmoteAssetSegment;
    this.rewardsPromoBannerService
      .fetchRewardsGB(this.userSegment)
      .pipe(finalize(() => this.changeDetectorRef.detectChanges()))
      .subscribe((rewards) => {
        this.mbRewards = rewards;
        this.studentFreeReward = !this.user.hasOrdered;
        this.serverTriggeredRewards = this.mbRewards.filter((reward) => !reward.triggeredByClient);
        this.clientTriggeredRewards = this.mbRewards.filter((reward) => reward.triggeredByClient);
        this.showServerTriggeredRewards = !this.user.hasOrdered && this.serverTriggeredRewards.length > 0;
        this.showClientTriggeredRewards = this.clientTriggeredRewards.length > 0;
        this.serverTriggeredRewardsText = 'Με την ολοκλήρωση της πρώτης σου παραγγελίας θα λάβεις:';
        this.clientTriggeredRewardsText = this.showServerTriggeredRewards
          ? FIRST_ORDER_CLIENT_REWARDS_TEXT
          : NEXT_ORDERS_CLIENT_REWARDS_TEXT;
      });
  }
}
