<div class="order-completion-ratings-container" *ngIf="visibleOrders?.length">
  <h4 class="order-completion-ratings-title ods-typography-subtitle-s">Βοήθησέ μας να γίνουμε καλύτεροι!</h4>
  <p class="order-completion-ratings-instructions ods-typography-body-s-regular">
    Μέχρι να φτάσει το γεύμα σου, βοήθησέ μας να αναδείξουμε τα καλύτερα καταστήματα!
  </p>
  <order-completion-ratings-order
    *ngFor="let order of visibleOrders; let i = index"
    [style.--animation-order]="i"
    [order]="order"
    (rateOrder)="onRateOrder(order, $event)"
  ></order-completion-ratings-order>

  <div *ngIf="showExpandButton" class="order-completion-ratings-expand-button-container">
    <button
      box-button
      theme="secondary"
      borders="none"
      class="order-completion-ratings-expand-button"
      (click)="onMoreToggle()"
    >
      {{ expandButtonText }}
    </button>
  </div>
</div>
