import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  SimpleChange
} from '@angular/core';
import { CheckoutSuggestionBanner } from '@box-types';

@Component({
  selector: 'checkout-suggestion-banner',
  templateUrl: './checkout-suggestion-banner.component.html',
  styleUrls: ['./checkout-suggestion-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutSuggestionBannerComponent implements OnChanges {
  @Input() public active: boolean;
  @Input() public checkoutSuggestionBanner: CheckoutSuggestionBanner;
  @Output() private bannerSelect = new EventEmitter<CheckoutSuggestionBanner>();

  public animationState: 'active' | 'inactive';
  public animationRan: boolean;
  public tagSign: string;
  public tagContent: string;

  @HostBinding('class') public hostClass = 'checkout-suggestion-banner';
  @HostBinding('class.animation-state-active') public activeAnimationState = false;
  @HostBinding('class.animation-state-inactive') public inactiveAnimationState = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active) {
      this.active = changes.active.currentValue as boolean;
      // We wanna show the animation only for the first time that the banner will be active
      if (this.shouldRunAnimation(changes.active)) {
        this.activeAnimationState = this.active;
        this.inactiveAnimationState = !this.active;
        this.animationRan = true;
      }
    }
    if (changes.checkoutSuggestionBanner) {
      this.checkoutSuggestionBanner = changes.checkoutSuggestionBanner.currentValue as CheckoutSuggestionBanner;
      this.tagSign = this.generateTagSign();
      this.tagContent = this.generageTagContent();
    }
  }

  public onBannerSelect(event: Event): void {
    event.stopPropagation();
    this.bannerSelect.emit(this.checkoutSuggestionBanner);
  }

  private shouldRunAnimation(change: SimpleChange): boolean {
    if (this.animationRan) return false;
    const previousValue = change.previousValue as boolean;
    const currentValue = change.currentValue as boolean;
    return currentValue === true && previousValue === false;
  }

  private generateTagSign(): string {
    const { multiplier } = this.checkoutSuggestionBanner;
    if (multiplier) return;
    return '+';
  }

  private generageTagContent(): string {
    const { points, multiplier } = this.checkoutSuggestionBanner;
    if (multiplier) return `x${multiplier}`;
    return new Intl.NumberFormat('el-GR', { style: 'decimal' }).format(points);
  }
}
