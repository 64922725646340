import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';

import { ClipboardModule } from 'ngx-clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LayoutModule } from '@angular/cdk/layout';

import * as Directives from './directives';
import * as Components from './components';
import * as Pipes from './pipes';

@NgModule({
  declarations: [
    Components.AddressComponent,
    Components.AddressCreateDialogComponent,
    Components.AddressEditDialogComponent,
    Components.AddressSelectDialogComponent,
    Components.AddressTypeComponent,
    Components.CookiesTogglesComponent,
    Components.BoxConfirmDialogComponent,
    Components.BoxDialogHeaderComponent,
    Components.BoxDialogWrapperComponent,
    Components.BoxEmptyStateComponent,
    Components.BoxInfoDialogComponent,
    Components.BoxLoaderComponent,
    Components.PointsTagComponent,
    Components.BoxQuantityComponent,
    Components.RatingComponent,
    Components.TimeslotDialogComponent,
    Components.TimeslotDialogComponent,
    Components.UnsupportedAreaComponent,
    Components.PromoTagComponent,
    Components.BOXSliderComponent,
    Components.ImageTileComponent,
    Components.ProductMYONavComponent,
    Components.BoxBottomSheetHeaderComponent,
    Components.PhoneVerificationDialogComponent,
    Components.PromoBadgeComponent,
    Components.CampaignCountdownComponent,
    Components.SearchInputComponent,
    Components.BoxTermsDialogComponent,
    Components.BoxDigitalCardComponent,
    Components.BOXDownloadComponent,
    Components.DownloadHeaderComponent,
    Components.BOXFooterComponent,
    Components.BoxMobileNavFooterComponent,
    Components.BOXBarcodeComponent,
    Components.ComponentLoaderComponent,
    Components.BoxAppLinksComponent,
    Components.VerificationResendCodeComponent,
    Components.ProfileDeletionDialogComponent,
    Components.ProductDescriptionBadgesComponent,
    Components.BOXChipComponent,
    Components.BOXChipsComponent,
    Components.BoxNavigationComponent,
    Components.DeliveryMethodComponent,
    Components.AllergensInfoComponent,
    Components.CollapsibleTileComponent,
    Components.CircularProgressDirective,
    Components.SingleBannerComponent,
    Components.ProgressiveRenderingTriggerComponent,
    Components.ProgressBarComponent,
    Components.InfoNotificationWrapperComponent,
    Components.InformationalMessageComponent,
    Components.SvgIconComponent,
    Components.BenefitsBannerComponent,
    Components.BenefitsBannerWrapperComponent,
    Directives.BadgeDirective,
    Directives.BodyScrollDirective,
    Directives.VisibilityObserverDirective,
    Directives.BoxButtonDirective,
    Directives.BOXNavDirective,
    Directives.BOXNavItemDirective,
    Directives.ClickPropagationDirective,
    Directives.DebounceInputDirective,
    Directives.ScrollSpyDirective,
    Directives.ScrollSpyParentDirective,
    Directives.StickyObserverDirective,
    Directives.StickyObserverDirective,
    Directives.SectionSliceDirective,
    Directives.MatInputDateFormatDirective,
    Directives.MatInputPinFormatDirective,
    Directives.SwiperDirective,
    Pipes.SafeHtmlPipe,
    Pipes.SafeURLPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    ClipboardModule,
    ScrollingModule,
    LayoutModule
  ],
  exports: [
    Components.AddressComponent,
    Components.AllergensInfoComponent,
    Components.AddressEditDialogComponent,
    Components.AddressCreateDialogComponent,
    Components.AddressSelectDialogComponent,
    Components.CookiesTogglesComponent,
    Components.BoxConfirmDialogComponent,
    Components.BoxDialogHeaderComponent,
    Components.BoxDialogWrapperComponent,
    Components.BoxEmptyStateComponent,
    Components.BoxInfoDialogComponent,
    Components.BoxLoaderComponent,
    Components.PointsTagComponent,
    Components.BoxQuantityComponent,
    Components.RatingComponent,
    Components.TimeslotDialogComponent,
    Components.TimeslotDialogComponent,
    Components.UnsupportedAreaComponent,
    Components.AddressTypeComponent,
    Components.PromoTagComponent,
    Components.BOXSliderComponent,
    Components.ProductMYONavComponent,
    Components.BoxBottomSheetHeaderComponent,
    Components.PhoneVerificationDialogComponent,
    Components.PromoBadgeComponent,
    Components.CampaignCountdownComponent,
    Components.SearchInputComponent,
    Components.BoxTermsDialogComponent,
    Components.BoxDigitalCardComponent,
    Components.BOXDownloadComponent,
    Components.DownloadHeaderComponent,
    Components.BOXFooterComponent,
    Components.ImageTileComponent,
    Components.BoxMobileNavFooterComponent,
    Components.BOXBarcodeComponent,
    Components.ComponentLoaderComponent,
    Components.BoxAppLinksComponent,
    Components.ProfileDeletionDialogComponent,
    Components.ProductDescriptionBadgesComponent,
    Components.BOXChipComponent,
    Components.BOXChipsComponent,
    Components.BoxNavigationComponent,
    Components.DeliveryMethodComponent,
    Components.CollapsibleTileComponent,
    Components.CircularProgressDirective,
    Components.SingleBannerComponent,
    Components.ProgressiveRenderingTriggerComponent,
    Components.ProgressBarComponent,
    Components.InfoNotificationWrapperComponent,
    Components.InformationalMessageComponent,
    Components.SvgIconComponent,
    Components.BenefitsBannerComponent,
    Directives.BadgeDirective,
    Directives.BodyScrollDirective,
    Directives.VisibilityObserverDirective,
    Directives.BoxButtonDirective,
    Directives.ClickPropagationDirective,
    Directives.DebounceInputDirective,
    Directives.ScrollSpyDirective,
    Directives.ScrollSpyParentDirective,
    Directives.StickyObserverDirective,
    Directives.StickyObserverDirective,
    Directives.BOXNavDirective,
    Directives.BOXNavItemDirective,
    Directives.SectionSliceDirective,
    Directives.MatInputDateFormatDirective,
    Directives.MatInputPinFormatDirective,
    Directives.SwiperDirective,
    Pipes.SafeURLPipe,
    Pipes.SafeHtmlPipe,
    FormsModule,
    GoogleMapsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    ClipboardModule,
    ScrollingModule,
    LayoutModule
  ]
})
export class SharedModule {}
