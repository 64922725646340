import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { OfferInstance, Offer } from '@box-types';
import {
  isElementScrollable,
  getOfferInstanceProductsTotalQuantity,
  isOfferWizardComplete,
  getOfferInstancePrice
} from '@box/utils';
import { OfferWizardGroupChangeEvent } from '../offer-wizard-groups/offer-wizard-groups.types';
import { OfferWizardService } from './offer-wizard.service';
import { OfferWizardOptions } from './offer-wizard.types';
import { BodyScrollEvent } from '@box-shared/directives/body-scroll-event.types';

@Component({
  selector: 'offer-wizard',
  templateUrl: './offer-wizard.component.html',
  styleUrls: ['./offer-wizard.component.scss'],
  providers: [OfferWizardService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferWizardComponent implements OnChanges, AfterViewInit {
  @ViewChild('wrapper') private wrapperElement: ElementRef<HTMLDivElement>;

  @Input() private options: OfferWizardOptions;

  @Output() private productChange = new EventEmitter<OfferWizardGroupChangeEvent>();
  @Output() private productMYO = new EventEmitter<OfferWizardGroupChangeEvent>();
  @Output() private offerInstanceChange = new EventEmitter<OfferInstance>();
  @Output() private wizardClose = new EventEmitter();
  @Output() private wizardSubmit = new EventEmitter<OfferWizardOptions>();

  public loading: boolean;
  public subHeaderStuck: boolean;
  public bodyScrollable: boolean;
  public bodyScrolled: boolean;
  public bodyScrolledToBottom: boolean;
  public heroImageSrc: string;
  public images: string[];
  public editMode: boolean;
  public offer: Offer;
  public offerInstance: OfferInstance;
  public offerInstanceToEdit: OfferInstance;
  public buttonText: string;
  public isOfferFixedPrice: boolean;
  public offerWizardComplete: boolean;
  public totalProductQuantity: number;

  constructor(private offerWizardService: OfferWizardService) {}

  @HostBinding('class') public hostClass = 'offer-wizard';

  ngOnChanges(changes: SimpleChanges): void {
    this.options = changes.options.currentValue as OfferWizardOptions;
    this.offer = this.options.offer;
    this.offerInstance = this.options.offerInstance;
    this.editMode = this.options.editMode;
    this.offerInstanceToEdit = this.options.offerInstanceToEdit;
    this.buttonText = this.editMode ? 'Αποθήκευση' : 'Προσθήκη';
    this.images = this.offerWizardService.getOfferImages(this.offer);
    this.heroImageSrc = this.offerWizardService.getOfferHeroImageSrc(this.offer);
    this.isOfferFixedPrice = this.offerInstance.offerType === 'fixedPrice';
    this.offerWizardComplete = isOfferWizardComplete(this.offerInstance);
    this.totalProductQuantity = getOfferInstanceProductsTotalQuantity(this.offerInstance);
  }

  ngAfterViewInit(): void {
    this.bodyScrollable = isElementScrollable(this.wrapperElement.nativeElement);
  }

  public onBodyScroll(event: BodyScrollEvent): void {
    this.bodyScrolled = event.scrolled;
    this.bodyScrolledToBottom = event.scrolledToBottom;
  }

  public onSubHeaderStickyChanges(isStuck: boolean): void {
    this.subHeaderStuck = isStuck;
  }

  public onProductChange(event: OfferWizardGroupChangeEvent): void {
    this.offerWizardComplete = isOfferWizardComplete(this.offerInstance);
    this.totalProductQuantity = getOfferInstanceProductsTotalQuantity(this.offerInstance);
    this.offerInstance = { ...this.offerInstance, price: getOfferInstancePrice(this.offerInstance) };
    this.productChange.emit(event);
  }

  public onProductMYO(event: OfferWizardGroupChangeEvent): void {
    this.productMYO.emit(event);
  }

  public onAfterGroupCollapse(): void {
    this.bodyScrollable = isElementScrollable(this.wrapperElement.nativeElement);
  }

  public onAfterGroupExpand(): void {
    this.bodyScrollable = isElementScrollable(this.wrapperElement.nativeElement);
  }

  public onClose(): void {
    this.wizardClose.emit();
  }

  public onSubmit(): void {
    this.wizardSubmit.emit({
      offer: this.offer,
      offerInstance: this.offerInstance,
      editMode: this.editMode,
      offerInstanceToEdit: this.offerInstanceToEdit
    });
  }

  public onOfferAdd(): void {
    this.offerInstance = { ...this.offerInstance, quantity: this.offerInstance.quantity + 1 };
    this.totalProductQuantity = getOfferInstanceProductsTotalQuantity(this.offerInstance);
    this.offerInstanceChange.emit(this.offerInstance);
  }

  public onOfferRemove(): void {
    if (this.offerInstance.quantity <= 1) return;
    this.offerInstance = { ...this.offerInstance, quantity: this.offerInstance.quantity - 1 };
    this.totalProductQuantity = getOfferInstanceProductsTotalQuantity(this.offerInstance);
    this.offerInstanceChange.emit(this.offerInstance);
  }
}
