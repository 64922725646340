import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BankCardLoyaltyUpdateEvent } from '@box-types';

/**
 * TODO
 * Move everything related to state in checkout in this service to simplify dependencies
 * points coupons payments delivery method loyalty methods
 */
@Injectable()
export class CheckoutStateService {
  private readonly pointsDiscountSource = new BehaviorSubject<number>(0);
  public readonly pointsDiscount$ = this.pointsDiscountSource.asObservable();

  public readonly bankLoyaltyRedemptionSource = new BehaviorSubject<BankCardLoyaltyUpdateEvent>(null);
  public readonly bankLoyaltyRedemption$ = this.bankLoyaltyRedemptionSource.asObservable();

  public getPointsDiscount(): number {
    return this.pointsDiscountSource.getValue();
  }

  public setPointsDiscount(pointsDiscount: number): void {
    this.pointsDiscountSource.next(pointsDiscount);
  }

  public getBankLoyaltyRedemption(): BankCardLoyaltyUpdateEvent {
    return this.bankLoyaltyRedemptionSource.getValue();
  }

  public setBankLoyaltyRedemption(bankLoyalty: BankCardLoyaltyUpdateEvent): void {
    this.bankLoyaltyRedemptionSource.next(bankLoyalty);
  }
}
