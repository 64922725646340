<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="'Οι διευθύνσεις μου'" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="addresses-wrapper">
      <ng-container *ngIf="addresses?.length > 0; else emptyState">
        <div class="addresses-container">
          <mat-radio-group>
            <mat-radio-button
              (click)="onAddressSelect(address)"
              color="primary"
              class="box-radio-button addresses-radio-button hoverable"
              *ngFor="let address of addresses"
              [value]="address"
              [checked]="areAddressesEqual(address, currentAddress)"
            >
              <address [address]="address"></address>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>
      <ng-template #emptyState>
        <div class="addresses-empty-state">
          <figure class="image contain-image empty-state-image">
            <img src="/assets/images/empty-state/scooter-empty-state.svg" />
          </figure>
        </div>
        <h4 class="empty-state-title">Που να στο φέρουμε;</h4>
        <div class="addresses-instructions">Βάλε τη διεύθυνσή σου και ξεκίνα να boxάρεις!</div>
      </ng-template>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer">
    <div class="footer-btn-wrapper">
      <button box-button theme="primary" type="button" (click)="addAddress()">Πρόσθεσε νέα διεύθυνση</button>
    </div>
  </footer>
</div>
