import { Injectable } from '@angular/core';
import {
  WhatsNewService,
  PromoBannersService,
  OnBoardingService,
  GDPRService,
  OrdersService,
  UserService,
  NotificationsService,
  GlobalPopupsService,
  ConfigurationService
} from '@box-core/services';
import { HomePageService } from '@box-core/pages/home/home-page.service';
import { Observable, switchMap, of, first, Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { RateAppNotificationWrapperComponent } from '@box-rating-widget/components';
import { isPrerenderBrowser, saveRewardsPointsNotificationTimestamp } from '@box/utils';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { BenefitsBannerWrapperComponent } from '@box-shared/components';

const DEFAULT_POPUPS_LIMIT = 3;

@Injectable()
export class HomePagePopupsService {
  private afterOpenedSubscription: Subscription;
  private notificationsSubscription: Subscription;
  private remotePopupsLimit: number;
  private popupsCount = 0;

  constructor(
    private whatsNewService: WhatsNewService,
    private promoBannersService: PromoBannersService,
    private onBoardingService: OnBoardingService,
    private ordersService: OrdersService,
    private gdprService: GDPRService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private homePageService: HomePageService,
    private globalPopupsService: GlobalPopupsService,
    private platform: Platform,
    private dialogRef: MatDialog,
    private configurationService: ConfigurationService
  ) {}

  public initializePopups(): void {
    if (!this.platform.isBrowser) return;
    const isPrerender = isPrerenderBrowser(window);
    if (isPrerender) return;
    if (this.globalPopupsService.getHomePopupsServiceState() === 'completed') return;

    this.remotePopupsLimit = this.configurationService.get('homePopupsLimit', DEFAULT_POPUPS_LIMIT);
    const globalPopupsComplete$ = this.globalPopupsService.globalPopupsServiceState$.pipe(
      first((state) => state === 'completed')
    );

    globalPopupsComplete$
      .pipe(
        switchMap(() => this.limitlessGroup$()),
        switchMap(() => this.limitedGroup$())
      )
      .subscribe(() => this.globalPopupsService.setHomePopupsServiceState('completed'));
  }

  private limitlessGroup$(): Observable<null> {
    return of(0).pipe(
      switchMap(() => this.whatsNewService.initWhatsNewSequence$()),
      switchMap(() => this.promoBannersService.openPromoBannerDetailsDialogCheck$())
    );
  }

  private limitedGroup$(): Observable<null> {
    return of(0).pipe(
      tap(() => {
        this.setAfterOpenedSubscription();
        this.setNotificationsSubscription();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.onBoardingService.initiateOnBoarding$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.homePageService.checkGBPromoDialog$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.gdprService.checkGDPRConsent$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.homePageService.showCollectionCampaignsDialog$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.initiateAppRateSequence$();
      }),
      switchMap(() => {
        if (this.isLimitReached()) return of(null);
        return this.initiatesRewardsPointsNotificationSequence$();
      }),
      tap(() => {
        this.afterOpenedSubscription?.unsubscribe();
        this.notificationsSubscription?.unsubscribe();
      })
    );
  }

  private setAfterOpenedSubscription(): void {
    this.afterOpenedSubscription = this.dialogRef.afterOpened.asObservable().subscribe(() => ++this.popupsCount);
  }

  private setNotificationsSubscription(): void {
    this.notificationsSubscription = this.notificationsService.afterOpened
      .asObservable()
      .subscribe((value) => ++this.popupsCount);
  }

  private isLimitReached(): boolean {
    return this.popupsCount >= this.remotePopupsLimit;
  }

  private initiateAppRateSequence$(): Observable<null> {
    if (this.userService.isGuest) return of(null);
    const user = this.userService.getUser();
    if (!user.hasOrdered) return of(null);
    const hasRated = Boolean(user?.appRating?.web);
    if (hasRated) return of(null);
    const lastOrder = this.ordersService.getOrderHistory()[0];
    if (!lastOrder?.createdAt) return of(null);
    const currentDate = dayjs();
    const lastOrderDate = dayjs(lastOrder.createdAt);
    if (currentDate.diff(lastOrderDate, 'hours') <= 2) return of(null);
    const notificationRef = this.notificationsService.addNotification(RateAppNotificationWrapperComponent);
    return notificationRef.afterDismissed.pipe(switchMap(() => of(null)));
  }

  private initiatesRewardsPointsNotificationSequence$(): Observable<null> {
    const showNotification = this.homePageService.shouldShowRewardsPointsNotification();
    if (!showNotification) return of(null);

    const notificationData = this.homePageService.getRewardsPointsNotificationData();
    if (!notificationData) return of(null);

    const notificationRef = this.notificationsService.addNotification(BenefitsBannerWrapperComponent, {
      data: { currencyText: notificationData, infoText: 'Την προηγούμενη εβδομάδα κέρδισες' }
    });

    return notificationRef.afterDismissed.pipe(
      tap(() => saveRewardsPointsNotificationTimestamp()),
      switchMap(() => of(null))
    );
  }
}
