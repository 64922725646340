<component-loader *ngIf="loading"></component-loader>
<header class="box-dialog-header card-verification-dialog-header">
  <box-dialog-header
    [class.transparent-header]="false"
    [alternativeTheme]="false"
    title="Επιβεβαίωση κάρτας"
    (closeDialog)="closeDialog()"
  ></box-dialog-header>
</header>
<main class="box-dialog-content card-verification-dialog-content">
  <span class="card-verification-dialog-content-text ods-typography-body-s-regular"
    >Παρακαλούμε συμπληρώστε τα στοιχεία της πιστωτικής ή χρεωστική σας κάρτας που λείπουν.</span
  >
  <form [formGroup]="cardForm" class="card-verification-dialog-form">
    <mat-form-field class="card-verification-dialog-form-field card-verification-dialog-form-field-pan-inputs">
      <mat-label>Αριθμός κάρτα</mat-label>
      <input
        matInput
        id="pan1"
        autocomplete="off"
        name="pan1"
        formControlName="pan1"
        [(ngModel)]="pan1"
        matInputPinFormat
      />
      <input
        matInput
        #panInput2
        id="pan2"
        placeholder="- - - -"
        autocomplete="off"
        name="pan2"
        matInputPinFormat
        [pinLength]="CARD_PIN_LENGTH"
        formControlName="pan2"
        [(ngModel)]="pan2"
        (keyup)="onInputPan2($event)"
      />
      <input
        matInput
        #panInput3
        id="pan3"
        placeholder="- - - -"
        autocomplete="off"
        name="pan3"
        matInputPinFormat
        [pinLength]="CARD_PIN_LENGTH"
        formControlName="pan3"
        [(ngModel)]="pan3"
        (keyup)="onInputPan3($event)"
        (keydown.backspace)="onBackSpacePan3()"
      />
      <input
        matInput
        id="pan4"
        autocomplete="off"
        name="pan4"
        formControlName="pan4"
        [(ngModel)]="pan4"
        matInputPinFormat
      />
    </mat-form-field>

    <mat-form-field class="card-verification-dialog-form-field">
      <mat-label>Ημερομηνία γέννησης ({{ BIRTH_DATE.label }})</mat-label>
      <input
        matInput
        #birthDateInput
        id="birthDate"
        autocomplete="off"
        name="birthDate"
        formControlName="birthDate"
        matInputDateFormat
        [shouldReturnFormattedValue]="true"
        [dateFormat]="BIRTH_DATE.format"
        [(ngModel)]="birthDate"
        (keyup)="onInputBirthDate($event)"
      />
    </mat-form-field>

    <mat-form-field class="card-verification-dialog-form-field">
      <mat-label>Ημερομηνία λήξης ({{ EXPIRATION_DATE.label }})</mat-label>
      <input
        matInput
        #expirationDateInput
        id="expirationDate"
        autocomplete="off"
        name="expirationDate"
        formControlName="expirationDate"
        matInputDateFormat
        [shouldReturnFormattedValue]="true"
        [dateFormat]="EXPIRATION_DATE.format"
        [(ngModel)]="expirationDate"
      />
    </mat-form-field>
  </form>
</main>
<footer class="box-dialog-footer card-verification-dialog-footer">
  <button
    box-button
    theme="primary"
    class="card-verification-dialog-button"
    [disabled]="cardForm.invalid"
    (click)="onSubmit()"
  >
    Συνέχεια
  </button>
</footer>
