import { RewardsNavItem } from './rewards-nav.types';

export const rewardsNavItems: Record<string, RewardsNavItem> = {
  points: {
    title: 'Πόντοι BOX',
    innerHTML: `<svg class="rewards-nav-svg" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5556 18.7038C11.5867 18.7038 9.00893 17.0208 7.72298 14.5556H14.5556V12.1852H6.95265C6.89337 11.8 6.85193 11.403 6.85193 11C6.85193 10.5971 6.89343 10.2 6.95265 9.81487H14.5556V7.44448H7.72298C9.00893 4.97932 11.5808 3.29632 14.5556 3.29632C16.4697 3.29632 18.2178 3.9956 19.563 5.15115L21.6667 3.05932C19.7823 1.37043 17.2874 0.333374 14.5556 0.333374C9.9156 0.333374 5.97487 3.30226 4.51115 7.44448H0.333374V9.81487H3.96004C3.91265 10.206 3.88893 10.5971 3.88893 11C3.88893 11.403 3.91265 11.7941 3.96004 12.1852H0.333374V14.5556H4.51115C5.97487 18.6978 9.9156 21.6667 14.5556 21.6667C17.2874 21.6667 19.7823 20.6297 21.6667 18.9408L19.563 16.843C18.2178 17.9985 16.4697 18.7038 14.5556 18.7038Z" fill="#807F85"/>
</svg>
`,
    relativeUrl: '/rewards'
  },
  coupons: {
    title: 'Κουπόνια BOX',
    innerHTML: `<svg class="rewards-nav-svg" width="24" height="14" viewBox="0 0 24 14">
      <defs>
        <path id="coupons-nav-item-svg-path" d="M23.308 4.968A.685.685 0 0 0 24 4.29V.677A.685.685 0 0 0 23.308 0H8.077v3.387c0 .374-.31.678-.692.678a.685.685 0 0 1-.693-.678V0h-6A.685.685 0 0 0 0 .677V4.29c0 .374.31.678.692.678 1.145 0 2.077.911 2.077 2.032 0 1.12-.931 2.032-2.077 2.032A.685.685 0 0 0 0 9.71v3.613c0 .374.31.677.692.677h6v-3.387c0-.374.31-.678.693-.678.382 0 .692.304.692.678V14h15.23a.685.685 0 0 0 .693-.677V9.71a.685.685 0 0 0-.692-.678c-1.146 0-2.077-.911-2.077-2.032 0-1.12.931-2.032 2.077-2.032ZM8.077 7.903c0 .374-.31.678-.692.678a.685.685 0 0 1-.693-.678V6.097c0-.374.31-.678.693-.678.382 0 .692.304.692.678v1.806Z"/>
      </defs>
      <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
        <g>
          <mask id="coupons-nav-item-svg-mask" fill="#fff">
            <use xlink:href="#coupons-nav-item-svg-path"/>
          </mask>
          <use xlink:href="#coupons-nav-item-svg-path" fill="#5a5a5a" fill-rule="nonzero"/>
          <path fill="#5a5a5a" d="M-4-9h32v32H-4z" mask="url(#coupons-nav-item-svg-mask)"/>
        </g>
      </g>
    </svg>`,
    relativeUrl: '/rewards/coupons'
  },
  gb: { title: 'Πακέτα Gigabyte', innerHTML: 'GB', relativeUrl: '/rewards/gb' }
};
