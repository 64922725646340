import { OutputBlock } from '@box-types';

export { normalizeHeaderOutputBlockLevel, getOutputBlockHtmlTagName, getOutputBlockClassName, getOutputBlockHtml };

function normalizeHeaderOutputBlockLevel(level: number): number {
  if ([1, 2, 3, 4, 5, 6].includes(level)) return level;
  return 1;
}

function getOutputBlockHtmlTagName(block: OutputBlock): string {
  if (block.type === 'header') return `h${normalizeHeaderOutputBlockLevel(block.data?.level as number)}`;
  if (block.type === 'paragraph') return 'p';
  return 'div';
}

function getOutputBlockClassName(block: OutputBlock): string {
  const defaultClass = 'output-block';
  if (block.type === 'header') return `${defaultClass} output-block-header`;
  if (block.type === 'paragraph') return `${defaultClass} output-block-paragraph`;
  return defaultClass;
}

function getOutputBlockHtml(block: OutputBlock): string {
  const text = block.data?.text ?? '';
  const tagName = getOutputBlockHtmlTagName(block);
  const className = getOutputBlockClassName(block);
  return `<${tagName} class="${className}">${text}</${tagName}>`;
}
