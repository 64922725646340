<section class="section">
  <div class="container">
    <div class="cookies-wrapper">
      <div class="cookies-content">
        <h3>Διαχείριση cookies και παρόμοιων τεχνολογιών</h3>
        <div class="cookies-content-scrollable">
          <div class="cookies-content-wrapper">
            <p>
              Τα cookies και άλλοι παρόμοιοι μηχανισμοί ιχνηλάτησης (εφεξής cookies) είναι σημαντικοί για την εύρυθμη
              λειτουργία του www.box.gr και για την βελτίωση της online εμπειρίας σας. Χρησιμοποιούμε cookies για:
            </p>
            <ul class="cookies-content-list">
              <li>
                <span class="cookies-content-square">&#9632;</span>
                την πραγματοποίηση της σύνδεσης στην ιστοσελίδα ή για την παροχή μιας υπηρεσίας διαδικτύου, την οποία
                έχετε ζητήσει (<strong>cookies αναγκαία και λειτουργικότητας</strong>). Χωρίς αυτά τα cookies δεν είναι
                τεχνικά εφικτή η σύνδεσή σας στην ιστοσελίδα μας ή δεν είναι εφικτό να σας παρέχουμε μια υπηρεσία που
                εσείς μας ζητήσατε (π.χ. cookies που αφορούν την καταχώρηση των αγορών σας στο ηλεκτρονικό μας
                κατάστημα).
                <strong>Για τον λόγο αυτό αυτά τα cookies είναι πάντα ενεργοποιημένα.</strong>
              </li>
              <li>
                <span class="cookies-content-square">&#9632;</span>
                την συλλογή <strong>συγκεντρωτικών πληροφοριών</strong> που μας επιτρέπουν να αντιληφθούμε πώς οι
                χρήστες χρησιμοποιούν τον ιστότοπό μας και βοηθούν στο να βελτιώσουμε την λειτουργία, την δομή και το
                περιεχόμενό του (<strong>cookies επιδόσεων</strong>).
              </li>
              <li>
                <span class="cookies-content-square">&#9632;</span>
                την προσαρμογή διαφημιστικού περιεχομένου (<strong>cookies στόχευσης διαφήμισης</strong>), ώστε να
                αντικατοπτρίζει τις ιδιαίτερες ανάγκες και τα ενδιαφέροντά σας και την μέτρηση ή/και βελτίωση
                αποτελεσματικότητας των διαφημιστικών μας καμπανιών.
              </li>
            </ul>
            <p>
              Πατήστε <strong>«Αποδοχή cookies»</strong> για την <strong>ενεργοποίηση όλων των cookies</strong> ή
              <strong>«Απόρριψη cookies»</strong> για την
              <strong>ενεργοποίηση μόνο της κατηγορίας «Cookies αναγκαία και λειτουργικότητας»</strong> ή επιλέξτε
              <strong>«Περισσότερες πληροφορίες»</strong> για να επιλέξετε ποια cookies επιθυμείτε να χρησιμοποιηθούν.
            </p>
          </div>
        </div>
      </div>
      <div class="cookies-actions">
        <button box-button theme="main" class="cookies-button" (click)="accept()">Αποδοχή Cookies</button>
        <button box-button theme="main" class="cookies-button" (click)="decline()">Απόρριψη Cookies</button>
        <button class="cookies-actions-more" box-button (click)="onCookiesClick()">Πληροφορίες και ρυθμίσεις</button>
      </div>
    </div>
  </div>
</section>
