<div class="checkout-points-slider-data-wrapper">
  <div *ngIf="alternativeSteps?.length" class="checkout-points-slider-data-steps">
    <span
      *ngFor="let step of steps; let i = index"
      class="checkout-points-slider-data-step"
      [class.active-step]="i === activeIndex"
      [attr.data-text]="step"
    ></span>
  </div>
  <ng-content></ng-content>
  <div *ngIf="alternativeSteps?.length" class="checkout-points-slider-data-steps">
    <span
      *ngFor="let step of alternativeSteps; let i = index"
      class="checkout-points-slider-data-step"
      [class.active-step]="i === activeIndex"
      [attr.data-text]="step"
    ></span>
  </div>
</div>
