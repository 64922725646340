<div class="market-cart-offer-wrapper" [class.hoverable]="!disabled" (click)="onEdit(itemInstance)">
  <div *ngIf="thumbnail" class="market-cart-offer-image-container">
    <promo-tag *ngIf="tagOptions" [options]="tagOptions"></promo-tag>
    <figure class="market-cart-offer-image">
      <img [src]="thumbnail" [alt]="name" />
    </figure>
  </div>
  <div class="market-cart-offer-details">
    <h4 class="market-cart-offer-name">{{ name }}</h4>
    <span class="market-cart-offer-description">{{ description }}</span>
  </div>
  <div class="market-cart-offer-actions">
    <div class="market-cart-offer-price-container">
      <div *ngIf="showBeginPrice" class="market-cart-offer-starting-price">
        {{ beginPrice / 100 | currency: 'EUR' }}
      </div>
      <div class="market-cart-offer-price">{{ finalPrice / 100 | currency: 'EUR' }}</div>
    </div>
    <div class="market-cart-offer-uom-step">{{ stepToUomDescription }}</div>
    <div class="market-cart-offer-quantity">
      <box-quantity
        [disabled]="disabled"
        [quantity]="itemInstance.quantity"
        (add)="onAdd(itemInstance)"
        (remove)="onRemove(itemInstance)"
      >
      </box-quantity>
    </div>
  </div>
</div>
