import { Injectable } from '@angular/core';
import { NotificationsService } from '@box-core/services/notifications.service';
import { Observable, Subscriber, from, catchError, of } from 'rxjs';
import { MapsService } from '@box-core/services/maps.service';
import { switchMap } from 'rxjs/operators';
import { isPlaceResultDeliveryCompliant } from '@box/utils';
import { APIError } from '@box-types';
import { InfoNotificationWidgetData } from '@box-shared/components/info-notification-wrapper/info-notification-wrapper.types';
import { InfoNotificationWrapperComponent } from '@box-core/components';

const GEOLOCATION_TIMEOUT = 10000; // 10 seconds in ms
const MAXIMUM_AGE = 60000; // 1 minute in ms
const POSITION_OPTIONS: PositionOptions = {
  timeout: GEOLOCATION_TIMEOUT,
  maximumAge: MAXIMUM_AGE,
  enableHighAccuracy: false // makes the response time a lot slower if set to true
};

const NO_ACCESS_TO_LOCATION_ERROR = { userMessage: 'Ο browser σου δεν έχει πρόσβαση στην τοποθεσία σου.' } as APIError;

const LOCATION_NOT_FOUND_ERROR = {
  userMessage: 'Δεν καταφέραμε να βρούμε την τοποθεσία σου, προσπάθησε ξανά.'
} as APIError;

const LOCATION_PERMISSIONS_ERROR = {
  userTitle: 'Κοινοποιήση τοποθεσίας',
  userMessage: 'Θα πρέπει να ενεργοποιήστε την κοινή χρήση τοποθεσίας στις ρυθμίσεις του προγράμματος περιήγησής σας.'
} as APIError;

@Injectable({ providedIn: 'any' })
export class GeolocationService {
  constructor(private notificationsService: NotificationsService, private mapsService: MapsService) {}

  public getUserPosition$(): Observable<GeolocationPosition> {
    return this.mapsService.loadAPI().pipe(
      switchMap((apiLoaded) => {
        return new Observable((subscriber: Subscriber<GeolocationPosition>) => {
          if (!navigator?.geolocation || !apiLoaded) {
            subscriber.error(NO_ACCESS_TO_LOCATION_ERROR);
            return subscriber.complete();
          }

          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              subscriber.next(position);
              subscriber.complete();
            },
            (error: GeolocationPositionError) => {
              if (error.code === 1) subscriber.error(LOCATION_PERMISSIONS_ERROR);
              if (error.code === 3) subscriber.error(LOCATION_NOT_FOUND_ERROR);
              subscriber.complete();
            },
            POSITION_OPTIONS
          );
        });
      })
    );
  }

  public reverseGeocodePosition$(latLng: google.maps.LatLng): Observable<google.maps.GeocoderResult> {
    const geocoder = new google.maps.Geocoder();
    const geocode$ = from(geocoder.geocode({ location: latLng }));
    return new Observable((subscriber: Subscriber<google.maps.GeocoderResult>) => {
      if (!geocoder || !latLng) {
        subscriber.error(LOCATION_NOT_FOUND_ERROR);
        return subscriber.complete();
      }

      geocode$.subscribe({
        next: (response) => {
          const results = response?.results;
          if (!results?.length) {
            subscriber.error(LOCATION_NOT_FOUND_ERROR);
            return subscriber.complete();
          }

          const bestResult = results.find((result) => isPlaceResultDeliveryCompliant(result));
          if (!bestResult) {
            subscriber.error(LOCATION_NOT_FOUND_ERROR);
            return subscriber.complete();
          }

          subscriber.next(bestResult);
          subscriber.complete();
        },
        error: () => {
          subscriber.error(LOCATION_NOT_FOUND_ERROR);
          subscriber.complete();
        }
      });
    });
  }

  private openErrorNotification(error: APIError): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
    const notificationData: InfoNotificationWidgetData = {
      matIcon: 'info_outline',
      title: error.userTitle ?? 'Κάτι πήγε στραβά',
      message: error.userMessage
    };
    this.notificationsService.addNotification(InfoNotificationWrapperComponent, { data: notificationData });
  }

  public getUserPlace$(): Observable<google.maps.GeocoderResult> {
    return this.getUserPosition$().pipe(
      switchMap((position) => {
        const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        return this.reverseGeocodePosition$(latLng);
      }),
      catchError((error: APIError) => {
        this.openErrorNotification(error);
        return of(null);
      })
    );
  }
}
