<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="Επιβεβαίωση διαγραφής" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="verification-dialog-content">
      <div class="verification-dialog-image-container">
        <figure class="verification-dialog-image">
          <img src="/assets/images/logo/logo.svg" alt="BOX" />
        </figure>
      </div>

      <p class="verification-dialog-description">
        Σου έχουμε στείλει έναν κωδικό με {{ mobilePhone?.length ? 'SMS' : 'email' }} στο
        {{ mobilePhone?.length ? mobilePhone : email }}, παρακαλούμε συμπλήρωσε αυτόν τον κωδικό εδώ
      </p>

      <mat-form-field class="verification-dialog-field">
        <mat-label>Κωδικός επιβεβαίωσης</mat-label>
        <input
          matInput
          autocomplete="off"
          name="verificationCode"
          (keydown.enter)="verifyOtp()"
          [formControl]="verificationControl"
        />
        <mat-error>Ο κωδικός επιβεβαίωσης πρέπει να αποτελείται από 4 ψηφία!</mat-error>
      </mat-form-field>
      <button
        box-button
        theme="primary"
        class="verification-dialog-button"
        [disabled]="verificationControl.invalid"
        (click)="verifyOtp()"
      >
        Συνέχεια
      </button>

      <footer class="verification-dialog-footer">
        <verification-resend-code
          #verificationResendCodeComponent
          [otpMethod]="{ msisdn: mobilePhone, email: this.email }"
          (send)="generateOtp()"
        ></verification-resend-code>
      </footer>
    </div>
  </main>
</div>
