import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckoutPointsDialogData, CheckoutPointsDialogResponse } from '@box-types';
import { BoxDialogWrapperComponent, BOXSliderComponent } from '@box-shared/components';
import { CheckoutPointsDialogService } from './checkout-points-dialog.service';
import { DialogService } from '@box-core/services';

@Component({
  selector: 'checkout-points-dialog',
  templateUrl: './checkout-points-dialog.component.html',
  styleUrls: ['./checkout-points-dialog.component.scss'],
  providers: [CheckoutPointsDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutPointsDialogComponent extends BoxDialogWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild(BOXSliderComponent, { read: ElementRef }) private boxSliderRef: ElementRef<HTMLElement>;
  public cartPrice: number;
  public totalDiscount: number;
  public euroRedemptionRate: number;
  public stepValue: number;
  public selectedValue: number;
  public selectedValueInEuro: number;
  public claimableValue: number;
  public claimableValueInEuro: number;
  public availableValue: number;
  public maximumValue: number;

  public activeIndex: number;
  public steps: string[];
  public alternativeSteps: string[];

  private incomingDiscount: number;

  constructor(
    public renderer: Renderer2,
    private dialogRef: MatDialogRef<CheckoutPointsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: CheckoutPointsDialogData,
    private checkoutPointsDialogService: CheckoutPointsDialogService,
    private dialogService: DialogService
  ) {
    super(renderer);
  }

  ngOnInit(): void {
    this.cartPrice = this.data.cartPrice;
    this.euroRedemptionRate = this.data.euroRedemptionRate;
    this.stepValue = this.data.stepValue;
    this.selectedValue = 0;
    this.selectedValueInEuro = 0;
    this.activeIndex = 0;
    this.incomingDiscount = this.data.totalDiscount;
    this.totalDiscount = this.incomingDiscount;
    this.claimableValue = this.data.claimableValue;
    this.claimableValueInEuro = (this.claimableValue / this.stepValue) * 100;
    this.availableValue = this.data.availableValue;
    this.maximumValue = this.data.maximumValue;
    this.steps = this.checkoutPointsDialogService.generatePointsSteps(this.stepValue, this.maximumValue);
    this.alternativeSteps = this.checkoutPointsDialogService.generateEurosSteps(this.stepValue, this.maximumValue);
  }

  ngAfterViewInit(): void {
    this.boxSliderRef.nativeElement.focus();
  }

  public closeDialog(data?: CheckoutPointsDialogResponse): void {
    this.dialogRef.close(data);
  }

  public onSubmit(): void {
    this.closeDialog({ value: this.selectedValue });
  }

  public onValueChange(value: number): void {
    this.selectedValue = value;
    this.selectedValueInEuro = (value / this.stepValue) * 100;
    this.totalDiscount = Math.min(this.cartPrice, this.incomingDiscount + this.selectedValueInEuro);
    if (value <= this.claimableValue) return;
    const errorMessage = this.checkoutPointsDialogService.getErrorMessage(
      value,
      this.availableValue,
      this.maximumValue / this.stepValue,
      this.euroRedemptionRate
    );
    this.dialogService
      .openInfoDialog({ title: 'Εξαργύρωση πόντων', messages: [errorMessage] })
      .afterClosed()
      .subscribe(() => this.setSliderToClaimableValue());
  }

  public onInputChange(value: number): void {
    const selectedStep = value / this.stepValue;
    this.activeIndex = selectedStep;
  }

  private setSliderToClaimableValue(): void {
    this.selectedValue = this.claimableValue;
    const selectedStep = this.claimableValue / this.stepValue;
    this.activeIndex = selectedStep;
    this.selectedValueInEuro = selectedStep * 100;
    this.totalDiscount = Math.min(this.cartPrice, this.incomingDiscount + this.selectedValueInEuro);
  }
}
