<div class="food-item-left">
  <div class="food-item-header">
    <promo-badge *ngIf="badgeOptions" [options]="badgeOptions"></promo-badge>
    <div class="ods-typography-body-m-bold">{{ name }}</div>
  </div>
  <div *ngIf="description" class="food-item-description ods-typography-body-s-regular">{{ description }}</div>
  <product-description-badges
    *ngIf="product.tags || product.brand"
    [brand]="product.brand"
    [tags]="product.tags"
  ></product-description-badges>
  <div class="food-item-price ods-typography-body-m-regular">{{ price / 100 | currency: 'EUR' }}</div>
</div>
<div class="food-item-right" [class.food-item-right-with-image]="thumbnailUrl">
  <figure
    *ngIf="thumbnailUrl"
    class="food-item-image"
    [class.food-item-image-non-zero-quantity]="product.cartQuantity > 0"
    [class.food-item-image-more-than-nine-quantity]="product.cartQuantity > 9"
  >
    <img loading="lazy" [src]="thumbnailUrl" [alt]="name" />
  </figure>
  <box-quantity
    [subtraction]="false"
    [quantity]="product.cartQuantity"
    (add)="onAdd()"
    dense="true"
    (remove)="onRemove()"
  ></box-quantity>
</div>
