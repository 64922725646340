<div class="rewards-promo-banner-wrapper">
  <ng-container *ngIf="showServerTriggeredRewards">
    <p class="rewards-promo-banner-text">{{ serverTriggeredRewardsText }}</p>
    <section *ngIf="serverTriggeredRewards.length > 0" class="rewards-promo-banner-section">
      <ng-container *ngFor="let reward of serverTriggeredRewards">
        <div class="rewards-promo-banner-free-reward-container">
          <reward-gb [segment]="userSegment" [reward]="reward" alternativeView="true" backgroundView="true"></reward-gb>
          <span class="rewards-promo-banner-free-reward-text">ΔΩΡΟ!</span>
        </div>
      </ng-container>
    </section>
  </ng-container>

  <ng-container *ngIf="showClientTriggeredRewards">
    <p class="rewards-promo-banner-text ods-typography-body-s-regular">{{ clientTriggeredRewardsText }}</p>
    <section class="rewards-promo-banner-section">
      <ng-container *ngFor="let reward of clientTriggeredRewards">
        <reward-gb [segment]="userSegment" [reward]="reward" alternativeView="true" backgroundView="true"></reward-gb>
      </ng-container>
    </section>
  </ng-container>
</div>
