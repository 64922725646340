import { Component, OnInit, ChangeDetectionStrategy, HostBinding, Output, EventEmitter } from '@angular/core';
import { CheckoutDonationService } from './checkout-donation.service';
import { CheckoutAmountChange } from '../checkout-amounts/checkout-amounts.types';

@Component({
  selector: 'checkout-donation',
  templateUrl: './checkout-donation.component.html',
  styleUrls: ['./checkout-donation.component.scss'],
  providers: [CheckoutDonationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutDonationComponent implements OnInit {
  @Output() private donationChange = new EventEmitter<number>();

  public donation: number;
  public amounts: number[];
  public minimumAmount: number;
  public maximumAmount: number;
  public title: string;
  public description: string;

  constructor(private checkoutDonationService: CheckoutDonationService) {}

  @HostBinding('class') public hostClass = 'checkout-donation';

  ngOnInit(): void {
    this.amounts = this.checkoutDonationService.getAmounts();
    this.minimumAmount = this.checkoutDonationService.getMinimumAmount();
    this.maximumAmount = this.checkoutDonationService.getMaximumAmount();
    this.title = this.checkoutDonationService.getTitle();
    this.description = this.checkoutDonationService.getDescription();
    this.donation = this.amounts[0];
  }

  public onAmountChange(event: CheckoutAmountChange): void {
    this.donation = event.value;
    this.donationChange.emit(event.value);
    this.checkoutDonationService.triggerAnalyticsEvent(event.value, event.label);
  }
}
