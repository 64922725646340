import { MapCircle, MapPoint, BoxTheme } from '@box-types';
import { environment } from '@box-env/environment';
import { LIGHT_MAPS_THEME, DARK_MAPS_THEME, LIGTHNESS_MAP_RULES, VISIBILITY_DAAS_MAP_RULES } from './maps-rules.types';

export {
  getCircleCenter,
  degreesToRadians,
  getPointsDistance,
  circleContainsPoint,
  getAddressEditMapStyles,
  getDaasMapStyles,
  getStaticMapUrl,
  getStaticMapStylesFromJson
};

const STATIC_MAPS_API_KEY = environment.google.STATIC_MAPS_API_KEY;

function getCircleCenter(circle: MapCircle): MapPoint {
  return { lat: circle.lat, lng: circle.lng };
}

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

/*
This uses the Haversine formula to calculate the great-circle distance between two points – that is,
the shortest distance over the earth’s surface – giving an ‘as-the-crow-flies’ distance between the
points (ignoring any hills they fly over, of course!).

Haversine
formula:	a = sin²(Δφ/2) + cos φ1 ⋅ cos φ2 ⋅ sin²(Δλ/2)
c = 2 ⋅ atan2( √a, √(1−a) )
d = R ⋅ c
where	φ is latitude, λ is longitude, R is earth’s radius (mean radius = 6,371km);
note that angles need to be in radians to pass to trig functions!
*/

function getPointsDistance(pointA: MapPoint, pointB: MapPoint): number {
  const R = 6378137; // Earths radius in meters
  const latDist = degreesToRadians(pointB.lat - pointA.lat);
  const lngDist = degreesToRadians(pointB.lng - pointA.lng);
  const a =
    Math.sin(latDist / 2) * Math.sin(latDist / 2) +
    Math.cos(degreesToRadians(pointA.lat)) *
      Math.cos(degreesToRadians(pointB.lat)) *
      Math.sin(lngDist / 2) *
      Math.sin(lngDist / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function circleContainsPoint(circle: MapCircle, point: MapPoint): boolean {
  const circleCenter = getCircleCenter(circle);
  const centerDistance = getPointsDistance(circleCenter, point);
  return centerDistance <= circle.radius;
}

function getAddressEditMapStyles(theme: BoxTheme): google.maps.MapTypeStyle[] {
  switch (theme) {
    case 'dark':
      return DARK_MAPS_THEME;
    case 'light':
      return LIGHT_MAPS_THEME;
    default:
      return LIGHT_MAPS_THEME;
  }
}

function getDaasMapStyles(theme: BoxTheme): google.maps.MapTypeStyle[] {
  switch (theme) {
    case 'dark':
      return [...DARK_MAPS_THEME, ...VISIBILITY_DAAS_MAP_RULES];
    case 'light':
      return [...LIGHT_MAPS_THEME, ...LIGTHNESS_MAP_RULES, ...VISIBILITY_DAAS_MAP_RULES];
    default:
      return [...LIGHT_MAPS_THEME, ...LIGTHNESS_MAP_RULES, ...VISIBILITY_DAAS_MAP_RULES];
  }
}

// https://stackoverflow.com/questions/19115223/converting-google-maps-styles-array-to-google-static-maps-styles-string
function getStaticMapStylesFromJson(jsonWithMapStyling): string {
  if (!jsonWithMapStyling?.length) return '';

  return jsonWithMapStyling
    .map((rule) => {
      let ruleText = '';
      if (!rule.stylers?.length) return '';
      // Needs to have a ruleText rule to be valid.
      ruleText += (rule.featureType ? 'feature:' + rule.featureType : 'feature:all') + '|';
      ruleText += (rule.elementType ? 'element:' + rule.elementType : 'element:all') + '|';
      rule.stylers.forEach(function (styleObject) {
        const propertyName = Object.keys(styleObject)[0];
        const propertyValue = styleObject[propertyName].toString().replace('#', '0x');
        ruleText += propertyName + ':' + propertyValue + '|';
      });
      return 'style=' + encodeURIComponent(ruleText);
    })
    .join('&');
}

function getStaticMapUrl(latitude: number, longitude: number, theme: BoxTheme): string {
  const zoom = '16';
  const size = '608x170';
  const markerColor = 'red';
  const stylingRules = theme !== 'dark' ? LIGHT_MAPS_THEME : DARK_MAPS_THEME;
  const staticStylingRules = getStaticMapStylesFromJson(stylingRules);
  return `url(https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${size}&maptype=roadmap&markers=color:${markerColor}%7C${latitude},${longitude}&key=${STATIC_MAPS_API_KEY}&${staticStylingRules}`;
}
