import { BoxMenuItem } from '../types';

export const menuItems: BoxMenuItem[] = [
  {
    title: 'Ιστορικό',
    icon: '/assets/images/general/menu/history.svg',
    link: '/account/history'
  },
  {
    title: 'Στοιχεία χρήστη',
    icon: '/assets/images/general/menu/account.svg',
    link: '/account/user-details'
  },
  {
    title: 'Δεδομένα επικοινωνίας',
    icon: '/assets/images/general/menu/account-settings.svg',
    link: '/account/contact'
  },
  {
    title: 'Διευθύνσεις',
    icon: '/assets/images/general/menu/map.svg',
    link: '/account/addresses'
  },
  {
    title: 'Κάρτες πληρωμών',
    icon: '/assets/images/general/menu/credit-card.svg',
    link: '/account/cards'
  },
  {
    title: 'Κάρτες Supermarket',
    icon: '/assets/images/general/menu/grocery.svg',
    link: '/account/supermarket-cards'
  },
  {
    title: 'Έχω κουπόνι δώρου',
    icon: '/assets/images/general/menu/coupons.svg',
    link: '/rewards/coupons'
  },
  {
    title: 'Διαγωνισμοί',
    icon: '/assets/images/general/menu/star.svg',
    link: '/account/contests'
  },
  {
    title: 'Πρόσκληση φίλων',
    icon: '/assets/images/general/menu/people.svg',
    link: '/account/referral'
  },
  {
    title: 'Ρυθμίσεις',
    icon: '/assets/images/general/menu/settings.svg',
    link: '/account/settings'
  },
  {
    title: 'Όροι χρήσης κι εξατομίκευσης',
    icon: '/assets/images/general/menu/terms.svg',
    link: '/account/terms'
  },
  {
    title: 'Ρυθμίσεις των Cookies',
    icon: '/assets/images/general/menu/cookies.svg',
    link: '/account/cookies'
  }
];
