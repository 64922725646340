import { Injectable } from '@angular/core';
import { BOXEmptyStateOptions, SearchStatusType } from '@box-types';

@Injectable()
export class HomeSearchResultsService {
  public generateEmptyStateOptions(searchTerm: string): BOXEmptyStateOptions {
    return {
      iconURL: '/assets/images/empty-state/empty-state--search.svg',
      title: `Δε βρέθηκαν αποτελέσματα για “${searchTerm}“`
    };
  }

  public generateCTAText(status: SearchStatusType, hasResults: boolean): string {
    if (status === 'COMPLETED' && !hasResults) return `Ανακάλυψε καταστήματα`;
    return `Δες καταστήματα, πιάτα και υλικά`;
  }
}
