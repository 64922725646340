<image-tile [imgUrl]="logoSrc" [altText]="logoAlt"></image-tile>
<div class="order-status-preview-content">
  <progress-bar [fragments]="fragments"></progress-bar>
  <div class="order-status-preview-content-title ods-typography-subtitle-s">{{ title }}</div>
  <span class="order-status-preview-content-instructions">Μπορείς να δεις περισσότερες πληροφορίες</span>
</div>
<div class="order-status-preview-icon">
  <svg class="order-status-preview-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="m8 17 5-5-5-5 2-1 6 6-6 6-2-1Z" />
  </svg>
</div>
