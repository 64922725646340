import { appConfig } from '@box-configs/app.config';
import { environment } from '@box-env/environment';

export {
  DEFAULT_DESCRIPTION,
  DEFAULT_KEYWORDS,
  generateTitleContent,
  generateDescriptionContent,
  generateKeywordsContent,
  generateImageContent,
  generateUrlContent
};

const DEFAULT_DESCRIPTION =
  'Παράγγειλε φαγητό online από το BOX. Κάνε τα ψώνια σου online. Μάζεψε πόντους από την παραγγελία σου και εξαργύρωσε τους. Είσαι έτοιμος να BOXάρεις;';
const DEFAULT_KEYWORDS =
  'online delivery app, online παραγγελία app, delivery application, online παραγγελία φαγητού, προσφορές delivery';

function generateTitleContent(title?: string): string {
  const appName = appConfig.name;
  const givenTitle = title ?? 'Online Delivery';
  return `${givenTitle} | ${appName}`;
}

function generateDescriptionContent(description?: string): string {
  return description ?? DEFAULT_DESCRIPTION;
}

function generateKeywordsContent(keywords?: string): string {
  return keywords ?? DEFAULT_KEYWORDS;
}

function generateImageContent(url?: string): string {
  const ROOT_URL = environment.application.ROOT_URL;
  return url ?? `${ROOT_URL}/assets/images/box-share-image.png`;
}

function generateUrlContent(routerUrl?: string): string {
  const ROOT_URL = environment.application.ROOT_URL;
  return routerUrl ? `${ROOT_URL}${routerUrl}` : ROOT_URL;
}
