import { Injectable } from '@angular/core';
import { Product, ProductPromo } from '@box-types';
import { getBestProductPromo } from '@box/utils';

@Injectable()
export class ProductMYODetailsService {
  public getProductBestPromo(product: Product): ProductPromo {
    if (!product.promos?.length) return;
    const bestProductPromo = getBestProductPromo(product.promos);
    if (!bestProductPromo?.detailsImage || !bestProductPromo?.detailsText) return;
    return bestProductPromo;
  }

  public getProductDescription(product: Product, isSuperMarket: boolean): string {
    if (isSuperMarket) return product.unitOfMeasurementDescription;
    return product.info?.el;
  }

  public getProductBeginPrice(product: Product): number {
    if (!product.beginPrice) return 0;
    if (product.beginPrice > product.finalPrice) return product.beginPrice;
  }
}
