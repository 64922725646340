<header *ngIf="title" class="box-dialog-header">
  <div class="box-confirm-dialog-header-wrapper">
    <h4 class="dialog-title">{{ title }}</h4>
  </div>
</header>
<main>
  <div *ngIf="messages.length > 0" class="dialog-text">
    <p *ngFor="let message of messages">{{ message }}</p>
  </div>
</main>
<footer>
  <div class="dialog-buttons" [ngClass]="buttonOrientation">
    <button box-button theme="secondary" class="dialog-reject-button" (click)="closeDialog({ accepted: false })">
      {{ cancelText }}
    </button>
    <button box-button theme="primary" class="dialog-accept-button" (click)="closeDialog({ accepted: true })">
      {{ confirmText }}
    </button>
  </div>
</footer>
