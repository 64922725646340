import { Injectable } from '@angular/core';
import { CheckoutCouponsService } from '@box-checkout/services';
import { DialogService, PaymentTypesService } from '@box-core/services';
import { isCouponCheckoutPreselected, isPaymentCard } from '@box/utils';
import { Observable, tap } from 'rxjs';
import { Coupon } from '@box-types';

@Injectable()
export class CheckoutCouponService {
  constructor(
    private checkoutCouponsService: CheckoutCouponsService,
    private paymentTypesService: PaymentTypesService,
    private dialogService: DialogService
  ) {}

  public initialize(): Observable<Coupon[]> {
    return this.checkoutCouponsService
      .updateCheckoutCoupons()
      .pipe(tap((coupons) => this.handleCouponPreselectionFlow(coupons)));
  }

  private handleCouponPreselectionFlow(coupons: Coupon[]): void {
    if (!coupons?.length) return;
    const preselectedCoupon = coupons.find((coupon) => isCouponCheckoutPreselected(coupon));
    if (!preselectedCoupon) return;
    this.checkoutCouponsService.selectCoupon(preselectedCoupon);
    const currentPayment = this.paymentTypesService.getPaymentType();
    const payments = this.paymentTypesService.getPaymentTypes();
    if (isPaymentCard(currentPayment)) return;
    const firstAvailableCardPayment = payments.find((payment) => isPaymentCard(payment));
    if (!firstAvailableCardPayment) return;
    this.paymentTypesService.setPaymentType(firstAvailableCardPayment);
    this.showCouponPreselectionDialog();
  }

  private showCouponPreselectionDialog(): void {
    const infoConfig = {
      title: 'Έχεις έκπτωση!',
      messages: [
        'Για να πάρεις την έκπτωση, ορίσαμε πληρωμή με κάρτα ως τρόπο πληρωμής και προσυμπληρώσαμε το εκπτωτικό κουπόνι.'
      ]
    };
    this.dialogService.openInfoDialog(infoConfig);
  }
}
