import { RewardsConfiguration, RewardsTutorialGifKey } from '@box-types';
import { generateImageSrc } from '../media';

function generateRewardsTutorialSrc(config: RewardsConfiguration, key: RewardsTutorialGifKey): string {
  if (!config.tutorialGifs) return;
  const mediaObject = config.tutorialGifs[key];
  if (!mediaObject) return;
  return generateImageSrc(mediaObject);
}

function generateRewardsOrderTutorialSrc(config: RewardsConfiguration): string {
  return generateRewardsTutorialSrc(config, 'orderProcess');
}

function generateRewardsRedemptionTutorialSrc(config: RewardsConfiguration): string {
  return generateRewardsTutorialSrc(config, 'pointsRedemption');
}

function generateRewardsGBTutorialSrc(config: RewardsConfiguration): string {
  return generateRewardsTutorialSrc(config, 'gbRewardClaim');
}

function generateRewardsCardTutorialSrc(config: RewardsConfiguration): string {
  return generateRewardsTutorialSrc(config, 'firstCardPayment');
}

export {
  generateRewardsTutorialSrc,
  generateRewardsOrderTutorialSrc,
  generateRewardsRedemptionTutorialSrc,
  generateRewardsGBTutorialSrc,
  generateRewardsCardTutorialSrc
};
