<div boxNav [options]="navOptions">
  <div boxNavItem [title]="description" [class.box-nav-item-active]="state === 'MYO'" (click)="onStateChange('MYO')">
    <span class="ods-typography-body-s-bold">{{ description }}</span>
  </div>

  <div
    boxNavItem
    title="Αξιολογήσεις"
    [class.box-nav-item-active]="state === 'REVIEWS'"
    (click)="onStateChange('REVIEWS')"
  >
    <span class="ods-typography-body-s-bold">
      Αξιολογήσεις <span *ngIf="reviewsCounter">({{ reviewsCounter }})</span>
    </span>
  </div>
</div>
