<div class="checkout-synergy-dialog-wrapper">
  <figure *ngIf="dialogHeaderImage" class="image contain-image checkout-synergy-dialog-header-image">
    <img [src]="dialogHeaderImage" [alt]="dialogHeaderAlt + 'Synergy'" />
  </figure>
  <button class="close-button" mat-icon-button (click)="onClose()">
    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color">close</mat-icon>
  </button>

  <ng-container *ngIf="synergyBanners?.length === 1; else multiView">
    <div class="checkout-synergy-dialog-text ods-typography-body-s-bold">
      <span>Κερδίζεις </span>
      <points-tag *ngIf="synergyBanners[0].multiplier"
        >x{{ synergyBanners[0].multiplier | number: '1.0-0' }}</points-tag
      >
      <points-tag *ngIf="synergyBanners[0].points" sign="+">{{
        synergyBanners[0].points | number: '1.0-0'
      }}</points-tag>
      <span> πόντους για</span>
      <p>την παραγγελία σου από{{ synergyBanners[0].name === shopName ? ' το κατάστημα' : '' }}</p>
    </div>
    <p class="checkout-synergy-dialog-shop-name ods-typography-body-m-bold">{{ synergyBanners[0].name }}</p>
  </ng-container>
  <ng-template #multiView>
    <div class="checkout-synergy-dialog-text-container">
      <p class="checkout-synergy-dialog-text ods-typography-body-s-bold">Οι πόντοι που κερδίζεις είναι:</p>
    </div>

    <div class="checkout-synergy-dialog-banners">
      <ng-container *ngFor="let synergyBanner of synergyBanners; let last = last">
        <div class="checkout-synergy-dialog-banner">
          <figure class="checkout-synergy-dialog-banner-image">
            <img [src]="synergyBanner.checkoutBannerLogo" [alt]="synergyBanner.name + 'Synergy'" />
          </figure>
          <points-tag *ngIf="synergyBanner.multiplier">x{{ synergyBanner.multiplier | number: '1.0-0' }}</points-tag>
          <points-tag *ngIf="synergyBanner.points" sign="+">{{ synergyBanner.points | number: '1.0-0' }}</points-tag>
          <span class="ods-typography-body-s-bold"> από {{ synergyBanner.name }} </span>
        </div>
        <div *ngIf="!last" class="box-divider"></div>
      </ng-container>
    </div>
  </ng-template>
</div>
