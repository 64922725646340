<mat-expansion-panel #paymentTypesPanel class="checkout-payment-types-expansion-panel">
  <mat-expansion-panel-header collapsedHeight="3rem" expandedHeight="3rem">
    <mat-panel-title>
      <payment-type
        [paymentType]="selectedPaymentType"
        [alternativeIconView]="true"
        [showCampaign]="false"
      ></payment-type>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <p *ngIf="showCardPromoMessage" class="checkout-payment-types-instructions">
    Πλήρωσε οnline με την κάρτα σου!
    <br />
    Κέρδισε {{ firstOrderCardPoints }} πόντους και μην αγχώνεσαι πια αν θα έχεις πάνω σου μετρητά!
  </p>
  <payment-type
    *ngFor="let paymentType of paymentTypes"
    matRipple
    class="hoverable"
    [paymentType]="paymentType"
    (click)="onPaymentSelect(paymentType)"
    [showCampaign]="true"
  >
  </payment-type>
</mat-expansion-panel>
