import { Injectable } from '@angular/core';
import { Card, PaymentCardLoyaltyInfo, ConversionRate, BankLoyaltyInfoConfiguration } from '@box-types';
import { storageSet, storageGet, storageRemove, hashString } from '@box/utils';
import { BankPointsRedeemSlideDialogData } from '@box-checkout/components/bank-points-redeem-slide-dialog/bank-points-redeem-slide-dialog.interface';
import { ConfigurationService, ShopService } from '@box-core/services';

const PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY = 'Box:paymentVerificationAlertsShown';

@Injectable()
export class BankPointsRedeemToggleService {
  constructor(private configService: ConfigurationService, private shopService: ShopService) {}

  public getBankLoyaltyInfo(card: Card): BankLoyaltyInfoConfiguration {
    if (!card?.cardDetailsAddedByBox) return;
    const bankLoyaltyInfo = this.configService.getConfiguration()?.bankLoyaltyInfoV2;
    if (!bankLoyaltyInfo) return;
    return bankLoyaltyInfo.find(
      (info) =>
        info.bank.toLowerCase() === card.cardDetailsAddedByBox.bank.toLowerCase() &&
        info.productType.toLowerCase() === card.cardDetailsAddedByBox.productType.toLowerCase()
    );
  }

  public isBusinessVerticalMatching(bankLoyaltyInfo: BankLoyaltyInfoConfiguration): boolean {
    if (!bankLoyaltyInfo.businessVertical) return true;
    return bankLoyaltyInfo.businessVertical === this.shopService.getShop().businessVertical;
  }

  public getSingleClaimablePlan(cardLoyaltyInfo: PaymentCardLoyaltyInfo): ConversionRate {
    if (!cardLoyaltyInfo?.conversionSteps?.length) return;
    const pointsSteps = this.generateConversionRatePointsSteps(cardLoyaltyInfo.conversionSteps);
    const availableIndex = this.calculateAvailablePointsIndex(cardLoyaltyInfo.points, pointsSteps);
    if (availableIndex === 0 || availableIndex > 1) return;
    if (availableIndex === 1) return cardLoyaltyInfo.conversionSteps[0];
  }

  public getVerifiedViewPointsText(cardLoyaltyInfo: PaymentCardLoyaltyInfo): string {
    if (!cardLoyaltyInfo?.conversionSteps?.length) return;
    const pointsSteps = this.generateConversionRatePointsSteps(cardLoyaltyInfo.conversionSteps);
    const availableIndex = this.calculateAvailablePointsIndex(cardLoyaltyInfo.points, pointsSteps);
    const biggestPlanPointsText = String(pointsSteps[pointsSteps.length - 1] ?? 0);
    const availablePlanPointsText = String(pointsSteps[availableIndex] ?? 0);
    return availableIndex === 0 ? biggestPlanPointsText : availablePlanPointsText;
  }

  public getVerifiedViewEurosText(cardLoyaltyInfo: PaymentCardLoyaltyInfo): string {
    if (!cardLoyaltyInfo?.conversionSteps?.length) return;
    const pointsSteps = this.generateConversionRatePointsSteps(cardLoyaltyInfo.conversionSteps);
    const eurosSteps = this.generateConversionRateEuroSteps(cardLoyaltyInfo.conversionSteps);
    const availableIndex = this.calculateAvailablePointsIndex(cardLoyaltyInfo.points, pointsSteps);
    const biggestPlanEurosText = String(`${(eurosSteps[eurosSteps.length - 1] ?? 0) / 100}€`);
    const availablePlanEurosText = String(`${(eurosSteps[availableIndex] ?? 0) / 100}€`);
    return availableIndex === 0 ? biggestPlanEurosText : availablePlanEurosText;
  }

  public hasEnoughPoints(cardLoyaltyInfo: PaymentCardLoyaltyInfo): boolean {
    if (!cardLoyaltyInfo?.points || !cardLoyaltyInfo.conversionSteps?.length) return false;
    return cardLoyaltyInfo.points >= cardLoyaltyInfo.conversionSteps[0].points;
  }

  public hasVerificationAlertBeenShown(card: Card): boolean {
    if (!card?.pan?.length) return false;
    const state: Record<string, boolean> = storageGet(PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY, localStorage);
    if (!state || !Object.keys(state)?.length) return false;
    const panHash = hashString(card.pan);
    return state[panHash] ?? false;
  }

  public saveVerificationAlertBeenShown(card: Card): void {
    if (!card?.pan?.length) return;
    const state: Record<string, boolean> = storageGet(PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY, localStorage) ?? {};
    const panHash = hashString(card.pan);
    state[panHash] = true;
    storageSet(PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY, state, localStorage);
  }

  public removeVerificationAlertFromStorage(card: Card): void {
    if (!card?.pan?.length) return;
    const state: Record<string, boolean> = storageGet(PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY, localStorage);
    if (!state || !Object.keys(state)?.length) return;
    const panHash = hashString(card.pan);
    delete state[panHash];
    if (!Object.keys(state)?.length) {
      storageRemove(PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY, localStorage);
    } else {
      storageSet(PAYMENT_VERIFICATION_ALERTS_SHOWN_KEY, state, localStorage);
    }
  }

  public generateConversionRatePointsSteps(conversionRates: ConversionRate[]): number[] {
    if (!conversionRates?.length) return [];
    const pointsSteps = conversionRates.map((rate) => rate?.points ?? 0);
    return [0, ...pointsSteps];
  }

  public generateConversionRateEuroSteps(conversionRates: ConversionRate[]): number[] {
    if (!conversionRates?.length) return [];
    const eurosSteps = conversionRates.map((rate) => rate?.monetaryValue ?? 0);
    return [0, ...eurosSteps];
  }

  public calculateAvailablePointsIndex(availablePoints: number, pointsSteps: number[]): number {
    if (pointsSteps?.length < 1 || !availablePoints) return 0;
    let index = 0; // not the first plan, this corresponds to no available plan
    do {
      const nextStepHasMorePointsThanAvailable = pointsSteps[index + 1] > availablePoints;
      if (nextStepHasMorePointsThanAvailable) break;
      index++;
    } while (index < pointsSteps.length - 1);
    return index;
  }

  public generateBankPointsRedeemSlideDialogData(
    selectedPoints: number,
    loyaltyInfo: PaymentCardLoyaltyInfo,
    price: number,
    loyaltyName: string
  ): BankPointsRedeemSlideDialogData {
    const pointsSteps = this.generateConversionRatePointsSteps(loyaltyInfo.conversionSteps);
    return {
      title: 'Εξαργύρωσε ' + loyaltyName + ' πόντους',
      cartPrice: price,
      euroRedemptionRate: 1,
      conversionRates: loyaltyInfo.conversionSteps,
      availablePoints: loyaltyInfo.points,
      maximumPoints: loyaltyInfo.points,
      pointsSteps,
      selectedPoints,
      eurosSteps: this.generateConversionRateEuroSteps(loyaltyInfo.conversionSteps),
      availableStep: this.calculateAvailablePointsIndex(loyaltyInfo.points, pointsSteps)
    } as BankPointsRedeemSlideDialogData;
  }
}
