import { Injectable } from '@angular/core';
import {
  getCouponPrimaryText,
  getCouponPrimaryTextColor,
  getCouponSecondaryText,
  getCouponSecondaryTextColor,
  getCouponDateText,
  getCouponBackgroundColor,
  getCouponLogo
} from '@box/utils';
import { Coupon } from '@box-types';

@Injectable()
export class CouponService {
  public getCouponPrimaryText(coupon: Coupon): string {
    return getCouponPrimaryText(coupon);
  }

  public getCouponPrimaryTextColor(coupon: Coupon): string {
    return getCouponPrimaryTextColor(coupon);
  }

  public getCouponSecondaryText(coupon: Coupon): string {
    return getCouponSecondaryText(coupon);
  }

  public getCouponSecondaryTextColor(coupon: Coupon): string {
    return getCouponSecondaryTextColor(coupon);
  }

  public getCouponDateText(coupon: Coupon): string {
    return getCouponDateText(coupon);
  }

  public getCouponBackgroundColor(coupon: Coupon): string {
    return getCouponBackgroundColor(coupon);
  }

  public getCouponLogo(coupon: Coupon): string {
    const logo = getCouponLogo(coupon);
    if (logo) return `url(${logo})`;
  }

  public getPrimaryTextSize(text: string): string {
    if (text.length > 8) return '18px';
    if (text.length === 7) return '22px';
    if (text.length === 6) return '24px';
    return '32px';
  }
}
