import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Timeslot } from '@box-types';

@Injectable({ providedIn: 'root' })
export class TimeslotsService {
  private readonly timeslotSource = new BehaviorSubject<Timeslot>(undefined);
  public readonly timeslot$ = this.timeslotSource.asObservable();

  private readonly timeslotsSource = new BehaviorSubject<Timeslot[]>(undefined);
  public readonly timeslots$ = this.timeslotsSource.asObservable();

  public getTimeslot(): Timeslot {
    return this.timeslotSource.getValue();
  }

  public setTimeslot(timeslot: Timeslot): void {
    this.timeslotSource.next(timeslot);
  }

  public clearTimeslot(): void {
    this.timeslotSource.next(undefined);
  }

  public getTimeslots(): Timeslot[] {
    return this.timeslotsSource.getValue();
  }

  public setTimeslots(timeslots: Timeslot[]): void {
    this.timeslotsSource.next(timeslots);
  }

  public clearTimeslots(): void {
    this.timeslotsSource.next(undefined);
  }
}
