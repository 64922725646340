import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@box-env/environment';
import {
  Shop,
  Product,
  ShopItems,
  Order,
  ShopTagRelation,
  Ingredient,
  ProductInstance,
  Drink,
  Category,
  PromoCampaign,
  CategoryItems,
  User,
  Offer,
  OfferGroup,
  OfferInstance,
  Coupon,
  ShopSuggestionBanner,
  APIResponse
} from '@box-types';
import {
  decorateShopWithCuisines,
  filterDisplayableShopItems,
  getCartOfferGAConfig,
  getCartProductGAConfig,
  normalizeShopItems,
  decorateProductWithBrand,
  getProductBadgeOptions,
  getProductPromoTagOptions,
  isFavoriteDrink,
  decorateProductWithTags,
  decorateProductWithSuggested,
  decorateProductWithSearchTerms,
  decorateGroupWithSuggestedProduct,
  decorateOfferWithSearchTerms,
  getOfferBadgeOptions,
  getOfferTagOptions,
  isDFYOffer,
  isProductIncludedInPromoCampaignCategory,
  syncProductCartQuantityWithCart,
  syncOfferCartQuantityWithCart,
  getOfferProductPromos,
  getProductPromos,
  isOrderValid,
  shopDefaultDecorator,
  decorateShopWithNewTag
} from '@box/utils';
import { PromoCampaignsService } from './promo-campaigns.service';
import { UserService } from '@box-core/services/user.service';
import { pickBy } from 'lodash-es';
import { CoreService } from './core.service';
import { CartService } from './cart.service';
import { AnalyticsService } from './analytics.service';

import { OrdersService } from './orders.service';
import { ConfigurationService } from '@box-core/services/configuration.service';

const DEFAULT_SHOP_ITEMS_OPTIONS = { categories: true, products: true, offers: true, tags: true };
const PREVIOUS_ORDERS_NUMBER = 20;

@Injectable({ providedIn: 'root' })
export class ShopService {
  private readonly BOX_API: string = environment.application.API_URL;

  public readonly shop = new BehaviorSubject<Shop>(null);
  public readonly tagRelations = new BehaviorSubject<ShopTagRelation[]>([]);
  public readonly menuProducts = new BehaviorSubject<Product[]>([]);
  public readonly menuOffers = new BehaviorSubject<Offer[]>([]);
  public readonly orders = new BehaviorSubject<Order[]>([]);
  public readonly categories = new BehaviorSubject<Category[]>([]);
  public readonly usersFrequentPlates = new BehaviorSubject<string[]>([]);
  public readonly canDeliverToAddress = new BehaviorSubject<boolean>(false);
  public readonly fetchShopItemsCompleted = new BehaviorSubject<boolean>(false);

  private readonly couponsSource = new BehaviorSubject<Coupon[]>([]);
  public readonly coupons$ = this.couponsSource.asObservable();
  private readonly shopSuggestionBannersSource = new BehaviorSubject<ShopSuggestionBanner[]>([]);
  public readonly shopSuggestionBanners$ = this.shopSuggestionBannersSource.asObservable();

  public guid: number;

  constructor(
    private http: HttpClient,
    private promoCampaignsService: PromoCampaignsService,
    private userService: UserService,
    private coreService: CoreService,
    private cartService: CartService,
    private analyticsService: AnalyticsService,
    private ordersService: OrdersService,
    private configService: ConfigurationService
  ) {}

  public getShopItems(shop: Shop): Observable<ShopItems> {
    if (shop.categoryView) return this.fetchCategoriesPreview(shop);
    return this.getItems(shop);
  }

  public getShop(): Shop {
    return this.shop.getValue();
  }

  // todo pats: revert to this when BE is ready
  // public fetchOrders(shopId: string): Observable<Order[]> {
  //   const params: HttpParams = new HttpParams().set('shopId', shopId);
  //   return this.http.get(this.BOX_API + '/orders/get/userOrderForShop/', { params }).pipe(
  //     map((response: APIResponse<{ orders: Order[] }>) => {
  //       const orders = response.payload.orders ?? [];
  //       return orders.filter((order) => isOrderValid(order)).slice(0, PREVIOUS_ORDERS_NUMBER);
  //     })
  //   );
  // }

  public fetchOrders$(shopId: string): Observable<Order[]> {
    return this.ordersService.getOrderHistory$().pipe(
      map((orders) => {
        return (orders ?? [])
          .filter((order) => order.shop._id === shopId && isOrderValid(order))
          .slice(0, PREVIOUS_ORDERS_NUMBER);
      })
    );
  }

  public getItems(shop: Shop): Observable<ShopItems> {
    const { collectionType } = shop;
    const paramsOptions = { ...DEFAULT_SHOP_ITEMS_OPTIONS, collectionType };
    const params: HttpParams = new HttpParams({ fromObject: paramsOptions });
    return this.http.get(this.BOX_API + '/shops/getItems', { params }).pipe(
      map((response: APIResponse<ShopItems>) => {
        const items = response.payload;
        const normalizedItems = normalizeShopItems(items);
        /* This filtering can be here since we do not show any displayable: false product in our view.
        The BE returns items that are displayable: false.
        Since, the mobile team uses the transition displayable: true -> false
        to update their local db */
        return filterDisplayableShopItems(normalizedItems, shop) as ShopItems;
      })
    );
  }

  public fetchCategoriesPreview(shop: Shop): Observable<ShopItems> {
    const supermarketGroup = shop.supermarketGroup;
    const shopId = shop._id;
    const company = shop.integrator?.company;
    const paramsOptions = pickBy({ supermarketGroup, shopId, company });
    const params: HttpParams = new HttpParams({ fromObject: paramsOptions });
    const collectionType = shop.collectionType;
    return this.http.get(`${this.BOX_API}/shops/${collectionType}/categories-offers-preview`, { params }).pipe(
      map((response: APIResponse<ShopItems>) => {
        const items = response.payload;
        const normalizedShopItems = normalizeShopItems(items);
        // the offer's categories are not returned in the categories array for masoutis, but are built upon the products.category obj
        const ignoreCategoryMatching = shop.integrator?.company === 'masoutis';
        return filterDisplayableShopItems(normalizedShopItems, shop, ignoreCategoryMatching) as ShopItems;
      })
    );
  }

  public fetchCategoryItems(
    collectionType: number,
    supermarketGroup?: number,
    categoryId?: string
  ): Observable<CategoryItems> {
    const shop = this.shop.getValue();
    const isOfferCategory = Boolean(categoryId === 'client_offers_category');
    const normalizedCategoryId = isOfferCategory ? 'offers' : categoryId;
    const paramsOptions = pickBy({ supermarketGroup });
    const params: HttpParams = new HttpParams({ fromObject: paramsOptions });
    return this.http.get(`${this.BOX_API}/shops/${collectionType}/category/${normalizedCategoryId}`, { params }).pipe(
      map((response: APIResponse<ShopItems>) => {
        const items = response.payload;
        const normalizedItems = normalizeShopItems(items);
        return filterDisplayableShopItems(normalizedItems, shop, true) as CategoryItems;
      })
    );
  }

  public getDynamicPrice(id: string, instance: ProductInstance): Observable<number> {
    const optionsSelected: Ingredient[] = [];

    if (instance.selections) {
      instance.selections.forEach((selection) => {
        if (!selection.disabledChoices) optionsSelected.push(...selection.choices.filter((choice) => choice.checked));
      });
    }

    const body = { productId: id, optionsSelected };
    return this.http
      .post(this.BOX_API + '/products/custom/price', body)
      .pipe(map((response: APIResponse<{ data: { price: number } }>) => response.payload.data.price));
  }

  public setFetchShopItemsCompleted(value: boolean): void {
    this.fetchShopItemsCompleted.next(value);
  }

  public setShop(shop: Shop): void {
    this.shop.next(shop);
  }

  public setItems(items: ShopItems): void {
    this.menuProducts.next(items.products);
    this.menuOffers.next(items.offers);
    this.tagRelations.next(items.tags);
    this.categories.next(items.categories);
    this.setUsersFrequentPlates(items.usersFrequentPlates);
  }

  public getShopItemsFromMemory(): ShopItems {
    return {
      products: this.menuProducts.getValue(),
      offers: this.menuOffers.getValue(),
      tags: this.tagRelations.getValue(),
      categories: this.categories.getValue(),
      usersFrequentPlates: this.usersFrequentPlates.getValue()
    };
  }

  public setCoupons(coupons: Coupon[]): void {
    this.couponsSource.next(coupons);
  }

  public getCoupons(): Coupon[] {
    return this.couponsSource.getValue();
  }

  public setShopSuggestionBanners(shopSuggestionBanners: ShopSuggestionBanner[]): void {
    this.shopSuggestionBannersSource.next(shopSuggestionBanners);
  }

  public getShopSuggestionBanners(): ShopSuggestionBanner[] {
    return this.shopSuggestionBannersSource.getValue();
  }

  public setTags(tags: ShopTagRelation[]): void {
    this.tagRelations.next(tags);
  }

  public setMenuProducts(products: Product[]): void {
    this.menuProducts.next(products);
  }

  public setMenuOffers(offers: Offer[]): void {
    this.menuOffers.next(offers);
  }

  public setOrders(orders: Order[]): void {
    this.orders.next(orders);
  }

  public getOrders(): Order[] {
    return this.orders.getValue();
  }

  public clearOrders(): void {
    this.orders.next([]);
  }

  public setCategories(categories: Category[]): void {
    this.categories.next(categories);
  }

  public setCanDeliverToAddress(value: boolean): void {
    this.canDeliverToAddress.next(value);
  }

  public getCanDeliverToAddress(): boolean {
    return this.canDeliverToAddress.getValue();
  }

  public setUsersFrequentPlates(usersFrequentPlates: string[]): void {
    this.usersFrequentPlates.next(usersFrequentPlates);
  }

  public getCartProductsAddedFromCheckout(): Product[] {
    const products = this.cartService.getCart().products;
    if (!products?.length) return [];
    return products.filter((p) => p?.cartInstances?.length && p.cartInstances.some((i) => i.addedFromCheckout));
  }

  public getCartOffersAddedFromCheckout(): Offer[] {
    const offers = this.cartService.getCart().offers;
    if (!offers?.length) return [];
    return offers.filter((o) => o?.cartInstances?.length && o.cartInstances.some((i) => i.addedFromCheckout));
  }

  // Move to Cart Service
  public cartProductWithSelectionsExists(product: Product): boolean {
    if (!product?.selections?.length) return false;
    const cartProducts = this.cartService.getCart().products;
    if (!cartProducts?.length) return false;
    return cartProducts.some((cartProduct) => cartProduct._id === product._id);
  }

  // Move to Cart Service
  public cartOfferExists(offer: Offer): boolean {
    if (!offer) return false;
    const cartOffers = this.cartService.getCart().offers;
    if (!cartOffers?.length) return false;
    return cartOffers.some((cartOffer) => cartOffer?._id === offer?._id);
  }

  // Move to Cart Service
  public cartHasDFYOffer(): boolean {
    const cartOffers = this.cartService.getCart().offers;
    if (!cartOffers?.length) return false;
    return cartOffers.some((offer) => offer.couponType === 'dfu');
  }

  public clearMenuItemsQuantities(): void {
    this.resetMenuProductsQuantity();
    this.resetMenuOffersQuantity();
  }

  public syncCartProductToMenu(product: Product): void {
    const menuProducts = this.menuProducts.getValue();
    const menuProductIndex = menuProducts.findIndex((p) => p._id === product._id);
    if (menuProductIndex === -1) return;
    const cart = this.cartService.getCart();
    const menuProduct = menuProducts[menuProductIndex];
    const syncedMenuProduct = syncProductCartQuantityWithCart(menuProduct, cart);
    menuProducts[menuProductIndex] = syncedMenuProduct;
    this.setMenuProducts(menuProducts);
  }

  public syncCartOfferToMenu(offer: Offer): void {
    const menuOffers = this.menuOffers.getValue();
    const menuOfferIndex = menuOffers.findIndex((o) => o._id === offer._id);
    if (menuOfferIndex === -1) return;
    const cart = this.cartService.getCart();
    const menuOffer = menuOffers[menuOfferIndex];
    const syncedMenuOffer = syncOfferCartQuantityWithCart(menuOffer, cart);
    menuOffers[menuOfferIndex] = syncedMenuOffer;
    this.setMenuOffers(menuOffers);
  }

  public syncCartToMenuState(): void {
    const cart = this.cartService.getCart();
    const menuProducts = this.menuProducts.getValue();
    const menuOffers = this.menuOffers.getValue();
    const syncedProducts = menuProducts.map((product) => syncProductCartQuantityWithCart(product, cart));
    const syncedOffers = menuOffers.map((offer) => syncOfferCartQuantityWithCart(offer, cart));
    this.setMenuProducts(syncedProducts);
    this.setMenuOffers(syncedOffers);
  }

  public resetMenuProductsQuantity(): void {
    const menuProducts = this.menuProducts.getValue().map((product) => {
      delete product.cartQuantity;
      delete product.cartInstances;
      return product;
    });
    this.setMenuProducts(menuProducts);
  }

  private resetMenuOffersQuantity(): void {
    const menuOffers = this.menuOffers.getValue().map((offer) => {
      delete offer.cartQuantity;
      delete offer.cartInstances;
      return offer;
    });
    this.setMenuOffers(menuOffers);
  }

  public clearServiceStateWhenGoingToDifferentShop(shop: Shop): void {
    if (shop?._id === this.shop.getValue()?._id) return;
    this.setMenuProducts([]);
    this.setMenuOffers([]);
    this.setTags([]);
    this.setCategories([]);
    this.setUsersFrequentPlates([]);
  }

  // Coca Cola Days
  // Move to Cart Service/Utilities
  public getCartDrinks(drinks: Drink[]): Drink[] {
    const cartProducts: Product[] = this.cartService.getCart().products;
    const cartOffers: Offer[] = this.cartService.getCart().offers;
    const cartProductDrinks: string[] = cartProducts.filter((p) => Boolean(p?.drinkId)).map((p) => p.drinkId);
    const cartOfferProductDrinks: string[] = cartOffers
      .flatMap((o) => o.groups.flatMap((g) => g.products))
      .filter((p) => Boolean(p?.drinkId))
      .map((p) => p.drinkId);
    const drinkIDs: string[] = Array.from(new Set([...cartProductDrinks, ...cartOfferProductDrinks]));
    return drinks.filter((d) => drinkIDs.includes(d._id));
  }

  public getShopDrinks(drinks: Drink[]): Drink[] {
    const products: Product[] = this.menuProducts.getValue();
    const productDrinks: string[] = products.filter((p) => Boolean(p?.drinkId)).map((p) => p.drinkId);
    const offers: Offer[] = this.menuOffers.getValue();
    const offerProductDrinks: string[] = offers
      .flatMap((o) => o.groups.flatMap((g) => g.products))
      .filter((p) => Boolean(p?.drinkId))
      .map((p) => p.drinkId);
    const drinkIDs: string[] = Array.from(new Set([...productDrinks, ...offerProductDrinks]));
    return drinks.filter((d) => drinkIDs.includes(d._id));
  }

  public shopPromoDecorator(shop: Shop): Shop {
    const defaultShop = shopDefaultDecorator(shop);
    const newTagDaysLimit = this.configService.getConfiguration()?.shops?.newTagDaysLimit;
    const shopWithNewTag = decorateShopWithNewTag(defaultShop, newTagDaysLimit);
    const cuisines = this.coreService.cuisines.getValue();
    const shopWithCuisines = decorateShopWithCuisines(shopWithNewTag, cuisines);
    return this.promoCampaignsService.decorateShopWithPromos(shopWithCuisines);
  }

  // Move to Cart Service/Utilities
  public cartHasProductsThatBelongToCategory(promoCampaign: PromoCampaign): boolean {
    if (!promoCampaign?.cartSuggestion?.cuisines?.length) return false;
    const cartProducts = this.cartService.getCart().products;
    if (!cartProducts?.length) return false;
    const combatibleProducts = cartProducts.filter((p) => isProductIncludedInPromoCampaignCategory(promoCampaign, p));
    if (!combatibleProducts.length) return false;
    return true;
  }

  // PRODUCT AND OFFER DECORATION

  /*
  The Decorators below should be ultimately transfered to Product Utils or Service.
  For now, we have to keep using them from shop.service due to the state of the
  Product/Offers Code. On the Cart and Products/Offers Refactore, this will be
  revisited.
  */

  public decorateProduct(product: Product, suggestedProductsIds?: string[], isInsideOffer?: boolean): Product {
    const user = this.userService.getUser();
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const brands = this.coreService.brands.getValue();
    const tags = this.coreService.tags.getValue(); // needed in discover
    const tagRelations = this.tagRelations.getValue();
    const productWithOfferInfo = { ...product, isInsideOffer };
    const productWithSearchTerms = decorateProductWithSearchTerms(productWithOfferInfo);
    const productWithFavouriteDrink = this.decorateProductWithFavouriteDrink(productWithSearchTerms, user);
    const productWithBrand = decorateProductWithBrand(productWithFavouriteDrink, brands);
    const productWithPromos = this.decorateProductWithPromos(productWithBrand, promoCampaigns);
    const productWithSuggested = decorateProductWithSuggested(productWithPromos, suggestedProductsIds);
    const productWithBadgeAndTagOptions = this.decorateProductWithBadgeAndTagOptions(productWithSuggested);
    return decorateProductWithTags(productWithBadgeAndTagOptions, tagRelations, tags);
  }

  public decorateProductWithFavouriteDrink(product: Product, user: User): Product {
    const userDrinks = user.relations.drinks;
    const favouriteDrink = isFavoriteDrink(product, userDrinks);
    return { ...product, favouriteDrink };
  }

  public decorateProductWithPromos(product: Product, promoCampaigns: PromoCampaign[]): Product {
    const promos = getProductPromos(product, promoCampaigns);
    return { ...product, promos };
  }

  public decorateProductWithBadgeAndTagOptions(product: Product): Product {
    const badgeOptions = getProductBadgeOptions(product);
    const tagOptions = getProductPromoTagOptions(product);
    return { ...product, badgeOptions, tagOptions };
  }

  public offerDecorator(offer: Offer, suggestedProductsIds?: string[]): Offer {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    const promos = getOfferProductPromos(offer, promoCampaigns);
    const groups = offer.groups?.map((group) => {
      const decoratedProducts = group.products.map((product) =>
        this.decorateProduct(product, suggestedProductsIds, true)
      );
      const groupWithDecoratedProducts = { ...group, products: decoratedProducts } as OfferGroup;
      if (!suggestedProductsIds?.length) return groupWithDecoratedProducts;
      return decorateGroupWithSuggestedProduct(groupWithDecoratedProducts, suggestedProductsIds);
    });
    const offerWithSearchTerms = decorateOfferWithSearchTerms(offer);

    return {
      ...offerWithSearchTerms,
      groups,
      promos,
      isDFY: isDFYOffer(offer),
      badgeOptions: getOfferBadgeOptions(offer, promos),
      tagOptions: getOfferTagOptions(offer, promos)
    };
  }

  public addToCartProductAnalyticsEvent(
    product: Product,
    productInstance: ProductInstance,
    itemListName: string
  ): void {
    const shop = this.shop.getValue();
    const cuisines = this.coreService.cuisines.getValue();
    const cuisinesDecoratedShop = decorateShopWithCuisines(shop, cuisines);
    const gaConfig = getCartProductGAConfig(product, productInstance, cuisinesDecoratedShop, itemListName);
    this.analyticsService.addGAEcommerceEvent('add_to_cart', gaConfig);
    this.analyticsService.addMPEvent('AddToCart');
  }

  public addToCartOfferAnalyticsEvent(offer: Offer, offerInstance: OfferInstance, itemListName: string): void {
    const shop = this.shop.getValue();
    const cuisines = this.coreService.cuisines.getValue();
    const cuisinesDecoratedShop = decorateShopWithCuisines(shop, cuisines);
    const gaConfig = getCartOfferGAConfig(offer, offerInstance, cuisinesDecoratedShop, itemListName);
    this.analyticsService.addGAEcommerceEvent('add_to_cart', gaConfig);
    this.analyticsService.addMPEvent('AddToCart');
  }
}
