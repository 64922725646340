import dayjs from 'dayjs';
import groupBy from 'lodash-es/groupBy';
import { Timeslot, DeliveryDate, Shop, TimeslotStatus } from '@box-types';
import { dateToCalendar } from './core';

export function timeslotsExactMatch(timeslotA: Timeslot, timeslotB: Timeslot): boolean {
  if (!timeslotA || !timeslotB) return false;
  return timeslotA.timeSlotStart === timeslotB.timeSlotStart && timeslotA.timeSlotEnd === timeslotB.timeSlotEnd;
}

export function timeslotsInclusiveMatch(shopTimeslot: Timeslot, selectedMatch: Timeslot): boolean {
  if (!shopTimeslot || !selectedMatch) return false;
  return (
    shopTimeslot.timeSlotStart <= selectedMatch.timeSlotStart && selectedMatch.timeSlotEnd <= shopTimeslot.timeSlotEnd
  );
}

export function generateTimeslots(period: number, days: number, minutesToSkip = 0): Timeslot[] {
  if (!period || !days) return [];
  const timeslots: Timeslot[] = [];
  const today: dayjs.Dayjs = dayjs();
  const nextTimeslotMinutes: number = Math.abs((today.minute() % period) - period);
  let currentStartingDate: dayjs.Dayjs = today.startOf('minute').add(minutesToSkip + nextTimeslotMinutes, 'minute');

  for (let i = 0; i < days; i++) {
    const startingDay = currentStartingDate.day();
    do {
      timeslots.push({
        timeSlotStart: currentStartingDate.unix(),
        timeSlotEnd: currentStartingDate.add(period, 'minute').unix(),
        isAvailable: true
      });
      currentStartingDate = dayjs(currentStartingDate).add(period, 'minute');
    } while (currentStartingDate.day() === startingDay);
  }

  return timeslots;
}

export function getDeliveryDates(timeslots: Timeslot[]): DeliveryDate[] {
  const timeslotsPerDate = groupBy(timeslots, (t) => dayjs.unix(t.timeSlotStart).format('YYYY-MM-DD'));
  const keys: string[] = Object.keys(timeslotsPerDate);
  return [
    ...keys.map((key) => {
      return {
        date: key,
        title: dateToCalendar(dayjs(key).toDate()),
        timeslots: timeslotsPerDate[key],
        timeslot: timeslotsPerDate[key][0]
      };
    })
  ];
}

export function getClosestTimeslot(previousTimeslot: Timeslot, timeslots: Timeslot[]): Timeslot {
  const previousDate: dayjs.Dayjs = dayjs.unix(previousTimeslot && previousTimeslot.timeSlotStart);
  const previousMinutes: number = previousDate.hour() * 60 + previousDate.minute();
  const timeslotsDiff = timeslots.map((timeslot) => {
    const currentDate: dayjs.Dayjs = dayjs.unix(timeslot.timeSlotStart);
    const currentMinutes: number = currentDate.hour() * 60 + currentDate.minute();
    const diff: number = Math.abs(currentMinutes - previousMinutes);
    return { timeslot, diff };
  });
  return timeslotsDiff.sort((a, b) => a.diff - b.diff)[0].timeslot;
}

export function getNextTimeslot(timeslots: Timeslot[], previousTimeslot?: Timeslot): Timeslot {
  if (!timeslots || timeslots.length === 0) return undefined;
  const previousDate = previousTimeslot ? dayjs.unix(previousTimeslot.timeSlotStart) : dayjs();
  const timeslotsDiff = timeslots.map((timeslot) => {
    const currentDate: dayjs.Dayjs = dayjs.unix(timeslot.timeSlotStart);
    const diff: number = currentDate.diff(previousDate);
    return { timeslot, diff };
  });
  return timeslotsDiff.filter((t) => t.diff > 0).sort((a, b) => a.diff - b.diff)[0].timeslot;
}

export function timeslotToDate(timeslot: Timeslot): Date {
  return dayjs.unix(timeslot.timeSlotStart).add(15, 'minute').toDate();
}

export function timeslotToText(timeslot?: Timeslot): string {
  if (!timeslot) return 'Το συντομότερο';
  const timeslotStartText = dayjs.unix(timeslot.timeSlotStart).format('ddd HH:mm');
  const timeslotEndText = dayjs.unix(timeslot.timeSlotEnd).format('HH:mm');
  return timeslotStartText + ' - ' + timeslotEndText;
}

export function getOnlyFutureTimeslotsStarting(beginTimeslot: Timeslot, timeslots: Timeslot[]): Timeslot[] {
  // includes beginTimeslot
  if (!timeslots) return [];
  if (!beginTimeslot) return timeslots;
  return timeslots.filter((slot) => beginTimeslot.timeSlotStart <= slot.timeSlotStart);
}

export function getTimeslotsStatus(shop: Shop, selectedTimeslot?: Timeslot): TimeslotStatus {
  const shopTimeslots = shop?.timeslots ?? [];
  const availableShopTimeslots = shopTimeslots.filter((timeslot) => timeslot.isAvailable);
  if (!selectedTimeslot && shop.isSuperMarket && shop.hasTimeSlots && !availableShopTimeslots?.length) {
    // https://www.notion.so/desquared/SM-timetable-bug-9f511c472521452e933143bc525b9051?pvs=4
    return { code: 'TIMESLOTS_UNAVAILABLE' };
  }
  if (!selectedTimeslot) return { code: 'ASAP' };
  if (!shop.hasTimeSlots || !shopTimeslots.length) return { code: 'NO_TIMESLOTS' };

  const shopSelectedTimeslotExactMatch: Timeslot = shopTimeslots.find((shopTimeslot) =>
    timeslotsExactMatch(shopTimeslot, selectedTimeslot)
  );
  if (shopSelectedTimeslotExactMatch?.isAvailable) {
    return {
      code: 'TIMESLOT_AVAILABLE'
    };
  }

  const shopSelectedTimeslotInclusiveMatch: Timeslot = shopTimeslots.find((shopTimeslot) =>
    timeslotsInclusiveMatch(shopTimeslot, selectedTimeslot)
  );
  if (shopSelectedTimeslotInclusiveMatch?.isAvailable) {
    return {
      code: 'TIMESLOT_INCLUDED',
      nextAvailableTimeslot: shopSelectedTimeslotInclusiveMatch
    };
  }

  const futureAvailableTimeslot = getOnlyFutureTimeslotsStarting(selectedTimeslot, shopTimeslots).find(
    (slot) => slot.isAvailable
  );
  if (futureAvailableTimeslot) {
    return { code: 'FUTURE_TIMESLOT_AVAILABLE', nextAvailableTimeslot: futureAvailableTimeslot };
  }

  return { code: 'TIMESLOTS_UNAVAILABLE' };
}
