<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="Πες μας τη γνώμη σου!" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <div class="review-dialog-container" [ngSwitch]="state">
      <div class="review-dialog-wrapper">
        <ng-container *ngSwitchCase="'PRE_SUBMIT'">
          <div class="review-dialog-question">
            <span class="review-dialog-question-title ods-typography-body-s-bold">Πώς ήταν η παραγγελία σου;</span>
            <rating
              class="review-dialog-question-rating"
              size="32"
              [rating]="rating"
              (changeRating)="onChangeRating($event)"
            ></rating>
          </div>

          <div *ngIf="showDeliveryRating" class="review-dialog-question">
            <span class="review-dialog-question-title ods-typography-body-s-bold">{{ deliveryRatingTitle }}</span>
            <rating
              class="review-dialog-question-rating"
              size="32"
              [rating]="deliveryRating"
              (changeRating)="onChangeDeliveryRating($event)"
            ></rating>
          </div>

          <div class="review-dialog-points" *ngIf="claimablePoints" [class.last-item]="!rating">
            <span class="ods-typography-body-s-regular">Πες μας τη γνώμη σου και κέρδισε </span>
            <points-tag class="review-dialog-points-tag" sign="+">{{ claimablePoints | number: '1.0-0' }}</points-tag>
            <span class="ods-typography-body-s-regular"> πόντους!</span>
          </div>

          <ng-container *ngIf="rating">
            <textarea
              class="review-dialog-textarea ods-typography-body-s-regular"
              maxlength="700"
              [(ngModel)]="comment"
              [placeholder]="placeholder"
            ></textarea>

            <div class="review-dialog-recommendations" *ngIf="isOrderReviewPositive && recommendations?.length">
              <div class="review-dialog-recommendations-title ods-typography-body-s-bold">
                {{ recommendationsTitle }}
              </div>
              <mat-checkbox
                class="review-dialog-recommendation"
                *ngFor="let recommendation of recommendations"
                color="primary"
                [(ngModel)]="recommendation.checked"
              >
                <span class="review-dialog-recommendation-content ods-typography-body-s-regular">{{
                  recommendation.productName
                }}</span>
              </mat-checkbox>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'POST_SUBMIT'">
          <div class="review-dialog-thank-you">
            <rating class="review-dialog-thank-you-rating" size="32" [rating]="rating" [disabled]="true"></rating>
            <div class="review-dialog-thank-you-title ods-typography-body-s-bold">Ευχαριστούμε για το tip σου!</div>
            <div class="review-dialog-points last-item" *ngIf="claimablePoints">
              <span class="review-dialog-points-text">Μόλις κέρδισες </span>
              <points-tag class="review-dialog-points-tag" sign="+">{{ claimablePoints | number: '1.0-0' }}</points-tag>
              <span class="review-dialog-points-text"> πόντους!</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </main>

  <footer #dialogFooter class="box-dialog-footer review-dialog-footer" [ngSwitch]="state">
    <div class="review-dialog-footer-container">
      <button
        *ngSwitchCase="'PRE_SUBMIT'"
        box-button
        theme="primary"
        class="review-dialog-footer-button"
        (click)="onSubmit()"
        [disabled]="!canSubmit"
      >
        Υποβολή
      </button>

      <ng-container *ngSwitchCase="'POST_SUBMIT'">
        <button box-button theme="primary" class="review-dialog-footer-button" (click)="closeDialog()">
          Επιστροφή
        </button>
      </ng-container>
    </div>
  </footer>
</div>
