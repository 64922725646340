import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'skeleton-shop-page-reviews',
  templateUrl: './skeleton-shop-page-reviews.component.html',
  styleUrls: ['./skeleton-shop-page-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonShopPageReviewsComponent {
  @HostBinding('class') public hostClass = 'skeleton-shop-page-reviews';
}
