import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoxShellComponent } from '@box-core/components';
import * as Guards from '@box-core/guards';
import * as Pages from '@box-core/pages';
import * as Resolvers from '@box-core/resolvers';

const routes: Routes = [
  {
    path: '',
    resolve: { maintenance: Resolvers.ConfigurationResolver },
    canActivate: [Guards.ThemeGuard],
    children: [
      {
        path: '',
        children: [
          { path: '', loadChildren: () => import('@box-pages/pages.module').then((m) => m.PagesModule) },
          {
            path: '',
            component: BoxShellComponent,
            children: [
              { path: '', loadChildren: () => import('@box-legal/legal.module').then((m) => m.LegalModule) },
              {
                path: 'home',
                component: Pages.HomePage,
                canActivate: [Guards.AddressGuard],
                runGuardsAndResolvers: 'always',
                data: { name: 'Home Page' }
              },
              { path: 'promos', redirectTo: 'home' },
              { path: 'shops/:oldVanityUrl', children: [], canActivate: [Guards.ShopRedirectGuard] },
              { path: 'shops/:oldVanityUrl/details', children: [], canActivate: [Guards.ShopRedirectGuard] },
              { path: 'shops/:oldVanityUrl/reviews', children: [], canActivate: [Guards.ShopRedirectGuard] },
              {
                path: 'delivery',
                loadChildren: () => import('@box-delivery/delivery.module').then((m) => m.DeliveryModule),
                data: { name: 'Delivery', breadcrumbName: 'Delivery' }
              },
              {
                path: 'contests',
                loadChildren: () => import('@box-contest/contest.module').then((m) => m.ContestModule)
              },
              {
                path: 'discover',
                loadChildren: () => import('@box-discover/discover.module').then((m) => m.DiscoverModule),
                data: { name: 'Discovery', breadcrumbName: 'Ανακάλυψε' },
                canActivate: [Guards.AddressGuard]
              },
              {
                path: 'rewards',
                data: { name: 'Rewards', breadcrumbName: 'Πόντοι' },
                loadChildren: () => import('@box-rewards/rewards.module').then((m) => m.RewardsModule)
              },
              {
                path: 'account',
                loadChildren: () => import('@box-account/account.module').then((m) => m.AccountModule),
                data: { name: 'Account', breadcrumbName: 'Λογαριασμός' },
                canActivate: [Guards.AuthGuard]
              },
              {
                path: 'checkout',
                loadChildren: () => import('@box-checkout/checkout.module').then((m) => m.CheckoutModule),
                canActivate: [Guards.AuthGuard]
              },
              {
                path: 'payment',
                children: [],
                canActivate: [Guards.AuthGuard],
                resolve: { payment: Resolvers.PaymentResolver },
                data: { name: 'Payment Page' }
              },
              {
                path: 'chains/closest-shop/:chainKey',
                children: [
                  {
                    path: '',
                    children: [],
                    resolve: { shop: Resolvers.ShopWithChainResolver }
                  }
                ],
                canActivate: [Guards.AddressGuard]
              }
            ],
            resolve: {
              campaigns: Resolvers.LoyaltyResolver,
              promoCampaigns: Resolvers.PromoCampaignsResolver,
              addresses: Resolvers.AddressesResolver
            }
          }
        ],
        resolve: {
          user: Resolvers.UserResolver,
          coreItems: Resolvers.CoreResolver
        }
      },
      {
        path: 'login',
        children: [
          {
            path: '',
            children: [],
            resolve: { payment: Resolvers.LoginResolver },
            data: { name: 'Login Page', breadcrumbName: 'Σύνδεση' }
          },
          {
            path: 'redirect',
            children: [],
            resolve: { payment: Resolvers.RedirectResolver },
            data: { name: 'Login Redirect Page' }
          }
        ]
      },
      { path: 'logout', children: [], resolve: { payment: Resolvers.LogoutResolver }, data: { name: 'Logout Page' } },
      { path: '**', component: Pages.FourOhFourPage, data: { name: '404 Page' } }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class BoxRoutingModule {}
