import { Injectable } from '@angular/core';
import { DeliveryMethodService, ShopService } from '@box-core/services';
import { Shop, TimetableDayDetails, BoxTheme } from '@box-types';
import {
  generateGoogleMapsDirectionsUrl,
  getAddressDescription,
  formatPhoneToITU,
  getShopDeliveryFeeText,
  getShopMinimumOrderPriceText,
  getTimetableDetails,
  getStaticMapUrl,
  generateImageSrc
} from '@box/utils';

@Injectable()
export class ShopPageDetailsService {
  constructor(private shopService: ShopService, private deliveryMethodService: DeliveryMethodService) {}

  public getShop(): Shop {
    return this.shopService.getShop();
  }

  public getShopLogoUrl(): string {
    const shop = this.shopService.getShop();
    return generateImageSrc(shop.logo);
  }

  public generateMapBackgroundImage(theme: BoxTheme): string {
    const shop = this.shopService.getShop();
    const { latitude, longitude } = shop.address;
    return getStaticMapUrl(latitude, longitude, theme);
  }

  public generatePaymentIcons(): string[] {
    const shop = this.shopService.getShop();
    const icons: string[] = [];
    if (shop.card) icons.push('/assets/images/shops/payment-methods/card.svg');
    if (shop.cash) icons.push('/assets/images/shops/payment-methods/cash.svg');
    return icons;
  }

  public getShopMinimumOrderPriceText(): string {
    const shop = this.shopService.getShop();
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const canDeliverToAddress = this.shopService.canDeliverToAddress.getValue();
    return getShopMinimumOrderPriceText(shop, deliveryMethod, canDeliverToAddress);
  }

  public getShopDeliveryFeeText(): string {
    const shop = this.shopService.getShop();
    if (!shop.deliveryFees.length) return;
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    return getShopDeliveryFeeText(shop, deliveryMethod);
  }

  public getAddressText() {
    const shop = this.shopService.getShop();
    return getAddressDescription(shop.address);
  }

  public getAddressAnchor() {
    const shop = this.shopService.getShop();
    return generateGoogleMapsDirectionsUrl(shop.address);
  }

  public getPhoneNumber() {
    const shop = this.shopService.getShop();
    return formatPhoneToITU(shop.phone);
  }

  public getTimetableDayDetails(): TimetableDayDetails[] {
    const shop = this.shopService.getShop();
    const timetableDetails = getTimetableDetails(shop.timetableV2);
    if (!timetableDetails.length) return [];
    return [...timetableDetails.slice(1), timetableDetails[0]]; // Bring sunday last on the list;
  }
}
