<div class="bank-redeem-alert-wrapper">
  <button [style.color]="contentColor" class="back-button" mat-icon-button (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <section class="bank-redeem-alert-top-section" [style.background-color]="backgroundColor">
    <div class="bank-redeem-alert-container">
      <figure class="image contain-image loyalty-product-image">
        <img [src]="imageUrl" [alt]="title" />
      </figure>
      <span class="bank-redeem-alert-text" [style.color]="contentColor">
        {{ title }}
      </span>
    </div>
  </section>
  <section class="bank-redeem-alert-bottom-section">
    <div class="bank-redeem-alert-container">
      <span class="bank-redeem-alert-text ods-typography-body-s-regular">{{ description }}</span>
      <button class="verify-button" box-button theme="primary" (click)="onVerifySelect()">Επιβεβαίωση στοιχείων</button>
      <button class="later-button" box-button theme="insignificant" (click)="onClose()">Αργότερα</button>
    </div>
  </section>
</div>
