import { Injectable } from '@angular/core';
import { APIError } from '@box-types';
import {
  init,
  withScope,
  captureException,
  SeverityLevel,
  BrowserTracing,
  routingInstrumentation
} from '@sentry/angular-ivy';
import { environment } from '@box-env/environment';

interface BOXSentryCaptureExceptionOptions {
  domain?: string;
  domainDetails?: string;
  severity?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
}

@Injectable({ providedIn: 'root' })
export class SentryService {
  public init(): void {
    init({
      dsn: environment.application.SENTRY_DSN,
      environment: environment.code,
      release: `box-web@${String(environment.application.VERSION)}`,
      integrations: [new BrowserTracing({ routingInstrumentation })],
      tracesSampleRate: 0.05,
      ignoreErrors: ['Error: Non-Error exception captured with keys: error, headers, message, name, ok']
    });
  }

  public captureException(error: Error | APIError, options?: BOXSentryCaptureExceptionOptions): void {
    withScope(function (scope) {
      if (options?.domain) scope.setTag('box.domain', options.domain);
      if (options?.domainDetails) scope.setTag('box.domain.details', options.domainDetails);
      if (options?.severity) scope.setLevel(options.severity as SeverityLevel);
      captureException(error);
    });
  }
}
