import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SentryService, ShopsService } from '@box-core/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShopRedirectGuard implements CanActivate {
  /*
  This is a redirection guard to handle the old Shop Page url
  redirection after the SEO changes.
  The new Shop Page URL is /delivery/:locationKey/:vanityUrl
  */
  constructor(private router: Router, private shopsService: ShopsService, private sentryService: SentryService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const oldVanityUrl = route.params.oldVanityUrl as string;
    return this.shopsService.fetchShopByOldVanityUrl(oldVanityUrl).pipe(
      map((shop) => {
        const { locationKey, vanity_url } = shop;
        if (!locationKey || !vanity_url) throw new Error('Missing Shop Redirection Data');
        const url = route.url;
        if (url.length === 3) void this.router.navigate(['/delivery', locationKey, vanity_url, url[2].path]);
        if (url.length === 2) void this.router.navigate(['/delivery', locationKey, vanity_url]);
        if (url.length > 3) void this.router.navigate(['/delivery', locationKey, vanity_url]);
        return false;
      }),
      catchError((error: Error): Observable<boolean> => {
        this.sentryService.captureException(error, {
          domain: 'Delivery',
          domainDetails: 'Shop Redirect Guard',
          severity: 'error'
        });
        void this.router.navigate(['/home']);
        return of(false);
      })
    );
  }
}
