<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header
      class="box-terms-dialog-header"
      [title]="title"
      alignment="center"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody class="box-dialog-content">
    <component-loader *ngIf="loading; else content"></component-loader>
    <ng-template #content>
      <div class="box-terms-dialog-content">
        <div class="legal-documents" [innerHTML]="termsHtml | safeHtml"></div>
      </div>
    </ng-template>
  </main>
</div>
