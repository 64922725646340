<mat-icon fontSet="material-icons-round" class="search-input-icon">search</mat-icon>
<input
  #input
  type="text"
  name="search"
  class="search-input ods-typography-body-s-bold"
  autocomplete="off"
  [(ngModel)]="searchTerm"
  [placeholder]="placeholder"
  debounceInput
  [debounceTime]="debounceTime"
  (debounceInput)="search($event)"
  (focus)="focus()"
  (blur)="blur()"
  (keydown.escape)="escape()"
  (keydown.enter)="onKeydownEnter($event)"
/>
<button matSuffix class="search-input-button" *ngIf="searchTerm" mat-icon-button (click)="clearValue()">
  <mat-icon class="search-input-icon">clear</mat-icon>
</button>
