import { Injectable } from '@angular/core';
import {
  ConfirmDialogResponse,
  InfoDialogData,
  Product,
  ProductInstance,
  ShopSuggestionBanner,
  Offer,
  OfferInstance
} from '@box-types';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  OfferWizardDialogComponent,
  ProductMYODialogComponent,
  SameMYODialogComponent,
  ShopSuggestionDialogComponent,
  UnavailableItemsDialogComponent
} from '@box-delivery/components';
import { AnalyticsService, CartService, DialogService, ShopService } from '@box-core/services';
import { Location } from '@angular/common';
import { BoxConfirmDialogComponent, BoxInfoDialogComponent } from '@box-shared/components';
import { SameMYODialogResponse } from '@box-delivery/components/same-myo-dialog/same-myo-dialog.types';
import { createOfferInstance, createProductInstance, getCartOfferGAConfig, getCartProductGAConfig } from '@box/utils';

@Injectable()
export class ShopMenuDialogService {
  constructor(
    private dialogService: DialogService,
    private location: Location,
    private shopService: ShopService,
    private cartService: CartService,
    private analyticsService: AnalyticsService
  ) {}

  public openSameMYODialog(item: Product | Offer, type: string): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: { item, type }
    };

    this.dialogService
      .openDialog(SameMYODialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: SameMYODialogResponse) => {
        if (!data?.openMYO) return;
        if (type === 'offer') this.openOfferWizard(item as Offer);
        if (type === 'product') this.openProductMYO(item as Product);
      });
  }

  public openProductMYO(product: Product): void {
    const shop = this.shopService.getShop();
    const productInstance = createProductInstance(product);
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: {
        product,
        productInstance: productInstance,
        shop: shop
      }
    };

    this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`, `productId=${product._id}`);
    const dialogRef = this.dialogService.openDialog(ProductMYODialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(() => this.triggerAnalyticsProductEvent(product, productInstance));
    dialogRef
      .afterClosed()
      .subscribe(() => this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`));
  }

  public openOfferWizard(offer: Offer): void {
    const shop = this.shopService.getShop();
    const offerInstance = createOfferInstance(offer);
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'box-dialog',
      data: { offer, offerInstance: offerInstance, shop: shop }
    };

    this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`, `offerId=${offer._id}`);
    const dialogRef = this.dialogService.openDialog(OfferWizardDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(() => this.triggerAnalyticsOfferEvent(offer, offerInstance));
    dialogRef
      .afterClosed()
      .subscribe(() => this.location.replaceState(`/delivery/${shop.locationKey}/${shop.vanity_url}`));
  }

  public openDFYWarningDialog(offer: Offer): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false,
      restoreFocus: false,
      data: {
        messages: [
          'Μπορείς να προσθέσεις μόνο μία προσφορά DFY ανά παραγγελία.',
          'Εάν θέλεις να συνεχίσεις με την προσθήκη άλλης DFY προσφοράς, θα αφαιρεθεί αυτόματα από το καλάθι σου αυτή που υπάρχει ήδη.'
        ],
        confirmText: 'OK',
        cancelText: 'Άκυρο'
      }
    };
    this.dialogService
      .openDialog(BoxConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) return undefined;
        const offers = this.cartService.getCart().offers;
        const cartOffer = offers.find((o) => o._id === offer._id);
        const instance = cartOffer.cartInstances[0];
        const cartResponse = this.cartService.removeOffer(offer, instance);
        if (cartResponse === 'OFFER_REMOVED') this.shopService.syncCartOfferToMenu(offer);
      });
  }

  public openSuggestionDialog(suggestionBanner: ShopSuggestionBanner): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      restoreFocus: false,
      autoFocus: false,
      data: { suggestionBanner }
    };
    this.dialogService.openDialog(ShopSuggestionDialogComponent, dialogConfig);
  }

  public showProductInfoDialog(data: InfoDialogData): void {
    const dialogConfig: MatDialogConfig = { restoreFocus: false, autoFocus: false, data };
    this.dialogService.openDialog(BoxInfoDialogComponent, dialogConfig);
  }

  public showUnavailableItemsDialog(products: Product[]): void {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog-fit-content',
      data: { title: 'Μη διαθέσιμα προϊόντα', products: products }
    };
    this.dialogService.openDialog(UnavailableItemsDialogComponent, dialogConfig);
  }

  private triggerAnalyticsProductEvent(product: Product, productInstance: ProductInstance): void {
    const shop = this.shopService.getShop();
    const categoryName = product.category.name ?? '';
    const gaConfig = getCartProductGAConfig(product, productInstance, shop, categoryName);
    this.analyticsService.addGAEcommerceEvent('view_item', gaConfig);
  }

  private triggerAnalyticsOfferEvent(offer: Offer, offerInstance: OfferInstance): void {
    const shop = this.shopService.getShop();
    const gaConfig = getCartOfferGAConfig(offer, offerInstance, shop, 'Offers');
    this.analyticsService.addGAEcommerceEvent('view_item', gaConfig);
  }
}
