import { Injectable } from '@angular/core';
import { ConfirmDialogResponse, Shop, Timeslot } from '@box-types';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TimeslotDialogComponent } from '@box-shared/components';
import dayjs from 'dayjs';
import { DialogService } from '@box-core/services/dialog.service';
import { Router } from '@angular/router';
import { TimeslotsService } from '@box-core/services/timeslots.service';
import { TimeslotDialogResponse } from '@box-shared/components/timeslot-dialog/timeslot-dialog-response.interface';

@Injectable({ providedIn: 'root' })
export class TimeslotsDialogsService {
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private timeslotsService: TimeslotsService
  ) {}

  public openTimeslotsDialog(shop: Shop, nextAvailableSlot?: Timeslot): MatDialogRef<TimeslotDialogComponent> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'box-dialog',
      data: {
        nextAvailableTimeslot: nextAvailableSlot,
        isSuperMarket: shop?.isSuperMarket
      }
    };

    const dialogRef = this.dialogService.openDialog(TimeslotDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data: TimeslotDialogResponse) => {
      if (!data) return;
      if (data.reset) this.timeslotsService.clearTimeslot();
      if (data.timeslot) this.timeslotsService.setTimeslot(data.timeslot);
    });

    return dialogRef;
  }

  public showCheckoutNoTimeslotSupportDialog(shop: Shop): void {
    this.dialogService
      .openConfirmDialog({
        title: 'Μη διαθέσιμες ώρες παράδοσης',
        messages: ['Προς το παρόν δεν υπάρχουν διαθέσιμες ώρες παράδοσης από το συγκεκριμένο κατάστημα.'],
        confirmText: 'Πίσω στα προϊόντα',
        cancelText: 'Δες άλλα καταστήματα',
        buttonOrientation: 'vertical'
      })
      .afterClosed()
      .subscribe((data: { accepted: boolean }) => {
        if (!data) return undefined;
        if (data.accepted === false) return void this.router.navigate(['/discover']);
        const { locationKey, vanity_url } = shop;
        if (data.accepted === true) return void this.router.navigate(['/delivery', locationKey, vanity_url]);
      });
  }

  public showCheckoutTimeslotUnavailabilityDialog(shop: Shop): void {
    this.dialogService
      .openConfirmDialog({
        messages: [
          'H ώρα που έχεις επιλέξει για προγραμματισμό παραγγελίας δεν είναι διαθέσιμη από το κατάστημα.',
          'Μπορείς να επιλέξεις κάποια άλλη ώρα παράδοσης ή να συνεχίσεις στο μενού.'
        ],
        confirmText: 'OK',
        cancelText: 'Επιλογή ώρας'
      })
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (!data?.accepted) {
          return this.openTimeslotsDialog(shop);
        }
      });
  }

  public showTimeslotsUnavailableDialog(): void {
    this.dialogService
      .openInfoDialog({
        title: 'Το κατάστημα δεν μπορεί να σε εξυπηρετήσει',
        messages: [
          'Λόγω μη διαθέσιμου χρόνου, το κατάστημα δεν μπορεί να σε εξυπηρετήσει. Μπορείς να παραγγείλεις από άλλο κατάστημα.'
        ],
        btnText: 'Δες άλλα καταστήματα'
      })
      .afterClosed()
      .subscribe(() => void this.router.navigate(['/discover']));
  }

  public showNoTimeslotSupportDialog(): void {
    this.dialogService
      .openInfoDialog({
        title: 'Το κατάστημα δεν μπορεί να σε εξυπηρετήσει',
        messages: ['Το κατάστημα δεν εξυπηρετεί προγραμματισμένες παραγγελίες.'],
        btnText: 'Δες άλλα καταστήματα'
      })
      .afterClosed()
      .subscribe(() => void this.router.navigate(['/discover']));
  }

  public showTimeslotIncludedDialog(shop: Shop, availableTimeslot: Timeslot): void {
    const timeslotStartString = dayjs.unix(availableTimeslot.timeSlotStart).format('dddd, στις HH:mm - ');
    const timeslotEndString = dayjs.unix(availableTimeslot.timeSlotEnd).format('HH:mm');
    const dateString = timeslotStartString + timeslotEndString;
    this.dialogService
      .openConfirmDialog({
        title: 'Αλλαγή ώρας προγραμματισμένης παραγγελίας',
        messages: ['Το κατάστημα μπορεί να εξυπηρετήσει προγραμματισμένη παραγγελία', `για την ${dateString}`],
        confirmText: 'Συνέχεια στο κατάστημα',
        cancelText: 'Δες άλλα καταστήματα',
        buttonOrientation: 'vertical'
      })
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (data?.accepted) {
          return this.timeslotsService.setTimeslot(availableTimeslot);
        }
        void this.router.navigate(['/discover']);
      });
  }

  public showFutureTimeslotAvailableDialog(shop: Shop, availableTimeslot: Timeslot): void {
    const timeslotStartString = dayjs.unix(availableTimeslot.timeSlotStart).format('dddd, στις HH:mm - ');
    const timeslotEndString = dayjs.unix(availableTimeslot.timeSlotEnd).format('HH:mm');
    const dateString = timeslotStartString + timeslotEndString;
    this.dialogService
      .openConfirmDialog({
        title: 'Μη διαθέσιμη ώρα παράδοσης',
        messages: [
          'Η ώρα παράδοσης που έχεις επιλέξει δεν είναι διαθέσιμη. Μπορείς να προγραμματίσεις παραγγελία',
          `για την ${dateString}`
        ],
        confirmText: 'Συνέχεια στο κατάστημα',
        cancelText: 'Δες άλλα καταστήματα',
        buttonOrientation: 'vertical'
      })
      .afterClosed()
      .subscribe((data: ConfirmDialogResponse) => {
        if (data?.accepted) {
          return this.timeslotsService.setTimeslot(availableTimeslot);
        }
        void this.router.navigate(['/discover']);
      });
  }
}
