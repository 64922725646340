import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@box-env/environment';
import { AuthenticationService } from '@box-core/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly COSMOTE_ACCOUNT_URL: string = environment.cosmote.ACCOUNT_URL;
  private readonly BOX_API_URL: string = environment.application.API_URL;
  private readonly ORDERS_API_URL: string = environment.application.ORDERS_API_URL;

  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = {} as Record<string, string>;

    if (request.url.includes(this.COSMOTE_ACCOUNT_URL)) {
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
      const token = this.authenticationService.COSMOTE_ACCESS_TOKEN;
      if (token) headers.Authorization = `Bearer ${token}`;
    }

    if (request.url.includes(this.BOX_API_URL) || request.url.includes(this.ORDERS_API_URL)) {
      headers.appId = 'box-web';
      headers['App-Version'] = String(environment.application.VERSION);
      headers['App-Build-Number'] = String(environment.application.BUILD);
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';

      if (!request.url.includes('initiateBoxSession')) {
        const token = this.authenticationService.BOX_TOKEN;
        if (token) headers.Authorization = `Bearer ${token}`;
      }
    }

    return next.handle(request.clone({ setHeaders: headers })).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.status === 401) {
          this.authenticationService.signOut();
          void this.router.navigate(['/']);
        }
        return throwError(() => error);
      })
    );
  }
}
