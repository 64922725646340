import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'checkout-points-slider-data',
  templateUrl: './checkout-points-slider-data.component.html',
  styleUrls: ['./checkout-points-slider-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutPointsSliderDataComponent implements OnChanges {
  @Input() public activeIndex = 0;
  @Input() public steps: string[] = [];
  @Input() public alternativeSteps: string[] = [];
  @HostBinding('class') public hostClass = 'checkout-points-slider-data';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeIndex) this.activeIndex = changes.activeIndex.currentValue as number;
    if (changes.steps) this.steps = changes.steps.currentValue as string[];
    if (changes.alternativeSteps) this.alternativeSteps = changes.alternativeSteps.currentValue as string[];
  }
}
