<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header [title]="title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
    <div class="market-card-connect-dialog-content">
      <section *ngIf="config.canSearch" class="market-card-connect-dialog-section">
        <div class="market-card-connect-dialog-container" [formGroup]="searchFormGroup">
          <ng-container *ngIf="validationMode === 'EMAIL'; else pinotp">
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              Έχεις ήδη κάρτα {{ shopName }}; Πληκτρολόγησε το Email σου για να τη συνδέσεις με το ΒΟΧ και να
              απολαμβάνεις όλα τα προνόμια!
            </p>
          </ng-container>
          <ng-template #pinotp>
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              Έχεις ήδη κάρτα {{ shopName }}; Πληκτρολόγησε τον αριθμό της για να τη συνδέσεις με το ΒΟΧ και να
              απολαμβάνεις όλα τα προνόμια!
            </p>
          </ng-template>

          <div *ngIf="config.canSearch && !config.canCreate" class="market-card-connect-dialog-rewards">
            <div class="market-card-connect-dialog-reward">
              <span class="market-card-connect-dialog-reward-icon">
                <mat-icon fontSet="material-icons-round">check</mat-icon>
              </span>
              <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                Συλλέγεις & εξαργυρώνεις πόντους από τα supermarket {{ shopName }}.
              </span>
            </div>
            <div class="market-card-connect-dialog-reward">
              <span class="market-card-connect-dialog-reward-icon">
                <mat-icon fontSet="material-icons-round">check</mat-icon>
              </span>
              <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                Tη χρησιμοποιείς και στα φυσικά καταστήματα supermarket {{ shopName }}.
              </span>
            </div>
          </div>

          <ng-container *ngIf="validationMode === 'EMAIL'; else pinotpinput">
            <mat-form-field class="market-card-connect-dialog-field">
              <mat-label>e-mail</mat-label>
              <input matInput autocomplete="off" (keydown.enter)="onSearch()" formControlName="searchInputControl" />
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.required"
                >Το πεδίο Email είναι υποχρεωτικό
              </mat-error>
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.pattern">Μη έγκυρο Email</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-template #pinotpinput>
            <mat-form-field class="market-card-connect-dialog-field">
              <mat-label>Αριθμός Κάρτας</mat-label>
              <input matInput autocomplete="off" (keydown.enter)="onSearch()" formControlName="searchInputControl" />
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.required"
                >Το πεδίο Αριθμός Κάρτας είναι υποχρεωτικός
              </mat-error>
              <mat-error *ngIf="searchFormGroup.controls.searchInputControl.errors?.pattern"
                >Το πεδίο Αριθμός Κάρτας δέχεται μόνο αριθμούς
              </mat-error>
            </mat-form-field>
          </ng-template>

          <mat-checkbox
            *ngIf="findCardTermsHTML"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="findCardTermsControl"
          >
            <span
              class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular"
              [innerHTML]="findCardTermsHTML"
            ></span>
          </mat-checkbox>

          <mat-checkbox
            *ngIf="saveCardTermsText"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="saveCardTermsControl"
          >
            <span class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular">{{
              saveCardTermsText
            }}</span>
          </mat-checkbox>

          <button
            box-button
            theme="primary"
            class="market-card-connect-dialog-button"
            [disabled]="searchFormGroup.invalid"
            (click)="onSearch()"
          >
            Αναζήτηση κάρτας
          </button>
        </div>
      </section>
      <hr *ngIf="showSeparator" class="box-hr market-card-connect-dialog-separator" data-title="εναλλακτικά" />
      <section *ngIf="config.canCreate" class="market-card-connect-dialog-section">
        <div class="market-card-connect-dialog-container" [formGroup]="createFormGroup" [ngSwitch]="createMode">
          <ng-container *ngSwitchCase="'normal'">
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              Θα σου δημιουργήσουμε μια νέα κάρτα, για να:
            </p>
            <div class="market-card-connect-dialog-rewards">
              <div class="market-card-connect-dialog-reward">
                <span class="market-card-connect-dialog-reward-icon">
                  <mat-icon fontSet="material-icons-round">check</mat-icon>
                </span>
                <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                  Συλλέγεις & εξαργυρώνεις πόντους από τα supermarket {{ shopName }}.
                </span>
              </div>

              <ng-container [ngSwitch]="isPhysical">
                <ng-container *ngSwitchCase="true">
                  <div class="market-card-connect-dialog-reward">
                    <span class="market-card-connect-dialog-reward-icon">
                      <mat-icon fontSet="material-icons-round">check</mat-icon>
                    </span>
                    <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                      Tη χρησιμοποιείς και στα φυσικά καταστήματα supermarket {{ shopName }}.
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                  <div class="market-card-connect-dialog-reward">
                    <span class="market-card-connect-dialog-reward-icon">
                      <mat-icon fontSet="material-icons-round">check</mat-icon>
                    </span>
                    <span class="market-card-connect-dialog-reward-text ods-typography-body-s-regular">
                      Tη χρησιμοποιείς και στο ηλεκτρονικό καταστήματα supermarket {{ shopName }}.
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'guest'">
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              Δεν έχεις κάρτα {{ shopName }}; Μην ανησυχείς, μπορείς να ολοκληρώσεις την παραγγελία σου κανονικά!
            </p>
            <p class="market-card-connect-dialog-text ods-typography-body-s-regular">
              Εάν αποκτήσεις μια κάρτα στο μέλλον, μπορείς να την εισάγεις αργότερα για να απολαμβάνεις όλα τα προνόμια
              και τις εκπτώσεις από τα Supermarket {{ shopName }}.
            </p>
          </ng-container>

          <mat-checkbox
            *ngIf="firstPersonalDataTermsHTML"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="firstPersonalDataTermsControl"
          >
            <span
              class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular"
              [innerHTML]="firstPersonalDataTermsHTML"
            ></span>
          </mat-checkbox>

          <mat-checkbox
            *ngIf="secondPersonalDataTermsHTML"
            class="market-card-connect-dialog-checkbox"
            color="primary"
            formControlName="secondPersonalDataTermsControl"
          >
            <span
              class="market-card-connect-dialog-checkbox-content ods-typography-body-s-regular"
              [innerHTML]="secondPersonalDataTermsHTML"
            ></span>
          </mat-checkbox>

          <button
            box-button
            theme="primary"
            class="market-card-connect-dialog-button"
            [disabled]="createFormGroup.invalid"
            (click)="onCreate()"
          >
            {{ createButtonText }}
          </button>
        </div>
      </section>
    </div>
  </main>
</div>
