import { ProductReplacementOption } from '../types';

export const productReplacementOptions: { [key: string]: ProductReplacementOption } = {
  phone: {
    name: 'phone',
    label: 'Τηλεφωνική επικοινωνία',
    description: 'Θα σε καλέσουμε για να επιλέξουμε μαζί το προϊόν αντικατάστασης',
    shopProperty: 'phoneProductReplacement'
  },
  smart: {
    name: 'smart',
    label: 'Έξυπνη αντικατάσταση',
    description: 'Θα αντικαταστήσουμε το προϊόν με ένα αντίστοιχης ποιότητας κι αξίας',
    shopProperty: 'smartProductReplacement'
  },
  none: {
    name: 'none',
    label: 'Καμία αλλαγή',
    description: 'Θα αφαιρέσουμε το προϊόν από την παραγγελία σου και το τελικό κόστος',
    shopProperty: 'noProductReplacement'
  }
};
