function currencyFormat(cents: number, options?: CurrencyFormatOptions): string {
  if (isNaN(cents)) return;
  const minimumFractionDigits = options?.minimumFractionDigits ?? 0;
  const maximumFractionDigits = options?.maximumFractionDigits ?? 2;
  const symbolSpace = options?.symbolSpace ?? true;
  const formatOptions = { style: 'currency', currency: 'EUR', minimumFractionDigits, maximumFractionDigits };
  const formattedCurrency = new Intl.NumberFormat('el-GR', formatOptions).format(cents / 100);
  if (symbolSpace) return formattedCurrency;
  return formattedCurrency.replace(/\xa0/g, '');
}

type CurrencyFormatOptions = {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  symbolSpace?: boolean;
};

export { CurrencyFormatOptions, currencyFormat };
