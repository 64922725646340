import { environment } from '@box-env/environment';
import { BusinessVertical } from '@box-types';
import {
  jsonScriptId,
  AggresiveRating,
  BreadcrumbList,
  InitFunctonInterface,
  breadcrumbListId,
  aggresiveRatingId,
  BreadcrumbListItem,
  JsonShopType
} from './seo.types';
import { canShowShopRatings } from '../shops';

export function getJSONLDElement(id: string): HTMLScriptElement {
  return document.querySelector(`#${id}`);
}

export function setJsonLd(id: jsonScriptId, jsonLd: AggresiveRating | BreadcrumbList) {
  const head = document.querySelector('head');
  const scriptTag = document.createElement('script');
  scriptTag.type = 'application/ld+json';
  scriptTag.id = id;
  const context = JSON.stringify(jsonLd);
  scriptTag.textContent = context;
  head.append(scriptTag);
}

export function updateJsonLd(id: jsonScriptId, jsonLd: AggresiveRating | BreadcrumbList) {
  const scriptTag = getJSONLDElement(id);
  const context = JSON.stringify(jsonLd);
  scriptTag.textContent = context;
}

export function removeJsonLdWithId(id: string) {
  const jsonScript = getJSONLDElement(id);
  if (jsonScript != null) {
    jsonScript.remove();
  }
}

export function addOrUpdateJsonLd(id: jsonScriptId, jsonLd: AggresiveRating | BreadcrumbList) {
  if (jsonLd) {
    if (getJSONLDElement(id)) {
      updateJsonLd(id, jsonLd);
    } else {
      setJsonLd(id, jsonLd);
    }
  }
}

export function initJsonLd(jsonLdProps: InitFunctonInterface): void {
  const { breadcrumbs, aggresiveRating } = jsonLdProps;
  addOrUpdateJsonLd(breadcrumbListId, breadcrumbs);
  addOrUpdateJsonLd(aggresiveRatingId, aggresiveRating);
}

export function deleteJsonLd() {
  removeJsonLdWithId(breadcrumbListId);
  removeJsonLdWithId(aggresiveRatingId);
}

export function addBreadCrumbListItem(position: number, name: string, item: string): BreadcrumbListItem {
  return {
    '@type': 'ListItem',
    position,
    name,
    item: `${item}`
  };
}

export function addRootUrlToBreadCrumbsItem(list: BreadcrumbListItem[]): BreadcrumbListItem[] {
  const ROOT_URL: string = environment.application.ROOT_URL;
  const newList: BreadcrumbListItem[] = [];
  let position = 1;
  for (const item of list) {
    newList.push({ ...item, item: `${ROOT_URL}${item.item}`, position });
    position++;
  }
  if (newList[newList.length - 1]?.item) {
    newList[newList.length - 1].item = undefined;
  }

  return newList;
}

function getServesCuisineJsonLd(shopType: JsonShopType, servesCuisine: string): string | undefined {
  if (shopType === 'Restaurant') {
    return servesCuisine;
  }
  return;
}

export function getAggresiveRatingObject(
  reviewCount: number,
  ratingValue: number,
  shopType: JsonShopType,
  image: string,
  name: string,
  servesCuisine: string,
  streetAddress: string,
  addressLocality: string,
  postalCode: string
): AggresiveRating {
  if (!canShowShopRatings(reviewCount)) {
    return;
  }

  return {
    '@context': 'https://schema.org/',
    '@type': 'AggregateRating',
    reviewCount: String(reviewCount) ?? undefined,
    ratingValue: String(ratingValue) ?? undefined,
    bestRating: '5',
    worstRating: '1',
    itemReviewed: {
      '@type': shopType,
      image: image ?? undefined,
      name: name ?? undefined,
      servesCuisine: getServesCuisineJsonLd(shopType, servesCuisine),
      address: {
        '@type': 'PostalAddress',
        streetAddress: streetAddress ?? undefined,
        addressLocality: addressLocality ?? undefined,
        postalCode: postalCode,
        addressCountry: 'Greece'
      }
    }
  };
}

export function getJsonShopType(businessVertical: BusinessVertical): JsonShopType {
  if (businessVertical === 'groceries') {
    return 'GroceryStore';
  }
  return 'Restaurant';
}

export function getPathWithoutQuery(path: string) {
  try {
    return path.split('?')[0];
  } catch {
    return path;
  }
}
