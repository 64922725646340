<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header checkout-invoice-dialog-header">
    <box-dialog-header
      [class.transparent-header]="false"
      [alternativeTheme]="false"
      title="Στοιχεία Τιμολογίου"
      alignment="center"
      (closeDialog)="closeDialog()"
    ></box-dialog-header>
  </header>
  <main #dialogBody class="box-dialog-content">
    <div class="checkout-invoice-dialog-wrapper">
      <div class="checkout-invoice-dialog-main-wrapper">
        <div class="checkout-invoice-dialog-content">
          <form [formGroup]="invoiceForm" class="checkout-invoice-dialog-form">
            <div class="checkout-invoice-dialog-form-wrapper">
              <div class="checkout-invoice-dialog-form-section">
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Επωνυμία επιχείρησης</mat-label>
                  <input
                    matInput
                    id="companyName"
                    autocomplete="off"
                    name="companyName"
                    formControlName="companyName"
                    required
                  />
                  <mat-error>Το πεδίο Επωνυμία επιχείρησης είναι <strong>υποχρεωτικό</strong></mat-error>
                </mat-form-field>

                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Δραστηριότητα</mat-label>
                  <input
                    matInput
                    id="profession"
                    autocomplete="off"
                    name="profession"
                    formControlName="profession"
                    required
                  />
                  <mat-error>Το πεδίο Δραστηριότητα είναι <strong>υποχρεωτικό</strong></mat-error>
                </mat-form-field>

                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>ΑΦΜ</mat-label>
                  <input matInput id="taxId" autocomplete="off" name="taxId" formControlName="taxId" required />
                  <mat-error> Το πεδίο ΑΦΜ είναι δεν είναι σωστό </mat-error>
                </mat-form-field>

                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>ΔΟΥ</mat-label>
                  <input
                    matInput
                    id="taxOffice"
                    autocomplete="off"
                    name="taxOffice"
                    formControlName="taxOffice"
                    required
                  />
                  <mat-error>Το πεδίο ΔΟΥ είναι <strong>υποχρεωτικό</strong></mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Τηλέφωνο εταιρίας</mat-label>
                  <input
                    matInput
                    id="companyPhone"
                    autocomplete="off"
                    name="companyPhone"
                    formControlName="companyPhone"
                    required
                  />
                  <mat-error *ngIf="invoiceForm.controls.companyPhone.errors?.required">
                    Το πεδίο Τηλέφωνο εταιρίας είναι <strong>υποχρεωτικό</strong>
                  </mat-error>
                  <mat-error *ngIf="invoiceForm.controls.companyPhone.errors?.invalidPhone">
                    Το τηλέφωνο επικοινωνίας δεν είναι σωστό
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="checkout-invoice-dialog-form-section">
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Διεύθυνση</mat-label>
                  <input
                    matInput
                    id="companyAddress"
                    autocomplete="off"
                    name="companyAddress"
                    formControlName="companyAddress"
                    required
                  />
                  <mat-error>Το πεδίο Διεύθυνση δεν είναι σωστό</mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Αριθμός</mat-label>
                  <input
                    matInput
                    id="companyAddressNumber"
                    autocomplete="off"
                    name="companyAddressNumber"
                    formControlName="companyAddressNumber"
                    required
                  />
                  <mat-error>Το πεδίο Αριθμός είναι <strong>υποχρεωτικό</strong></mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Περιοχή</mat-label>
                  <input
                    matInput
                    id="companyArea"
                    autocomplete="off"
                    name="companyArea"
                    formControlName="companyArea"
                    required
                  />
                  <mat-error>Το πεδίο Περιοχή δεν είναι σωστό</mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Πόλη</mat-label>
                  <input
                    matInput
                    id="companyCity"
                    autocomplete="off"
                    name="companyCity"
                    formControlName="companyCity"
                    required
                  />
                  <mat-error>Το πεδίο Πόλη δεν είναι σωστό</mat-error>
                </mat-form-field>
                <mat-form-field class="checkout-invoice-dialog-form-field">
                  <mat-label>Ταχυδρομικός Κώδικας</mat-label>
                  <input
                    matInput
                    id="companyPostalCode"
                    autocomplete="off"
                    name="companyPostalCode"
                    formControlName="companyPostalCode"
                    required
                  />
                  <mat-error> Το πεδίο Ταχυδρομικός Κώδικας δέν είναι σωστό</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
  <footer #dialogFooter class="box-dialog-footer checkout-invoice-dialog-footer">
    <button
      box-button
      theme="primary"
      class="checkout-invoice-dialog-button"
      [disabled]="!invoiceForm.valid"
      (click)="onSubmit()"
    >
      Επιβεβαίωση
    </button>
  </footer>
</div>
