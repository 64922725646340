import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService, CosmoteIDService, SentryService, UserService } from '@box-core/services';
import { User } from '@box-types';
import { Platform } from '@angular/cdk/platform';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

const MOBILE_BREAKPOINT = '(max-width: 768px)';

@Component({
  selector: 'box-cosmote-id-button',
  templateUrl: './box-cosmote-id-button.component.html',
  styleUrls: ['./box-cosmote-id-button.component.scss']
})
export class BoxCosmoteIdButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  disabled = true;
  authenticated: boolean;
  buttonText: string;
  private user: User;
  private GUID: number;
  public isSmallScreen: boolean;
  private breakPointObserverSubscription: Subscription;

  constructor(
    private platform: Platform,
    private sentryService: SentryService,
    private cosmoteIDService: CosmoteIDService,
    private authenticationService: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.authenticated = this.authenticationService.isAuthenticated;
    this.user = this.userService.getUser();
    this.buttonText = this.getButtonText();
    this.setBreakPointObserverSubscription();
  }

  ngOnDestroy() {
    this.breakPointObserverSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      this.setCosmoteIDSSOLogoutListener();
      this.setCosmoteIDLogin();
    }
  }

  private setBreakPointObserverSubscription(): void {
    this.breakPointObserverSubscription = this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => {
      this.isSmallScreen = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    });
  }

  public onConnectClick(): void {
    if (this.authenticated) return;
  }

  private getButtonText(): string {
    return this.authenticated ? `${this.user.firstName[0]}${this.user.lastName[0]}` : 'Σύνδεση / Εγγραφή';
  }

  private setCosmoteIDLogin(): void {
    if (!this.cosmoteIDService.scriptLoaded) return this.loadCosmoteIDScript();
    return this.setCosmoteIDWidget();
  }

  private loadCosmoteIDScript(): void {
    this.GUID = this.authenticationService.GUID || null;
    this.cosmoteIDService
      .loadScript({ guid: this.GUID || '' })
      .catch((error: unknown) =>
        this.sentryService.captureException(error, {
          domain: 'Authentication',
          domainDetails: 'Cosmote ID Script loading',
          severity: 'error'
        })
      )
      .finally(() => (this.disabled = false));
  }

  private setCosmoteIDWidget(): void {
    try {
      setTimeout(() => {
        const mdLoginElement = document.getElementById('md-login');
        const state = (window as any).cosmoteId_parseInitialState(mdLoginElement); // eslint-disable-line
        (window as any).cosmoteId_SetModalLogin(state); // eslint-disable-line
        this.disabled = false;
      });
    } catch (error: unknown) {
      this.sentryService.captureException(error, {
        domain: 'Authentication',
        domainDetails: 'Cosmote ID Widget Init',
        severity: 'error'
      });
    }
  }

  private setCosmoteIDSSOLogoutListener(): void {
    const messageCallback = (event: MessageEvent) => {
      if (!this.authenticated) return undefined;
      if (event.data === '##cosmoteid-sso-logout##') {
        this.authenticationService.signOut();
        window.location.reload();
      }
    };

    window.addEventListener('message', messageCallback, false);
  }
}
