import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SentryService } from '@box-core/services';
import { APIError } from '@box-types';
import { finalize } from 'rxjs/operators';
import { RateAppService } from './rate-app.service';

type RateAppState = 'RATE' | 'COMMENT' | 'SUBMITTED';

@Component({
  selector: 'rate-app',
  templateUrl: './rate-app.component.html',
  styleUrls: ['./rate-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RateAppService]
})
export class RateAppComponent implements OnInit {
  @ViewChild('commentInput') private commentInput: ElementRef<HTMLTextAreaElement>;
  @Output() private rate: EventEmitter<void> = new EventEmitter<void>();
  @Output() private cancel: EventEmitter<void> = new EventEmitter<void>();

  public title: string;
  public commentTitle: string;
  public state: RateAppState;
  public loading: boolean;
  public errorMessage: string;
  public commentControl: FormControl;

  private rating: number;

  constructor(
    private rateAppService: RateAppService,
    private sentryService: SentryService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'rate-app';

  ngOnInit(): void {
    this.commentControl = new FormControl('', Validators.maxLength(500));
    this.title = 'Πώς σου φαίνεται το ΒΟX;';
    this.commentTitle = 'Θα ήθελες να αξιολογήσεις την εμπειρία σου στο BOX app;';
    this.state = 'RATE';
  }

  public onChangeRating(rating: number): void {
    this.rating = rating;
    this.state = 'COMMENT';
    this.commentTitle =
      rating > 3 ? 'Πες μας τι σου άρεσε περισσότερο;' : 'Πες μας τι δεν πήγε καλά; Tι μπορούμε να βελτιώσουμε;';
  }

  public onClose(): void {
    this.cancel.emit();
  }

  public onSubmitRating(): void {
    this.onSubmit(this.rating);
  }

  public onSubmitRatingAndComment(): void {
    this.onSubmit(this.rating, this.commentControl.value as string);
  }

  private onSubmit(rating: number, comment?: string): void {
    if (this.commentControl.errors?.maxlength) return undefined;
    this.loading = true;
    this.rateAppService
      .rateApp(rating, comment)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.state = 'SUBMITTED';
          this.rate.emit();
        },
        error: (error: APIError) => {
          this.errorMessage = error.userMessage;
          this.commentControl.enable();
          this.commentControl.setErrors({ invalid: true });
          this.commentInput.nativeElement.focus();
          this.sentryService.captureException(error, {
            domain: 'Rating',
            domainDetails: 'Rate App Endpoint',
            severity: 'error'
          });
        }
      });
  }
}
