import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { environment } from '@box-env/environment';
import { FAQData, FAQCategory } from '@box-types';
import { map, tap } from 'rxjs/operators';
import dayjs from 'dayjs';
import { isTimestampExpired } from '@box/utils';

const FAQ_SESSION_EXPIRATION = 5 * 60 * 1000; // 5 hours

@Injectable({ providedIn: 'root' })
export class FAQService {
  public faq: BehaviorSubject<FAQCategory[]> = new BehaviorSubject<FAQCategory[]>(undefined);
  private FAQS_URL: string = environment.application.FAQS_URL;
  private faqTimestamp: number;

  constructor(private http: HttpClient) {}

  public getFAQ(): Observable<FAQCategory[]> {
    const expired: boolean = isTimestampExpired(this.faqTimestamp, FAQ_SESSION_EXPIRATION);
    if (!expired) return of(this.faq.getValue());
    return this.fetchFAQ();
  }

  public fetchFAQ(): Observable<FAQCategory[]> {
    // Cache-Control: no-cache, no-store, must-revalidate, post-check=0, pre-check=0
    // Expires: 0
    return this.http.get<FAQData>(this.FAQS_URL).pipe(
      map((response) => this.filterWebFAQs(response.faqs)),
      tap((faq) => {
        this.faqTimestamp = dayjs().unix();
        this.setFAQ(faq);
      })
    );
  }

  private setFAQ(faq: FAQCategory[]): void {
    this.faq.next(faq);
  }

  public filterWebFAQs(faq: FAQCategory[]): FAQCategory[] {
    return faq.map((category) => ({
      title: category.title,
      dynamicDescriptions: (category.dynamicDescriptions ?? []).filter(
        (d) => !d.platforms || d.platforms.includes('web')
      ),
      questions: (category.questions ?? []).filter((q) => !q.platforms || q.platforms.includes('web'))
    }));
  }
}
