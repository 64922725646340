import {
  Component,
  Output,
  EventEmitter,
  HostBinding,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { CheckoutPointsDialogResponse, CheckoutPointsValueChange, User } from '@box-types';
import { map } from 'rxjs/operators';
import { AnalyticsService, DialogService, UserService } from '@box-core/services';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable, Subscription } from 'rxjs';
import { CheckoutPointsDialogComponent } from '@box-checkout/components';
import {
  getCheckoutPointsRedeemablePoints,
  getCheckoutPointsMaximumValue,
  pointsToCents,
  getCheckoutPointsBannerEuros,
  getCheckoutPointsBannerText
} from '@box/utils';
import { CheckoutCouponsService } from '@box-checkout/services';

@Component({
  selector: 'checkout-points',
  templateUrl: './checkout-points.component.html',
  styleUrls: ['./checkout-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutPointsComponent implements OnInit, OnChanges, OnDestroy {
  public checked = false;
  public bannerText: string;
  public bannerEuros: string;
  public pointsToRedeem = 0;

  private userAvailablePoints: number;
  private redeemablePoints: number;
  private userSubscription: Subscription;

  /*controls whether the toggle should be functional*/
  @Input() private disabled: boolean;

  /*amount of points that correspond to 1euro (1600),
  in this case we want the slide to jump from 1600 to 3200 etc... */
  @Input() private pointsToEurosRatio: number;

  /*the amount of euros needed to redeem 1€ in points
  X = 4 for food stores
  X = 10 for sm stores
  for k*X€ value in your cart you can have a discount of k€*/
  @Input() private euroRedemptionRate: number;
  @Input() private cartPrice: number;
  @Input() private totalDiscount: number;
  @Input() private cartPriceWhichCanBeDiscounted: number;
  @Output() private valueChange = new EventEmitter<CheckoutPointsValueChange>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private userService: UserService,
    private checkoutCouponsService: CheckoutCouponsService,
    private analyticsService: AnalyticsService
  ) {}

  @HostBinding('class') public hostClass = 'checkout-points';

  ngOnInit() {
    this.setUserSubscription();
  }

  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
  }

  private setUserSubscription(): void {
    this.userSubscription = this.userService.user$.subscribe((user: User) => {
      this.userAvailablePoints = user.marketPlacePoints.remainingPoints;
      this.updateComponentData(this.pointsToRedeem);
      this.reset();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) this.disabled = changes.disabled.currentValue as boolean;
    if (changes.stepValue) this.pointsToEurosRatio = changes.stepValue.currentValue as number;
    if (changes.euroRedemptionRate) this.euroRedemptionRate = changes.euroRedemptionRate.currentValue as number;
    if (changes.cartPrice) this.cartPrice = changes.cartPrice.currentValue as number;
    if (changes.totalDiscount) this.totalDiscount = changes.totalDiscount.currentValue as number;
    if (changes.cartPriceWhichCanBeDiscounted) {
      this.cartPriceWhichCanBeDiscounted = changes.cartPriceWhichCanBeDiscounted.currentValue as number;
    }
    if (
      changes.stepValue ||
      changes.euroRedemptionRate ||
      changes.cartPrice ||
      changes.totalDiscount ||
      changes.cartPriceWhichCanBeDiscounted
    ) {
      this.updateComponentData(this.pointsToRedeem);
    }
  }

  public reset(): void {
    this.checked = false;
    this.updateComponentData(0);
    this.emitValueChangeEvent();
  }

  public onSlideToggleChange(event: MatSlideToggleChange): void {
    if (this.disabled) {
      event.source.checked = false;
      this.checked = false;
      this.changeDetectorRef.detectChanges();
      return void this.dialogService.openInfoDialog({
        title: 'Εξαργύρωση πόντων',
        messages: ['Πρέπει να επιλέξεις πληρωμή με κάρτα για να εξαργυρώσεις του πόντους σου.']
      });
    }

    if (this.checked) {
      event.source.checked = false;
      this.checked = false;
      this.changeDetectorRef.detectChanges();
      this.updateComponentData(0);
      return this.emitValueChangeEvent();
    }

    const selectedCoupon = this.checkoutCouponsService.getCoupon();
    if (selectedCoupon && !selectedCoupon.isCombinedWithPointsRedemption) {
      event.source.checked = false;
      this.checked = false;
      this.changeDetectorRef.detectChanges();
      this.triggerAnalyticsEvent();
      return void this.dialogService.openInfoDialog({
        messages: [
          'Η εξαργύρωση πόντων δε συνδυάζεται με το κουπόνι που έχει ήδη επιλεχθεί.',
          'Αν προτιμάς εξαργύρωση πόντων, θα χρειαστεί να αφαιρέσεις το κουπόνι.'
        ]
      });
    }

    if (this.cartPriceWhichCanBeDiscounted === 0 && !this.checked) {
      event.source.checked = false;
      this.checked = false;
      this.changeDetectorRef.detectChanges();
      return void this.dialogService.openInfoDialog({
        title: 'Εξαργύρωση πόντων',
        messages: ['Το ποσό της παραγγελίας σου έχει ήδη λάβει μέγιστη δυνατή έκπτωση.']
      });
    }

    /*checkout points are eligible for autocheck*/
    if (this.redeemablePoints === this.pointsToEurosRatio) {
      event.source.checked = true;
      this.checked = true;
      this.updateComponentData(this.redeemablePoints);
      return this.emitValueChangeEvent();
    }

    this.openCheckoutPointsDialog().subscribe((data: CheckoutPointsDialogResponse): void => {
      if (!data?.value) {
        event.source.checked = false;
        this.checked = false;
        return;
      }
      event.source.checked = true;
      this.checked = true;
      this.updateComponentData(data.value);
      this.emitValueChangeEvent();
    });
  }

  private openCheckoutPointsDialog(): Observable<CheckoutPointsDialogResponse> {
    const maximumValue = getCheckoutPointsMaximumValue(
      this.cartPrice,
      this.euroRedemptionRate,
      this.pointsToEurosRatio
    );

    return this.dialogService
      .openDialog(CheckoutPointsDialogComponent, {
        data: {
          cartPrice: this.cartPrice,
          euroRedemptionRate: this.euroRedemptionRate,
          stepValue: this.pointsToEurosRatio,
          claimableValue: this.redeemablePoints,
          availableValue: this.userAvailablePoints,
          totalDiscount: this.totalDiscount,
          maximumValue
        }
      })
      .afterClosed()
      .pipe(map((response) => response as CheckoutPointsDialogResponse));
  }

  private emitValueChangeEvent(): void {
    const pointsInCents = pointsToCents(this.pointsToRedeem, this.pointsToEurosRatio);
    this.valueChange.emit({ points: this.pointsToRedeem, pointsInCents });
  }

  private updateComponentData(pointsToRedeem: number): void {
    this.pointsToRedeem = pointsToRedeem;
    this.redeemablePoints = getCheckoutPointsRedeemablePoints(
      this.cartPriceWhichCanBeDiscounted,
      this.userAvailablePoints,
      this.pointsToEurosRatio,
      this.euroRedemptionRate
    );
    this.bannerEuros = getCheckoutPointsBannerEuros(
      this.checked,
      this.pointsToRedeem,
      this.redeemablePoints,
      this.pointsToEurosRatio
    );
    this.bannerText = getCheckoutPointsBannerText(
      this.checked,
      this.pointsToRedeem,
      this.redeemablePoints,
      this.pointsToEurosRatio
    );
    this.redeemablePoints = getCheckoutPointsRedeemablePoints(
      this.cartPriceWhichCanBeDiscounted,
      this.userAvailablePoints,
      this.pointsToEurosRatio,
      this.euroRedemptionRate
    );
    this.changeDetectorRef.detectChanges();
  }

  private triggerAnalyticsEvent(): void {
    this.analyticsService.addGACustomEvent('points_tapped_for_ineligible_coupon', {});
  }
}
