import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Shop, TimetableDayDetails } from '@box-types';
import { ShopPageDetailsService } from './shop-page-details.service';
import { ThemingService } from '@box-core/services/theming.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'shop-page-details',
  templateUrl: './shop-page-details.component.html',
  styleUrls: ['./shop-page-details.component.scss'],
  providers: [ShopPageDetailsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopPageDetailsComponent implements OnInit, OnDestroy {
  private shop: Shop;
  private themeSubscription: Subscription;

  public name: string;
  public logoUrl: string;
  public cuisineText: string;
  public paymentIcons: string[];
  public minimumOrderPriceText: string;
  public deliveryFeeText: string;
  public mapBackgroundImage: string;
  public addressText: string;
  public addressAnchor: string;
  public phoneNumber: string;
  public timetableDetails: TimetableDayDetails[];
  public taxNumber: string;
  public brandName: string;
  public companyHeadquarters: string;
  public commercialRegister: string;

  constructor(
    private shopPageDetailsService: ShopPageDetailsService,
    private themingService: ThemingService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'shop-page-details';

  ngOnInit(): void {
    this.shop = this.shopPageDetailsService.getShop();
    this.name = this.shop.name;
    this.taxNumber = this.shop.taxNumber;
    this.brandName = this.shop.legalInfo?.brandName;
    this.companyHeadquarters = this.shop.legalInfo?.companyHeadquarters;
    this.commercialRegister = this.shop.legalInfo?.commercialRegister;
    this.logoUrl = this.shopPageDetailsService.getShopLogoUrl();
    this.cuisineText = this.shop.cuisinesText;
    this.paymentIcons = this.shopPageDetailsService.generatePaymentIcons();
    this.minimumOrderPriceText = this.shopPageDetailsService.getShopMinimumOrderPriceText();
    this.deliveryFeeText = this.shopPageDetailsService.getShopDeliveryFeeText();
    this.addressText = this.shopPageDetailsService.getAddressText();
    this.addressAnchor = this.shopPageDetailsService.getAddressAnchor();
    this.phoneNumber = this.shopPageDetailsService.getPhoneNumber();
    this.timetableDetails = this.shopPageDetailsService.getTimetableDayDetails();
    this.setThemeSubscription();
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.subscribe((theme) => {
      this.mapBackgroundImage = this.shopPageDetailsService.generateMapBackgroundImage(theme);
      this.changeDetectorRef.detectChanges();
    });
  }
}
