import { Injectable } from '@angular/core';
import {
  DialogService,
  PromoCampaignsService,
  ShopService,
  UserService,
  PaymentTypesService,
  CartService,
  ExplorePromoCampaignService,
  AddressesService,
  DeliveryMethodService,
  CampaignEligibilityService
} from '@box-core/services';
import { PromoCampaignBanner, Shop, InfoDialogData, MarketCardDetails, DeliveryMethod } from '@box-types';
import {
  getLoyaltyCardForIntegrator,
  isLowOrderProbabilityUserWithCoupon,
  promoCampaignHasImageKey,
  getShopMinimumPrice,
  getShopDeliveryFee,
  getCouponDiscount,
  isPaymentCard,
  checkMerchantSponsoredCampaignEligibility
} from '@box/utils';
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { BoxConfirmDialogComponent } from '@box-shared/components';
import { CurrencyPipe } from '@angular/common';
import { CheckoutOrderPreviewOptions, CheckoutOrderPreview } from '@box-checkout/checkout.types';
import { CheckoutInfoService, CheckoutCouponsService, CartSuggestionService } from '@box-checkout/services';

export const PROBLEMATIC_ADDRESS_DIALOG_DATA: InfoDialogData = {
  title: 'Λείπουν στοιχεία από τη διεύθυνση σου',
  messages: ['Παρακαλούμε πρόσθεσε την εκ νέου']
};

const EMPTY_CHECKOUT_ORDER_PREVIEW: CheckoutOrderPreview = {
  cartStartingPrice: 0,
  cartPriceWithPureDiscounts: 0,
  priceCoveredByCoupon: 0,
  deliveryFee: 0,
  totalEnvFee: 0,
  totalDiscount: 0,
  serviceFee: 0,
  totalPrice: 0,
  tip: 0,
  donation: 0
};

@Injectable()
export class CheckoutService {
  private readonly checkoutOrderPreviewSource = new BehaviorSubject<CheckoutOrderPreview>(EMPTY_CHECKOUT_ORDER_PREVIEW);
  public readonly checkoutOrderPreview$ = this.checkoutOrderPreviewSource.asObservable();

  private promoCampaignsShowedSource: BehaviorSubject<string[]>;

  constructor(
    private cartService: CartService,
    private shopService: ShopService,
    private paymentTypesService: PaymentTypesService,
    private promoCampaignsService: PromoCampaignsService,
    private userService: UserService,
    private dialogService: DialogService,
    private currencyPipe: CurrencyPipe,
    private exploreService: ExplorePromoCampaignService,
    private addressesService: AddressesService,
    private checkoutInfoService: CheckoutInfoService,
    private deliveryMethodService: DeliveryMethodService,
    private checkoutCouponsService: CheckoutCouponsService,
    private cartSuggestionService: CartSuggestionService,
    private campaignEligibilityService: CampaignEligibilityService
  ) {
    this.initializePromoCamapignsShowed();
  }

  public getCheckoutOrderPreview(): CheckoutOrderPreview {
    return this.checkoutOrderPreviewSource.getValue();
  }

  public updateCheckoutOrderPreview(options: CheckoutOrderPreviewOptions): void {
    const checkoutOrderPreview = this.generateCheckoutOrderPreview(options);
    this.checkoutOrderPreviewSource.next(checkoutOrderPreview);
  }

  public showInsufficientMinimumPriceDialog(
    shop: Shop,
    method: DeliveryMethod
  ): MatDialogRef<BoxConfirmDialogComponent> {
    // todo handle sm case where there is no delivery
    const minimumDeliveryPrice = getShopMinimumPrice(shop, method);
    const priceToText: string = this.currencyPipe.transform(minimumDeliveryPrice / 100, 'EUR');
    return this.dialogService.openConfirmDialog(
      {
        title: 'Ελάχιστη παραγγελία',
        messages: [
          `Η τιμή της ελάχιστης παραγγελίας για ${
            method === 'delivery' ? 'delivery' : 'παραλαβή απο το κατάστημα'
          } είναι ${priceToText}.`,
          `Το καλάθι σου δεν επαρκεί για να συνεχίσεις σε ${
            method === 'delivery' ? 'delivery' : 'παραλαβή απο το κατάστημα'
          }. Πρόσθεσε επιπλέον προϊόντα από το κατάστημα, ή επίλεξε ${
            method === 'delivery' ? 'παραλαβή από το κατάστημα' : 'delivery'
          }.`
        ],
        cancelText: 'Πίσω στο κατάστημα',
        confirmText: method === 'delivery' ? 'Θα παραλάβω από το κατάστημα' : 'Θα παραλάβω με delivery',
        buttonOrientation: 'vertical'
      },
      { panelClass: 'box-dialog-small-with-large-buttons' }
    );
  }

  public showTakeAwayAutoPaymentSelectionInfo(): void {
    const title = 'Take away';
    const message =
      'Για παραγγελίες μέσω Take away απαιτείται πληρωμή με κάρτα, οπότε αλλάξαμε αυτόματα τον τρόπο πληρωμής σου';
    this.dialogService.openInfoDialog({ title, messages: [message] });
  }

  public getPromoCampaignsShowed(): string[] {
    return this.promoCampaignsShowedSource.getValue();
  }

  public addPromoCampaignsShowed(name: string): void {
    const currentNames = this.promoCampaignsShowedSource.getValue();
    if (currentNames.includes(name)) return;
    this.setPromoCampaignsShowed([...currentNames, name]);
  }

  public setPromoCampaignsShowed(names: string[]): void {
    this.promoCampaignsShowedSource.next(names);
    window.sessionStorage.setItem('Box:promoCampaignShowed', JSON.stringify(names));
  }

  public initializePromoCamapignsShowed(): void {
    const localNames = (JSON.parse(window.sessionStorage.getItem('Box:promoCampaignShowed')) as string[]) ?? [];
    this.promoCampaignsShowedSource = new BehaviorSubject<string[]>(localNames);
  }

  public isCheckoutTipEnabled(): boolean {
    const shop = this.shopService.getShop();
    if (!shop.supportsTip) return false;
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    if (deliveryMethod === 'takeAway') return false;
    const paymentType = this.paymentTypesService.getPaymentType();
    if (!paymentType) return false;
    const isCard = isPaymentCard(paymentType);
    if (!isCard) return false;
    return true;
  }

  public isCheckoutDonationEnabled(): boolean {
    const paymentType = this.paymentTypesService.getPaymentType();
    if (!paymentType) return false;
    const isCard = isPaymentCard(paymentType);
    if (!isCard) return false;
    return true;
  }

  public getCheckoutSynergyBanners(): PromoCampaignBanner[] {
    const consumedCampaigns = this.campaignEligibilityService.getConsumedPromoCampaigns().filter(
      // we filter out campaigns that are only eligible if you add products from checkout
      (campaign) => {
        const exploreCouponActive = isLowOrderProbabilityUserWithCoupon(this.userService.getUser());
        if (exploreCouponActive && campaign.name === 'new_users') return false;
        const { name, triggeredByClient, cartSuggestion } = campaign;
        const isBoxaki = ['new_users', 'happy_hour', 'returning_users'].includes(name);
        // if the campaign is boxaki, then we replace the image with the shop image, so we count as hasImage
        const hasImage = isBoxaki || (!isBoxaki && promoCampaignHasImageKey(campaign, 'checkoutBannerLogo'));
        if (!(triggeredByClient && cartSuggestion?.enabled) && hasImage) return true;
        return false;
      }
    );
    return consumedCampaigns.map((campaign) => this.promoCampaignsService.campaignToCampaignBanner(campaign));
  }

  private generateCheckoutOrderPreview(options: CheckoutOrderPreviewOptions): CheckoutOrderPreview {
    const shop = this.shopService.getShop();
    const cart = this.cartService.getCart();
    const deliveryMethod = this.deliveryMethodService.getDeliveryMethod();
    const { isSuperMarket } = shop;
    const {
      tip,
      donation,
      coupon,
      bagsPrice,
      checkOrderPrice,
      pointsDiscount,
      smLoyaltyDiscount,
      bankLoyaltyDiscount
    } = options;
    /* We are using the isSupermarket here due to a tech flaw we have with the Discount Calculation. We
    assume that the non SM Shops have no pure Discount. That is a subject to change.
    if the price is raised by checkOrder it needs to be added to the starting price.
    Bags are handled like any other cart item, they need to be added to both the starting price and final price*/
    const itemsStartingPrice = Math.max(checkOrderPrice, cart.itemsStartingPrice);
    const cartStartingPrice = (isSuperMarket ? itemsStartingPrice : cart.itemsFinalPrice) + bagsPrice;
    const cartPriceWithPureDiscounts = (checkOrderPrice > 0 ? checkOrderPrice : cart.itemsFinalPrice) + bagsPrice;
    const pureDiscount = isSuperMarket ? Math.max(cartStartingPrice - cartPriceWithPureDiscounts, 0) : 0;
    const totalEnvFee = cart.envFee ?? 0;
    const deliveryFee = getShopDeliveryFee(shop, deliveryMethod, cartPriceWithPureDiscounts) ?? 0;
    const serviceFee = this.checkoutInfoService.getCheckoutInfo().serviceFee ?? 0;
    const additionsNotCoveredByDiscounts = tip + donation + deliveryFee + serviceFee;
    /** the order of discounts only matters when there is a percentage coupon being applied,
     * in that case the coupon percentage discount should be the last in order.
     * For that reason we need to subtract all the other discounts first. */
    const priceCoveredByCoupon = Math.max(
      cartPriceWithPureDiscounts - pointsDiscount - smLoyaltyDiscount - bankLoyaltyDiscount,
      0
    );
    const couponDiscount = getCouponDiscount(coupon, priceCoveredByCoupon);
    const totalDiscount = pureDiscount + pointsDiscount + smLoyaltyDiscount + bankLoyaltyDiscount + couponDiscount;
    const totalPrice = Math.max(cartStartingPrice - totalDiscount, 0) + additionsNotCoveredByDiscounts;
    return {
      cartStartingPrice: cartStartingPrice ?? 0, // includes envFee
      cartPriceWithPureDiscounts: cartPriceWithPureDiscounts ?? 0, // includes check order alterations and bags
      priceCoveredByCoupon: priceCoveredByCoupon ?? 0,
      deliveryFee,
      serviceFee: deliveryMethod === 'delivery' ? serviceFee : 0,
      totalEnvFee,
      totalDiscount: Math.min(totalDiscount, cartStartingPrice) ?? 0,
      totalPrice: Math.ceil(totalPrice) ?? 0,
      tip,
      donation
    };
  }

  public getCheckoutPointsVisibility(shop: Shop, cartPrice: number): boolean {
    if (!shop.isEligibleForMyoBoxaki) return false;
    const userRemainingPoints = this.userService.getUser()?.marketPlacePoints?.remainingPoints;
    const boxakiEuroRate = shop.loyaltyRuleStepInEuroForRedemption;
    const boxakiPointsRate = shop.loyaltyPointsToRedeemRate;
    const isUserEligible = userRemainingPoints >= boxakiPointsRate;
    const isOrderEligible = cartPrice >= boxakiEuroRate * 100;
    return isUserEligible && isOrderEligible;
  }

  public getSuperMarketLoyaltyCard(): MarketCardDetails {
    const company = this.shopService.getShop().integrator?.company;
    if (!company) return;
    const loyaltyCards = this.userService.getLoayltyCards();
    if (!loyaltyCards?.length) return;
    return getLoyaltyCardForIntegrator(loyaltyCards, company);
  }

  public openCheckOrderPriceConfirmDialog(price: number): MatDialogRef<BoxConfirmDialogComponent> {
    const priceText = this.currencyPipe.transform(price / 100, 'EUR');
    return this.dialogService.openConfirmDialog({
      title: 'Ανανέωση προϊόντων',
      messages: [
        `Οι τιμές για κάποια προϊόντα που είχες στο καλάθι σου έχουν ανανεωθεί. Η νέα τελική τιμή της παραγγελίας σου είναι ${priceText}.`
      ],
      confirmText: 'Συνέχεια',
      cancelText: 'Επιστροφή'
    });
  }

  public onLowProbabilitySuccessfulOrder(): void {
    this.exploreService.fetchExploreCouponData().subscribe((exploreData) => {
      const { userSegments } = exploreData;
      this.exploreService.clearCoupon();
      this.exploreService.clearTimer();
      this.userService.setUser({ ...this.userService.getUser(), segments: userSegments });
    });
  }

  public problematicAddressFlow(): void {
    this.dialogService
      .openInfoDialog(PROBLEMATIC_ADDRESS_DIALOG_DATA)
      .afterClosed()
      .subscribe(() => this.addressesService.initiateAddFullAddressDialogFlow$().subscribe());
  }

  public isHappyHourEligible(): boolean {
    if (this.isNewUsersEligible()) return false;

    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    if (!promoCampaigns?.length) return false;

    const happyHourPromo = promoCampaigns.find((promoCampaign) => promoCampaign.name === 'happy_hour');
    if (!happyHourPromo) return false;

    return checkMerchantSponsoredCampaignEligibility(this.shopService.getShop(), happyHourPromo);
  }

  public isNewUsersEligible(): boolean {
    const promoCampaigns = this.promoCampaignsService.getActivePromoCampaigns();
    if (!promoCampaigns?.length) return false;

    const promoCampaign = promoCampaigns.find((promoCampaign) => promoCampaign.name === 'new_users');
    if (!promoCampaign) return false;

    const shopIsEligible = checkMerchantSponsoredCampaignEligibility(this.shopService.getShop(), promoCampaign);
    if (!shopIsEligible) return false;

    const exploreCouponExists = isLowOrderProbabilityUserWithCoupon(this.userService.getUser());
    if (!exploreCouponExists) return true;

    return this.checkoutCouponsService.isExploreCouponSelected();
  }

  public getOrderPromoCampaigns(): string[] {
    const promoCampaignNames: string[] = [];
    // todo we are missing logic for campaigns that are triggeredByClient: true and cartSuggestion.enabled === false
    const reminderCampaignsAddedInCheckout = this.cartSuggestionService.getEligibleReminderCampaignsTriggeredByClient();
    if (reminderCampaignsAddedInCheckout) promoCampaignNames.push(...reminderCampaignsAddedInCheckout);
    if (this.isNewUsersEligible()) promoCampaignNames.push('new_users');
    if (this.isHappyHourEligible()) promoCampaignNames.push('happy_hour');
    return promoCampaignNames;
  }
}
