import { Injectable } from '@angular/core';
import { CartService } from '@box-core/services';
import { Product, PromoCampaign, ShopSuggestionDialogBanner, Offer } from '@box-types';
import {
  syncOfferCartQuantityWithCart,
  syncProductCartQuantityWithCart,
  getPromoCampaignImage,
  getPromoCampaignText,
  generateImageSrc,
  formatPoints
} from '@box/utils';
import { Observable } from 'rxjs';

@Injectable()
export class ShopSuggestionDialogService {
  constructor(private cartService: CartService) {}

  public getSuggestionDialogBanner(promoCampaign: PromoCampaign): ShopSuggestionDialogBanner {
    const campaignName = promoCampaign.name;
    const description = getPromoCampaignText(promoCampaign, 'dialogBannerText');
    const multiplier = promoCampaign.multiplier ? `x${formatPoints(promoCampaign.multiplier)}` : null;
    const pointsAmount = promoCampaign.marketPlacePointsAmount
      ? `+${formatPoints(promoCampaign.marketPlacePointsAmount)}`
      : null;
    const image = generateImageSrc(getPromoCampaignImage(promoCampaign, 'drinksListLogo'));
    return { campaignName, description, image, multiplier, pointsAmount };
  }

  public cartChanges$(): Observable<{ products: Product[]; offers: Offer[] }> {
    return this.cartService.cart$;
  }

  public syncProductCartQuantityWithCartState(product: Product): Product {
    const cart = this.cartService.getCart();
    return syncProductCartQuantityWithCart(product, cart);
  }

  public syncOfferCartQuantityWithCartState(offer: Offer): Offer {
    const cart = this.cartService.getCart();
    return syncOfferCartQuantityWithCart(offer, cart);
  }
}
