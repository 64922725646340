<div class="offer-wizard-wrapper" #wrapper bodyScroll (bodyScroll)="onBodyScroll($event)">
  <box-dialog-header
    class="offer-wizard-header"
    canClose="true"
    [class.offer-wizard-header-transparent]="!subHeaderStuck"
    [alternativeTheme]="!subHeaderStuck"
    (closeDialog)="onClose()"
  ></box-dialog-header>
  <div *ngIf="images?.length || heroImageSrc" class="offer-wizard-hero-container">
    <product-myo-slider *ngIf="images?.length; else heroImage" [images]="images"></product-myo-slider>
    <ng-template #heroImage>
      <div *ngIf="heroImageSrc" class="offer-wizard-hero" [style.visibility]="subHeaderStuck ? 'hidden' : 'visible'">
        <figure class="offer-wizard-hero-image">
          <img [src]="heroImageSrc" [alt]="offer.name" />
        </figure>
      </div>
    </ng-template>
    <div
      sectionSlice
      class="offer-wizard-hero-slice"
      customSliceUrl="/assets/images/bottom-slice-myo.svg"
      sliceShadow="true"
      bottom="-4px"
    ></div>
  </div>
  <div
    stickyObserver
    (stickyChanges)="onSubHeaderStickyChanges($event)"
    class="offer-wizard-sub-header"
    [class.has-shadow]="subHeaderStuck && bodyScrolled"
  >
    <offer-wizard-details
      [showPrice]="offerWizardComplete || isOfferFixedPrice"
      [offer]="offer"
      [offerInstance]="offerInstance"
      (offerAdd)="onOfferAdd()"
      (offerRemove)="onOfferRemove()"
    ></offer-wizard-details>
  </div>

  <main class="offer-wizard-main">
    <offer-wizard-groups
      [offerInstance]="offerInstance"
      (productChange)="onProductChange($event)"
      (productMYO)="onProductMYO($event)"
      (afterCollapse)="onAfterGroupCollapse()"
      (afterExpand)="onAfterGroupExpand()"
    ></offer-wizard-groups>
  </main>
  <footer
    *ngIf="offerWizardComplete"
    class="offer-wizard-footer"
    [class.has-shadow]="bodyScrollable && !bodyScrolledToBottom"
  >
    <cart-button
      (click)="onSubmit()"
      class="offer-wizard-footer-button"
      [quantity]="totalProductQuantity"
      [showBenefits]="false"
      [price]="(offerInstance.price / 100) * offerInstance.quantity"
    >
      Αποθήκευση
    </cart-button>
  </footer>
</div>
