<component-loader *ngIf="loading"></component-loader>
<div class="rate-app-header">
  <svg class="rate-app-header-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
    <path
      fill="#2A292E"
      fill-rule="nonzero"
      d="M33.96 12.169c-.122-.37-.49-.602-1.103-.696L22.6 10.001 18.002.827C17.716.275 17.382 0 17 0c-.395 0-.73.275-1.002.827L11.402 10 1.144 11.473c-.613.094-.981.326-1.104.696-.122.37.034.77.47 1.2l7.438 7.138-1.757 10.082c-.068.443-.027.789.123 1.038s.381.373.695.373c.231 0 .504-.08.817-.242L17 26.998l9.175 4.76c.313.161.586.242.817.242.314 0 .546-.124.695-.373.15-.25.19-.595.123-1.038l-1.758-10.082 7.418-7.138c.45-.43.613-.83.49-1.2Zm-9.726 6.483-1.001.947.245 1.331 1.226 7.159-6.498-3.388-1.205-.625V4.658l3.249 6.493.612 1.21 1.35.201 7.274 1.048-5.252 5.042Z"
    />
  </svg>
  <div class="rate-app-header-title">Πώς σου φαίνεται το ΒΟX;</div>
</div>
<div class="rate-app-content">
  <ng-container [ngSwitch]="state">
    <div class="rate-app-state-rate" *ngSwitchCase="'RATE'">
      <p class="rate-app-state-title">Θα ήθελες να αξιολογήσεις την εμπειρία σου στο BOX app;</p>
      <rating size="48" (changeRating)="onChangeRating($event)"></rating>
      <div class="rate-app-state-rate-button-container">
        <button box-button theme="secondary" borders="none" class="rate-app-state-rate-button" (click)="onClose()">
          Ίσως αργότερα
        </button>
      </div>
    </div>

    <div class="rate-app-state-comment" *ngSwitchCase="'COMMENT'">
      <p class="rate-app-state-title">{{ commentTitle }}</p>
      <mat-form-field class="rate-app-state-comment-textarea" appearance="outline">
        <textarea
          #commentInput
          matInput
          matTextareaAutosize="true"
          placeholder="Η γνώμη σου είναι πολύτιμη."
          type="text"
          rows="3"
          [formControl]="commentControl"
        ></textarea>
        <mat-hint align="end">{{ commentControl.value?.length }} / 500</mat-hint>
        <mat-error>{{ errorMessage }}</mat-error>
        <mat-error *ngIf="commentControl.errors?.maxlength"
          >Το σχόλιο σου πρέπει να μην ξεπερνάει τους 500 χαρακτήρες!</mat-error
        >
      </mat-form-field>

      <div class="rate-app-state-comment-button-container">
        <button box-button theme="secondary" class="rate-app-state-comment-button" (click)="onSubmitRating()">
          Όχι τώρα
        </button>
        <button
          box-button
          theme="primary"
          class="rate-app-state-comment-button"
          [disabled]="commentControl.invalid"
          (click)="onSubmitRatingAndComment()"
        >
          Αποστολή
        </button>
      </div>
    </div>

    <div class="rate-app-state-submitted" *ngSwitchCase="'SUBMITTED'">
      <p class="rate-app-state-submitted-text">
        Ευχαριστούμε πολύ!
        <br />
        <br />
        Η γνώμη σου μας βοηθάει να γινόμαστε καλύτεροι!
      </p>
    </div>
  </ng-container>
</div>
