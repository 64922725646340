import { Component, ViewChild, OnInit, Input, OnDestroy, HostBinding } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { User, Campaign, Shop, PromoCampaignBanner, Payment } from '@box-types';
import {
  DialogService,
  LoyaltyService,
  PromoCampaignsService,
  UserService,
  PaymentTypesService,
  AnalyticsService,
  PurchaseEventService,
  CartService,
  ShopService,
  CampaignEligibilityService
} from '@box-core/services';
import { Subscription } from 'rxjs';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CheckoutSynergyDialogComponent } from '@box-checkout/components';
import { getAddPaymentInfoEventGAConfig } from '@box/utils';
import { CheckoutCouponsService } from '@box-checkout/services';

@Component({
  selector: 'checkout-payment-types',
  templateUrl: './checkout-payment-types.component.html',
  styleUrls: ['./checkout-payment-types.component.scss']
})
export class CheckoutPaymentTypesComponent implements OnInit, OnDestroy {
  @ViewChild('paymentTypesPanel') public paymentTypesPanel: MatExpansionPanel;

  @Input() public shop: Shop;
  @Input() public cartTotal: number;

  public selectedPaymentType: Payment;
  public paymentTypes: Payment[];
  public firstOrderCardPoints: number;
  public showCardPromoMessage: boolean;

  private user: User;
  private paymentTypeSubscription: Subscription;
  private paymentTypesSubscription: Subscription;
  private paymentPointsDialogShown: boolean;

  constructor(
    private paymentTypesService: PaymentTypesService,
    private loyaltyService: LoyaltyService,
    private promoCampaignsService: PromoCampaignsService,
    private userService: UserService,
    private campaignEligibilityService: CampaignEligibilityService,
    private dialogService: DialogService,
    private cartService: CartService,
    private analyticsService: AnalyticsService,
    private purchaseEventService: PurchaseEventService,
    private shopService: ShopService,
    private checkoutCouponsService: CheckoutCouponsService
  ) {}

  @HostBinding('class') public hostClass = 'checkout-payment-types';

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.setCampaignPoints();
    this.showCardPromoMessage = this.shop.card && !this.user.hasPayedUsingCard && this.firstOrderCardPoints > 0;
    this.setPaymentTypesSubscription();
    this.setPaymentTypeSubscription();
  }

  ngOnDestroy(): void {
    this.paymentTypeSubscription?.unsubscribe();
    this.paymentTypesSubscription?.unsubscribe();
  }

  public onPaymentSelect(paymentType: Payment): void {
    if (!paymentType || paymentType.disabled) return;
    this.paymentTypesService.setPaymentType(paymentType);
    this.paymentTypesPanel.close();
    this.triggerAnalyticsEvent(paymentType);
    this.handlePaymentPointsDialog();
  }

  private handlePaymentPointsDialog() {
    if (this.paymentPointsDialogShown) return;
    const campaignsCompatibleWithSelectedPayment =
      this.campaignEligibilityService.getPromoCampaignsForSelectedPaymentType();
    if (!campaignsCompatibleWithSelectedPayment?.length) return;
    const paymentCampaignBanners = campaignsCompatibleWithSelectedPayment.map((campaign) =>
      this.promoCampaignsService.campaignToCampaignBanner(campaign)
    );
    this.openSynergyDialog(this.shop, paymentCampaignBanners)
      .afterClosed()
      .subscribe(() => (this.paymentPointsDialogShown = true));
  }

  private setPaymentTypeSubscription(): void {
    this.paymentTypeSubscription = this.paymentTypesService.payment$.subscribe(
      (paymentType) => (this.selectedPaymentType = paymentType)
    );
  }

  private setPaymentTypesSubscription(): void {
    this.paymentTypesSubscription = this.paymentTypesService.payments$.subscribe(
      (paymentTypes) => (this.paymentTypes = paymentTypes)
    );
  }

  private setCampaignPoints(): void {
    const campaigns: Campaign[] = this.loyaltyService.campaigns.value;
    if (this.user?.hasPayedUsingCard) return;
    const campaign: Campaign = campaigns.find((c) => c.event === 'first_order_card');
    this.firstOrderCardPoints = campaign?.marketPlacePointsAmount;
  }

  private openSynergyDialog(
    shop: Shop,
    synergyBanners: PromoCampaignBanner[]
  ): MatDialogRef<CheckoutSynergyDialogComponent> {
    const dialogConfig: MatDialogConfig = {
      closeOnNavigation: true,
      autoFocus: false,
      restoreFocus: false,
      panelClass: ['box-dialog-fit-content', 'no-background', 'borderless'],
      data: { shop, synergyBanners }
    };
    return this.dialogService.openDialog(CheckoutSynergyDialogComponent, dialogConfig);
  }

  private triggerAnalyticsEvent(paymentType: Payment): void {
    const purchaseEvent = this.purchaseEventService.getPurchaseEvent(this.shop._id);
    if (!purchaseEvent) return;
    const options = {
      coupon: this.checkoutCouponsService.getCoupon(),
      cart: this.cartService.getCart(),
      shop: this.shop,
      purchaseEvent: purchaseEvent,
      categories: this.shopService.getShopItemsFromMemory().categories,
      paymentType: paymentType
    };
    const gaConfig = getAddPaymentInfoEventGAConfig(options);
    this.analyticsService.addGAEcommerceEvent('add_payment_info', gaConfig);
  }
}
