<div class="order-completion-contact-info-icon">
  <svg-icon class="order-completion-contact-info-image" [svgPath]="icon"></svg-icon>
</div>
<div class="order-completion-contact-info-content">
  <p class="order-completion-contact-info-title ods-typography-body-s-bold">{{ title }}</p>
  <p class="order-completion-contact-info-subtitle ods-typography-body-s-regular">{{ subtitle }}</p>
</div>
<a
  *ngIf="anchorHref"
  box-button
  theme="secondary"
  size="small"
  borders="none"
  type="button"
  target="blank"
  [attr.href]="anchorHref"
  class="order-completion-contact-info-action"
  (click)="onAnchorTextClick()"
>
  {{ anchorText }}
</a>
