import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {
  generateImageSrc,
  createOfferInstance,
  getOfferMaxQuantityPerOrderText,
  getOfferMinOrderItemsPriceText
} from '@box/utils';
import { PromoVisualOptions, Offer, OfferInstance } from '@box-types';

@Component({
  selector: 'food-offer',
  templateUrl: './food-offer.component.html',
  styleUrls: ['./food-offer.component.scss']
})
export class FoodOfferComponent implements OnChanges {
  @Input() public offer: Offer;
  @Output() private add: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output() public remove: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output() public view: EventEmitter<Offer> = new EventEmitter<Offer>();

  public name: string;
  public offerType: string;
  public description: string;
  public minCartPriceText: string;
  public maxQuantityText: string;
  public preOfferPrice: number;
  public price: number;
  public instance: OfferInstance;
  public thumbnailUrl: string;
  public badgeOptions: PromoVisualOptions;

  ngOnChanges(changes: SimpleChanges): void {
    this.offer = changes.offer.currentValue as Offer;
    this.instance = createOfferInstance(this.offer);
    this.name = this.offer.name;
    this.description = this.offer.description;
    this.minCartPriceText = getOfferMinOrderItemsPriceText(this.offer);
    this.maxQuantityText = getOfferMaxQuantityPerOrderText(this.offer);
    this.preOfferPrice = this.instance.preOfferPrice;
    this.price = this.instance.offerType === 'fixedPrice' ? this.instance.basePrice : 0;
    this.thumbnailUrl = generateImageSrc(this.offer.mobileImage, '@1x');
    this.badgeOptions = this.offer.badgeOptions;
  }

  public onAdd(): void {
    this.add.emit(this.offer);
  }

  public onRemove(): void {
    this.remove.emit(this.offer);
  }
}
