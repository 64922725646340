import { Component, ChangeDetectionStrategy, HostBinding, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { OrderStatusService } from './order-status.service';
import { Order, OrderCompletionContactInfo, APIError, SingleBannerConfiguration } from '@box-types';
import { Subscription, pairwise, skip } from 'rxjs';
import {
  isOrderAccepted,
  isOrderPending,
  isOrderRejected,
  orderRequiresPolling,
  getOrderCompletionAddressContactInfo,
  getOrderCompletionPhoneContactInfo,
  saveBAFOrderAcceptanceBannerViewed
} from '@box/utils';
import { AnalyticsService, DialogService, OrdersService, PointsAnimationService } from '@box-core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'order-status',
  templateUrl: './order-status.page.html',
  styleUrls: ['./order-status.page.scss'],
  providers: [OrderStatusService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderStatusPage implements OnInit, OnDestroy {
  public order: Order;
  public loading: boolean;
  public addressContactInfo: Partial<OrderCompletionContactInfo>;
  public phoneContactInfo: Partial<OrderCompletionContactInfo>;
  public singleBannerConfig: SingleBannerConfiguration;
  public isSuperMarket: boolean;
  public showOrderStatusRating: boolean;
  public showContanctInfo: boolean;
  public showGBRewards: boolean;
  public showOrderDetails: boolean;
  public showOrderCompletionRatings: boolean;
  public orderPoints: string;

  private orderSubscription: Subscription;
  private orderStatusChangeSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orderStatusService: OrderStatusService,
    private dialogService: DialogService,
    private ordersService: OrdersService,
    private router: Router,
    private pointsAnimationService: PointsAnimationService,
    private analyticsService: AnalyticsService
  ) {}

  @HostBinding('class') public pageWrapper = 'page-wrapper order-status';

  ngOnInit(): void {
    this.setOrderSubscription();
    this.setOrderStatusChangeSubscription();
    this.initialOrderLoad();
    // This will be removed when we implement the In App Payment Dialog (iFrame)
    window.sessionStorage.removeItem('Box:promoCampaignShowed');
  }

  ngOnDestroy(): void {
    this.orderSubscription?.unsubscribe();
    this.orderStatusChangeSubscription?.unsubscribe();
  }

  private setOrderStatusChangeSubscription(): void {
    this.orderStatusChangeSubscription = this.orderStatusService.order$
      .pipe(skip(1), pairwise())
      .subscribe(([previousValue, currentValue]) => {
        const isPreviousStatusPending = isOrderPending(previousValue);
        const isCurrentStatusAccepted = isOrderAccepted(currentValue);
        const isCurrentStatusRejected = isOrderRejected(currentValue);
        if (isPreviousStatusPending && isCurrentStatusAccepted) {
          /* The auto accepted order flow is missing here. We might wanna consider hiding the
          Points Element from the Header so that when we show it after the user visits any
          other page again, the endpoint will update the state */
          this.orderStatusService.addUserPointsAfterAcceptance(currentValue);
          this.orderStatusService.handleCouponsUpdate(currentValue);
          this.handleAnalyticsEvent(currentValue);
        }

        if (isPreviousStatusPending && isCurrentStatusRejected) {
          this.orderStatusService.handleUserPointsRefund(currentValue);
        }
      });
  }

  private setOrderSubscription(): void {
    this.orderSubscription = this.orderStatusService.order$.pipe(skip(1)).subscribe((order) => {
      this.order = order;
      this.ordersService.updateOrderHistory([order]);
      this.showOrderStatusRating = this.orderStatusService.shouldShowOrderStatusRating(order);
      this.showContanctInfo = this.orderStatusService.shouldShowContactInfo(order);
      if (this.showContanctInfo) {
        this.addressContactInfo = getOrderCompletionAddressContactInfo(this.order);
        this.phoneContactInfo = getOrderCompletionPhoneContactInfo(this.order);
      }
      this.handleSingleBannerConfigGeneration();
      this.orderPoints = this.pointsAnimationService.getOrderPointsBalanceText(order);
      this.showGBRewards = this.orderStatusService.shouldShowGBRewards(order);
      this.showOrderDetails = this.orderStatusService.shouldShowOrderDetails(order);
      this.showOrderCompletionRatings = this.orderStatusService.shouldShowOrderCompletionRatings(order);
      if (!orderRequiresPolling(order)) this.orderStatusService.stopOrderPolling();
      this.changeDetectorRef.detectChanges();
    });
  }

  private initialOrderLoad(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.orderStatusService.fetchOrder().subscribe({
      next: (order) => {
        this.orderStatusService.setOrder(order);
        this.isSuperMarket = order.shop.isSuperMarket;
        this.orderStatusService.setMetaTags(order);
        if (orderRequiresPolling(order)) this.orderStatusService.startOrderPolling();
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      },
      error: (error: APIError) =>
        this.dialogService
          .openErrorDialog(error)
          .afterClosed()
          .subscribe(() => void this.router.navigate(['/home']))
    });
  }

  /* This might need some styling refactor when we start adding the new elements for the Live Tracking
  @vasiliadis, check this when you handle the Maps on this page */
  private handleSingleBannerConfigGeneration(): void {
    if (this.singleBannerConfig) return;
    this.singleBannerConfig = this.orderStatusService.generateSingleBannerConfig(this.order);
    if (!this.singleBannerConfig) return;
    saveBAFOrderAcceptanceBannerViewed();
  }

  private handleAnalyticsEvent(order: Order): void {
    const pointsEarned = order.marketPlacePoints?.collected;
    if (!pointsEarned) return;
  }

  public onBenefitsBannerClick(): void {
    this.triggerAnalyticsEvent();
    void this.router.navigate(['/rewards']);
  }

  private triggerAnalyticsEvent(): void {
    this.analyticsService.addGACustomEvent('accepted_order_points_tapped', {});
  }
}
