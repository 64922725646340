import { Injectable } from '@angular/core';
import { CoreService } from '@box-core/services';
import { Shop, Coupon } from '@box-types';
import {
  getCouponDetailsDescription,
  getCouponDetailsExpirationDateText,
  getCouponDetailsRedemptionDateText,
  getCouponDetailsRemainingValueText,
  getCouponDetailsShops,
  getCouponDetailsCtaTitle
} from '@box/utils';

@Injectable()
export class CouponDetailsDialogService {
  constructor(private coreService: CoreService) {}

  public getCouponDetailsDescription(coupon: Coupon): string {
    return getCouponDetailsDescription(coupon);
  }

  public getCouponDetailsExpirationDateText(coupon: Coupon): string {
    return getCouponDetailsExpirationDateText(coupon);
  }

  public getCouponDetailsRedemptionDateText(coupon: Coupon): string {
    return getCouponDetailsRedemptionDateText(coupon);
  }

  public getCouponDetailsRemainingValueText(coupon: Coupon): string {
    return getCouponDetailsRemainingValueText(coupon);
  }

  public getCouponDetailsShops(coupon: Coupon, shops: Shop[]): Shop[] {
    const cuisines = this.coreService.cuisines.getValue();
    const chains = this.coreService.chains.getValue();
    return getCouponDetailsShops(coupon, shops, cuisines, chains);
  }

  public getCouponDetailsCtaTitle(coupon: Coupon): string {
    return getCouponDetailsCtaTitle(coupon);
  }
}
