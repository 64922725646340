import { Injectable } from '@angular/core';
import { ConfigurationService } from '@box-core/services';
import { Order } from '@box-types';
import { generateImageSrc, currencyFormat, getOrderTotalDiscountPrice } from '@box/utils';

@Injectable()
export class OrderService {
  constructor(private configService: ConfigurationService) {}

  public getOrderSMLogo(order: Order): string {
    const smLoyaltyInfo = this.configService.getConfiguration()?.smLoyaltyInfo;
    if (!smLoyaltyInfo?.length) return;
    const integratorData = smLoyaltyInfo.find((info) => info.integrator === order.shop.integrator?.company);
    if (!integratorData) return;
    return generateImageSrc(integratorData.orderHistoryLogo);
  }

  public getOrderItems(order: Order): { name: string; quantity: number }[] {
    const offers = (order.offers ?? []).map((o) => ({ name: o.name, quantity: o.quantity }));
    const products = (order.products ?? []).map((p) => ({ name: p.name, quantity: p.quantity }));
    return [...offers, ...products];
  }

  public getOrderShopLogoSrc(order: Order): string {
    return generateImageSrc(order.shop.logo);
  }

  public getTotalDiscountText(order: Order): string {
    if (!order) return;
    const discountPrice = getOrderTotalDiscountPrice(order);
    if (discountPrice <= 0) return;
    return currencyFormat(discountPrice, { symbolSpace: false, minimumFractionDigits: 2 });
  }
}
