import { User, RewardsHistoryEarning } from '@box-types';

function showHistoryEarnings(user: User): boolean {
  const earnedRewardsInfo = user.earnedRewardsInfo;
  if (!earnedRewardsInfo) return false;
  const { earnedGB, earnedGiftcards, earnedEuros } = earnedRewardsInfo;
  return Boolean(earnedGB) || Boolean(earnedGiftcards) || Boolean(earnedEuros);
}

function getHistoryEarningsEuro(user: User): RewardsHistoryEarning {
  const earnedEuros = user.earnedRewardsInfo?.earnedEuros;
  if (!earnedEuros) return;
  const formatOptions = { style: 'currency', currency: 'EUR' };
  const title = new Intl.NumberFormat('el-GR', formatOptions).format(earnedEuros);
  return { title, description: 'από πόντους και κουπόνια' };
}

function getHistoryEarningsGB(user: User): RewardsHistoryEarning {
  const earnedGB = user.earnedRewardsInfo?.earnedGB;
  if (!earnedGB) return;
  return { title: `${earnedGB} GB`, description: 'για το κινητό σου' };
}

function getGBColorSchemeFamily(user: User): string {
  const userSegment = user?.mainCosmoteAssetSegment;
  if (userSegment === 'whatsup') return 'whats-up';
  if (userSegment === 'whatsupstudent') return 'whats-up';
  return 'cosmote';
}

export { showHistoryEarnings, getHistoryEarningsEuro, getHistoryEarningsGB, getGBColorSchemeFamily };
