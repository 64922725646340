<section class="checkout-page-section">
  <div class="container">
    <div class="checkout-container">
      <div class="checkout-summary">
        <h3 class="checkout-summary-title">Η παραγγελία μου</h3>
        <div class="checkout-block">
          <checkout-points
            *ngIf="showCheckoutPoints"
            [disabled]="checkoutPointsDisabled"
            [pointsToEurosRatio]="shop?.loyaltyPointsToRedeemRate"
            [euroRedemptionRate]="shop?.loyaltyRuleStepInEuroForRedemption"
            [cartPrice]="cartStartingPrice"
            [cartPriceWhichCanBeDiscounted]="cartPriceWithPureDiscounts"
            [totalDiscount]="totalDiscount"
            (valueChange)="onCheckoutPointsValueChange($event)"
          ></checkout-points>
          <checkout-market-points
            *ngIf="hasLoyaltyCard"
            class="checkout-market-points"
            [shop]="shop"
            (cardConnected)="onMarketCardConnected()"
          ></checkout-market-points>
          <bank-points-redeem-toggle
            *ngIf="paymentType?.card?.cardDetailsAddedByBox?.loyalty?.canRedeem"
            [cartPrice]="cartPriceWithPureDiscounts"
            [cartPriceWhichCanBeDiscounted]="cartPriceWithPureDiscounts - totalDiscount"
            [card]="paymentType.card"
          ></bank-points-redeem-toggle>
          <checkout-coupon class="checkout-coupon" [startingPrice]="priceCoveredByCoupon"></checkout-coupon>
          <payment-synergy-banner [selectedPaymentType]="paymentType"></payment-synergy-banner>
        </div>
        <div class="checkout-block">
          <h5 class="checkout-block-title">Πληρωμή με</h5>
          <checkout-payment-types #paymentTypes [shop]="shop" [cartTotal]="totalPrice"></checkout-payment-types>
          <checkout-tip *ngIf="tipEnabled" (tipChange)="onTipChange($event)"></checkout-tip>
          <checkout-donation *ngIf="donationEnabled" (donationChange)="onDonationChange($event)"></checkout-donation>
        </div>

        <div class="checkout-block">
          <h5 class="checkout-block-title">Παράδοση</h5>
          <delivery-method class="checkout-delivery-method"></delivery-method>
          <checkout-addresses [shop]="shop" (resetAddress)="onResetAddress()"></checkout-addresses>
          <div class="box-divider"></div>
          <ng-container *ngIf="showScheduledSection">
            <checkout-timeslot [shop]="shop" [checkDaasAvailability]="checkDaasAvailability"></checkout-timeslot>
            <div class="box-divider"></div>
          </ng-container>
        </div>
        <div *ngIf="hasInvoiceOption" class="checkout-block">
          <checkout-invoice class="checkout-invoice"></checkout-invoice>
          <div class="box-divider"></div>
        </div>
        <div *ngIf="bagsConfig?.hasPlasticBagSelection" class="checkout-block">
          <checkout-bags [bagsConfig]="bagsConfig" (valueChange)="onBagsChange($event)"></checkout-bags>
          <div class="box-divider"></div>
        </div>
        <div *ngIf="hasReplacementOption" class="checkout-block">
          <checkout-product-replacement class="chaeckout-product-replacement"></checkout-product-replacement>
          <div class="box-divider"></div>
        </div>
        <div class="checkout-block">
          <h5 class="checkout-block-title">Τηλέφωνο επικοινωνίας</h5>
          <mat-form-field class="phone-field" [floatLabel]="'never'">
            <input
              class="checkout-input"
              matInput
              [formControl]="phoneFormControl"
              cdkFocusRegionStart
              cdkFocusInitial
              aria-label="phone"
              placeholder="Πληκτρολόγησε ένα τηλέφωνο επικοινωνίας"
            />
            <mat-error *ngIf="phoneFormControl.errors?.required">Το τηλέφωνο επικοινωνίας είναι υποχρεωτικό</mat-error>
            <mat-error *ngIf="phoneFormControl.errors?.invalidPhone"
              >Το τηλέφωνο επικοινωνίας δεν είναι σωστό
            </mat-error>
          </mat-form-field>
          <div class="checkout-block-header">
            <h5 class="checkout-comment-title ods-typography-body-s-bold">Σχόλιο</h5>
            <mat-slide-toggle
              color="primary"
              class="checkout-block-toggle"
              (change)="onChangeSlider()"
            ></mat-slide-toggle>
          </div>
          <mat-form-field *ngIf="hasComment; else commentDivider" class="comment-field" [floatLabel]="'never'">
            <textarea
              type="text"
              matInput
              [formControl]="commentFormControl"
              matTextareaAutosize="true"
              placeholder="Πληκτρολόγησε εδώ το σχόλιο σου"
            ></textarea>
            <mat-hint align="end">{{ commentFormControl.value?.length }} / 500</mat-hint>
            <mat-error *ngIf="commentFormControl.errors?.invalid"
              >Οι χαρακτήρες, {{INVALID_COMMENT_MESSAGE_CHARS}}, δεν είναι έγκυροι.</mat-error
            >
            <mat-error *ngIf="commentFormControl.errors?.maxlength"
              >Το σχόλιο σου πρέπει να μην ξεπερνάει τους 500 χαρακτήρες!</mat-error
            >
          </mat-form-field>
          <ng-template #commentDivider>
            <div class="box-divider"></div>
          </ng-template>
        </div>

        <cart-button
          class="checkout-button"
          (click)="onOrderSubmit()"
          [pointsSum]="pointsSum"
          [multiplierSum]="multiplierSum"
          [disabled]="!canSubmitOrder"
          [quantity]="cartTotalQuantity"
          [price]="totalPrice / 100"
          >{{cartCtaTitle}}</cart-button
        >
      </div>
      <div class="checkout-page-separator"></div>
      <div class="checkout-order-preview-container">
        <h4 class="checkout-order-preview-container-title ods-typography-subtitle-l">Τα προϊόντα μου</h4>
        <checkout-suggestion-banners
          *ngIf="checkoutSuggestionBanners?.length"
          [checkoutSuggestionBanners]="checkoutSuggestionBanners"
          (bannerTrigger)="onCheckoutSuggestionBannerTrigger($event)"
        ></checkout-suggestion-banners>
        <div class="checkout-block">
          <checkout-order-preview></checkout-order-preview>
        </div>
      </div>
    </div>
  </div>
</section>
