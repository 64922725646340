import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { SentryService, LoaderService } from '@box-core/services';
import { Location, Shop, ShopDetails, APIError } from '@box-types';
import { finalize } from 'rxjs/operators';
import { ShopExtraInfoService } from './shop-extra-info.service';
import { ShopExtraInfoLocationData } from './shop-extra-info.types';

@Component({
  selector: 'shop-extra-info',
  templateUrl: './shop-extra-info.component.html',
  styleUrls: ['./shop-extra-info.component.scss'],
  providers: [ShopExtraInfoService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShopExtraInfoComponent implements OnInit {
  @Input() public shopDetails: ShopDetails;
  @Input() private shop: Shop;

  public descriptionHTML: string;
  public extraInfoLocationData: ShopExtraInfoLocationData;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private shopExtraInfoService: ShopExtraInfoService,
    private sentryService: SentryService,
    private loaderService: LoaderService
  ) {}

  @HostBinding('class') public hostClass = 'shop-extra-info';

  ngOnInit(): void {
    this.descriptionHTML = this.shopExtraInfoService.getShopDescriptionHTML(this.shop, this.shopDetails);
    this.setLocationDataSubscription();
  }

  private setLocationDataSubscription(): void {
    this.loaderService.setState(true);
    this.shopExtraInfoService
      .getShopLocationData(this.shop, this.shopDetails.location as Location)
      .pipe(finalize(() => this.loaderService.setState(false)))
      .subscribe({
        next: (extraInfoLocationData) => {
          this.extraInfoLocationData = extraInfoLocationData;
          this.changeDetectorRef.detectChanges();
        },
        error: (error: APIError) =>
          this.sentryService.captureException(error, {
            domain: 'Delivery',
            domainDetails: 'Shop Extra Info Location Data',
            severity: 'error'
          })
      });
  }
}
